import { usePresignedUpload, uuid } from 'next-s3-upload'
import React from 'react'
import { toast } from '../components'

/**
 * Represents an uploaded image
 * @interface StudioImage
 */
export type StudioImage = {
  /**
   * @description
   * The unique identifier of the image.
   * It can be either a number or a string.
   * We use array index as the id for temporary images.
   * @type {number | string}
   */
  id: number | string

  /**
   * @description
   * The binary large object (BLOB) data of the image.
   * This property is used to store temporary image data, often in Base64 format.
   * Uploaded images will have this property set to the URL where the image is hosted.
   * If the image is done uploading, this property will be null.
   * @type {string}
   */
  blob: string | null

  /**
   * @description
   * The URL where the image is hosted.
   * If the image is not uploaded or available online, it can be null.
   * An empty string means the image is not uploaded / be processed yet.
   * @type {string | null}
   */
  url: string
}

interface ImageUploader {
  callbacks: {
    onAdded?: (files: File[]) => void | Promise<void>
    onUploaded: (urls: string[]) => void | Promise<void>
    onRemove: (url: string) => void | Promise<void>
  }
  config: {
    workspaceGuid?: string
    allowedMimeTypes?: string[]
  }
}

const useImageUploader = (data: StudioImage[], props: ImageUploader) => {
  const [isDragActive, setIsDragActive] = React.useState(false)
  const [images, setImages] = React.useState<StudioImage[]>(data)
  const { allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'], ...config } =
    props.config

  const { uploadToS3 } = usePresignedUpload()

  const handleDrag = <T extends HTMLElement>(e: React.DragEvent<T>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }

  const handleDrop = <T extends HTMLElement>(e: React.DragEvent<T>) => {
    e.preventDefault()
    e.stopPropagation()

    if (isDragActive) setIsDragActive(false)

    if (!config.workspaceGuid) return console.error('Workspace guid is not provided')

    if (!e.dataTransfer.files && !e.dataTransfer.files[0]) {
      toast.error({
        title: 'No files',
        description: 'No files found',
      })
      return
    }

    const files = Array.from(e.dataTransfer.files)

    handlePreupload(files)
  }

  const handleFilter = (files: File[]) => {
    return files.filter((file) => {
      const isInvalid = !allowedMimeTypes.includes(file.type)

      if (isInvalid) {
        toast.error({
          title: 'Invalid image type',
          description: `Invalid image type for: ${file.name}. Allowed image types are: ${allowedMimeTypes
            .map((type) => type.split('/')[1])
            .join(', ')}`,
        })
        return false
      }

      return true
    })
  }

  const handleLoad = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!e.target.files) return

    const files = Array.from(e.target.files)

    handlePreupload(files)
  }

  const handleUpload = async (
    file: File,
    callbacks: { onSuccess: (url: string) => void; onError: (file: File) => void }
  ) => {
    try {
      const { workspaceGuid } = config

      if (!workspaceGuid) throw new Error('Workspace guid is not provided')

      const { url } = await uploadToS3(file, {
        endpoint: {
          request: {
            body: {
              workspaceGuid,
            },
          },
        },
      })

      callbacks.onSuccess(url)

      return url
    } catch (e) {
      callbacks.onError(file)
      const err = e instanceof Error ? e : JSON.stringify(e)

      toast.error({
        title: 'Failed to upload image',
        description: `${err.toString()}`,
      })

      console.error(err)
    }
  }

  const handlePreupload = (files: File[]) => {
    // eslint-disable-next-line prefer-const
    let uploadedImages: StudioImage[] = []

    const filteredFiles = handleFilter(files)

    if (typeof props.callbacks.onAdded === 'function' && props.callbacks.onAdded) {
      void props.callbacks.onAdded(filteredFiles)
    }

    for (const file of filteredFiles) {
      const reader: FileReader = new FileReader()

      reader.readAsDataURL(file)
      reader.onabort = () => console.error('Abort read: ', file.name)
      reader.onerror = () =>
        toast.error({
          title: 'Failed to load the image',
          description: `Try to upload the image again: ${file.name} from different source`,
        })
      reader.onload = () => {
        const init: StudioImage = {
          id: uuid(),
          url: '',
          blob: reader ? (reader.result ? reader.result.toString() : null) : null,
        }

        setImages((prev) => [...prev, init])

        void handleUpload(file, {
          onSuccess: (url) => {
            const update: StudioImage = {
              ...init,
              url,
            }

            setImages((prev) =>
              prev.map((x, id) =>
                x.id === init.id
                  ? {
                      ...x,
                      ...update,
                      id: id + 1,
                    }
                  : x
              )
            )

            uploadedImages.push(update)

            const urls = uploadedImages
              .map((x) => x.url ?? x.blob)
              .filter((item): item is string => item !== null && item !== '')

            if (urls.length > 0) {
              void props.callbacks.onUploaded(urls)
            }
          },
          onError: () => setImages((prev) => prev.filter((x) => x.id !== init.id)),
        })
      }
    }
  }

  const handleRemove = (url: string) => {
    toast.info({
      title: 'Image removed',
      description: 'Successfully removed the image',
    })

    const newImages = images.filter((i) => i.url !== url)

    setImages(newImages)
    void props.callbacks.onRemove(url.replace('/api/files/download?fileUrl=', ''))
  }

  return {
    images,
    isDragActive,
    handleDrag,
    handleDrop,
    handleLoad,
    handleRemove,
  }
}

export { useImageUploader }
