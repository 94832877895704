import { SaveIcon } from '@/client/assets/icons/icons'
import { Button } from '@/client/components'
import type { StudioContextState } from '@/client/containers/views/Studio/context/types'
import { useActionSave } from './logic'

export const SaveButtonBase = ({
  action,
}: {
  action: NonNullable<StudioContextState['action']>
}) => {
  const { state, handleSubmit } = useActionSave(action)

  return (
    <Button
      variant="outline"
      disabled={state.isSubmitting || !state.isEdited}
      className={`focus:z-10 hover:z-10`}
      startIcon={SaveIcon}
      onClick={() => handleSubmit()}
    >
      Save
    </Button>
  )
}
