import { z } from 'zod'

export const transformDatasetItemsFormSchema = z
  .object({
    selectAll: z.boolean(),
    datasetId: z.number(),
    datasetItemIds: z.array(z.number()).optional(),
    transformation: z.enum(['redact_pii', 'strip_whitespace', 'llm_rewrite']),
    attribute: z.enum(['input', 'output']),
    config: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.transformation === 'llm_rewrite') {
      if (!data?.config) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['config'],
          message: 'This is required',
        })
      }
    }
  })
