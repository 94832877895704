import { SaveIcon } from '@/client/assets/icons/icons'
import { Button } from '@/client/components'
import { NewDialog } from '@/client/containers/views/Studio/components/Topbar/components/SaveAction/components/New/components'
import { useNewActionForm } from '@/client/containers/views/Studio/components/Topbar/components/SaveAction/components/New/form'
import { useNewActionDialog } from '@/client/containers/views/Studio/components/Topbar/components/SaveAction/components/New/logic'
import { SaveButtonBase } from '@/client/containers/views/Studio/components/Topbar/components/SaveAction/components/SaveButtonBase'
import { useStudio } from '@/client/containers/views/Studio/context'

export const SaveAction = ({
  redirectOnSuccess = true,
  deployAction = false,
}: {
  redirectOnSuccess?: boolean
  deployAction?: boolean
}) => {
  const {
    state: { action },
  } = useStudio()

  const { isOpenNewActionDialog, onIsOpenNewActionDialogChange } = useNewActionDialog()
  const { formik } = useNewActionForm({
    onIsOpenNewActionDialogChange,
    redirectOnSuccess,
    deployAction,
  })

  return (
    <>
      <NewDialog
        isOpen={isOpenNewActionDialog}
        formik={formik}
        onIsOpenChange={(open) => {
          onIsOpenNewActionDialogChange(open)

          if (!open) {
            formik.resetForm()
          }
        }}
      />
      {action ? (
        <div className="inline-flex rounded-md">
          <SaveButtonBase action={action} />
        </div>
      ) : (
        <Button
          variant="outline"
          startIcon={SaveIcon}
          onClick={() => onIsOpenNewActionDialogChange(true)}
        >
          Save
        </Button>
      )}
    </>
  )
}
