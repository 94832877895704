import type { ReactNode } from 'react'
import React from 'react'

export type TabType = 'versions' | 'api'

interface TabContextType {
  selectedTab: TabType
  setSelectedTab: (tab: TabType) => void
}

export const TabContext = React.createContext<TabContextType | undefined>(undefined)

export const TabProvider = ({ children }: { children: ReactNode }) => {
  const [selectedTab, setSelectedTab] = React.useState<TabType>('versions')

  return (
    <TabContext.Provider value={{ selectedTab, setSelectedTab }}>{children}</TabContext.Provider>
  )
}

export const useDeployTab = (): TabContextType => {
  const context = React.useContext(TabContext)
  if (!context) {
    throw new Error('useTab must be used within a TabProvider')
  }
  return context
}
