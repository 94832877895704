import type { TopbarRootProps } from './types'

const TopbarRoot = ({ children }: React.PropsWithChildren<TopbarRootProps>) => {
  return (
    <nav className="sticky top-0 flex h-16 items-center border-b border-grey-200 bg-white bg-opacity-50 px-3 backdrop-blur dark:border-grey-700 dark:bg-black">
      {children}
    </nav>
  )
}

export { TopbarRoot }
