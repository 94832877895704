import React from 'react'

const useCopy = (
  text: string | undefined,
  cb?: {
    onSuccess?: () => void
    onError?: (err: Error) => void
  }
) => {
  const [isCopied, setCopied] = React.useState(false)

  const copy = React.useCallback(() => {
    if (!text) return

    if (!navigator.clipboard) return

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true)
        cb?.onSuccess?.()
        setTimeout(() => setCopied(false), 2000)
      })
      .catch((err) => {
        cb?.onError?.(err as Error)
      })
  }, [text, cb])

  return {
    copy,
    isCopied,
  }
}

export default useCopy
