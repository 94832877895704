import type { EvalType } from '@prisma/client'
import { ComboboxSingleV2 } from '@/client/components/Combobox/SingleV2'
import type { EvalTypeMetadata } from '@/common/types/eval'
import { getEvalIcon } from '@/pages/[workspaceSlug]/apps/[slug]/actions/[actionSlug]/monitors'

export const AddEvalTypeInput = ({
  evalTypes,
  selectedEvalType,
  children,
  addEvalType,
  matchTargetWidth,
}: {
  evalTypes: EvalType[]
  selectedEvalType?: number | null
  setSelectedEvalType: (evalTypeId: number) => void
  addEvalType: (evalTypeId: number) => void
  children?: React.ReactNode
  matchTargetWidth?: boolean
}) => {
  return (
    <>
      <ComboboxSingleV2
        placeholder="Select an evaluation type to add"
        value={selectedEvalType?.toFixed()}
        options={evalTypes.map((evalType) => {
          const { description, icon } = evalType.metadata as EvalTypeMetadata
          const evalIcon = getEvalIcon(icon)
          return {
            value: evalType.id.toFixed(),
            label: evalType.name,
            description: description,
            icon: evalIcon ?? undefined,
          }
        })}
        content={{
          align: 'start',
          matchTargetWidth: matchTargetWidth,
        }}
        item={{
          icon: {
            style: 'outline',
          },
        }}
        search={{
          style: 'outline',
          placeholder: 'Search evaluation types',
        }}
        onChange={(option) => {
          if (option) {
            addEvalType(parseFloat(option))
          }
        }}
      >
        {children}
      </ComboboxSingleV2>
    </>
  )
}
