import type { Workspace } from '@prisma/client'
import { Avatar, AvatarImage } from '@/client/components'

interface WorkspaceLogoProps extends React.HTMLAttributes<HTMLDivElement> {
  workspace: Workspace
  className?: string
}

export function WorkspaceLogo({ workspace, className }: WorkspaceLogoProps) {
  if (workspace.logoUrl) {
    return (
      <Avatar className={className}>
        <AvatarImage src={workspace.logoUrl} alt={workspace.name} />
        {/* <AvatarFallback>{shortName()}</AvatarFallback> */}
      </Avatar>
    )
  }

  return (
    <Avatar className={className}>
      <AvatarImage src="/klu.svg" alt={workspace.name} />
      {/* <AvatarFallback>{shortName()}</AvatarFallback> */}
    </Avatar>
  )
}
