import { ScaleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@/client/components'
import { useFetchEvalRunItemsForEvalPaginated } from '@/common/hooks'
import type { DetailedEval } from '@/common/types/eval'
import { EvalCharts } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]'
import { classNames } from '@/utils/classNames'
import { EvalRunItemsGrid } from '../../evalRuns/grid/EvalRunItemsGrid'
import { useWorkspace } from '../../ui/context'
import { MonitorSettings } from './MonitorSettings'

export const MonitorDrawer = ({
  evalRecord,
  setOpen,
  open,
  initiallySelectedTab,
}: {
  evalRecord: DetailedEval
  open: boolean
  setOpen: (val: boolean) => void
  initiallySelectedTab?: string
}) => {
  const workspace = useWorkspace()
  const [selectedTab, setSelectedTab] = useState(initiallySelectedTab || 'overview')

  const { evalRunItems, isLoading } = useFetchEvalRunItemsForEvalPaginated({
    evalId: evalRecord.id,
    page: 1,
    perPage: 500,
  })

  const tabs = [
    {
      name: 'Overview',
      id: 'overview',
    },
  ]

  tabs.push({
    name: 'Results',
    id: 'evalRunItems',
  })
  tabs.push({
    name: 'Settings',
    id: 'settings',
  })

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="max-w-4xl sm:max-w-5xl">
        <SheetHeader>
          <SheetTitle
            className="text-grey-700 font-medium text-md flex gap-1 items-center 
          "
          >
            <ScaleIcon className="h-4 w-4" />
            Evaluate {evalRecord.name}
          </SheetTitle>
          <SheetDescription>
            <div>
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>

                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full rounded-md border-grey-300 py-2 pl-3 pr-10 text-base focus:border-grey-500 focus:outline-none focus:ring-grey-500 sm:text-sm"
                  defaultValue={tabs.find((tab) => tab.id === selectedTab)?.name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-grey-300">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {workspace &&
                      tabs.map((tab) => (
                        <div
                          key={tab.name}
                          onClick={() => setSelectedTab(tab.id)}
                          className={classNames(
                            selectedTab === tab.id
                              ? 'border-grey-800 text-grey-800'
                              : 'border-transparent text-grey-500 hover:border-grey-300',
                            'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium cursor-pointer'
                          )}
                        >
                          {tab.name}
                        </div>
                      ))}
                  </nav>
                </div>
              </div>
            </div>

            <div className="py-6">
              {selectedTab == 'overview' && <EvalCharts evalRecord={evalRecord} />}
              {!isLoading && evalRunItems && selectedTab == 'evalRunItems' && (
                <EvalRunItemsGrid evalRecord={evalRecord} evalRunItems={evalRunItems} />
              )}
              {selectedTab == 'settings' && <MonitorSettings evalRecord={evalRecord} />}
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}
