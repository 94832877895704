import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import useBreakpoint from '@/client/hooks/useBreakpoint'
import { useResizableSidebar } from '@/client/utils/resizableSidebar/useResizableSidebar'
import { Select } from '@/common/components'
import { classNames } from '@/utils'

interface PaginationProps {
  total: number
  perPage: number
  currentPage: number
  onPageChange: (page: number) => void
  setPerPage: (perPage: number) => void
  children?: React.ReactNode
}

export function Pagination({
  total,
  perPage,
  currentPage,
  onPageChange,
  setPerPage,
  children,
}: PaginationProps) {
  const totalPages = Math.ceil(total / perPage)

  const hasNext = currentPage < totalPages

  const { sidebarWidth } = useResizableSidebar()

  const { width } = useBreakpoint()

  if (totalPages <= 1) {
    return null
  }

  return (
    <div className="relative overflow-hidden md:w-full">
      <nav
        style={width < 768 ? { width: '100%' } : { width: `calc(100% - ${sidebarWidth}px)` }}
        className={`max-w-screen md:ml-auto w-screen bottom-0 flex z-10 gap-2 items-center justify-between border-t border-grey-200 bg-white bg-opacity-50 px-3 py-3 backdrop-blur fixed left-0 right-0`}
      >
        <div className="flex">
          <p className="text-xs uppercase">
            Showing <span className="font-medium">Page {currentPage}</span> of {totalPages}
          </p>
        </div>

        <div className="flex flex-1 justify-end space-x-2">
          {children}
          <div
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              disabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
              className={classNames(
                currentPage === 1
                  ? 'cursor-not-allowed bg-gray-50'
                  : 'bg-grey-50 hover:bg-grey-50 hover:text-grey-900',
                'relative inline-flex items-center rounded-l-md px-4 py-2 text-sm ring-1 ring-inset ring-grey-300 focus:z-20 focus:outline-offset-0'
              )}
            >
              <ChevronLeftIcon className="mr-2 h-3 w-3" aria-hidden="true" />
              <span>Previous</span>
            </button>

            <button
              disabled={!hasNext}
              onClick={() => {
                onPageChange(currentPage + 1)
              }}
              className={classNames(
                !hasNext
                  ? 'cursor-not-allowed bg-gray-50 text-grey-300'
                  : 'bg-grey-50 hover:bg-grey-50 hover:text-grey-900',
                'relative inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset ring-grey-300 focus:z-20 focus:outline-offset-0'
              )}
            >
              <span>Next</span>
              <ChevronRightIcon className="ml-2 h-3 w-3" aria-hidden="true" />
            </button>
          </div>
          <div className="min-w-[150px] max-w-lg">
            <Select.Single
              position="above"
              defaultOption={{ id: perPage, name: `Show ${perPage}` }}
              fieldName="pageSize"
              placeholder="Set a page size"
              onSelect={(option) => {
                if (option) {
                  setPerPage(Number(option.id))
                  onPageChange(1)
                }
              }}
              options={[10, 20, 50, 100, 200].map((pageSize) => {
                return {
                  id: pageSize,
                  name: `Show ${pageSize.toString()}`,
                }
              })}
              showAvatars={false}
            />
          </div>
        </div>
      </nav>
    </div>
  )
}
