import type { CustomTooltipProps } from '@tremor/react'
import { AreaChart } from '@tremor/react'
import { useState } from 'react'
import { Loader } from '@/client/components'
import { useFetchEvalResultsByRun } from '@/common/hooks'
import type { DetailedEval } from '@/common/types/eval'
import { EvalRunDrawer } from '../evalRuns/EvalRunDrawer'

const dataFormatter = (number: number) => {
  return Intl.NumberFormat('us').format(Math.abs(number)).toString()
}

const customTooltip = (props: CustomTooltipProps) => {
  const { payload, active } = props
  if (!active || !payload) return null

  const firstPayload = (
    payload[0] as {
      payload?: {
        groupName: string
        versionName: string
      }
    }
  ).payload

  const groupName = firstPayload?.groupName
  const versionName = firstPayload?.versionName

  return (
    <div className="w-56 rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
      <div className="flex flex-col gap-1">
        <p className=" text-tremor-content-emphasis font-semibold">{groupName}</p>
        <p className=" text-xs text-grey-400">{versionName}</p>

        {payload.map((category, idx) => (
          <ToolTipContent category={category} key={idx} />
        ))}
      </div>
    </div>
  )
}

const ToolTipContent = ({
  category,
}: {
  category: {
    color?: string | undefined
    value?: string | number | (string | number)[] | undefined
    dataKey?: string | number | undefined
    payload?: {
      groupName: string
      versionName: string
    }
  }
}) => {
  return (
    <div className="flex flex-1 space-x-2.5">
      <div className={`flex w-1 flex-col bg-${category?.color || 'grey'}-500 rounded`} />
      <div className="space-y-1">
        <p className="text-tremor-content font-semibold">{category.dataKey}</p>
        <p className="font-medium text-tremor-content-emphasis">{category.value}</p>
      </div>
    </div>
  )
}

export const EvalScoreChart = ({ evalRecord }: { evalRecord: DetailedEval }) => {
  const [selectedEvalRunId, setSelectedEvalRunId] = useState<number | null>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const { data, isLoading } = useFetchEvalResultsByRun({
    evalId: evalRecord.id,
  })

  if (isLoading || !data) {
    return (
      <div className="flex justify-center h-[250px] items-center">
        <Loader className="h-4 w-4 text-grey-400" />
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <>
        <h4 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          Eval Scores
        </h4>
        <div className="flex justify-center h-[250px] items-center text-grey-400">No data.</div>
      </>
    )
  }

  const categories = data.reduce((acc, { evalTypeName }) => {
    const name = evalTypeName.includes('Latency') ? 'Latency (% passed)' : evalTypeName
    acc.add(name)
    return acc
  }, new Set<string>())

  interface EvalResultAccumulator {
    [key: string]: { [key: string]: number | string }
  }

  const chartData = data.reduce<EvalResultAccumulator>(
    (acc, { groupName, avg, evalTypeName, createdAt, versionName, evalRunId }) => {
      const name = `Run #${groupName} - ${createdAt.toDateString()}`
      if (!acc[name]) {
        acc[name] = {}
      }
      const temp = acc[name] || {}

      const evalScoreName = evalTypeName.includes('Latency') ? 'Latency (% passed)' : evalTypeName
      temp[evalScoreName] = +Number(avg).toFixed(2) // unary plus operator to convert to number
      temp['versionName'] = versionName
      temp['evalRunId'] = evalRunId
      acc[name] = temp

      return acc
    },
    {} as EvalResultAccumulator
  )

  const chartDataMapped = Object.entries(chartData).map(([groupName, values]) => {
    return {
      groupName,
      ...values,
    }
  })

  return (
    <>
      {selectedEvalRunId && (
        <EvalRunDrawer evalRunId={selectedEvalRunId} open={drawerOpen} setOpen={setDrawerOpen} />
      )}
      Eval Run Scores
      {chartDataMapped.length > 0 && (
        <AreaChart
          enableLegendSlider={true}
          className="h-[250px]"
          showLegend={true}
          startEndOnly={true}
          showTooltip={true}
          index="groupName"
          data={chartDataMapped}
          categories={Array.from(categories)}
          valueFormatter={dataFormatter}
          connectNulls={true}
          customTooltip={customTooltip}
          onValueChange={(v) => {
            if (!v) return

            v && v.evalRunId && setSelectedEvalRunId(v.evalRunId as number)
            setDrawerOpen(true)
          }}
        />
      )}
    </>
  )
}
