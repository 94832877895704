import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
/* eslint-disable @next/next/no-img-element */
import type { ParsedUrlQueryInput } from 'querystring';
import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Composer, Thread } from '@liveblocks/react-comments';
import type { App, Workspace } from '@prisma/client';
import cronstrue from 'cronstrue';
import { format } from 'date-fns';
import { useFormik, type FormikHelpers } from 'formik';
import { useThreads } from 'liveblocks.config';
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Button, toast } from '@/client/components';
import { cn } from '@/client/utils';
import { DeleteContextModal, FieldError, Loader, OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { ProcessingStatus } from '@/common/components/contexts/ContextProcessingStatus';
import { RefreshContextButton } from '@/common/components/contexts/ContextRefreshButton';
import { ContextSchedule } from '@/common/components/contexts/ContextSchedule';
import { ContextSources } from '@/common/components/contexts/ContextSources';
import { ResponseModeSelect } from '@/common/components/contexts/ResponseModeSelect';
import { TextSplitterSelect } from '@/common/components/contexts/TextSplitterSelect';
import { Context404 } from '@/common/components/notFound/404';
import { FullLoaderPage } from '@/common/components/ui/FullLoaderPage';
import { GuidCopyButton } from '@/common/components/ui/GuidCopyButton';
import { useCurrentWorkspace, useFetchApps, useFetchContextActions, useFetchContextBySlug, useFetchContextSchedule, useUser } from '@/common/hooks';
import { useDebounce } from '@/common/hooks/debounce';
import { withContext } from '@/common/lib/ssr';
import { Room } from '@/common/Room';
import type { DetailedContext } from '@/common/types/context';
import { ContextSplitterConfigSchema, type ContextSplitterConfig, type SingleContextMetadataCache } from '@/server/service/types';
import { api, classNames } from '@/utils';
import { DocumentsTable, SearchForm, VectorSearchDocumentsTable } from './embeddings';
import { ContextTestForm } from './test';
import { ContextVersionsTable } from './versions';
export type ResponseModes = {
  [key: string]: {
    name: string;
    description: string;
  };
};
export const responseModes: ResponseModes = {
  search: {
    name: 'Search',
    description: "Searches for similar documents and returns their text as context. Doesn't synthesize an answer."
  },
  search_with_citations: {
    name: 'Search w/Citations',
    description: 'Identical to Search mode, but provides source citation metadata. Citations do not count against response length limit.'
  },
  refine: {
    name: 'Refine',
    description: 'Generates detailed answers by examining each document one by one. Each document is reviewed separately to ensure thoroughness.'
  },
  compact: {
    name: 'Compact',
    description: 'Summarizes information by fitting as much content as possible into a single overview. If the content is too long, it will be broken down and summarized in stages for clarity.'
  },
  tree_summarize: {
    name: 'Tree Summarize',
    description: 'Creates a summary by organizing information from different documents into a structured overview, similar to a tree diagram. This helps in understanding the main points at a glance.'
  },
  simple_summarize: {
    name: 'Simple Summarize',
    description: 'Provides a brief overview by shortening the content to fit a small space. Ideal for quick summaries without much detail.'
  },
  hyDe: {
    name: 'HyDE',
    description: 'HyDE is a method that first creates an imaginary document in response to a question. This imaginary document is then used to find the most relevant information, instead of using the question directly.'
  }
};
function ContextUpdateForm({
  context
}: {
  context: DetailedContext;
}) {
  const updateContext = api.context.update.useMutation();
  const refreshContext = api.context.refresh.useMutation();
  const {
    user
  } = useUser();
  const utils = api.useContext();
  const [isLoading, setLoading] = useState(false);
  function onSuccess() {
    toast.success({
      title: 'Context settings updated',
      description: 'Settings saved. Refresh embeddings to apply any document changes'
    });
  }
  const schema = z.object({
    name: z.string(),
    description: z.string().optional(),
    typeId: z.number(),
    responseMode: z.string(),
    responseLength: z.number().min(1).max(4096),
    similarityTopK: z.number().min(1).max(100),
    similarityCutoff: z.number().min(0).max(1).optional(),
    splitterConfig: ContextSplitterConfigSchema,
    rerankLlmTopN: z.number().optional()
  });
  type FormSchema = z.infer<typeof schema>;
  const saveContext = async (values: FormSchema, refreshAfterSave = false) => {
    setLoading(true);
    await updateContext.mutateAsync({
      ...values,
      contextId: context.id
    });
    if (refreshAfterSave) {
      await refreshContext.mutateAsync({
        id: context.id
      });
      await utils.context.getStatus.invalidate({
        id: context.id
      });
    }
    await utils.context.get.invalidate({
      id: context.id
    });
    setLoading(false);
  };
  const initialSplitterConfig = (context.splitterConfig as ContextSplitterConfig);
  const initialValues: FormSchema = {
    name: context.name,
    description: context.description,
    typeId: context.typeId,
    responseMode: context.responseMode,
    responseLength: context.responseLength,
    similarityTopK: context.similarityTopK,
    similarityCutoff: context.similarityCutoff || undefined,
    splitterConfig: initialSplitterConfig,
    rerankLlmTopN: context.rerankLlmTopN || undefined
  };
  const formik = useFormik<FormSchema>({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, {
      validateForm
    }: FormikHelpers<FormSchema>) => {
      const {
        ...formErrors
      } = await validateForm();
      if (formErrors && Object.keys(formErrors).length > 0) {
        return;
      }
      try {
        await saveContext(values);
        onSuccess();
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  });
  return <form onSubmit={formik.handleSubmit}>
      <div className="p-6">
        <div className="text-center sm:mt-0 sm:text-left">
          <div className="">
            <p className="text-lg font-medium">Search Settings</p>
          </div>

          <div className="mt-6">
            <p className="text-sm text-grey-700">Response Mode</p>
            <p className="mt-1 text-xs text-grey-500">Retrieval style, default is Search</p>
            <div className="mt-2">
              <div className="flex flex-row gap-6 relative group">
                <ResponseModeSelect formik={formik} responseModes={responseModes} setResponseMode={mode => {
                void formik.setFieldValue('responseMode', mode);
              }} responseMode={formik.values.responseMode} />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <p className="text-sm text-grey-700">Retrieval Length</p>
            <p className="mt-1 text-xs text-grey-500">
              Maximum number of Context tokens to retrieve
            </p>
            <div className="mt-2 grid grid-cols-4">
              <div className="col-span-1">
                <input name="responseLength" type="number" value={formik.values.responseLength} min="50" max="4096" className="w-80 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>
            </div>
            <FieldError fieldName="responseLength" formik={formik} />
          </div>

          <div className="mt-6">
            <p className="text-sm text-grey-700">Docs to Retrieve</p>
            <p className="text-xs text-grey-500 mt-1">Number of docs to retrieve as Context</p>
            <div className="mt-2 grid grid-cols-4">
              <div className="col-span-1">
                <input name="similarityTopK" type="number" value={formik.values.similarityTopK} min="1" max="100" className="w-80 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>
            </div>
            <FieldError fieldName="similarityTopK" formik={formik} />
          </div>

          {user && user.isStaff && <>
              <div className="mt-6">
                <p className="text-sm text-grey-700">Similarity Cutoff</p>
                <p className="text-xs text-grey-500 mt-1">
                  Minimum similarity score to consider a document as a candidate for the LLM.
                </p>
                <div className="mt-2 grid grid-cols-4">
                  <div className="col-span-1">
                    <input name="similarityCutoff" type="number" value={formik.values.similarityCutoff ?? ''} min="0" max="1" step=".01" className="w-80 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={e => {
                  if (e.target.value === '') {
                    void formik.setFieldValue('similarityCutoff', undefined);
                  } else {
                    void formik.setFieldValue('similarityCutoff', parseFloat(e.target.value));
                  }
                }} onBlur={formik.handleBlur} />
                  </div>
                </div>

                <FieldError fieldName="similarityCutoff" formik={formik} />
              </div>
              <div className="mt-6">
                <p className="text-sm text-grey-700">LLM ReRank Top N</p>
                <p className="text-xs text-grey-500 mt-1">
                  Rerank context results using the LLM, limit to N nodes.
                </p>
                <div className="mt-2 grid grid-cols-4">
                  <div className="col-span-1">
                    <input name="rerankLlmTopN" type="number" value={formik.values.rerankLlmTopN ?? ''} min="1" max="100" className="w-80 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={e => {
                  if (e.target.value === '') {
                    void formik.setFieldValue('rerankLlmTopN', undefined);
                  } else {
                    void formik.setFieldValue('rerankLlmTopN', parseInt(e.target.value));
                  }
                }} onBlur={formik.handleBlur} />
                  </div>
                </div>
                <FieldError fieldName="rerankLlmTopN" formik={formik} />
              </div>
            </>}

          <div className="mt-6">
            <p className="text-lg font-medium">Document Settings</p>
          </div>

          <div className="mt-6">
            <p className="text-sm text-grey-700">
              Length <span className="text-xs text-grey-500">(in Tokens)</span>
            </p>
            <div className="mt-1 text-xs text-grey-500">
              Length of each retrieval-optimized Doc, default is 256
            </div>
            <div className="mt-2 grid grid-cols-4">
              <div className="col-span-1">
                <input name="splitterConfig.chunkSize" type="number" value={formik.values.splitterConfig.chunkSize} min="50" max="4000" className="block w-80 min-w-0 max-w-sm flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>
            </div>
            <FieldError fieldName="splitterConfig.chunkSize" formik={formik} />
            <div className="mt-2"></div>
          </div>

          <div className="mt-6">
            <p className="text-sm text-grey-700">
              Overlap <span className="text-xs text-grey-500">(in Tokens)</span>
            </p>
            <div className="mt-1 text-xs text-grey-500">
              Overlap helps avoid missing information between text parts
            </div>
            <div className="mt-2 grid grid-cols-4">
              <div className="col-span-1">
                <input name="splitterConfig.chunkOverlap" type="number" value={formik.values.splitterConfig.chunkOverlap} min="0" max="100" className="block w-80 min-w-0 max-w-sm flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>
            </div>
            <FieldError fieldName="splitterConfig.chunkOverlap" formik={formik} />
          </div>

          <div className="mt-6">
            <p className="text-sm text-grey-700">Text Splitter</p>
            <div className="mt-1 text-xs text-grey-500">
              Strategy for how to assemble Docs, default is Token
            </div>
            <div className="mt-2">
              <div className="flex flex-row gap-6 relative group">
                <TextSplitterSelect formik={formik} setSplitter={splitter => {
                void formik.setFieldValue('splitterConfig.splitter', splitter);
              }} splitter={formik.values.splitterConfig.splitter} />
              </div>
            </div>
          </div>

          {formik.values.splitterConfig.splitter == 'code' && <div className="mt-6">
              <p className="text-sm text-grey-700">Code Language</p>
              <div className="mt-2">
                <div className="flex flex-row gap-6 relative group">
                  <select name="splitterConfig.code_language" id="splitterConfig.code_language" className="block w-full min-w-0 max-w-lg flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.splitterConfig.code_language}>
                    <option value="ruby">Ruby</option>
                    <option value="python">Python</option>
                    <option value="js">Javascript</option>
                    <option value="ts">Typescript</option>
                    <option value="java">Java</option>
                    <option value="go">Go</option>
                    <option value="php">PHP</option>
                    <option value="scala">Scala</option>
                    <option value="swift">Swift</option>
                    <option value="html">HTML</option>
                    <option value="markdown">Markdown</option>
                  </select>
                </div>
              </div>
            </div>}

          {formik.values.splitterConfig.splitter == 'token' && <div className="mt-6">
              <p className="text-sm text-grey-700">Separator</p>
              <div className="mt-2">
                <div className="flex flex-row gap-6 relative group">
                  <input name="splitterConfig.separator" placeholder="Default is an empty space (' ')" type="text" className="block w-80 min-w-0 max-w-lg flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.splitterConfig.separator} />
                </div>
                <FieldError fieldName="splitterConfig.separator" formik={formik} />
              </div>
            </div>}

          <div className="my-6">
            <p className="text-lg font-medium">Library Settings</p>
          </div>
          <div className="">
            <p className="text-sm text-grey-700">Context Name</p>
            <div className="mt-2">
              <div className="flex flex-row rounded-md">
                <input name="name" placeholder="Jeff Bezos letters collection" type="text" id="key" className="block w-full min-w-0 max-w-lg flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} />
              </div>
              <FieldError fieldName="name" formik={formik} />
            </div>
          </div>
          <div className="my-6">
            <p className="text-sm text-grey-700">
              Context Description <span className="text-grey-500">(Optional)</span>
            </p>
            <div className="mt-2">
              <div className="flex flex-row rounded-md">
                <input name="description" placeholder="A collection of Jeffs letters to shareholders" type="text" id="key" className="block w-full min-w-0  max-w-lg flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.description} />
              </div>
              <FieldError fieldName="description" formik={formik} />
            </div>
          </div>

          <div className="py-3">
            <Button className="w-64" disabled={isLoading} type="submit" loading={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </div>
      </div>
    </form>;
}
interface IParams extends ParsedUrlQueryInput {
  contextSlug: string;
}
function ContextDangerZone({
  context,
  workspace
}: {
  context: DetailedContext;
  workspace: Workspace;
}) {
  const [open, setOpen] = useState(false);
  return <>
      <DeleteContextModal context={context} open={open} setOpen={setOpen} workspace={workspace} />

      <div className="space-y-8 border-t border-grey-200 pt-8">
        <div className="space-y-6 p-6 pt-0">
          <div>
            <h3 className="text-lg font-medium leading-6">Refresh Context</h3>
            <p className="mt-1 max-w-2xl text-sm text-grey-500">
              Recreate Context library with new saved settings
            </p>
          </div>

          <div className="mt-6 space-y-3">
            <RefreshContextButton context={context}>
              <div className="cursor-pointer inline-flex justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm ease-in-out hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                Refresh Document Embedding
              </div>
            </RefreshContextButton>

            <p className="font-mono text-xs text-grey-700">
              Note: This will delete any custom document changes and re-index from sources.
            </p>
          </div>
        </div>
      </div>

      <div className="space-y-8 border-t border-grey-200 pt-8">
        <div className="space-y-6 p-6 pt-0">
          <div>
            <h3 className="text-lg font-medium leading-6">Danger Zone</h3>
            <p className="mt-1 max-w-2xl text-sm text-grey-500">Destructive context options</p>
          </div>

          <div className="mt-3 flex items-center justify-start">
            <Button variant="outline" onClick={() => setOpen(!open)}>
              Delete Context
            </Button>
            <p className="ml-3 rounded-full bg-grey-50 p-2 px-3 font-mono text-xs text-grey-700">
              Warning: This cannot be undone, proceed with caution
            </p>
          </div>
        </div>
      </div>
    </>;
}
function TabCountLabel({
  count
}: {
  count?: number;
}) {
  if (!count || count < 1) {
    return null;
  }
  return <span className="ml-1 inline-flex items-center rounded-full bg-blue-50 px-2.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-blue-200">
      {count}
    </span>;
}
export function ContextPageTabs({
  context,
  workspace
}: {
  context: DetailedContext;
  workspace: Workspace;
}) {
  const router = useRouter();
  const queryParamValue = (router.query as {
    tab?: string;
  });
  const [selected, setSelected] = useState(queryParamValue.tab ?? 'overview');
  interface Tab {
    name: string | JSX.Element;
    id: string;
  }
  const tabs: Tab[] = [{
    name: 'Overview',
    id: 'overview'
  }, {
    name: 'Settings',
    id: 'edit'
  }];
  if (context) {
    const contextMeta = (context.metadata as SingleContextMetadataCache);
    const contextSourceCount = contextMeta.contextSourceCount || 0;
    const contextDocumentCount = contextMeta.documentCount || 0;
    tabs.push({
      name: <>Sources {<TabCountLabel count={contextSourceCount} />}</>,
      id: 'sources'
    });
    if (contextSourceCount > 0) {
      tabs.push({
        name: <>Documents {<TabCountLabel count={contextDocumentCount} />}</>,
        id: 'embeddings'
      });
      tabs.push({
        name: 'Retrieval Test',
        id: 'test'
      });
      tabs.push({
        name: 'Scheduled Refresh',
        id: 'schedule'
      });
    }
    tabs.push({
      name: 'Revisions',
      id: 'revisions'
    });
  }
  const handleRouteChange = async (selectedOption: string) => {
    const currentUrl = router.asPath;
    const newQuery = {
      tab: selectedOption
    };
    const newUrl = {
      pathname: currentUrl.split('?')[0],
      query: newQuery
    };
    await router.push(newUrl, undefined, {
      shallow: true
    });
  };
  const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    setSelected(selectedOption);
    await handleRouteChange(selectedOption);
  };
  const handleOnClick = async (selectedOption: string) => {
    setSelected(selectedOption);
    await handleRouteChange(selectedOption);
  };
  return <div className="max-w-8xl mx-auto px-4 sm:px-6">
      {workspace && <>
          <div className={cn('pt-3', {
        'pb-20': selected === 'embeddings',
        'pb-6': selected !== 'embeddings'
      })}>
            <div className="md:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select id="tabs" name="tabs" className="hidden w-full rounded-md border-grey-300 py-2 pl-3 pr-10 text-base focus:border-grey-500 focus:outline-none focus:ring-grey-500 sm:block sm:text-sm" defaultValue={tabs.find(tab => tab.id === selected)?.id} onChange={e => void handleSelectChange(e)}>
                {tabs.map(tab => <option key={tab.id}>{tab.id}</option>)}
              </select>
            </div>
            <div className="hidden md:block">
              <div className="border-b border-grey-300">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map(tab => <button key={tab.id} onClick={() => void handleOnClick(tab.id)} className={classNames(selected === tab.id ? 'border-grey-800 text-grey-800' : 'border-transparent text-grey-500 hover:border-grey-300', 'whitespace-nowrap border-b-2 px-3 py-2 text-sm font-medium')}>
                      {tab.name}
                    </button>)}
                </nav>
              </div>
            </div>

            {context && <>
                <div className="mt-6 rounded-lg border bg-white shadow-sm">
                  {selected === 'overview' && <ContextOverview context={context} workspace={workspace} />}

                  {selected === 'edit' && <>
                      <ContextUpdateForm context={context} />
                      <ContextDangerZone context={context} workspace={workspace} />
                    </>}
                  {selected === 'schedule' && <ContextSchedule context={context} />}
                  {selected === 'test' && <ContextTestForm context={context} />}
                  {selected === 'embeddings' && <ContextEmbeddingsTab context={context} />}
                  {selected === 'sources' && <ContextSources context={context} workspace={workspace} />}
                  {selected === 'revisions' && <ContextVersionsTable context={context} />}
                </div>

                <Room id={`context-${context.id}`} hideLoader={true}>
                  <ContextComments />
                </Room>
              </>}
          </div>
        </>}
    </div>;
}
export function ContextPage() {
  const router = useRouter();
  const {
    contextSlug
  } = (router.query as IParams);
  const {
    context,
    isLoading
  } = useFetchContextBySlug(contextSlug);
  const {
    workspace
  } = useCurrentWorkspace();
  if (isLoading) {
    return <FullLoaderPage />;
  }
  if (!context) {
    return null;
  }
  return <Sidebar>
      <Head>
        <title>{context?.name} / Update</title>
        <meta name="description" content="Context update page" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky z-10 top-0 flex h-16 flex-shrink-0 border-b border-grey-200 bg-white bg-opacity-50 backdrop-blur dark:bg-black">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-6">
            <OpenMobileSidebarButton />
            {workspace && <div className="flex flex-1 items-center">
                <Link href={`/${workspace.slug}/context`}>
                  <h1 className="text-xl font-semibold text-grey-800">Context Library</h1>
                </Link>
                <ChevronRightIcon className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                <h1 className="text-xl font-semibold text-grey-800">{context?.name}</h1>
              </div>}
          </div>
        </div>
        <main>{workspace && <ContextPageTabs context={context} workspace={workspace} />}</main>
      </div>
    </Sidebar>;
}
export function ContextOverview({
  context,
  workspace
}: {
  context: DetailedContext;
  workspace: Workspace;
}) {
  const {
    actions,
    isLoading
  } = useFetchContextActions(context);
  const {
    apps,
    isLoading: isLoadingApps
  } = useFetchApps(workspace.id);
  const contextMetadata = (context.metadata as SingleContextMetadataCache);
  const contextSourceCount = contextMetadata.contextSourceCount || 0;
  const {
    scheduledTask
  } = useFetchContextSchedule(context);
  if (isLoading || isLoadingApps) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400" />
      </div>;
  }
  const appRecord: Record<number, App> = {};
  apps?.forEach(app => {
    appRecord[app.id] = app;
  });
  let cronString;
  if (scheduledTask) {
    cronString = scheduledTask && scheduledTask.schedule ? `${scheduledTask.schedule.minute} ${scheduledTask.schedule.hour} ${scheduledTask.schedule.day_of_week} ${scheduledTask.schedule.day_of_month} ${scheduledTask.schedule.month_of_year}` : '';
  }
  return <div>
      <div className="">
        <div className="border-1 flex items-center justify-between border-b p-6">
          <div>
            <h3 className="mr-3 inline-flex text-2xl">{context.name}</h3>
            {context.description && <p className="pt-1 text-grey-600">{context.description}</p>}
          </div>
          <div className="ml-auto flex gap-2 items-center">
            {contextSourceCount > 0 && <>
                <ProcessingStatus context={context} showLog={true} />

                <RefreshContextButton context={context}>
                  <ArrowPathIcon className={`cursor-pointer h-4 w-4 text-grey-600 ${!context.processed && 'animate-spin' || ''}`} />
                </RefreshContextButton>
              </>}
          </div>
        </div>
        {contextSourceCount > 0 && <div className="border-1 border-b p-6">
            <div className="space-y-3">
              <p className="text-xs font-medium uppercase text-grey-900">Summary of Contents</p>
              <p className="text-grey-600">{context.summary}</p>
            </div>
          </div>}

        {cronString && context.processed && context.reindexedAt && <div className="border-1 border-b p-6">
            <div className="space-y-3">
              <p className="text-xs font-medium uppercase text-grey-900">Scheduled Refresh</p>
              <p className="text-grey-600">
                <span className="text-xs text-grey-500">
                  {cronstrue.toString(cronString, {
                throwExceptionOnParseError: false
              })}
                </span>
              </p>
              <p className="text-grey-600">
                <span className="text-xs text-grey-500">
                  Last indexed: {new Date(context.reindexedAt).toLocaleString()}
                </span>
              </p>
            </div>
          </div>}

        {/* ../ until we have a better no-action-connected experience, just hide this section */}
        {actions && actions.length > 0 && <div className="border-1 border-b p-6">
            <div className="overflow-hidden shadow ring-1 ring-grey-300/50 sm:rounded-lg">
              <table className="min-w-full divide-y divide-grey-300 dark:divide-grey-600">
                {actions && actions.length < 1 && <tr>
                    <td className="p-6 py-2 text-sm font-medium text-grey-900">
                      Not used in any Actions.
                    </td>
                  </tr>}
                {actions && actions.length > 0 && <tr>
                    <p className="min-w-full divide-y divide-grey-300 rounded-t-md bg-grey-25 p-3 text-xs font-medium uppercase tracking-wider text-grey-600 dark:divide-grey-600">
                      Linked Actions
                    </p>
                  </tr>}
                {actions && actions.map(action => <tr key={action.id}>
                      <td className="max-w-xs whitespace-nowrap p-3 text-sm font-medium">
                        <div className="flex flex-col">
                          <Link className="transition-colors ease-in-out hover:text-blue-700" href={`/${workspace.slug}/apps/${appRecord[action.appId]?.slug || ''}/actions/${action.slug}`}>
                            {action.name}
                          </Link>
                          <span className="truncate text-sm font-normal text-grey-500">
                            {action.description}
                          </span>
                        </div>
                      </td>
                    </tr>)}
              </table>
            </div>
          </div>}
        {context.createdBy && <div className="inline-flex items-center px-6 py-3">
            <img className="h-8 w-8 rounded-full" alt={context.createdBy?.name || ''} src={context.createdBy?.image || ''} referrerPolicy="no-referrer" />
            <div className="ml-3">
              <p className="text-grey-800">Created by {context.createdBy?.name}</p>
              <p className="text-xs text-grey-600">
                Updated on {format(new Date(context.updatedAt.toString()), 'MMMM dd yyyy')}
              </p>
            </div>
          </div>}

        <div className="float-right inline-flex px-6 py-4">
          {context && <GuidCopyButton label={'Context GUID'} guid={context.guid} />}
        </div>
      </div>
    </div>;
}
const ContextComments = () => {
  const {
    threads
  } = useThreads();
  return <div className="mt-4 text-sm">
      {threads.map(thread => <div className="p-4 rounded-lg border bg-white shadow-sm mb-4" key={thread.id}>
          <Thread thread={thread} showResolveAction={false} />
        </div>)}
      <div className="">
        <Composer className="mt-4 rounded-lg border bg-white shadow-sm" />
      </div>
    </div>;
};
export const ContextProcessingPlaceholder = function () {
  return <div className="flex items-center justify-center py-12">
      <p className="text-grey-600">
        This context is being processed. This page will be available when complete.
      </p>
    </div>;
};
const ContextEmbeddingsTab = function ({
  context
}: {
  context: DetailedContext;
}) {
  const [query, setQuery] = useState('');
  const [isVectorSearch, setIsVectorSearch] = useState(false);
  const [page, setPage] = useState(1);
  const searchQuery = useDebounce(query, 400);
  const handleSearchForm = ({
    query,
    isVectorSearch
  }: {
    query: string;
    isVectorSearch: boolean;
  }) => {
    setQuery(query);
    setIsVectorSearch(isVectorSearch);
    setPage(1);
  };
  if (!context.processed) {
    return <ContextProcessingPlaceholder />;
  }
  return <div className="">
      <div className="p-6 mb-2">
        <SearchForm query={query} onChange={handleSearchForm} setIsVectorSearch={setIsVectorSearch} isVectorSearch={isVectorSearch} />
      </div>
      {isVectorSearch && <>
          <VectorSearchDocumentsTable context={context} query={searchQuery} />
        </>}
      {!isVectorSearch && <>
          <DocumentsTable context={context} query={searchQuery} initialPage={page} />
        </>}
    </div>;
};
const Context404Wrapper: NextPage = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <Context404 />;
  }
  return <ContextPage {...props} />;
};
export default _withSuperJSONPage(Context404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withContext(), []);