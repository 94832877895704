import { Button, Input } from '@/client/components'
import { useCreateContextForm } from './form'
import type { FormProps } from './types'

export const Form = ({ workspace, onIsOpenChange }: FormProps) => {
  const { formik, saveDisabled } = useCreateContextForm({
    workspace,
    onIsOpenChange,
    redirectAfterCreate: true,
  })
  const { values, errors, handleChange, handleSubmit } = formik

  return (
    <div className="flex flex-col">
      <div className="px-6 pb-4 text-left">
        <Input
          id="name"
          name="name"
          label="Context Name"
          className="font-inter"
          placeholder="Jeff Bezos letters to Elon Musk"
          autoComplete="off"
          message={errors.name}
          hasError={!!errors.name}
          value={values.name}
          onChange={handleChange}
        />
      </div>
      <div className="px-6 pb-4 text-left">
        <Input
          id="description"
          name="description"
          label="Optional Description"
          className="font-inter"
          placeholder="Use these when understanding communication between Amazon (Jeff) and Tesla (Elon)"
          autoComplete="off"
          message={errors.description}
          hasError={!!errors.description}
          value={values.description}
          onChange={handleChange}
        />
      </div>

      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
