import { Icon } from '@/client/components'
import type { IconLabelProps } from './types'

export const IconLabel = ({ icon, label }: IconLabelProps) => {
  return (
    <>
      <Icon size="md" component={icon} />

      <p>{label}</p>
    </>
  )
}
