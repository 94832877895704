import { useStudio } from '@/client/containers/views/Studio/context'
import { useFetchAppsWithActions } from '@/common/hooks'

export const useAppsWithActions = (actionType?: 'chat' | 'prompt') => {
  const {
    state: { workspace },
  } = useStudio()
  const { isLoading: isLoadingApps, apps = [] } = useFetchAppsWithActions(workspace.id, actionType)

  return {
    isLoadingApps,
    apps,
  }
}
