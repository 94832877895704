import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import type { StudioFormInputs } from '@/client/containers/views/Studio/components/Formik/types'

export type StudioTab = 'completion' | 'chat' | 'document'

type PersistedStudioState = Partial<{
  completion: StudioFormInputs['completion']
  chat: StudioFormInputs['chat']
  document: StudioFormInputs['document']
  textToSpeechSettings: StudioFormInputs['textToSpeechSettings']
}>

export const usePersistStudioState = () => {
  const searchParams = useSearchParams()
  const router = useRouter()

  const getFormikState = useCallback(() => {
    const studioState = localStorage.getItem('studio')
    if (studioState) {
      return JSON.parse(studioState) as Record<string, string>
    }
  }, [])

  const getFormikStateByActionId = useCallback(
    (actionId: string) => {
      const studioState = getFormikState()
      if (studioState) {
        return JSON.parse(studioState[actionId] ?? '{}') as PersistedStudioState
      }
    },
    [getFormikState]
  )

  const updateStateForActionId = useCallback(
    (actionId: string, values: PersistedStudioState) => {
      const currentFormikState = getFormikState()
      localStorage.setItem(
        'studio',
        JSON.stringify({
          ...currentFormikState,
          [actionId]: JSON.stringify(values),
        })
      )
    },
    [getFormikState]
  )

  const selectedTab = useMemo(() => {
    return (searchParams.get('tab') ?? 'chat') as StudioTab
  }, [searchParams])

  const selectedVersionGuid = useMemo(() => {
    return searchParams.get('version') ?? ''
  }, [searchParams])

  const selectTab = async (tab: StudioTab) => {
    await router.replace({ query: { ...router.query, tab } }, undefined, {
      shallow: true,
    })
  }

  const replaceWithState = useCallback(
    async (params: { pathname: string; actionId: number; formikState: PersistedStudioState }) => {
      updateStateForActionId(params.actionId.toString(), params.formikState)
      await router.replace({
        pathname: params.pathname,
      })
    },
    [router, updateStateForActionId]
  )

  return {
    getFormikState,
    getFormikStateByActionId,
    updateStateForActionId,
    selectedTab,
    replaceWithState,
    selectTab,
    selectedVersionGuid,
  }
}
