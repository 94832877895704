import FolderIcon from '@/client/assets/icons/folder.svg'
import { useStudio } from '@/client/containers/views/Studio/context'
import { useFetchApps } from '@/common/hooks'

export const useAppOptions = () => {
  const {
    state: { workspace },
  } = useStudio()
  const { isLoading: isLoadingApps, apps = [] } = useFetchApps(workspace.id)

  const appOptions = apps.map((app) => {
    return {
      value: app.id,
      label: app.name,
      icon: FolderIcon,
    }
  })

  return {
    isLoadingApps,
    appOptions,
  }
}
