export const sourceTypes = [
  {
    id: 7,
    name: 'Database',
    required_metadata: [
      {
        name: 'query',
        value: '',
      },
    ],
  },
  {
    id: 9,
    name: 'Crawler',
    required_metadata: [
      {
        name: 'homepage',
        value: '',
      },
      {
        name: 'max_depth',
        value: '100',
      },
      { name: 'use_playwright', value: 'false' },
    ],
  },
  {
    id: 11,
    name: 'Zendesk',
    required_metadata: [
      {
        name: 'subdomain',
        value: '',
      },
      {
        name: 'limit',
        value: 100,
      },
      {
        name: 'locale',
        value: 'en-us',
      },
    ],
  },
  {
    id: 19,
    name: 'YouTube',
    required_metadata: [
      {
        name: 'video_url',
        value: '',
      },
    ],
  },
  {
    id: 21,
    name: 'Sitemap',
    required_metadata: [
      {
        name: 'site_url',
        value: '',
        required: true,
      },
      {
        name: 'max_urls',
        value: 100,
        required: true,
      },
    ],
  },
  // {
  //   id: 22,
  //   name: 'Slack',
  //   required_metadata: [
  //     {
  //       name: 'channel_name',
  //       value: '',
  //     },
  //     {
  //       name: 'limit',
  //       value: '',
  //     },
  //   ],
  // },
]

export const databaseExtraMetadata = [
  {
    name: 'PostgreSQL',
    extra_metadata: [
      {
        name: 'query',
        value: '',
      },
    ],
  },
  {
    name: 'MySQL',
    extra_metadata: [
      {
        name: 'query',
        value: '',
      },
    ],
  },
  {
    name: 'Elastic',
    extra_metadata: [
      {
        name: 'query',
        value: '',
      },
    ],
  },
  {
    name: 'Redis',
    extra_metadata: [
      {
        name: 'filter',
        value: '',
      },
    ],
  },
  {
    name: 'Pinecone',
    extra_metadata: [
      {
        name: 'query',
        value: '',
      },
    ],
  },
  {
    name: 'Snowflake',
    extra_metadata: [
      {
        name: 'query',
        value: '',
      },
    ],
  },
  {
    name: 'MongoDB',
    extra_metadata: [
      {
        name: 'database',
        value: '',
      },
      {
        name: 'collection',
        value: '',
      },
      {
        name: 'fields',
        value: '',
      },
      {
        name: 'max_documents',
        value: '',
      },
      {
        name: 'metadata_names',
        value: '',
      },
    ],
  },
]
