import type { App } from '@prisma/client'
import { useFormik } from 'formik'
import { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, Dialog, Select, toast } from '@/client/components'
import { FieldError } from '@/common/components/forms'
import { useFetchAppActions } from '@/common/hooks'
import { api } from '@/utils'

function ExperimentForm({ app, setOpen }: { app: App; setOpen: (val: boolean) => void }) {
  const createExperiment = api.experiment.create.useMutation()
  const { actions, isLoading: isLoadingActions } = useFetchAppActions(app.id)
  const [loading, setLoading] = useState(false)
  const utils = api.useContext()

  function onSuccess() {
    toast.success({
      title: 'Experiment created',
      description: 'Successfully created the experiment',
    })
    setOpen(false)
  }

  const schema = z.object({
    name: z.string(),
    actionPrimaryId: z.number(),
    actionSecondaryId: z.number(),
  })

  type FormSchema = z.infer<typeof schema>
  const formik = useFormik<FormSchema>({
    initialValues: {
      name: '',
      actionPrimaryId: 0,
      actionSecondaryId: 0,
    },
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true)
        await createExperiment.mutateAsync({ ...values, appId: app.id })
        void utils.experiment.getAll.invalidate({ appId: app.id })
        setLoading(false)
        onSuccess()
        resetForm()
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    },
  })

  function getSecondaryActions() {
    if (!actions) return []
    if (formik.values.actionPrimaryId) {
      return actions.filter((action) => action.id !== formik.values.actionPrimaryId)
    }
    return actions
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="rounded-lg bg-white">
        <div>
          <div className="p-6 text-center sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">Create an Experiment</h3>
            <div className="mt-6">
              <p className="text-sm text-grey-700">Experiment Name</p>
              <div className="mt-2">
                <div className="flex flex-row rounded-md">
                  <input
                    name="name"
                    placeholder="Alien vs. Predator"
                    type="text"
                    autoComplete="off"
                    id="key"
                    className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                </div>
                <FieldError fieldName="name" formik={formik} />
              </div>
            </div>
            <div className="mt-6">
              <p className="text-sm text-grey-700">Select a Primary Action</p>
              <div className="mt-2">
                {isLoadingActions ? (
                  <div className="flex animate-pulse space-x-4">
                    <div className="flex-1 space-y-2">
                      <div className="h-2 rounded bg-grey-700"></div>
                      <div className="h-2 rounded bg-grey-700"></div>
                    </div>
                  </div>
                ) : (
                  <Select.Field
                    id="actionPrimaryId"
                    options={
                      actions
                        ? actions.map((action) => {
                            return {
                              label: action.name,
                              value: action.id.toString(),
                            }
                          })
                        : []
                    }
                    placeholder="Select an action"
                    value={formik.values.actionPrimaryId.toString()}
                    hasError={!!formik.errors.actionPrimaryId}
                    message={formik.errors.actionPrimaryId}
                    onChange={formik.handleChange}
                  />
                )}
              </div>
              <FieldError fieldName="actionPrimaryId" formik={formik} />
            </div>
            <div className="mt-6">
              <p className="text-sm text-grey-700">Select a Secondary Action</p>
              <div className="mt-2">
                {isLoadingActions ? (
                  <div className="flex animate-pulse space-x-4">
                    <div className="flex-1 space-y-2">
                      <div className="h-2 rounded bg-grey-700"></div>
                      <div className="h-2 rounded bg-grey-700"></div>
                    </div>
                  </div>
                ) : (
                  <Select.Field
                    id="actionSecondaryId"
                    options={getSecondaryActions().map((action) => {
                      return {
                        label: action.name,
                        value: action.id.toString(),
                      }
                    })}
                    placeholder="Select an action"
                    value={formik.values.actionSecondaryId.toString()}
                    hasError={!!formik.errors.actionSecondaryId}
                    message={formik.errors.actionSecondaryId}
                    onChange={formik.handleChange}
                  />
                )}
              </div>
              <FieldError fieldName="actionSecondaryId" formik={formik} />
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between rounded-b-lg border-t border-grey-200 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
        <Button
          variant="outline"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-grey-300 bg-white px-4 py-2 text-base font-medium shadow-sm hover:border-grey-400 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button type="submit" disabled={loading} loading={loading}>
          {loading ? 'Saving...' : 'Create'}
        </Button>
      </div>
    </form>
  )
}

export const CreateExperimentModal = ({
  open,
  setOpen,
  app,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  app: App | null
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>{app && <ExperimentForm app={app} setOpen={setOpen} />}</Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
