import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { useCopyActionGUID } from '../../CopyGUID/form'
import { deployActionFormSchema } from './schema'
import type { DeployActionFormInputs, UseDeployActionFormProps } from './types'

export const useDeployAction = ({ action, workspace, callbacks }: UseDeployActionFormProps) => {
  const { copy } = useCopyActionGUID({ action })
  const deployVersion = api.action.deployVersion.useMutation()
  const utils = api.useContext()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: DeployActionFormInputs = {
      environmentId: 0,
    }

    return initialValues
  }, [])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: `${action.name} deployed`,
      description: action.status === 'ACTIVE' ? '' : `${action.name} ready for external use`,
    })
  }, [action.status, action.name])

  const onSubmit = React.useCallback(
    async (
      values: DeployActionFormInputs,
      { resetForm }: FormikHelpers<DeployActionFormInputs>
    ) => {
      await deployVersion.mutateAsync({
        actionId: action.id,
        environmentIds: [values.environmentId],
      })
      await utils.action.get.invalidate({ id: action.id })
      await utils.action.getBySlug.invalidate({ slug: action.slug })
      await utils.actionVersion.getAllForAction.invalidate({ actionId: action.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.action_updated, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'Action ID': action.id,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      copy()
      resetForm()
      onSuccess()
      callbacks && (await callbacks.onDone?.())
    },
    [
      deployVersion,
      action.id,
      action.slug,
      utils.action.get,
      utils.action.getBySlug,
      utils.actionVersion.getAllForAction,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      copy,
      onSuccess,
      callbacks,
    ]
  )

  const formikConfig: FormikConfig<DeployActionFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(deployActionFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
