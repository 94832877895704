import { EvalDataType } from '@prisma/client'
import { QueryBuilderTremor } from '@react-querybuilder/tremor'
import * as React from 'react'
import ReactDOM from 'react-dom'
import type { Field, RuleGroupType, RuleType } from 'react-querybuilder'
import { QueryBuilder } from 'react-querybuilder'
import { CustomValueEditor } from './CustomValueEditor'

export const EvalAssertionField = ({
  onChange,
  value,
  evalDataType,
}: {
  onChange: (query: RuleGroupType) => void
  value?: RuleGroupType | null
  evalDataType?: EvalDataType | undefined
}) => {
  const usesExpectedOutput = (EvalDataType['TEST'] as string) === evalDataType
  const fields: Field[] = [
    {
      name: 'actual_output',
      label: 'Full output',
      value: 'actual_output',
    },
    { name: 'json_path', label: 'JSON Path <=> Value' },
    { name: 'retrieval_context', label: 'Retrieved Context' },
  ]

  const query = value || { combinator: 'and', rules: [] }

  const operators = [
    { name: 'is_equal_to', value: 'is_equal_to', label: '=' } as const,
    { name: 'is_not_equal_to', value: 'is_not_equal_to', label: '!=' } as const,

    { name: 'contains', value: 'contains', label: 'contains' } as const,
    { name: 'starts_with', value: 'starts_with', label: 'begins with' } as const,
    { name: 'ends_with', value: 'ends_with', label: 'ends with' } as const,
    { name: 'does_not_contain', value: 'does_not_contain', label: 'does not contain' } as const,
    { name: 'matches', value: 'matches', label: 'matches (regex)' } as const,
    { name: 'does_not_match', value: 'does_not_match', label: 'does not match (regex)' } as const,
    // { name: 'doesNotBeginWith', value: 'does_not_start_with', label: 'does not begin with' } as const,
    // { name: 'doesNotEndWith', value: 'doesNotEndWith', label: 'does not end with' } as const,
    { name: 'is_empty', value: 'is_empty', label: 'is empty', arity: 'unary' } as const,
    { name: 'is_not_empty', value: 'is_not_empty', label: 'is not empty', arity: 'unary' } as const,

    {
      name: 'contains_no_invalid_links',
      value: 'contains_no_invalid_links',
      label: 'contains no invalid links',
    } as const,

    { name: 'is_less_than', value: 'is_less_than', label: '<' } as const,
    { name: 'is_greater_than', value: 'is_greater_than', label: '>' } as const,
    { name: 'is_less_than_or_equal_to', value: 'is_less_than_or_equal_to', label: '<=' } as const,
    {
      name: 'is_greater_than_or_equal_to',
      value: 'is_greater_than_or_equal_to',
      label: '>=',
    } as const,
    // { name: 'in', value: 'is_in', label: 'in' } as const,
    // { name: 'notIn', value: 'is_not_in', label: 'not in' } as const,
    // { name: 'between', value: 'is_between', label: 'between' } as const,
    // { name: 'notBetween', value: 'is_not_between', label: 'not between' } as const,
  ]

  const targetRef = React.useRef<HTMLDivElement>(null)
  const [ruleGroupElement, setRuleGroupElement] = React.useState<Element | null>(null)

  React.useEffect(function addRuleHeader() {
    const targetElement = targetRef.current
    if (targetElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            const ruleGroup = targetElement.querySelector('.ruleGroup')
            const ruleGroupBody = targetElement.querySelector('.ruleGroup-body')

            if (!ruleGroup) return
            if (ruleGroupBody && ruleGroupBody.children.length > 0) {
              setRuleGroupElement(ruleGroup)
            } else {
              setRuleGroupElement(null)
            }
          }
        })
      })

      observer.observe(targetElement, { childList: true, subtree: true })
      return () => observer.disconnect()
    }
  }, [])

  const getDefaultValue = (rule: RuleType) => {
    console.log('getDefaultValue', rule)

    if (rule.field === 'actual_output') {
      if (rule.operator == 'contains_no_invalid_links') {
        return true
      }
      return '%{expected_output}'
    }
  }

  return (
    <div className="flex flex-col w-full gap-3" ref={targetRef}>
      <QueryBuilderTremor>
        <QueryBuilder
          showCombinatorsBetweenRules
          operators={operators}
          fields={fields}
          query={query}
          onQueryChange={onChange}
          controlElements={{
            valueEditor: CustomValueEditor,
          }}
          getDefaultValue={getDefaultValue}
        />
      </QueryBuilderTremor>

      {ruleGroupElement ? ReactDOM.createPortal(<DynamicDiv />, ruleGroupElement) : null}

      {usesExpectedOutput ? (
        <span className="text-sm text-grey-500">
          Use <span className="mx-1 font-mono">%&#123;expected_output&#125;</span> to refer to the
          expected output in the rule value.
        </span>
      ) : null}
    </div>
  )
}

const DynamicDiv = () => (
  <div className="flex flex-row gap-3 text-xs text-grey-800">
    <div className="grid w-full grid-cols-3 gap-2">
      <p className="w-full">Rule</p>
      <p className="w-full">Operator</p>
      <p className="w-full">Output</p>
    </div>
    <div className="w-[36px]" />
  </div>
)
