const numBars = 100

const getBlankMap = (startDate: number, endDate: number, intervalMin: number) => {
  const blankMap: { [key: number]: number } = {}
  const startGroup = Math.ceil(startDate / (intervalMin * 60 * 1000))
  const endGroup = endDate / (intervalMin * 60000)
  for (let i = startGroup; i <= endGroup; i += 1) {
    blankMap[i] = 0
  }
  return blankMap
}

const getDataMap = (
  data: {
    interval_number: number
    interval_start: number
    total_usage_count: number
  }[]
) => {
  const dataMap: { [key: number]: number } = {}
  data.forEach((dataItem) => {
    const key = dataItem.interval_number
    if (!key) {
      return
    }
    dataMap[key] = Number(dataItem.total_usage_count) // We need to use number, as it can handle scientific notation
  })
  return dataMap
}

const minIntervalSizeMinutes = 10

export const getIntervalSize = (startDate: number, endDate: number) => {
  const calculatedIntervalSize = Math.ceil((endDate - startDate) / (numBars * 60000)) // Make sure it is at least 1
  return Math.max(calculatedIntervalSize, minIntervalSizeMinutes)
}

export const getCombinedMap = (
  data: {
    interval_number: number
    interval_start: number
    total_usage_count: number
  }[],
  startDate: number,
  endDate: number,
  intervalMin: number
) => {
  const dataMap = getDataMap(data)
  const blankMap = getBlankMap(startDate, endDate, intervalMin)
  return { ...blankMap, ...dataMap }
}

export const getFormattedDate = (combinedMap: { [key: number]: number }, intervalMin: number) =>
  Object.keys(combinedMap)
    .filter((key) => key !== undefined && key != 'NaN')
    .map((key: string) => {
      const keyAsInt = parseInt(key, 10)
      const epoch = keyAsInt * intervalMin * 60 * 1000
      const epochDate = new Date(epoch)
      const usage = combinedMap[keyAsInt] as number
      return { epoch, epochDate, usage }
    })

const epochMin = 60 * 1000
const epochDay = 24 * 60 * epochMin
const EPOCHS = {
  MIN: epochMin,
  HOUR: epochMin * 60,
  DAY: epochDay,
  WEEK: epochDay * 7,
  MONTH: epochDay * (365 / 12),
  YEAR: epochDay * 365,
}

export const getFormatString = (startDate: number, endDate: number) => {
  const epochSpan = endDate - startDate
  if (epochSpan > EPOCHS.YEAR || epochSpan > EPOCHS.MONTH) {
    return 'd MMM yy'
  } else if (epochSpan > EPOCHS.WEEK * 2) {
    return 'd MMM'
  } else if (epochSpan > EPOCHS.DAY) {
    return 'HH:mm E d'
  } else if (epochSpan > EPOCHS.HOUR) {
    return 'HH:mm, d MMM'
  } else {
    return 'HH:mm'
  }
}
