import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { api } from '@/utils'
import { useDatasetBulkAction } from '../../context'
import { deleteDatasetItemsFormSchema } from './schema'
import type { DeleteDatasetItemsFormInputs, UseDeleteDatasetItemsFormProps } from './types'

export const useDeleteDatasetItemsForm = ({
  onIsOpenChange,
  onDeleteSuccess,
}: UseDeleteDatasetItemsFormProps & {
  onDeleteSuccess?: () => void
}) => {
  const { selectedIds, dataset, selectAllRows, clearSelectedItems } = useDatasetBulkAction()
  const deleteDatasetItems = api.dataset.deleteDatasetItems.useMutation()
  const utils = api.useContext()

  const initialValues = React.useMemo(() => {
    const initialValues: DeleteDatasetItemsFormInputs = {}

    return initialValues
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: DeleteDatasetItemsFormInputs,
      { resetForm }: FormikHelpers<DeleteDatasetItemsFormInputs>
    ) => {
      await deleteDatasetItems.mutateAsync(
        {
          datasetId: dataset.id,
          datasetItemIds: selectedIds ?? [],
          selectAllRows: selectAllRows,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error deleting dataset items',
              description: error.message,
            })
          },
          onSuccess: () => {
            toast.success({
              title: 'Dataset items deleted',
              description: 'Your dataset items were deleted successfully',
            })
            onDeleteSuccess?.()
          },
        }
      )
      await utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
      clearSelectedItems()

      resetForm()
      onIsOpenChange(false)
    },
    [
      deleteDatasetItems,
      dataset.id,
      selectedIds,
      selectAllRows,
      utils.datasetItem.getAll,
      clearSelectedItems,
      onIsOpenChange,
      onDeleteSuccess,
    ]
  )

  const formikConfig: FormikConfig<DeleteDatasetItemsFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(deleteDatasetItemsFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
