import { DocumentIcon } from '@heroicons/react/24/outline'
import { AssistantIcon, CompletionIcon } from '@/client/assets/icons/icons'
import { Tabs } from '@/client/components'
import type { StudioTab } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { usePersistStudioState } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { STUDIO_TABS } from '@/client/containers/views/Studio/constants'

const TopbarTabs = () => {
  const { selectedTab, selectTab } = usePersistStudioState()

  return (
    <Tabs.Root
      className="grow flex justify-center pt-2"
      value={selectedTab}
      onValueChange={(tab) => {
        void selectTab(tab as StudioTab)
      }}
    >
      <Tabs.List className="gap-5">
        <Tabs.Trigger className="text-sm flex items-center" value={STUDIO_TABS.CHAT}>
          <AssistantIcon className="mr-2 h-5 w-5" />
          Assistant
        </Tabs.Trigger>
        <Tabs.Trigger className="text-sm flex items-center" value={STUDIO_TABS.COMPLETION}>
          <CompletionIcon className="mr-2 h-5 w-5" />
          Completion
        </Tabs.Trigger>
        <Tabs.Trigger className="text-sm flex items-center" value={STUDIO_TABS.DOCUMENT}>
          <DocumentIcon className="mr-2 h-5 w-5" />
          Document
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  )
}

export { TopbarTabs }
