import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { api } from '@/utils'
import { useDatasetBulkAction } from '../../context'
import { expandDatasetFormSchema } from './schema'
import type { GenerateMoreDatasetFormInputs, UseGenerateMoreDatasetFormProps } from './types'

export const useGenerateMoreDatasetForm = ({
  onIsOpenChange,
  onUpdateSuccess,
}: UseGenerateMoreDatasetFormProps & {
  onUpdateSuccess?: () => void
}) => {
  const {
    selectedIds,
    dataset,
    clearSelectedItems,
    selectAllRows,
    toggleSelectAllRows,
    dataCount,
  } = useDatasetBulkAction()
  const [loading, setLoading] = React.useState(false)

  const expandDataset = api.dataset.expandDataset.useMutation()
  const utils = api.useContext()

  const initialValues = React.useMemo(() => {
    const initialValues: GenerateMoreDatasetFormInputs = {
      selectAll: selectAllRows,
      datasetId: dataset.id,
      count: 1,
      instructions: '',
    }

    return initialValues
  }, [dataset.id, selectAllRows])

  const onSubmit = React.useCallback(
    async (
      values: GenerateMoreDatasetFormInputs,
      { resetForm }: FormikHelpers<GenerateMoreDatasetFormInputs>
    ) => {
      setLoading(true)
      await expandDataset.mutateAsync(
        {
          ...values,
          datasetItemIds: selectedIds,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error generating more dataset items',
              description: error.message,
            })
          },
          onSuccess: () => {
            toast.success({
              title: 'Dataset items created',
              description: 'Your dataset is expanded',
            })
            void utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
            void utils.dataset.getByGuid.invalidate({ guid: dataset.guid })
            onUpdateSuccess?.()
          },
        }
      )

      setLoading(false)
      clearSelectedItems()

      resetForm()
      onIsOpenChange(false)
    },
    [
      expandDataset,
      selectedIds,
      clearSelectedItems,
      onIsOpenChange,
      utils.datasetItem.getAll,
      utils.dataset.getByGuid,
      dataset.id,
      dataset.guid,
      onUpdateSuccess,
    ]
  )

  const formikConfig: FormikConfig<GenerateMoreDatasetFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(expandDatasetFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
    loading,
    selectedIds,
    clearSelectedItems,
    dataset,
    selectAllRows,
    toggleSelectAllRows,
    dataCount,
  }
}
