import { LineChart } from '@tremor/react'
import { useFetchEvalResultsByRun } from '@/common/hooks'

export const EvalLatencyByRunChart = ({ evalId }: { evalId: number }) => {
  const { data, isLoading } = useFetchEvalResultsByRun({ evalId })
  if (isLoading || !data) {
    return null
  }
  const categories = ['Avg. Latency']

  interface EvalResultAccumulator {
    [key: string]: { [key: string]: number }
  }
  const chartData = data.reduce<EvalResultAccumulator>(
    (acc, { groupName, average_latency, createdAt }) => {
      const name = `Run #${groupName} - ${createdAt.toDateString()}`
      if (!acc[name]) {
        acc[name] = {}
      }
      const temp = acc[name]
      if (!temp) return acc
      else {
        temp['Avg. Latency'] = +Number((average_latency || 0) / 1000).toFixed(2)
        acc[name] = temp
      }
      return acc
    },
    {} as EvalResultAccumulator
  )

  const chartDataMapped = Object.entries(chartData).map(([groupName, values]) => {
    return {
      groupName,
      ...values,
    }
  })

  return (
    <>
      {chartDataMapped.length > 0 ? (
        <LineChart
          className="h-[100px] mt-3"
          showLegend={false}
          showXAxis={false}
          autoMinValue={true}
          showGridLines={true}
          showTooltip={true}
          index="groupName"
          data={chartDataMapped}
          categories={categories}
        />
      ) : (
        <div className="flex justify-center h-full items-center text-grey-400">No data.</div>
      )}
    </>
  )
}
