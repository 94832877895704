import type { LinkProps } from 'next/link'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FC, PropsWithChildren } from 'react'
import React from 'react'
import { cn } from '@/client/utils'

export const ActiveLink: FC<
  PropsWithChildren<
    LinkProps & { activeClassName?: string; className?: string; forceActive?: boolean }
  >
> = ({ children, className, activeClassName, forceActive, ...props }) => {
  const router = useRouter()
  const pathWithoutQueryParams = router.asPath.split('?')[0]

  const isActive = pathWithoutQueryParams === props.href || forceActive

  return (
    <Link {...props} className={isActive ? cn(className, activeClassName) : className}>
      {children}
    </Link>
  )
}
