import { toast } from '@/client/components'
import useCopy from '@/client/hooks/useCopy'
import type { UseCopyActionGUIDProps } from './types'

export const useCopyActionGUID = ({ action }: UseCopyActionGUIDProps) => {
  const { copy, isCopied } = useCopy(action.guid, {
    onSuccess: () => {
      toast.success({
        title: 'Action GUID copied',
        description: 'You can use this GUID to reference this action in your code',
      })
    },
    onError: (err) => {
      console.error('Failed to copy text: ', err)
    },
  })

  return {
    copy,
    copied: isCopied,
  }
}
