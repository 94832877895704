import type { ContextSource } from '@prisma/client'
import { useEventListener } from 'liveblocks.config'
import { useEffect, useState } from 'react'
import type { ContextStatusEvent } from '@/common/types/context'
import { api } from '@/utils'

export function ProcessingStatus({ contextSource }: { contextSource: ContextSource }) {
  const utils = api.useContext()
  const [percentDone, setPercentDone] = useState<number>(0)
  const [status, setStatus] = useState<string | null>(contextSource.indexingStatus)

  useEventListener(({ event }) => {
    const { status, metadata } = event as ContextStatusEvent
    setStatus(status)

    let percentDone = 0

    if (metadata) {
      const documentsCount = metadata.documentsCount

      const progressCount = metadata.documentsCreatedCount || metadata.documentsEmbeddedCount

      if (documentsCount && progressCount) {
        percentDone = (progressCount / documentsCount) * 100
      }
    }

    setPercentDone(percentDone)
  })

  useEffect(() => {
    let debounceTimeout: NodeJS.Timeout | null = null
    async function invalidate() {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
      await utils.context.get.invalidate({ id: contextSource.contextId })
      await utils.contextSource.getAll.invalidate({ contextId: contextSource.contextId })
    }
    // invalidate if the status changed
    if (
      status != contextSource.indexingStatus &&
      (status == 'PROCESSING' || status == 'DONE' || status == 'FAILED')
    ) {
      debounceTimeout = setTimeout(() => {
        void invalidate()
      }, 250)
    }
  }, [
    status,
    contextSource.contextId,
    contextSource.indexingStatus,
    utils.context.get,
    utils.contextSource.getAll,
  ])

  const processed = status === 'DONE' || status === 'processed'
  const processing = status === 'PROCESSING'

  return (
    <div className="group overflow-hidden relative ml-3 inline-flex items-center rounded-full bg-primary-50 ring-1 ring-grey-200 text-xs text-primary-800 capitalize">
      <span className="px-3 py-1">{processed ? 'Ready for Search' : status || 'UNPROCESSED'}</span>
      {processed && <div className="rounded-full bg-green-400 h-3 w-3 mr-3"></div>}
      {processing && !percentDone && (
        <div className="rounded-full bg-primary-400 h-3 w-3 mr-3 animate-pulse"></div>
      )}
      {processing && percentDone > 0 && percentDone < 100 && (
        <div className="w-[100px] h-3 bg-slate-300 rounded-lg dark:bg-slate-700 mr-3 transition duration-300 ease-in-out">
          <div
            className={`bg-primary-400 h-3 rounded-lg transition-width duration-200 ease-in-out`}
            style={{ width: `${percentDone}%` }}
          ></div>
        </div>
      )}
    </div>
  )
}
