import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Composer, Thread } from '@liveblocks/react-comments';
/* eslint-disable react/jsx-no-undef */
import { Badge, Card } from '@tremor/react';
import { useThreads } from 'liveblocks.config';
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, type ReactNode } from 'react';
import { Button, ConfirmActionDialog, Loader, toast } from '@/client/components';
import { OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { EvalRunItemsGrid } from '@/common/components/evalRuns/grid/EvalRunItemsGrid';
import { App404 } from '@/common/components/notFound/404';
import { useWorkspace } from '@/common/components/ui/context';
import { useCurrentApp, useCurrentWorkspace } from '@/common/hooks';
import { useFetchEvalRun, useRefetchEvalRunItemsUntilScored } from '@/common/hooks/evalRuns';
import { withApp } from '@/common/lib';
import { Room } from '@/common/Room';
import type { DetailedEvalRun, EvalRunMetadata } from '@/common/types/eval';
import { api } from '@/utils';
type IParams = {
  runId: string;
};
function EvalRunPage() {
  const router = useRouter();
  const {
    workspace
  } = useCurrentWorkspace();
  const {
    runId
  } = (router.query as IParams);
  const {
    app
  } = useCurrentApp();
  const {
    evalRun
  } = useFetchEvalRun(parseInt(runId));
  if (!app || !evalRun || !workspace) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="w-6 h-6 text-grey-400" />
      </div>;
  }
  return <Sidebar>
      <Head>
        <title>Klu.ai</title>
      </Head>
      <div className="flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white bg-opacity-50 border-b border-grey-200 backdrop-blur dark:bg-black">
          <div className="flex items-center justify-between flex-1 px-4 mx-auto max-w-8xl sm:px-6">
            <OpenMobileSidebarButton />
            <div className="flex items-center flex-1">
              <h1 className="flex items-center gap-1 text-xl font-semibold min-w-fit text-grey-800">
                <Link href={`/${workspace.slug}/apps/${app.slug}/evaluate`} className="">
                  Evaluate
                </Link>
                <ChevronRightIcon className="w-5 h-5 mx-2 text-grey-400" aria-hidden="true" />
                <Link href={`/${workspace.slug}/apps/${app.slug}/evaluate/evals/${evalRun.Eval.slug}`} className="">
                  {evalRun.Eval.name}
                </Link>
                <ChevronRightIcon className="w-5 h-5 mx-2 text-grey-400" aria-hidden="true" />
                Run #{evalRun.run_number}
              </h1>
            </div>
          </div>
        </div>
        <main>
          <div className="px-4 mx-auto max-w-8xl sm:px-6">
            {workspace && evalRun && <div className="py-6">
                <EvalRunDetails evalRun={evalRun} />
              </div>}
          </div>
        </main>
      </div>
    </Sidebar>;
}
export const EvalRunDetails = ({
  evalRun
}: {
  evalRun: DetailedEvalRun;
}) => {
  const {
    evalRunItems,
    isLoading
  } = useRefetchEvalRunItemsUntilScored(evalRun.id);
  const {
    evalRunItemsFailed,
    evalRunItemsTotal,
    status,
    evalRunItemsCompleted
  } = (evalRun.metadata as EvalRunMetadata) ?? {};
  if (!evalRunItems && isLoading) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="w-6 h-6 text-grey-400" />
      </div>;
  }
  if (!evalRunItems) {
    return <div className="flex items-center justify-center py-12">No Eval Run Items</div>;
  }
  if (evalRunItems.length == 0) {
    return <div className="flex items-center h-10 gap-2 m-5">
        <Loader className="flex flex-col items-center justify-center h-full" />
        Still running this eval. Please check back soon.
        <CancelEvalRunButton evalRun={evalRun} />
      </div>;
  }
  return <div className="space-y-6">
      <Room id={`evalRun-${evalRun.id}`}>
        <div className="flex gap-6">
          {/* <div className="flex flex-col gap-2 text-sm font-normal text-grey-500"> */}
          <Card className="h-full w-auto min-w-[25%] p-3 space-y-2">
            <EvalRunScores evalRun={evalRun} />
            <div className="text-xs text-gray-400">
              {evalRunItemsCompleted}/{evalRunItemsTotal} items completed
              {status == 'running' && <CancelEvalRunButton evalRun={evalRun} />}
              {status == 'cancelled' && <div className="flex items-center gap-2 text-red-500">Canceled Run</div>}
              {evalRunItemsFailed > 0 && <RetryFailedItemsButton evalRun={evalRun} />}
            </div>
          </Card>
          {/* </div> */}
          <div className="flex-grow">
            <EvalRunComments />
          </div>
        </div>
      </Room>
      <EvalRunItemsGrid evalRecord={evalRun.Eval} evalRunItems={evalRunItems} />
    </div>;
};
export const CancelEvalRunButton = ({
  evalRun
}: {
  evalRun: DetailedEvalRun;
}) => {
  const utils = api.useContext();
  const cancelEvalRun = api.eval.cancelEvalRun.useMutation();
  const handleCancelEvalRun = async () => {
    await cancelEvalRun.mutateAsync({
      evalRunGuid: evalRun.guid
    });
    toast.info({
      title: 'Eval run cancelled',
      description: 'Successfully cancelled this eval run'
    });
    await utils.evalRunItem.getAll.invalidate({
      evalRunId: evalRun.id
    });
    await utils.evalRun.get.invalidate({
      id: evalRun.id
    });
    await utils.evalRun.getAll.invalidate({
      evalId: evalRun.evalId
    });
  };
  return <ConfirmActionDialog variant={'destructive'} header={`Cancel eval run`} confirmationMessage={'Are you sure you want to cancel this eval run?'} onConfirm={() => {
    void handleCancelEvalRun();
  }}>
      <Button variant="outline">Cancel Run</Button>
    </ConfirmActionDialog>;
};
export const RetryFailedItemsButton = ({
  evalRun
}: {
  evalRun: DetailedEvalRun;
}) => {
  const utils = api.useContext();
  const [running, setRunning] = useState(false);
  const {
    evalRunItemsFailed
  } = (evalRun.metadata as EvalRunMetadata) ?? {};
  const runEvalRunItems = api.eval.reRunFailedItems.useMutation();
  const workspace = useWorkspace();
  if (evalRunItemsFailed < 1) return null;
  const handleRetryFailedItems = async () => {
    setRunning(true);
    toast.info({
      title: 'Retrying failed items',
      description: 'This may take a few minutes'
    });
    await runEvalRunItems.mutateAsync({
      evalGuid: evalRun.Eval.guid,
      workspaceId: workspace.id,
      evalRunGuid: evalRun.guid
    });
    await utils.evalRunItem.getAll.invalidate({
      evalRunId: evalRun.id
    });
    await utils.evalRun.get.invalidate({
      id: evalRun.id
    });
    setRunning(false);
  };
  return <ConfirmActionDialog variant={'destructive'} header={`Retry failed items`} confirmationMessage={'Are you sure you want to retry failed items?'} onConfirm={() => {
    void handleRetryFailedItems();
  }}>
      <div className="flex items-center gap-2 my-3">
        {running ? <div className="flex items-center gap-2">
            <ArrowPathIcon className="animate-spin" />
            <span>Retrying {evalRunItemsFailed}...</span>
          </div> : <Button variant="outline" startIcon={ArrowPathIcon} title={`Retry ${evalRunItemsFailed} failed items`}>
            Retry {evalRunItemsFailed}
          </Button>}
        <span className="text-red-500">{evalRunItemsFailed} evaluations failed</span>
      </div>
    </ConfirmActionDialog>;
};
const EvalRunComments = () => {
  const {
    threads
  } = useThreads();
  return <div className="flex flex-col gap-2 text-sm">
      {threads.map(thread => <div className="p-4 mb-4 bg-white border rounded-lg shadow-sm" key={thread.id}>
          <Thread thread={thread} showResolveAction={true} />
        </div>)}
      <div className="">
        <Composer className="bg-white border rounded-lg shadow-sm" />
      </div>
    </div>;
};
export const MetricBubble = ({
  label,
  value
}: {
  label: string | boolean;
  value: number | string | ReactNode;
}) => {
  const showLabel = Boolean(label);
  return <div className="flex items-center text-xs bg-white rounded-full ring-1 ring-black/20 text-grey-400 overflow-clip">
      {showLabel && <div className="px-2 text-grey-600 bg-grey-100">{label}</div>}

      <div className={`${showLabel && 'border-l border-black/20' || ''} px-2 text-grey-900 bg-white`}>
        {value != '' ? <>{value}</> : <span className="text-grey-400">-</span>}
      </div>
    </div>;
};
const EvalRunScores = ({
  evalRun
}: {
  evalRun: DetailedEvalRun;
}) => {
  const {
    scores
  } = (evalRun.metadata as EvalRunMetadata);
  const evalOnEvalTypes = evalRun.Eval.evalTypes;
  if (!scores) {
    return '';
  }
  const totalPassRate = Object.values(scores).reduce((acc, score) => {
    return acc + Number(score);
  }, 0) / Object.values(scores).length;
  return <>
      <h4 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
        Overall pass rate
      </h4>

      {isNaN(totalPassRate) ? <div className="flex items-center justify-center h-full text-grey-400">No data.</div> : <p className="font-semibold text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong">
          {totalPassRate.toFixed(1)}%
        </p>}

      <div className="mt-2 space-y-4">
        {scores && Object.keys(scores).length > 1 && Object.entries(scores).map(([evalTypeId, score], i) => {
        const color = score >= 80 ? 'green' : score >= 50 ? 'yellow' : 'red';
        return <div key={`${evalTypeId}-${i}`}>
                <p className="flex items-center gap-2 text-sm text-tremor-content dark:text-dark-tremor-content">
                  <Badge size="xs" color={color}>
                    {score.toFixed(1)}%
                  </Badge>
                  {evalOnEvalTypes?.find(e => e.id == parseInt(evalTypeId))?.name}
                </p>
              </div>;
      })}
      </div>
    </>;
};
const EvalRunPageDetail404Wrapper: NextPage = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <App404 />;
  }
  return <EvalRunPage {...props} />;
};
export default _withSuperJSONPage(EvalRunPageDetail404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withApp(), []);