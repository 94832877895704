import type { Workspace, WorkspaceKey } from '@prisma/client'
import { useFormik } from 'formik'
import { useState } from 'react'
//import { CopyToClipboard } from 'react-copy-to-clipboard'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, Dialog, toast } from '@/client/components'
import { FieldError } from '@/common/components/forms'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function NewAPIKeyCard({ workspaceKey }: { workspaceKey: WorkspaceKey }) {
  //const [copied, setCopied] = useState(false)

  return (
    <div className="mb-6">
      <h3 className="text-sm font-medium text-green-800">New API Key</h3>
      <div className="mt-2 text-sm text-grey-600">
        Copy this API key and store it in a safe place.
      </div>
      <div className="mt-4">
        <div className="flex items-center">
          <div className="w-full flex flex-row">
            <span className="rounded bg-green-50 px-4 py-2 font-mono text-xs leading-6 select-all">
              {workspaceKey.apikey}
            </span>
            {/* <div className="mt-2 flex items-center">
              <CopyToClipboard text={workspaceKey.apikey} onCopy={() => setCopied(true)}>
                <Button>{copied ? 'Copied' : 'Copy'}</Button>
              </CopyToClipboard>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

function CreateKeyForm({
  setOpen,
  workspace,
}: {
  workspace: Workspace
  setOpen: (val: boolean) => void
}) {
  const createKey = api.workspaceKey.create.useMutation()
  const utils = api.useContext()
  const [tempKey, setTempKey] = useState<WorkspaceKey | null>(null)
  const { user } = useUser()

  function onSuccess() {
    toast.success({
      title: 'API key created',
      description: 'API key created successfully',
    })
  }

  const schema = z.object({
    name: z.string(),
  })

  type FormSchema = z.infer<typeof schema>

  const formik = useFormik<FormSchema>({
    initialValues: {
      name: '',
    },
    validationSchema: toFormikValidationSchema(schema),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        const workspaceKey = await createKey.mutateAsync({ ...values, workspaceId: workspace.id })
        if (workspaceKey) {
          setTempKey(workspaceKey)
        }
        void utils.workspaceKey.getAll.invalidate({ workspaceId: workspace.id })
        if (user) {
          const id = user.id ? user.id : ''
          const email = user.email ? user.email : ''
          const uname = user.name ? user.name : ''
          identify_and_group(id, email, uname, workspace?.slug)
        }
        track(EVENT_NAMES.api_key_created, {
          'Workspace ID': workspace.id,
          'Worspace Name': workspace.name,
          'User ID': user?.id,
          'User Email': user?.email,
          'User Name': user?.name,
        })
        onSuccess()
        resetForm()
      } catch (error) {
        console.error(error)
        // Field-specific errors from server side validation.
        // if (error.formError) {
        //   const errorObj = formatFormError<FormSchema>(error.formError)
        //   setErrors(errorObj)
        // }
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-4 sm:p-6 sm:pb-0">
        <div className="w-full">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            {!tempKey && (
              <>
                <h3 className="text-lg font-medium leading-6 text-grey-900">Create API Key</h3>
                <span className="text-xs text-grey-600">
                  API Keys have access to all apps and actions in the workspace.
                </span>
              </>
            )}
            <div className="mt-2">
              {!tempKey && (
                <div className="mb-6">
                  <div className="rounded-md">
                    <div className="space-y-6 sm:space-y-5">
                      <div>
                        <div></div>
                        <div className="mt-1 sm:col-span-1 sm:mt-2">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                              placeholder="Name (Eg. Production Backend Service API Key)"
                              className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                            />
                          </div>
                          <FieldError fieldName="name" formik={formik} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tempKey && <NewAPIKeyCard workspaceKey={tempKey} />}
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between rounded-b-lg border-t border-grey-200 bg-grey-50 sm:flex px-6 p-4">
        <Button
          className={`${tempKey ? 'w-full' : ''}`}
          variant="outline"
          onClick={() => setOpen(false)}
        >
          {!tempKey ? 'Cancel' : 'Close'}
        </Button>
        {!tempKey && (
          <Button type="submit" disabled={formik.isSubmitting} loading={formik.isSubmitting}>
            {formik.isSubmitting ? 'Saving...' : 'Create Key'}
          </Button>
        )}
      </div>
    </form>
  )
}

export const CreateKeyModal = ({
  open,
  setOpen,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {workspace && <CreateKeyForm setOpen={setOpen} workspace={workspace} />}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
