import { DatasetItemSchema } from 'api/src/routers/evals/schemas'
import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import { usePresignedUpload } from 'next-s3-upload'
import React, { useState } from 'react'
import type { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import { useCurrentApp, useUser } from '@/common/hooks'
import { DATASET_LABELS } from '@/lib/finetunes'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { importDataFormSchema, importDataFromOpenAIFileFormSchema } from './schema'
import type { FormProps, ImportDataFormInputs, ImportDataFromOpenAIFileFormInputs } from './types'

export const useImportDataForm = ({ dataset, workspace, onIsOpenChange }: FormProps) => {
  const importData = api.data.importData.useMutation()
  const utils = api.useContext()
  const { uploadToS3, files: uploadedFiles } = usePresignedUpload()
  const [files, setFiles] = React.useState<File[]>([])
  const { app } = useCurrentApp()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: ImportDataFormInputs = {
      datasetName: '',
      datasetId: dataset?.id,
      labels: '',
    }

    return initialValues
  }, [dataset?.id])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Data import started',
      description: 'Your data is being imported',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (values: ImportDataFormInputs, { resetForm }: FormikHelpers<ImportDataFormInputs>) => {
      if (!app) {
        return
      }

      const file = files[0]
      if (!file) {
        toast.error({
          title: 'No file selected',
          description: 'Please select a file to import',
        })
        return
      }

      const fileExtension = file.name.split('.').pop()
      if (fileExtension !== 'csv' && fileExtension !== 'jsonl') {
        toast.error({
          title: 'Invalid file type',
          description: 'Please upload either a .csv file or .jsonl file',
        })
        return
      }

      const { key } = await uploadToS3(file, {
        endpoint: {
          request: {
            body: {
              workspaceGuid: workspace.project_guid,
            },
            url: '/api/s3-upload',
          },
        },
      })

      await importData.mutateAsync(
        {
          datasetId: values.datasetId,
          workspaceId: workspace.id,
          appId: app.id,
          fileKey: key,
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error importing data',
              description: error.message,
            })
          },
          onSuccess,
        }
      )
      await utils.dataset.getAll.invalidate({ appId: app.id })
      await utils.datasetItem.getAll.invalidate({ datasetId: dataset?.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.data_imported, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onIsOpenChange(false)
    },
    [
      files,
      uploadToS3,
      workspace.project_guid,
      workspace.id,
      workspace.name,
      workspace?.slug,
      importData,
      utils.dataset.getAll,
      user,
      onSuccess,
      onIsOpenChange,
      dataset?.id,
      utils.datasetItem.getAll,
      app,
    ]
  )

  const formikConfig: FormikConfig<ImportDataFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: true,
      validationSchema: toFormikValidationSchema(importDataFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { isSubmitting, errors } = formik

  const saveDisabled = Object.keys(errors).length > 0 || isSubmitting

  const labelOptions = Object.values(DATASET_LABELS).map((label) => ({
    name: label,
    id: label,
  }))
  labelOptions.unshift({ name: 'Optimize (80% training/20% evaluation)', id: 'optimize' })
  labelOptions.unshift({ name: 'Both', id: '' })

  return {
    formik,
    saveDisabled,
    setFiles,
    files,
    uploadedFiles,
    labelOptions,
  }
}

export const useImportDataFromOpenAIFileForm = ({
  dataset,
  workspace,
  onIsOpenChange,
}: FormProps) => {
  const importDataFromOpenAIFile = api.dataset.importDataFromOpenAIFile.useMutation()
  const utils = api.useContext()
  const { app } = useCurrentApp()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: ImportDataFromOpenAIFileFormInputs = {
      datasetId: dataset?.id,
      datasetName: '',
      fileId: '',
    }

    return initialValues
  }, [dataset?.id])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Data import started',
      description: 'Your data is being imported',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: ImportDataFromOpenAIFileFormInputs,
      { resetForm }: FormikHelpers<ImportDataFromOpenAIFileFormInputs>
    ) => {
      if (!app) {
        return
      }
      await importDataFromOpenAIFile.mutateAsync(
        {
          workspaceId: workspace.id,
          appId: app.id,
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error importing data',
              description: error.message,
            })
          },
          onSuccess,
        }
      )
      await utils.dataset.getAll.invalidate({ appId: app.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.data_imported, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      workspace.id,
      workspace.name,
      workspace?.slug,
      importDataFromOpenAIFile,
      utils.dataset.getAll,
      user,
      onSuccess,
      onIsOpenChange,
      app,
    ]
  )

  const formikConfig: FormikConfig<ImportDataFromOpenAIFileFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: true,
      validationSchema: toFormikValidationSchema(importDataFromOpenAIFileFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { isSubmitting, errors } = formik

  const saveDisabled = Object.keys(errors).length > 0 || isSubmitting

  const labelOptions = Object.values(DATASET_LABELS).map((label) => ({
    name: label,
    id: label,
  }))
  labelOptions.unshift({ name: 'Optimize (80% training/20% evaluation)', id: 'optimize' })
  labelOptions.unshift({ name: 'Both', id: '' })

  return {
    formik,
    saveDisabled,
    labelOptions,
  }
}

export const useAddDatasetItemRowForm = ({ workspace, dataset, onIsOpenChange }: FormProps) => {
  const utils = api.useContext()
  const [isLoading, setLoading] = useState(false)
  const createManyDatasetItems = api.datasetItem.createMany.useMutation()

  const schema = DatasetItemSchema
  type FormSchema = z.infer<typeof schema>

  const initialValues = React.useMemo(() => {
    const initialValues: FormSchema = {
      input: '',
      output: '',
      labels: undefined,
    }

    return initialValues
  }, [])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Data import started',
      description: 'Your data is being imported.',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (values: FormSchema, { resetForm, setFieldValue }: FormikHelpers<FormSchema>) => {
      if (!dataset) {
        return
      }
      setLoading(true)
      await createManyDatasetItems.mutateAsync(
        {
          workspaceId: workspace.id,
          datasetId: dataset.id,
          dataItems: [values],
        },
        {
          onSuccess: () => {
            toast.success({
              title: 'Dataset items created',
              description: 'Your dataset items have been created',
            })
            setFieldValue('input', '').catch(console.error)
            setFieldValue('output', '').catch(console.error)
            resetForm()
          },
          onError: (error) => {
            toast.success({
              title: 'Error',
              description: error.message,
            })
          },
          onSettled: () => {
            setLoading(false)
          },
        }
      )
      await utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
      await utils.eval.get.invalidate({})

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      dataset,
      createManyDatasetItems,
      workspace.id,
      utils.datasetItem.getAll,
      utils.eval.get,
      onSuccess,
      onIsOpenChange,
    ]
  )

  const formikConfig: FormikConfig<FormSchema> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(schema),
      onSubmit,
    }),
    [initialValues, onSubmit, schema]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { isSubmitting, errors } = formik

  const saveDisabled = Object.keys(errors).length > 0 || isSubmitting

  const labelOptions = Object.values(DATASET_LABELS).map((label) => ({
    name: label,
    id: label,
  }))
  labelOptions.unshift({ name: 'Training & Eval', id: '' })

  return {
    labelOptions,
    formik,
    saveDisabled,
    isLoading,
  }
}
