import type { Workspace } from '@prisma/client'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, Dialog, Input, toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { reservedWorkspaceNames } from '@/server/constants'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function WorkspaceForm() {
  const createWorkspace = api.workspace.create.useMutation()

  const router = useRouter()
  const { user } = useUser()

  async function redirect(workspace: Workspace) {
    toast.success({
      title: `${workspace.name || 'Workspace'} created`,
      description: 'Successfully created a new workspace',
    })
    await router.push(`/${workspace.slug}`)
  }

  const schema = z.object({
    name: z.string().refine(
      (name) => {
        return !reservedWorkspaceNames.includes(name.toLowerCase())
      },
      {
        message: 'Workspace name is not allowed',
      }
    ),
  })

  type FormSchema = z.infer<typeof schema>

  const formik = useFormik<FormSchema>({
    initialValues: {
      name: '',
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, { resetForm }) => {
      const workspace = await createWorkspace.mutateAsync(
        { ...values },
        {
          onSuccess: (workspace) => {
            if (user) {
              const id = user.id ? user.id : ''
              const email = user.email ? user.email : ''
              const uname = user.name ? user.name : ''
              identify_and_group(id, email, uname, workspace?.slug)
            }
            track(EVENT_NAMES.workspace_created, {
              'Workspace Name': workspace.name,
              'Workspace ID': workspace.id,
              'User ID': user?.id,
              'User Email': user?.email,
              'User Name': user?.name,
            })
            resetForm()
          },
          onError: (err) => {
            toast.error({
              title: 'Something went wrong',
              description: err.message,
            })
          },
        }
      )
      await redirect(workspace)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-6">
        <div className="flex flex-col max-w-lg">
          <Input
            id="name"
            name="name"
            label="Workspace Name"
            placeholder="Initech Workspace"
            message={formik.errors.name}
            hasError={!!formik.errors.name}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <span className="mt-2 text-sm text-grey-600">
            You can edit the name later in Settings
          </span>
        </div>
      </div>
      <Button
        variant="primary"
        disabled={formik.isSubmitting}
        loading={formik.isSubmitting}
        type="submit"
      >
        {formik.isSubmitting ? 'Creating your new Workspace...' : 'Create Workspace'}
      </Button>
    </form>
  )
}

export const CreateWorkspaceModal = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen} modal={true}>
      <Dialog.Content className={'p-6'}>
        <Dialog.Header>
          <h1 className="text-xl font-medium leading-6">Create Workspace</h1>
        </Dialog.Header>

        <WorkspaceForm />
      </Dialog.Content>
    </Dialog.Root>
  )
}
