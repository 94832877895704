import { z } from 'zod'

export const createDatabaseProviderFormSchema = z.object({
  provider: z.string(),
  name: z.string(),
  metadata: z
    .array(
      z.object({
        name: z.string({ required_error: 'This field is required ' }),
        value: z.string({ required_error: 'This field is required ' }),
      })
    )
    .optional()
    .default([]),
})
