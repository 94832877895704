import React from 'react'
import { Button, Dialog } from '@/client/components'
import { useCustomField } from '@/client/hooks'
import { Apps } from './components'
import type { NewDialogProps } from './types'

export const NewDialog = ({ isOpen, formik, onIsOpenChange }: NewDialogProps) => {
  const { dirty, isSubmitting, submitForm } = formik

  const { getFieldError } = useCustomField({ formik })

  const nameError = getFieldError('name')
  const appNameError = getFieldError('appName')
  const appIdError = getFieldError('appId')

  const saveDisabled = !dirty || isSubmitting || !!nameError || !!appNameError || !!appIdError

  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className={'p-6 ring-1 ring-grey-300/50'}>
        <Dialog.Header>
          <Dialog.Title asChild>
            <h3 className="title3">Save Action</h3>
          </Dialog.Title>
        </Dialog.Header>

        <Apps formik={formik} />

        <Button
          className="w-full"
          disabled={saveDisabled}
          onClick={() => {
            void submitForm()
          }}
        >
          Save
        </Button>
      </Dialog.Content>
    </Dialog.Root>
  )
}
