import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { ParsedUrlQueryInput } from 'querystring';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, EllipsisHorizontalIcon, LinkIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import type { Action, App, Dataset, Workspace } from '@prisma/client';
import { useEventListener } from 'liveblocks.config';
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { AppData, AppEval, FinetuneIcon } from '@/client/assets/icons/icons';
import { Button, DropdownMenu, Input, Loader, Progress, Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, toast, Tooltip } from '@/client/components';
import { CopyButton } from '@/client/components/MarkdownRenderer/CodeBlock/components';
import { OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { DatasetBulkActionProvider } from '@/common/components/datasets/context';
import { DataImport } from '@/common/components/datasets/DataImport';
import { EditDatasetItem } from '@/common/components/datasets/DatasetItemForm';
import { DatasetItemsGrid } from '@/common/components/datasets/DatasetItemsGrid';
import { DeleteDatasetModal } from '@/common/components/datasets/DeleteDataset';
import { useEditDatasetForm } from '@/common/components/datasets/EditDataset/Form/form';
import { ExpectedOutputCell } from '@/common/components/evalRuns/grid/cells/ExpectedOutputCell';
import { InputCell, StringInputWithImagePreviews } from '@/common/components/evalRuns/grid/cells/InputCell';
import { CreateFinetune } from '@/common/components/finetunes/CreateFinetune';
import { CreateEvalDrawer } from '@/common/components/modals/evals/CreateEvalDrawer';
import { Dataset404 } from '@/common/components/notFound/404';
import { MessagePromptRenderer } from '@/common/components/tables/v2/drawer';
import { useWorkspace } from '@/common/components/ui/context';
import { useCurrentApp, useCurrentWorkspace, useFetchDatasetItemByGuid, useFetchFinetunesForDataset } from '@/common/hooks';
import { useFetchDatasetByGuid } from '@/common/hooks/datasets';
import { withDataset } from '@/common/lib';
import type { Message } from '@/common/types/messages';
import type { DatasetItemWithData } from '@/server/api/routers/datasetItem';
import { type DatasetMetadata, type DetailedDataset } from '@/server/service/types';
import { api, classNames, dateAgo } from '@/utils';
import { FinetuneRow } from '../../optimization';
interface IParams extends ParsedUrlQueryInput {
  slug: string;
  datasetGuid: string;
}
function FinetuneTable({
  dataset,
  app
}: {
  dataset: DetailedDataset;
  app: App;
}) {
  const workspace = useWorkspace();
  const metadata = (dataset.metadata as DatasetMetadata);
  const isValidForFinetune = metadata.dataCount && metadata.dataCount > 10;
  const {
    finetunes,
    isLoading
  } = useFetchFinetunesForDataset(dataset.guid);
  if (isLoading) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400" />
      </div>;
  }

  // this will never be triggered due to the same check occuring 2 levels higher in the page assembly
  if (!finetunes || finetunes.length === 0) {
    return <div className="rounded-lg border border-grey-200 bg-white py-8 text-center shadow-sm">
        <FinetuneIcon className="mx-auto h-12 w-12 text-grey-400" />
        <h3 className="mt-2 text-sm font-semibold">Dataset Optimization</h3>

        {isValidForFinetune ? <>
            <p className="mt-1 text-sm text-grey-500">Fine-tune model using {dataset.name}</p>
            <div className="mt-6">
              <CreateFinetune app={app} dataset={dataset} />
            </div>
          </> : <>
            <p className="mt-1 text-sm text-grey-500">
              You need at least 10 data points to create a finetune.
            </p>
          </>}
      </div>;
  }
  return <div className="flex items-center justify-between overflow-clip rounded-lg border border-grey-200 dark:border-grey-600">
      <div className="w-full">
        <div className="inline-block w-full align-middle">
          <div className="">
            <table className="w-full min-w-full bg-white dark:bg-black divide-y divide-grey-300 dark:divide-grey-600">
              <thead className="bg-grey-50 dark:bg-grey-900">
                <tr>
                  <th scope="col" className="py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200">
                    Fine-tuned Model
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Base Model
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Model Name
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Training Dataset
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Data Points
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Date Created
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 dark:divide-grey-600">
                {finetunes?.map(finetune => <FinetuneRow key={finetune.id} finetune={finetune} workspace={workspace} app={app} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>;
}
export function DatasetFinetuneTable({
  dataset,
  app
}: {
  dataset: DetailedDataset;
  app: App;
}) {
  // TODO show relationship of evals when migrated Test Set over
  return <FinetuneTable dataset={dataset} app={app} />;
}
const DataItemDeleteForm = ({
  datasetItem,
  setOpen
}: {
  datasetItem: DatasetItemWithData;
  setOpen: (open: boolean) => void;
}) => {
  const deleteDataItem = api.datasetItem.delete.useMutation();
  const utils = api.useContext();
  const handleDelete = async (id: number) => {
    await deleteDataItem.mutateAsync({
      datasetItemId: id
    }, {
      onSuccess: () => {
        toast.success({
          title: 'Data item deleted',
          description: 'Successfully deleted the data item'
        });
      },
      onError: error => {
        toast.error({
          title: 'Your data item could not be deleted. Please try again',
          description: error.message
        });
      }
    });
    await utils.datasetItem.getAll.invalidate({
      datasetId: datasetItem.datasetId
    });
    setOpen(false);
  };
  return <div>
      <div className="mt-6 space-y-6 rounded-md border border-grey-200 bg-white px-4 pb-4 pt-5 shadow-sm sm:space-y-5 sm:p-6 sm:pb-6">
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-lg font-medium leading-6">Danger Zone</h3>
              {/* <p className="mt-1 max-w-2xl text-sm text-grey-500">Destructive options</p> */}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start">
          <Button variant="outline" onClick={() => void handleDelete(datasetItem.id)}>
            Delete this dataset item?
          </Button>
          <p className="ml-3 rounded-full bg-grey-50 p-2 px-3 font-mono text-xs">
            Warning: This cannot be undone, proceed with caution
          </p>
        </div>
      </div>
    </div>;
};
export function DatasetItemDrawer({
  datasetItemGuid,
  dataset,
  workspace,
  open,
  setOpen,
  selectNextRow,
  selectPreviousRow,
  hasPreviousRow,
  hasNextRow,
  action
}: {
  datasetItemGuid: string;
  dataset: Dataset;
  workspace: Workspace;
  action?: Action;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectNextRow: () => void;
  selectPreviousRow: () => void;
  hasPreviousRow: boolean;
  hasNextRow: boolean;
}) {
  const router = useRouter();
  const {
    slug
  } = (router.query as IParams);
  const {
    datasetItem
  } = useFetchDatasetItemByGuid({
    guid: datasetItemGuid
  });
  useEffect(() => {
    if (open) {
      const query = {
        ...router.query,
        datasetItemGuid
      };
      void router.push({
        pathname: router.pathname,
        query
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetItemGuid]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      /* Disallow keyboard shortcuts when the user is typing in the comment box */
      const commentEl = document.getElementsByClassName('lb-composer-editor')[0];
      /* Dissaallow keyboard shortcuts when the user is typing in the correction/feedback textarea box */
      const correctionEl = document.getElementById('correction');
      const isActive = commentEl === document.activeElement || correctionEl === document.activeElement;
      if (isActive) return;
      if (event.key === 'w' || event.key === 'W') {
        if (!hasPreviousRow) return;
        selectPreviousRow();
        return;
      }
      if (event.key === 's' || event.key === 'S') {
        if (!hasNextRow) return;
        selectNextRow();
        return;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [hasNextRow, hasPreviousRow, selectNextRow, selectPreviousRow]);
  const handleOpenChange = (val: boolean) => {
    if (val) {
      const query = {
        ...router.query,
        datasetItemGuid
      };
      void router.push({
        pathname: router.pathname,
        query
      });
    } else {
      delete router.query.datasetItemGuid;
      void router.push(router);
    }
    setOpen(val);
  };
  return <Sheet open={open} onOpenChange={handleOpenChange} modal={false}>
      <SheetContent className="max-w-4xl sm:max-w-3xl" onOpenAutoFocus={e => {
      e.preventDefault();
    }}>
        {!datasetItem ? <SheetHeader>
            <SheetTitle className="">
              <div className="flex items-center space-x-3" id="drawer-top">
                <div className="w-8 h-8 animate-pulse bg-grey-200 rounded-md" />
                <div className="w-8 h-8 animate-pulse bg-grey-200 rounded-md" />
              </div>
              <div className="mt-5 flex justify-between">
                <div className="flex flex-col space-y-2 group">
                  <div className="w-[350px] h-[30px] animate-pulse bg-grey-200 rounded-md" />
                  <div className="h-[20px] w-[200px] animate-pulse bg-grey-200 rounded-sm" />
                  <div className="flex gap-2 items-center font-mono font-light text-xs text-gray-700">
                    <div className="h-[20px] w-[250px] animate-pulse bg-grey-200 rounded-sm" />
                    <div className="h-[20px] w-[20px] animate-pulse bg-grey-200 rounded-sm" />
                  </div>
                </div>
              </div>
            </SheetTitle>
          </SheetHeader> : <DrawerContent datasetItem={datasetItem} dataset={dataset} appSlug={slug} workspace={workspace} selectNextRow={selectNextRow} selectPreviousRow={selectPreviousRow} hasPreviousRow={hasPreviousRow} hasNextRow={hasNextRow} setOpen={setOpen} action={action} />}
      </SheetContent>
    </Sheet>;
}
const DrawerContent = ({
  datasetItem,
  dataset,
  appSlug,
  workspace,
  selectNextRow,
  selectPreviousRow,
  hasPreviousRow,
  hasNextRow,
  setOpen,
  action
}: {
  datasetItem: DatasetItemWithData;
  dataset: Dataset;
  appSlug: string;
  workspace: Workspace;
  selectNextRow: () => void;
  selectPreviousRow: () => void;
  hasPreviousRow: boolean;
  hasNextRow: boolean;
  setOpen: (open: boolean) => void;
  action?: Action;
}) => {
  const [selected, setSelected] = useState('details');
  const tabs = [{
    name: 'Details',
    id: 'details'
  }, {
    name: 'Edit',
    id: 'edit'
  }, {
    name: 'Delete',
    id: 'delete'
  }];
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());
  params.set('datasetItemGuid', datasetItem.guid);
  const permalink = `${window.location.host}${window.location.pathname}?${params.toString()}`;
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the event's target is an input field
      if (event.target instanceof HTMLInputElement) {
        // If it is, stop the execution of the event handler
        return;
      }

      /* Dissaallow keyboard shortcuts when the user is typing in the output textarea box */
      const outputEl = document.getElementById('output');
      const isActive = outputEl === document.activeElement;
      if (isActive) return;
      if (event.key === 'w' || event.key === 'W') {
        if (!hasPreviousRow) return;
        selectPreviousRow();
        return;
      }
      if (event.key === 's' || event.key === 'S') {
        if (!hasNextRow) return;
        selectNextRow();
        return;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [hasNextRow, hasPreviousRow, selectNextRow, selectPreviousRow]);
  let fullPromptAsJson: Message[] = [];
  let fullPromptAsString = '';
  try {
    fullPromptAsJson = datasetItem ? (JSON.parse(datasetItem.full_prompt_sent || '[]') as Message[]) : [];
  } catch (error) {
    console.error('Failed to parse JSON, outputting the string itself: ', error);
    fullPromptAsString = datasetItem?.full_prompt_sent ?? '';
  }

  // the item has variables for the input, but not `messages` or `tools`
  const showFullPrompt = typeof datasetItem.input != 'string' && !Array.isArray(datasetItem.input) && Object.keys(datasetItem.input || {}).length > 0 && !Object.keys(datasetItem.input || {}).includes('messages');
  return <SheetHeader>
      <SheetTitle className="">
        <div className="flex items-center space-x-2" id="drawer-top">
          <Tooltip.Root delayDuration={0} disableHoverableContent={true}>
            <Tooltip.Trigger asChild>
              <button type="button" onClick={selectPreviousRow} className={`p-2 focus:border-none focus:outline-none focus:ring-0 ${!hasPreviousRow ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={!hasPreviousRow}>
                <ChevronUpIcon className="h-4 w-4 text-grey-700" />
              </button>
            </Tooltip.Trigger>
            {hasPreviousRow ? <Tooltip.Content side="bottom" align="start" className="font-normal flex items-center gap-2.5">
                <span>Go to previous data point</span>
                <div className="inline-block min-w-3 rounded-md bg-grey-100 ring-1 ring-grey-200 px-1 py-1 text-center text-xs">
                  W
                </div>
              </Tooltip.Content> : null}
          </Tooltip.Root>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>
              <button type="button" onClick={selectNextRow} className={`p-2 focus:border-none focus:outline-none focus:ring-0 ${!hasNextRow ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={!hasNextRow}>
                <ChevronDownIcon className="h-4 w-4 text-grey-700" />
              </button>
            </Tooltip.Trigger>
            {hasNextRow ? <Tooltip.Content side="bottom" align="start" className="font-normal flex items-center gap-2.5">
                <span>Go to next data point</span>
                <div className="inline-block min-w-3 rounded-md bg-grey-100 ring-1 ring-grey-200 px-1 py-1 text-center text-xs">
                  S
                </div>
              </Tooltip.Content> : null}
          </Tooltip.Root>
        </div>
        <div className="mt-5 flex justify-between">
          <div className="flex flex-col space-y-2 group">
            <div className="flex items-center gap-3">
              {dataset.name}
              <div className="cursor-pointer hidden group-hover:flex items-center justify-center">
                <CopyButton text={permalink} icon={LinkIcon} />
              </div>
            </div>
            <span className="text-xs text-grey-500 font-light">
              {`${datasetItem.data ? 'Added' : 'Imported'} ${dateAgo(new Date(datasetItem.createdAt))}`}
            </span>
            <div className="flex items-center font-mono font-light text-xs text-gray-700">
              <div>{datasetItem.guid}</div>
              <div className="ml-2">
                <CopyButton text={datasetItem.guid} />
              </div>
            </div>
          </div>

          <div>
            {datasetItem.data ? <Tooltip.Root delayDuration={333}>
                <Tooltip.Trigger asChild>
                  <button type="button" onClick={() => {
                if (!datasetItem.data) return;
                void router.push(`/${workspace.slug}/apps/${appSlug}/data?guid=${datasetItem.data.guid}`);
              }} className="bg-white hover:bg-grey-50 hover:border-grey-400 cursor-pointer inline-flex h-9 w-9 items-center justify-center rounded-md border">
                    <AppData className="h-5 w-5 text-grey-700" />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content sideOffset={5} side="bottom" align="end" className="font-normal">
                  Open data source
                </Tooltip.Content>
              </Tooltip.Root> : null}
          </div>
        </div>
      </SheetTitle>
      <SheetDescription>
        <div className="p-4 sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select id="tabs" name="tabs" className="w-full rounded-md border-grey-300 py-2 pl-3 pr-10 text-base focus:border-grey-500 focus:outline-none focus:ring-grey-500 block" defaultValue={tabs.find(tab => tab.id === selected)?.name}>
            {tabs.map(tab => <option key={tab.name}>{tab.name}</option>)}
          </select>
        </div>
        <div className="">
          <div className="border-b border-grey-300">
            <nav className="flex space-x-8 overflow-x-auto" aria-label="Tabs">
              {tabs.map(tab => {
              return <button key={tab.name} onClick={() => setSelected(tab.id)} className={classNames(selected === tab.id ? 'border-grey-800 text-grey-800' : 'border-transparent text-grey-500 hover:border-grey-300', 'whitespace-nowrap border-b-2 py-4 text-sm font-medium')}>
                    {tab.name}
                  </button>;
            })}
            </nav>
          </div>
        </div>

        {selected === 'details' && <div className="text-left divide-y divide-gray-100">
            {showFullPrompt ? <div className="border-b border-white flex flex-col gap-4 py-4">
                <div className="text-xs text-grey-500 uppercase p-0">prompt</div>
                <div className="text-sm leading-6 text-grey-900 font-inter select-text">
                  <div className="divide-y divide-gray-100">
                    <div className="sm:grid sm:gap-4">
                      <dd className="text-sm leading-6 text-gray-700 col-span space-y-5">
                        {Array.isArray(fullPromptAsJson) && fullPromptAsJson.length > 0 ? fullPromptAsJson.map(({
                    role,
                    content
                  }, index) => <>
                              <MessagePromptRenderer key={index} role={role} content={content!} />
                            </>) : <span className="text-sm leading-6 text-grey-900 select-text font-inter">
                            <StringInputWithImagePreviews value={fullPromptAsString} />
                          </span>}
                      </dd>
                    </div>
                  </div>
                </div>
              </div> : null}
            <div className="border-b border-white flex flex-col gap-4 py-4">
              <div className="text-xs text-grey-500 uppercase p-0">Input</div>
              <div className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                <InputCell className="max-h-full pt-0 pb-0" value={typeof datasetItem.input === 'string' ? datasetItem.input : JSON.stringify(datasetItem.input)} />
              </div>
            </div>

            <div className="border-b border-white flex flex-col gap-4 py-4">
              <div className="text-xs text-grey-500 uppercase p-0">Output</div>
              <div className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                <ExpectedOutputCell className="max-h-full pt-0 pb-0" value={typeof datasetItem.output === 'string' ? datasetItem.output : JSON.stringify(datasetItem.output)} />
              </div>
            </div>

            {datasetItem.context && JSON.stringify(datasetItem.context) !== '[]' && <div className="flex flex-col gap-4 py-5">
                <dt className="text-xs text-grey-500 uppercase">Context</dt>
                <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                  {JSON.stringify(datasetItem.context)}
                </dd>
              </div>}
            <div className="flex flex-col gap-4 py-5">
              <dt className="text-xs text-grey-500 uppercase">Split</dt>
              <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                {datasetItem.labels.length > 0 ? datasetItem.labels.join(', ') : 'Both'}
              </dd>
            </div>
            <div className="flex flex-col gap-4 py-5">
              <dt className="text-xs text-grey-500 uppercase">Tags</dt>
              <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text space-x-1">
                {datasetItem.tags?.map(tag => <span key={tag} className="inline-block px-2 py-1 bg-grey-100 rounded-md">
                    {tag}
                  </span>)}
              </dd>
            </div>
            <div className="flex flex-col gap-4 py-5">
              <dt className="text-xs text-grey-500 uppercase">Input Tokens</dt>
              <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                {datasetItem.num_input_tokens || datasetItem.num_input_tokens === 0 ? datasetItem.num_input_tokens : <span className="text-grey-500">Please wait ...</span>}
              </dd>
            </div>
            <div className="flex flex-col gap-4 py-5">
              <dt className="text-xs text-grey-500 uppercase">Output Tokens</dt>
              <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                {datasetItem.num_output_tokens || datasetItem.num_output_tokens === 0 ? datasetItem.num_output_tokens : <span className="text-grey-500">Please wait ...</span>}
              </dd>
            </div>
            {datasetItem.num_context_tokens !== undefined && datasetItem.num_context_tokens !== 0 && <div className="flex flex-col gap-4 py-5">
                  <dt className="text-xs text-grey-500 uppercase">Context Tokens</dt>
                  <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                    {datasetItem.num_context_tokens}
                  </dd>
                </div>}
            <div className="flex flex-col gap-4 py-5">
              <dt className="text-xs text-grey-500 uppercase">Source</dt>
              <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter">
                {!datasetItem.data ? 'Imported' : <Link href={`/${workspace.slug}/apps/${appSlug}/data?guid=${datasetItem.data.guid}`} className="inline-flex items-center space-x-2">
                    <span>Data: {datasetItem.data.guid}</span>
                  </Link>}
              </dd>
            </div>
          </div>}

        {selected === 'edit' && <EditDatasetItem action={action} datasetItem={datasetItem} />}

        {selected === 'delete' && <DataItemDeleteForm datasetItem={datasetItem} setOpen={setOpen} />}
      </SheetDescription>
    </SheetHeader>;
};
interface IParams extends ParsedUrlQueryInput {
  datasetItemGuid: string;
}
function DatasetPage() {
  const router = useRouter();
  const {
    workspace
  } = useCurrentWorkspace();
  const {
    datasetGuid
  } = (router.query as IParams);
  const {
    app
  } = useCurrentApp();
  const {
    dataset
  } = useFetchDatasetByGuid(datasetGuid);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openCreateModal, toggleCreateModal] = useState(false);
  const {
    finetunes
  } = useFetchFinetunesForDataset(datasetGuid);
  return <Sidebar>
      <Head>
        <title>{dataset?.name} / Klu.ai</title>
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-grey-200 bg-white bg-opacity-50 backdrop-blur dark:bg-black">
          <div className="max-w-8xl mx-auto flex flex-1 justify-between px-4 sm:px-6">
            <OpenMobileSidebarButton />
            {workspace && app && dataset && <div className="flex items-center">
                <h1 className="min-w-fit text-xl font-semibold text-grey-800 items-center flex gap-1">
                  <Link href={`/${workspace.slug}/apps/${app.slug}/datasets`}>Dataset</Link>
                  <ChevronRightIcon className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                  <DatasetName dataset={dataset} app={app} />
                </h1>
              </div>}
            <div className="flex items-center space-x-4">
              {workspace && app && dataset && <>
                  <DeleteDatasetModal dataset={dataset} workspace={workspace} app={app} isOpen={isDeleteModalOpen} onIsOpenChange={setIsDeleteModalOpen} />
                  <DatasetImportStatus datasetId={dataset?.id} />
                  <DataImport workspace={workspace} dataset={dataset} />
                  <CreateFinetune app={app} dataset={dataset} />

                  <CreateEvalDrawer dataset={dataset} open={openCreateModal} setOpen={toggleCreateModal} app={app} />
                  <Button startIcon={AppEval} onClick={() => toggleCreateModal(true)} tooltip={{
                content: 'Add new Eval',
                side: 'bottom'
              }}>
                    Add Eval
                  </Button>
                </>}
              <DropdownMenu.Root modal={false}>
                <DropdownMenu.Trigger asChild>
                  <Button variant={'outline'} size={'sm'} startIcon={EllipsisHorizontalIcon} />
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end" alignOffset={2}>
                  <DropdownMenu.Item onClick={() => {
                  setIsDeleteModalOpen(true);
                }} className="flex items-center gap-2 text-red-600 w-full">
                    <XMarkIcon className="w-4 h-4" />
                    <span>Delete</span>
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
          </div>
        </div>
        <main>
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            {workspace && dataset && app && <DatasetBulkActionProvider app={app} dataset={dataset} workspace={workspace} isOpen={isOpenExport} onIsOpenChange={setIsOpenExport}>
                <div className="py-6 space-y-6">
                  {finetunes && finetunes.length > 0 && <DatasetFinetuneTable dataset={dataset} app={app} />}
                  {/* <DatasetItemsTable dataset={dataset} app={app} /> */}
                  <DatasetItemsGrid dataset={dataset} />
                </div>
              </DatasetBulkActionProvider>}
          </div>
        </main>
      </div>
    </Sidebar>;
}
function DatasetName({
  dataset,
  app
}: {
  dataset: Dataset;
  app: App;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const nameInput = useRef<HTMLInputElement>(null);
  const workspace = useWorkspace();
  const {
    formik
  } = useEditDatasetForm({
    dataset,
    workspace,
    app,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onIsOpenChange: () => {},
    isOpen: false
  });
  const {
    values,
    errors,
    handleChange
  } = formik;
  const handleSubmit = () => {
    if (values.name === dataset.name) return;
    void formik.handleSubmit();
  };
  return <div className="inline-flex justify-center items-center w-full group">
      {isEditing ? <div className="flex flex-row rounded-md transition-all hover:ring-1 hover:ring-grey-200 focus-within:ring-grey-400 focus-within:ring">
          <Input ref={nameInput} className="text-xl font-semibold flex-grow min-w-fit [&>#wrapper]:border-0" id="name" name="name" label="Name" maxLength={50} autoComplete="off" hideLabel onBlur={() => {
        void handleSubmit();
        setIsEditing(false);
      }} onChange={handleChange} onKeyUp={e => {
        if (e.key === 'Enter') {
          void handleSubmit();
          setIsEditing(false);
        }
      }} value={values.name} placeholder="Customer Feedback Analysis" hasError={!!errors.name} message={errors.name} />
        </div> : <div className="text-xl flex items-center">
          {values.name}
          <PencilSquareIcon className="h-5 w-5 ml-2 text-grey-500 cursor-pointer group-hover:visible invisible" onClick={() => {
        setIsEditing(true);
        setTimeout(() => nameInput.current?.focus(), 50);
      }} />
        </div>}
    </div>;
}
export const DatasetImportStatus = ({
  datasetId,
  hideLabel
}: {
  datasetId: number;
  hideLabel?: boolean;
}) => {
  const [progress, setProgress] = useState(0);
  const [importStatus, setImportStatus] = useState<string | undefined>();
  const timer = useRef<NodeJS.Timeout | null>(null);
  const utils = api.useContext();
  useEventListener(({
    event
  }) => {
    const {
      progress,
      name,
      datasetId: updatedDatasetId,
      status
    } = (event as {
      progress: number;
      name: string;
      datasetId: number;
      status: string;
    });
    if (updatedDatasetId == datasetId && name == 'importProgress') {
      console.log('Data imported', status, progress);
      setImportStatus(status);
      setProgress(progress);
    }
  });
  useEffect(() => {
    if (!importStatus) {
      return;
    }
    console.log('status:', importStatus);
    console.log('progress:', progress);
    if (progress >= 100 && timer.current === null) {
      void utils.datasetItem.getAll.invalidate({
        datasetId
      });
      void utils.dataset.getAll.invalidate({});
      if (['empty', 'failed'].includes(importStatus)) {
        toast.error({
          title: 'Import failed',
          description: 'Your data could not be imported. Please try again'
        });
      } else {
        toast.success({
          id: `import-complete-${datasetId}`,
          title: 'Import complete',
          description: "Successfully imported your data. It's now available for use"
        });
      }
      timer.current = setTimeout(() => {
        setProgress(0);
        timer.current = null;
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, progress, importStatus]);
  if (progress == 0) {
    return null;
  }
  return <div className="flex items-center text-sm text-grey-500 gap-3">
      {progress == 100 ? 'Data imported!' : <>
          {!hideLabel ? 'Data import progress' : ''}
          {progress && <Progress value={progress} className="h-3 w-[80px]" />}
        </>}
    </div>;
};
const DatasetViewerPage404Wrapper: NextPage = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <Dataset404 />;
  }
  return <DatasetPage {...props} />;
};
export default _withSuperJSONPage(DatasetViewerPage404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withDataset(), []);