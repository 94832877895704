import React from 'react'
import { KluSparkIcon } from '@/client/assets/icons/icons'
import { Button, MarkdownRenderer, Select, toast } from '@/client/components'
import type { ActionContentType } from '@/client/types'
import { useFetchEnvironments } from '@/common/hooks'
import { useActionContent } from '../logic'
import type { UseActionContentProps } from '../types'
import { useDeployAction } from './Deploy/Form/form'

export function ActionAPIDetails({ action, workspace }: UseActionContentProps) {
  const { contentTypeOptions, contentType, setContentType, getMarkdownContent, getGUID } =
    useActionContent({ action })

  const { environments } = useFetchEnvironments(workspace.id)

  const { formik } = useDeployAction({
    workspace,
    action,
  })

  const handleDeployToEnvironment = React.useCallback(
    async (name: string) => {
      if (!environments) return
      try {
        const selectedEnv = environments.find((env) => env.name === name)
        if (selectedEnv) {
          await formik.setFieldValue('environmentId', selectedEnv.id)
          formik.handleSubmit()
        }
      } catch (error) {
        toast.error({
          title: 'Deployment failed',
          description: 'Failed to deploy the action to the selected environment',
        })
        console.error(error)
      }
    },
    [environments, formik]
  )

  if (action.status !== 'ACTIVE') {
    return (
      <div className="rounded-sm w-full py-8 text-center shadow-sm border border-grey-200">
        <div className="flex max-w-full items-center justify-center py-12">
          <div className="text-center">
            <KluSparkIcon className="mx-auto h-12 w-12 fill-grey-400" />
            <h3 className="mt-2 text-sm font-semibold">Public Access</h3>
            <p className="mt-1 text-sm text-grey-500">
              Deploy {action.name} to make it publicly accessible.
            </p>

            <div className="flex flex-col gap-3">
              <Button className="mt-4" onClick={() => void handleDeployToEnvironment('Production')}>
                Deploy to Production
              </Button>

              <p className="text-xs opacity-50">or deploy to another environment</p>

              <div className="flex flex-col items-center gap-2 w-full">
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => void handleDeployToEnvironment('Staging')}
                >
                  Deploy to Staging
                </Button>

                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => void handleDeployToEnvironment('Preview')}
                >
                  Deploy to Preview
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Select.Field
        id="content-type"
        label="Content type"
        hideLabel
        className="hover:bg-grey-25"
        options={contentTypeOptions}
        value={contentType}
        onChange={(newValue) => {
          setContentType(newValue as ActionContentType)
        }}
      />

      <div className="flex flex-col gap-4">
        <div>
          {contentType === 'url' ||
          contentType === 'Python' ||
          contentType === 'Typescript' ||
          contentType === 'bash' ? (
            <div>
              <p className="caption1">
                {contentType === 'url' && 'Public URL for '}
                {contentType === 'Python' && 'Python SDK for '}
                {contentType === 'Typescript' && 'Typescript SDK for '}
                {contentType === 'bash' && 'cURL Request for '}
                {action.name}
              </p>
            </div>
          ) : null}

          <MarkdownRenderer markdownText={getMarkdownContent()} />
        </div>

        <div>
          <div className="caption1">{action.name} Action GUID</div>

          <span className="w-full items-center">
            <MarkdownRenderer markdownText={getGUID()} />
          </span>
        </div>
      </div>
    </>
  )
}
