import type { Action, Workspace } from '@prisma/client'
import React from 'react'
import { Button, Dialog } from '@/client/components'
import { ActionVersions } from '@/client/containers/views/Studio/components/Topbar/components/PublishAction/components/PublishDialog/components/ActionVersions'

export const useActionVersionsModal = () => {
  const [isOpen, setOpen] = React.useState(false)

  const render = ({
    action,
    workspace,
    appslug,
  }: {
    action: Action
    workspace: Workspace
    appslug: string
  }) => (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Content className="max-w-4xl min-h-3xl bg-white ring-1 ring-black/5 gap-0">
        <Dialog.Header>
          <div className="p-6">
            <Dialog.Title asChild>
              <h3 className="title3 grow">
                <span className="text-grey-900 dark:text-white">{action.name}</span> {'Versions'}
              </h3>
            </Dialog.Title>
          </div>
        </Dialog.Header>
        <div className="px-6 flex flex-col space-y-6">
          <ActionVersions action={action} workspace={workspace} appslug={appslug} />
        </div>

        <Dialog.Footer className="mt-5 flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
          <Button className="min-w-full" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )

  return { render, setOpen }
}
