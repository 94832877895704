import type { Tool, Workspace } from '@prisma/client'
import { useEffect, useState } from 'react'
import { SkillIcon } from '@/client/assets/icons/icons'
import {
  Button,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/client/components'
// import { useFetchConnections } from '@/common/hooks'
import { Form } from './Form'

// import { IntegrationForm } from './Form/integration-form'

export const CreateSkill = ({ workspace }: { workspace: Workspace }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [newSkill, setNewSkill] = useState<Tool | null>(null)

  return (
    <>
      <CreateSkillDrawer
        workspace={workspace}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setNewSkill={setNewSkill}
        newSkill={newSkill}
      />
      <Button onClick={() => setIsOpen(true)} startIcon={SkillIcon}>
        Add Skill
      </Button>
    </>
  )
}

export const CreateSkillDrawer = ({
  workspace,
  isOpen,
  setIsOpen,
  newSkill,
  setNewSkill,
}: {
  workspace: Workspace
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  newSkill?: Tool | null
  setNewSkill?: (tool: Tool | null) => void
}) => {
  useEffect(() => {
    if (setNewSkill) {
      setNewSkill(null)
    }
  }, [isOpen, setNewSkill])

  // const { connections } = useFetchConnections(workspace.project_guid)
  // const [formType, setFormType] = useState<'default' | 'integration'>('default')

  return (
    <>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetContent className="max-w-4xl sm:max-w-5xl">
          <SheetTitle className="p-4">
            <header className="flex items-center justify-between">
              <div>
                <h1 className="text-xl font-medium leading-6">Add Skill</h1>
              </div>
              {/* <div className="flex items-center space-x-4">
                {connections && connections.length > 0 && (
                  <button
                    onClick={() => setFormType(formType === 'default' ? 'integration' : 'default')}
                    className="flex items-center rounded-md border border-grey-300 bg-white px-4 py-2 text-sm font-medium shadow-sm hover:border-grey-400"
                  >
                    {formType === 'default' ? (
                      <>
                        <SquaresPlusIcon className="mr-2 h-5 w-5 text-grey-600" />
                        From Integration
                      </>
                    ) : (
                      <>
                        <SkillIcon className="mr-2 h-5 w-5 text-grey-600" />
                        Browse Skills
                      </>
                    )}
                  </button>
                  // Content for when integrations are available
                )}
              </div> */}
            </header>
          </SheetTitle>
          <SheetHeader>
            <SheetDescription>
              {workspace && !newSkill && (
                <>
                  {/* {formType === 'default' ? ( */}
                  <Form
                    workspace={workspace}
                    onIsOpenChange={setIsOpen}
                    setNewSkill={setNewSkill}
                  />
                  {/* ) : ( */}
                  {/* <IntegrationForm workspace={workspace} setOpen={setIsOpen} /> */}
                  {/* )} */}
                </>
              )}
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  )
}
