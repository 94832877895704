import { InformationCircleIcon } from '@heroicons/react/24/outline'
import * as Tooltip from '@radix-ui/react-tooltip'
import type { DetailedContext } from '@/common/types/context'

export function ProcessingLog({ index }: { index: DetailedContext }) {
  return (
    <>
      {index.processingLog && (
        <Tooltip.Root>
          <Tooltip.Trigger>
            <InformationCircleIcon className="ml-2 h-4 w-4 text-grey-500" />
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-s bg-white">
            <div className="caption1 flex max-h-[300px] w-450px flex-col-reverse justify-end overflow-y-scroll rounded-md bg-grey-950 p-2 text-white/[.92]">
              <pre className="whitespace-pre-wrap">{index.processingLog}</pre>
            </div>
          </Tooltip.Content>
        </Tooltip.Root>
      )}
    </>
  )
}
