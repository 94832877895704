import React from 'react'

export const useSelectActionDialog = () => {
  const [isOpenSelectActionDialog, setIsOpenSelectActionDialog] = React.useState(false)

  const onIsOpenSelectActionDialogChange = (open: boolean) => {
    setIsOpenSelectActionDialog(open)
  }

  return {
    isOpenSelectActionDialog,
    onIsOpenSelectActionDialogChange,
  }
}
