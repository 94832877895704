import { api } from '@/utils'
import { useUser } from './user'

export function useFetchAppCount(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getAppCount.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchActionCount(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getActionCount.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchExperimentCount(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getExperimentCount.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchFinetuneCount(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getFinetuneCount.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchDataCount(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getDataCount.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchFeedbackCount(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getFeedbackCount.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchIssueCount(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getIssueCount.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchErrorCount(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getErrorCount.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchAvgLatency(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getAvgLatency.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchAvgInputTokens(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getAvgInputTokens.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchAvgOutputTokens(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getAvgOutputTokens.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchTotalCost(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getTotalCost.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchTopSources(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getTopSources.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchTopModels(workspaceId: number, appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getTopModels.useQuery(
    { workspaceId, appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchWorkspaceUsageDataGraph(
  workspaceId: number,
  filters: {
    startDate: number
    endDate: number
    intervalSize: number
    appId?: number
    actionId?: number
    experimentId?: number
  }
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getUsageGraphData.useQuery(
    { workspaceId, filters },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchWorkspaceUsageRange(
  workspaceId: number,
  filters: { appId?: number; experimentId?: number }
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getUsageGraphRange.useQuery(
    { workspaceId, filters },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
  return {
    usageData: data,
    isLoading,
    error,
  }
}
