import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { api } from '@/utils'
import { useDatasetBulkAction } from '../../context'
import { deduplicateDatasetFormSchema } from './schema'
import type { DeduplicateDatasetFormInputs, UseDeduplicateDatasetFormProps } from './types'

export const useDeduplicateDatasetForm = ({
  onIsOpenChange,
  onUpdateSuccess,
}: UseDeduplicateDatasetFormProps & {
  onUpdateSuccess?: () => void
}) => {
  const {
    selectedIds,
    dataset,
    clearSelectedItems,
    selectAllRows,
    toggleSelectAllRows,
    dataCount,
  } = useDatasetBulkAction()
  const [loading, setLoading] = React.useState(false)

  const deduplicateDataset = api.dataset.deduplicateDataset.useMutation()
  const utils = api.useContext()

  const initialValues = React.useMemo(() => {
    const initialValues: DeduplicateDatasetFormInputs = {
      selectAll: selectAllRows,
      datasetId: dataset.id,
      threshold: 0.9,
      attribute: 'input',
    }

    return initialValues
  }, [dataset.id, selectAllRows])

  const onSubmit = React.useCallback(
    async (
      values: DeduplicateDatasetFormInputs,
      { resetForm }: FormikHelpers<DeduplicateDatasetFormInputs>
    ) => {
      setLoading(true)
      await deduplicateDataset.mutateAsync(
        {
          ...values,
          datasetItemIds: selectedIds,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error deduplicating dataset',
              description: error.message,
            })
          },
          onSuccess: () => {
            toast.success({
              title: 'Dataset items updated',
              description: 'Your dataset is being deduplicated',
            })
            void utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
            void utils.dataset.getByGuid.invalidate({ guid: dataset.guid })
            onUpdateSuccess?.()
          },
        }
      )

      setLoading(false)
      clearSelectedItems()

      resetForm()
      onIsOpenChange(false)
    },
    [
      deduplicateDataset,
      selectedIds,
      clearSelectedItems,
      onIsOpenChange,
      utils.datasetItem.getAll,
      utils.dataset.getByGuid,
      dataset.id,
      dataset.guid,
      onUpdateSuccess,
    ]
  )

  const formikConfig: FormikConfig<DeduplicateDatasetFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(deduplicateDatasetFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
    loading,
    selectedIds,
    clearSelectedItems,
    dataset,
    selectAllRows,
    toggleSelectAllRows,
    dataCount,
  }
}
