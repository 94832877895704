import React from 'react'
import { Button, Dialog } from '@/client/components'
import { classNames } from '@/utils'
import type { TabType } from '../../../../../../context'
import { useDeployTab } from '../../../../../../context'
import type { PublishDialogProps } from '../../../../types'
import { ActionAPIDetails } from '../../../ActionAPIDetails'
import { ActionVersions } from '../../../ActionVersions'

export const StandardPublishDialog = ({
  isOpen,
  action,
  workspace,
  appslug,
  onIsOpenChange,
}: PublishDialogProps) => {
  const { selectedTab, setSelectedTab } = useDeployTab()

  const tabs: {
    id: TabType
    name: string
  }[] = [
    { id: 'versions', name: 'Versions' },
    { id: 'api', name: 'API' },
  ]

  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-4xl min-h-3xl bg-white ring-1 ring-black/5 gap-0">
        <Dialog.Header>
          <div className="p-6">
            <Dialog.Title asChild>
              <h3 className="title3 grow">
                {`Publish `}
                <span className="text-grey-900 dark:text-white">{action.name}</span>
              </h3>
            </Dialog.Title>

            <div>
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. test. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="hidden w-full rounded-md border-grey-300 py-2 pl-3 pr-10 text-base focus:border-grey-500 focus:outline-none focus:ring-grey-500 sm:block sm:text-sm"
                  defaultValue={tabs.find((tab) => tab.id === selectedTab)?.name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-grey-300 dark:border-grey-700">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <button
                        key={tab.name}
                        onClick={() => setSelectedTab(tab.id)}
                        className={classNames(
                          selectedTab === tab.id
                            ? 'border-grey-800 text-grey-800 dark:border-grey-200 dark:text-grey-200'
                            : 'border-transparent text-grey-600 hover:border-grey-800 hover:text-grey-800 dark:text-grey-600',
                          'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                        )}
                      >
                        {tab.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Header>
        <div className="px-6 flex flex-col space-y-6">
          {selectedTab === 'versions' && (
            <ActionVersions
              action={action}
              workspace={workspace}
              appslug={appslug}
              onIsOpenChange={onIsOpenChange}
            />
          )}
          {selectedTab === 'api' && <ActionAPIDetails action={action} workspace={workspace} />}
        </div>

        <Dialog.Footer className="mt-5 flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
          <Button
            className="min-w-full"
            onClick={() => {
              onIsOpenChange(false)
            }}
          >
            Close
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
