import React from 'react'
import { StudioContext } from '.'

export default function useStudio() {
  const context = React.useContext(StudioContext)

  if (!context) {
    throw new Error('useStudio must be used within a StudioProvider')
  }

  return context
}
