import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { ParsedUrlQueryInput } from 'querystring';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import type { Context, Workspace } from '@prisma/client';
import { useFormik } from 'formik';
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Button, toast } from '@/client/components';
import { OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { DeleteDocumentModal } from '@/common/components/modals/documents/DeleteDocumentModal';
import { useCurrentWorkspace, useFetchContextBySlug, useFetchDocument } from '@/common/hooks';
import { withContext } from '@/common/lib';
import type { Document } from '@/common/types/document';
import { api } from '@/utils';
interface IParams extends ParsedUrlQueryInput {
  documentId: string;
  contextSlug: string;
}
const DocumentPage: NextPage = ({}) => {
  const router = useRouter();
  const {
    documentId,
    contextSlug
  } = (router.query as IParams);
  const {
    workspace
  } = useCurrentWorkspace();
  const {
    document,
    isLoading
  } = useFetchDocument(parseInt(documentId));
  const {
    context,
    isLoading: isLoadingContext
  } = useFetchContextBySlug(contextSlug);
  return <Sidebar>
      {isLoading || isLoadingContext || document ? <div className="hidden">Embeds Loading ...</div> : null}
      <Head>
        <title>Document {document?.id} / Klu.ai</title>
        <meta name="description" content="Document detail page" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-grey-200 bg-white bg-opacity-50 backdrop-blur dark:bg-black">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-4">
            <OpenMobileSidebarButton />
            {workspace && document && <div className="flex flex-1 items-center">
                <Link href={`/${workspace.slug}/context`}>
                  <h1 className="text-xl font-semibold text-grey-800">Context Library</h1>
                </Link>
                <ChevronRightIcon className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                <Link href={`/${workspace.slug}/context/${context?.slug || ''}`}>
                  <h1 className="text-xl font-semibold text-grey-800">{context?.name}</h1>
                </Link>
                <ChevronRightIcon className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                <Link href={`/${workspace.slug}/context/${context?.slug || ''}?tab=embeddings`}>
                  <h1 className="text-xl font-semibold text-grey-800">Embeddings</h1>
                </Link>
              </div>}
          </div>
        </div>

        <main>
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            {document && workspace && context && <div className="py-6">
                <UpdateDocumentForm workspace={workspace} document={document} context={context} />
              </div>}
          </div>
        </main>
      </div>
    </Sidebar>;
};
export const UpdateDocumentForm = ({
  workspace,
  document,
  context,
  showCancel,
  onDeletedCallback
}: {
  workspace: Workspace;
  document: Document;
  context: Context;
  showCancel?: boolean;
  onDeletedCallback?: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const updateDocument = api.document.update.useMutation();
  const utils = api.useContext();
  const schema = z.object({
    content: z.string().min(1),
    metadata: z.any()
  });
  type FormSchema = z.infer<typeof schema>;
  async function onSubmit(data: FormSchema) {
    setLoading(true);
    await updateDocument.mutateAsync({
      workspaceId: workspace.id,
      id: document.id,
      content: data.content,
      metadata: (data.metadata as string)
    }, {
      onSuccess: () => {
        setLoading(false);
        toast.success({
          title: 'Document updated',
          description: "Successfully updated the document's content"
        });
      },
      onError: error => {
        setLoading(false);
        toast.error({
          title: 'Something went wrong',
          description: error.message
        });
      },
      onSettled: () => {
        setLoading(false);
        void utils.document.get.invalidate({
          id: document.id
        });
        void utils.document.getAllPaginated.invalidate({
          indexId: document.indexId
        });
      }
    });
  }
  const initialValues: FormSchema = {
    content: document.content,
    metadata: JSON.stringify(document.metadata, null, 2)
  };
  const formik = useFormik<FormSchema>({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: onSubmit
  });
  return <>
      <div className="rounded-md border border-grey-200 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-4">
        {workspace && document && <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex flex-col">
                <label htmlFor="content" className="block text-sm font-medium">
                  Content
                </label>
                <div className="mt-1">
                  <textarea name="content" placeholder={formik.values.content} rows={19} id="key" className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.content} />
                </div>
              </div>
            </div>
            <div className="mt-3 justify-between py-5 sm:flex">
              <Button disabled={loading} type="submit" loading={loading}>
                {loading ? 'Saving...' : 'Save'}
              </Button>

              {showCancel && <div>
                  <Link className="inline-flex w-full justify-center rounded-md border border-grey-300 bg-white px-4 py-2 text-base font-medium text-black shadow-sm ease-in-out hover:border-grey-400 dark:bg-grey-700 sm:w-auto sm:text-sm" href={`/workspaces/${workspace.slug}/context/${context.slug}?tab=embeddings`}>
                    Cancel
                  </Link>
                </div>}
            </div>
          </form>}
      </div>
      <DocumentDangerZone document={document} onDeletedCallback={onDeletedCallback} />
    </>;
};
function DocumentDangerZone({
  document,
  onDeletedCallback
}: {
  document: Document;
  onDeletedCallback?: () => void;
}) {
  const [open, setOpen] = useState(false);
  return <>
      <DeleteDocumentModal document={document} open={open} setOpen={setOpen} onDeletedCallback={onDeletedCallback} />
      <div className="mt-3 pt-3">
        <div className="space-y-6 rounded-md border border-grey-200 bg-white px-4 pb-4 pt-5 shadow-sm sm:space-y-5 sm:p-6 sm:pb-6">
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6">Danger Zone</h3>
                <p className="mt-1 max-w-2xl text-sm text-grey-500">Destructive action options</p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start">
            <Button onClick={() => setOpen(!open)}>Delete this document?</Button>
            <p className="ml-3 rounded-full bg-grey-50 p-2 px-3 font-mono text-xs">
              Warning: This cannot be undone, proceed with caution
            </p>
          </div>
        </div>
      </div>
    </>;
}
export default _withSuperJSONPage(DocumentPage);
export const getServerSideProps = _withSuperJSONProps(withContext(), []);