/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { getIn } from 'formik'
import React from 'react'
import { cn } from '@/client/utils'

export function FieldError({
  fieldName,
  formik,
  className,
}: {
  fieldName: string
  formik: any
  className?: string
}) {
  const hasError = getIn(formik.errors, fieldName) && getIn(formik.touched, fieldName)

  const error = getIn(formik.errors, fieldName)
  return (
    <>
      {hasError && (
        <p className={cn('mt-2 text-xs font-regular text-red-500', className)}>
          {typeof error === 'string' ? error : JSON.stringify(error)}
        </p>
      )}
    </>
  )
}
