import Link from 'next/link'
import { HomeIcon } from '@/client/assets/icons/icons'
import { Button } from '@/client/components'
import { Logo } from '@/common/components'

const NotFoundPage = ({ title, message }: { title: string; message: string }) => (
  <>
    <main className="flex min-h-screen flex-col items-center justify-center space-y-6">
      <div className="flex h-16 w-16 items-center justify-center rounded-full bg-grey-900 p-4">
        <Logo className="h-12 w-auto fill-white" />
      </div>
      <div className="overflow-hidden rounded-lg bg-grey-50 text-center shadow ring-1 ring-grey-300 w-[440px] space-y-6 p-12">
        <h1 className="text-5xl font-bold tracking-tight">{title}</h1>
        <p className="text-base">
          {message.split(/(?<=\.)\s+/).map((sentence, index) => (
            <span key={index} className="block">
              {sentence}
            </span>
          ))}
        </p>
        <div className="flex items-center justify-center gap-x-6">
          <Link href={`/`}>
            <Button startIcon={HomeIcon} variant="primary">
              Return Home
            </Button>
          </Link>
        </div>
      </div>
    </main>
  </>
)

export function App404() {
  return (
    <NotFoundPage
      title="App not found"
      message={`This is not the App you are looking for. It may have been renamed or deleted.`}
    />
  )
}

export function Action404() {
  return (
    <NotFoundPage
      title="Action not found"
      message={`The Force is not strong with this Action. It may have been renamed or deleted.`}
    />
  )
}

export function Workflow404() {
  return (
    <NotFoundPage
      title="Workflow not found"
      message={`This is not the Workflow you seek. It may have been renamed or deleted.`}
    />
  )
}

export function Experiment404() {
  return (
    <NotFoundPage
      title="Experiment not found"
      message={`This Experiment is lost in the galaxy. It may have been renamed or deleted.`}
    />
  )
}

export function Conversation404() {
  return (
    <NotFoundPage
      title="Conversation not found"
      message={`This Conversation is not the one you are looking for. It may have been renamed or deleted.`}
    />
  )
}

export function Provider404() {
  return (
    <NotFoundPage
      title="Provider not found"
      message={`This Provider is not the droid you seek. It may have been renamed or deleted.`}
    />
  )
}

export function Context404() {
  return (
    <NotFoundPage
      title="Context not found"
      message={`This Context is hidden in the Force. It may have been renamed or deleted.`}
    />
  )
}

export function Skill404() {
  return (
    <NotFoundPage
      title="Skill not found"
      message={`This Skill is not the one you are looking for. It may have been renamed or deleted.`}
    />
  )
}

export function Finetune404() {
  return (
    <NotFoundPage
      title="Finetune not found"
      message={`This Finetune is lost in hyperspace. It may have been renamed or deleted.`}
    />
  )
}

export function Dataset404() {
  return (
    <NotFoundPage
      title="Dataset not found"
      message={`This Dataset is not the one you seek. It may have been renamed or deleted.`}
    />
  )
}

export function Curator404() {
  return (
    <NotFoundPage
      title="Curator not found"
      message={`This Curator is not the one you seek. It may have been renamed or deleted.`}
    />
  )
}
