import { getMimeType } from '@/common/lib/getMimeType'

export const sanitizeImageURL = (url: string) => {
  if (url?.match(/https?:\/\//)) {
    return url.includes('amazonaws.com') ? `/api/files/download?fileUrl=${url}` : url
  } else if (!url.includes('data:')) {
    return 'data:' + (getMimeType(url.slice(0, 50) || '') || '') + ';base64, ' + url
  } else {
    return url
  }
}
