import { ClientSideSuspense } from '@liveblocks/react'
import type { Workspace } from '@prisma/client'
import { RoomProvider } from 'liveblocks.config'
import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { subscriptionNotRequiredPaths } from '@/common/config/constants'
import { useCheckWorkspaceSubscription } from '@/common/hooks'
import { FullLoaderPage } from './FullLoaderPage'

interface SidebarContextType {
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void
}

export const SidebarContext = React.createContext<SidebarContextType>({
  sidebarOpen: false,
  setSidebarOpen: () => null,
})

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false)

  return (
    <SidebarContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => React.useContext(SidebarContext)

interface ApplicationContextType {
  workspace: Workspace
  createWorkspaceModalOpen: boolean
  setCreateWorkspaceModalOpen: (value: boolean) => void
  billing: {
    hasScaleLLMAccess: boolean
    hasAccess: boolean
    canStartFreeTrial: boolean
    isFlaggedAsRisky: boolean
  }
}

export const ApplicationContext = React.createContext<ApplicationContextType | null>(null)

export const ApplicationProvider = ({
  workspace,
  children,
}: {
  workspace: Workspace
  children: ReactNode
}) => {
  const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = React.useState(false)
  const { data, isLoading } = useCheckWorkspaceSubscription(workspace.id)

  useEffect(() => {
    if (subscriptionNotRequiredPaths.some((path) => window.location.pathname.endsWith(path))) {
      return
    }

    const billingUrl = `/${workspace.slug}/settings/plans`
    if (data !== undefined && !data?.hasAccess) {
      window.location.href = billingUrl
    }
  }, [data, workspace.slug])

  if (isLoading) {
    return <FullLoaderPage />
  }

  if (!data) {
    return null
  }

  return (
    <ApplicationContext.Provider
      value={{
        workspace,
        createWorkspaceModalOpen,
        setCreateWorkspaceModalOpen,
        billing: {
          hasAccess: data.hasAccess,
          hasScaleLLMAccess: data.hasScaleLlmsAccess,
          canStartFreeTrial: data.canStartFreeTrial,
          isFlaggedAsRisky: data.isFlaggedAsRisky,
        },
      }}
    >
      <RoomProvider id={`${workspace.project_guid}:application`} initialPresence={{}}>
        <ErrorBoundary fallback={<>{children}</>}>
          <ClientSideSuspense fallback={<FullLoaderPage />}>{() => children}</ClientSideSuspense>
        </ErrorBoundary>
      </RoomProvider>
    </ApplicationContext.Provider>
  )
}

export const useApplication = () => {
  const context = React.useContext(ApplicationContext)
  if (!context) {
    throw new Error('useApplication must be used within an ApplicationProvider')
  }
  return context
}

export const useWorkspace = () => {
  const { workspace } = useApplication()
  if (!workspace) {
    throw new Error('Workspace not found')
  }
  return workspace
}
