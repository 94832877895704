import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@/client/components'

export const InfoTooltip = ({
  icon,
  children,
}: {
  icon?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger>
        {icon || <InformationCircleIcon className="h-4 w-4 text-grey-500" />}
      </Tooltip.Trigger>
      <Tooltip.Content className="max-w-[250px] bg-white text-xs">{children}</Tooltip.Content>
    </Tooltip.Root>
  )
}
