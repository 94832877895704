import { cn } from '@/client/utils'
import type { ListItemProps } from './types'

export const ListItem = <C extends React.ElementType>({
  as,
  className,
  disabled,
  children,
  onClick,
}: ListItemProps<C>) => {
  const Component = as || 'button'

  return (
    <Component
      className={cn(
        'body2 flex grow items-center gap-3 border-b border-grey-300 py-3 outline-none focus-visible:border-grey-800 disabled:opacity-50 dark:border-grey-600',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Component>
  )
}
