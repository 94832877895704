import Link from 'next/link'
import React, { useEffect } from 'react'
import CheckIcon from '@/client/assets/icons/check.svg'
import PlusIcon from '@/client/assets/icons/plus.svg'
import XCloseIcon from '@/client/assets/icons/x-close.svg'
import { FieldMessage, Icon, Input, ScrollArea, Skeleton } from '@/client/components'
import { useCustomField } from '@/client/hooks'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import { cn } from '@/client/utils'
import { TIER_APPS_FEATURE_ID } from '@/common/config/tierConstants'
import { useCheckWorkspaceFeatureAccess } from '@/common/hooks'
import { IconLabel, ListItem } from './components'
import { useAppOptions } from './logic'
import type { AppsProps } from './types'

export const Apps = ({ formik }: AppsProps) => {
  const { workspaceId } = formik.values
  const { isLoadingApps, appOptions } = useAppOptions()
  const { handleChangeField, getFieldError, resetField } = useCustomField({ formik })

  const { values } = formik

  const appsDisabled = values.newApp

  const nameError = getFieldError('name')
  const appNameError = getFieldError('appName')
  const appIdError = getFieldError('appId')

  useEffect(() => {
    if (!values.appId && appOptions && appOptions.length === 1 && appOptions[0]?.value) {
      void handleChangeField('appId', appOptions[0].value)
    }
  }, [values.appId, appOptions, handleChangeField])

  const { hasFeatureAccess } = useCheckWorkspaceFeatureAccess({
    workspaceId: workspaceId,
    featureId: TIER_APPS_FEATURE_ID,
  })

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  return (
    <>
      {!values.newApp ? (
        <Input
          id="name"
          name="name"
          label="Action Name"
          autoComplete="off"
          placeholder="Untitled new Action"
          message={nameError}
          hasError={!!nameError}
          value={values.name}
          onChange={(e) => {
            void handleChangeField('name', e.target.value)
          }}
        />
      ) : null}

      <ScrollArea className="max-h-[calc(100vh-30rem)]">
        <div className="flex flex-col gap-2.5">
          <div className="flex flex-col">
            {values.newApp ? (
              <>
                {hasFeatureAccess ? (
                  <ListItem as="div">
                    <Input
                      className="grow"
                      id="app-name"
                      name="appName"
                      label="App Name"
                      autoComplete="off"
                      placeholder="Untitled new App"
                      endIcon={() => {
                        return (
                          <button
                            className="rounded outline-none outline-offset-0 focus-visible:outline-grey-800/[.62]"
                            onClick={() => {
                              void handleChangeField('newApp', false)
                              void resetField('appName')
                            }}
                          >
                            <Icon size="md" component={XCloseIcon} />
                          </button>
                        )
                      }}
                      message={appNameError}
                      hasError={!!appNameError}
                      value={values.appName}
                      onChange={(e) => {
                        void handleChangeField('appName', e.target.value)
                      }}
                    />
                  </ListItem>
                ) : (
                  <ListItem>
                    <div className="text-left">
                      You&apos;ve reached the Apps limit for your current plan.
                      <br />
                      <span
                        onClick={() => {
                          void handleChangeField('newApp', false)
                        }}
                        className="text-xs cursor-pointer text-grey-300 hover:text-primary-700"
                      >
                        (go back)
                      </span>
                    </div>

                    <Link
                      href={`/api/deep-link/settings/plans`}
                      target="_blank"
                      className="px-3 py-2 text-sm text-white ease-in-out rounded-md bg-grey-700 hover:bg-grey-900 group-hover:text-white"
                    >
                      Upgrade
                    </Link>
                  </ListItem>
                )}
              </>
            ) : (
              <ListItem
                className="font-medium hover:text-primary-600"
                onClick={() => {
                  void handleChangeField('newApp', true)
                  void resetField('name')
                  void resetField('appId')
                }}
              >
                <IconLabel icon={PlusIcon} label="New App" />
              </ListItem>
            )}

            {isLoadingApps ? (
              <div className="flex">
                <div className="flex-1 space-y-4">
                  <Skeleton />

                  <Skeleton />
                </div>
              </div>
            ) : (
              <div className="relative flex flex-col">
                {appOptions.map(({ value, label, icon }, index) => {
                  const selectedOption = value === values.appId

                  return (
                    <ListItem
                      key={index}
                      disabled={appsDisabled}
                      onClick={() => {
                        void handleChangeField('appId', selectedOption ? undefined : value)
                      }}
                    >
                      <div
                        className={cn(
                          ' flex h-4 w-4 items-center justify-center rounded border border-grey-700 dark:border-white',
                          {
                            'bg-primary-lightest-1 border-primary text-primary dark:border-primary dark:bg-primary dark:text-grey-50':
                              selectedOption,
                          }
                        )}
                      >
                        <Icon
                          className={cn({
                            'sr-only': !selectedOption,
                          })}
                          size="sm"
                          component={CheckIcon}
                        />
                      </div>

                      <IconLabel icon={icon} label={label} />
                    </ListItem>
                  )
                })}
                {appIdError ? (
                  <FieldMessage
                    message={appIdError}
                    disabled={Boolean(!appIdError)}
                    hasError={Boolean(appIdError)}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </ScrollArea>
    </>
  )
}
