import type { ToolType } from '@prisma/client'
import type { FormikProps } from 'formik'
import { Formik, Form as FormikForm } from 'formik'
import { useMemo, useState } from 'react'
import { Button, Combobox, Input } from '@/client/components'
import { databaseExtraMetadata } from '@/common/components/contextSources/data'
import { FieldError } from '@/common/components/forms'
import { useFetchToolTypes } from '@/common/hooks'
import { useFetchDatabaseProviders } from '@/common/hooks/connections'
import { SkillMetadataForm } from '../../common/metadata-form'
import { useCreateSkillForm } from './form'
import type { CreateSkillFormInputs, FormProps } from './types'

const SQL_QUERY_TOOL_TYPE_ID = 20

export function shouldShowSkillDescription(typeId: number | undefined) {
  if (!typeId) {
    return false
  }
  const types = [
    21, //API Request
    20, //SQL Query
    17, //Python Interpreter
    15, //Python REPL (what's the difference between this and the above?)
    14, //Integration
  ]
  return types.includes(typeId)
}

const hiddenToolTypeIds = [
  14, //api docs
  9, //news
  8, //openmeteo
  7, //calculator
  19, //pandas
  5, // PAL-MATH
  15, //'Python REPL',
  3, //'Scrape HTML',
  10, //'TMDB API',
  16, //'Zapier',
  2, //'Wolfram Alpha',
  1, // search
  12, // searxng
  18, // Browse Webpage
  11, // Google Search
  24, // Image Generation
  17, // Python Interpreter,
  4, // terminal
  13, // Google serper
]

export const Form = ({ workspace, onIsOpenChange, setNewSkill }: FormProps) => {
  const { formikConfig, saveDisabled, setRequiredMetadata } = useCreateSkillForm({
    workspace,
    onIsOpenChange,
    setNewSkill,
  })
  const [selectedToolType, setSelectedToolType] = useState<ToolType | null>(null)
  const { toolTypes } = useFetchToolTypes()
  const { providers } = useFetchDatabaseProviders(workspace.id)
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null)

  const toolTypeOptions = useMemo(() => {
    if (!toolTypes) {
      return []
    } else {
      return toolTypes
        .filter((toolType) => !hiddenToolTypeIds.includes(toolType.id))
        .map((toolType) => ({
          value: String(toolType.id),
          label: toolType.name,
        }))
    }
  }, [toolTypes])

  async function onChangeSkillType(value: string, formik: FormikProps<CreateSkillFormInputs>) {
    {
      if (!value) return
      const _selectedToolType = toolTypes?.find((types) => types.id === parseInt(value))
      if (!_selectedToolType) {
        throw new Error('Tool type not found')
      }
      setSelectedToolType(_selectedToolType)
      await formik.setFieldValue('typeId', parseInt(value))
      await setRequiredMetadata(_selectedToolType.name, formik, toolTypes)
    }
  }

  async function onChangeProvider(providerId: string, formik: FormikProps<CreateSkillFormInputs>) {
    if (!providerId) return
    const selectedProvider = providers?.find((providers) => providers.id === parseInt(providerId))
    if (!selectedProvider) {
      throw new Error('Provider not found')
    }
    setSelectedProvider(providerId)
    await formik.setFieldValue('databaseProviderId', selectedProvider.id)

    // now update the loader config metadata with the provider's extra metadata
    const providerMetadata = selectedProvider.metadata as {
      provider: string
      config: Record<string, string>
    }

    const extraMetadata = databaseExtraMetadata.find(
      (metadata) => metadata.name === providerMetadata.provider
    )

    if (!extraMetadata) {
      throw new Error('Provider not found')
    }

    const requiredMetadata = extraMetadata.extra_metadata
    await formik.setFieldValue('loaderConfig', requiredMetadata)
  }

  return (
    <Formik
      initialValues={formikConfig.initialValues}
      onSubmit={formikConfig.onSubmit}
      validationSchema={formikConfig.validationSchema}
      validateOnMount={false}
    >
      {(formik) => (
        <FormikForm>
          <div className="flex flex-col p-6 gap-6">
            <div>
              <Combobox.Single
                label={''}
                classNameContent="bg-white text-sm"
                options={toolTypeOptions}
                value={selectedToolType?.id.toString() || undefined}
                placeholder="Select Skill type"
                onChange={(value) => {
                  void onChangeSkillType(value, formik)
                  const selectedToolType = toolTypes?.find((type) => String(type.id) === value)
                  if (selectedToolType) {
                    void formik.setFieldValue('name', selectedToolType.name, false)
                  }
                }}
              />
              <FieldError fieldName="typeId" formik={formik} />
            </div>

            {selectedToolType && selectedToolType.id === SQL_QUERY_TOOL_TYPE_ID && (
              <>
                <div>
                  <Input
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Database Reader"
                    message={formik.errors.name}
                    hasError={!!formik.errors.name}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <Combobox.Single
                    classNameContent="bg-white text-sm"
                    label="Connection"
                    options={
                      providers
                        ? providers.map((provider) => {
                            const metadata = provider.metadata as {
                              provider: string
                              config: Record<string, string>
                            }
                            return {
                              value: provider.id.toString(),
                              label: `${provider.name} (${metadata.provider})`,
                            }
                          })
                        : []
                    }
                    value={selectedProvider ? selectedProvider : undefined}
                    placeholder="Select a provider"
                    onChange={(value) => {
                      void onChangeProvider(value, formik)
                    }}
                  />
                  <FieldError fieldName="databaseProviderId" formik={formik} />
                </div>
              </>
            )}

            {providers && selectedToolType && (
              <SkillMetadataForm
                toolType={selectedToolType}
                formik={formik}
                databaseProviders={providers}
              />
            )}

            <div className="flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5 -mx-6">
              {onIsOpenChange && (
                <Button
                  variant="outline"
                  onClick={() => {
                    onIsOpenChange(false)
                  }}
                >
                  Close
                </Button>
              )}

              <Button type="submit" disabled={saveDisabled || !formik.isValid}>
                Add
              </Button>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}
