import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import type { MessageType } from '@/client/containers/views/Studio/components/Tabs/Chat/types'
import { decodeToken } from '@/common/hooks/tokenizer'
import { convertStreamEvent } from '@/common/stream/stream/convertStreamEvent'

export interface StreamingData {
  streamingUrl: string
  value: string
  dataGuid: string
  status:
    | 'not-started'
    | 'waiting-on-first-data'
    | 'streaming'
    | 'end-stream'
    | 'canceled'
    | 'tool_calls_request'
    | 'tool_calls_response'
  metadata: Record<string, string>
  tool_calls?: MessageType['tool_calls']
  eventSource: EventSource | null
  startedAt?: number
  timeElapsed?: number
  tokensCount?: number
}

interface StreamState {
  streams: StreamingData[]
  isStreamAvailable: (dataGuid: string) => boolean
  addStream: (
    data: Pick<StreamingData, 'dataGuid' | 'streamingUrl'>,
    options?: {
      onFinished?: (
        value: string,
        duration: number,
        token: number,
        tool_calls?: MessageType['tool_calls']
      ) => void
    }
  ) => void
  addFinishedStream: (data: Pick<StreamingData, 'dataGuid' | 'value'>) => void
  getStreamOrThrow: (dataGuid: string) => StreamingData
  getStream: (dataGuid?: string | null) => StreamingData | null
  stopStream: (dataGuid: string) => void
}

export const useStreamStore = create<StreamState>()(
  devtools(
    (set, get) => ({
      streams: [],
      addStream: (newStream, options) => {
        const eventSource = new EventSource(newStream.streamingUrl)

        const t0 = performance.now()

        let tokensCount: number
        let timeElapsed: number

        set((state) => ({
          streams: [
            ...state.streams,
            {
              ...newStream,
              value: '',
              metadata: {},
              status: 'not-started',
              eventSource,
              startedAt: t0,
            },
          ],
        }))

        eventSource.onmessage = (e) => {
          set((state) => {
            const index = state.streams.findIndex(
              (stream) => stream.dataGuid === newStream.dataGuid
            )

            if (index === -1) return state

            const parsedEvent = convertStreamEvent(e)

            const newStreams = [...state.streams]

            if (!newStreams[index]) return state

            newStreams[index] = {
              ...newStreams[index]!,
              value: newStreams[index]!.value + parsedEvent.value,
              status: parsedEvent.status,
              tool_calls: [
                ...(newStreams[index]!.tool_calls || []),
                ...(parsedEvent.tool_calls || []),
              ],
            }

            if (parsedEvent.status === 'end-stream') {
              const t1 = performance.now()

              timeElapsed = t1 - newStreams[index]!.startedAt!

              const lastValue = newStreams[index]!.value
              tokensCount = decodeToken(lastValue).decodedArr.length

              options?.onFinished?.(
                lastValue,
                timeElapsed,
                tokensCount,
                newStreams[index]!.tool_calls
              )
              eventSource.close()
            }

            newStreams[index] = {
              ...newStreams[index]!,
              timeElapsed,
              tokensCount,
            }

            return {
              streams: newStreams,
            }
          })
        }
      },

      addFinishedStream: (newStream) => {
        set((state) => ({
          streams: [
            ...state.streams,
            {
              ...newStream,
              status: 'end-stream',
              streamingUrl: '',
              eventSource: null,
              metadata: {},
            },
          ],
        }))
      },

      getStreamOrThrow: (dataGuid) => {
        const stream = get().streams.find((stream) => stream.dataGuid === dataGuid)
        if (!stream) throw new Error(`Stream with dataGuid ${dataGuid} not found`)

        return stream
      },
      isStreamAvailable: (dataGuid) => {
        return get().streams.some((stream) => stream.dataGuid === dataGuid)
      },
      getStream: (dataGuid) => {
        return get().streams.find((stream) => stream.dataGuid === dataGuid) ?? null
      },
      stopStream: (dataGuid) => {
        const stream = get().streams.find((stream) => stream.dataGuid === dataGuid)
        if (!stream) return

        stream.eventSource?.close()

        set((state) => {
          const index = state.streams.findIndex((stream) => stream.dataGuid === dataGuid)

          if (index === -1) return state

          const newStreams = [...state.streams]
          if (!newStreams[index]) return state

          const t1 = performance.now()

          const timeElapsed = t1 - newStreams[index]!.startedAt!

          const lastValue = newStreams[index]!.value

          const tokensCount = decodeToken(lastValue).decodedArr.length

          newStreams[index] = {
            ...newStreams[index]!,
            status: 'canceled',
            timeElapsed,
            tokensCount,
          }

          return {
            streams: newStreams,
          }
        })
      },
    }),
    {
      name: 'stream-store',
    }
  )
)
