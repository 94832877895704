import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import type { Action, Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { useFetchApp, useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function DeleteActionForm({
  setOpen,
  action,
  workspace,
}: {
  workspace: Workspace
  action: Action
  setOpen: (val: boolean) => void
}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteAction = api.action.delete.useMutation()
  const utils = api.useContext()
  const { user } = useUser()
  const { app } = useFetchApp(action.appId)

  async function onSuccess() {
    await utils.action.getAllForWorkspace.invalidate({ workspaceId: workspace.id })
    await utils.app.get.invalidate({ appId: action.appId })
    toast.success({
      title: `${action.name} deleted`,
      description: 'Successfully deleted the action',
    })
    setOpen(false)
    await router.push(`/${workspace.slug}/apps/${app?.slug ?? ''}`)
  }

  async function onDelete() {
    setLoading(true)
    const appId = action.appId
    await deleteAction.mutateAsync(
      { agentId: action.id },
      {
        onError: (e) => {
          toast.error({
            title: 'Error deleting action',
            description: e.message,
          })
        },
        onSuccess: () => {
          void onSuccess()
        },
        onSettled: () => {
          setLoading(false)
        },
      }
    )
    await utils.action.getAllForApp.invalidate({ appId })
    if (user) {
      const id = user.id ? user.id : ''
      const email = user.email ? user.email : ''
      const uname = user.name ? user.name : ''
      identify_and_group(id, email, uname, workspace?.slug)
    }
    track(EVENT_NAMES.action_deleted, {
      'Workspace ID': workspace.id,
      'Workspace Name': workspace.name,
      'App ID': action.appId,
      'Action ID': action.id,
      'Action Name': action.name,
      'Action Type': action.action_type,
      'Action Description': action.description,
      'User ID': user?.id,
      'User Email': user?.email,
      'User Name': user?.name,
    })
  }

  return (
    <>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">Delete {action?.name}</h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700">
                Are you sure you want to delete {action?.name}?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between px-6 py-5 border-t rounded-b-lg border-grey-200 bg-grey-50 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          disabled={loading}
          loading={loading}
          onClick={() => void onDelete()}
        >
          {loading ? 'Deleting...' : 'Delete Action'}
        </Button>
      </div>
    </>
  )
}

export const DeleteActionModal = ({
  open,
  setOpen,
  action,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  action: Action | null
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {action && <DeleteActionForm setOpen={setOpen} action={action} workspace={workspace} />}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
