import { z } from 'zod'

export const skillMetadataItemSchema = z
  .object({
    name: z.string({ required_error: 'This field is required' }),
    value: z.string({ required_error: 'Cannot be blank' }),
    required: z.boolean().optional(),
    readOnly: z.boolean().optional(),
    type: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    console.log('data', data)
    if (data.name === 'definition') {
      const functionSchema = z.object(
        {
          name: z.string({ required_error: 'The name attribute is required.' }),
          description: z.string({ required_error: 'The description attribute is required.' }),
          parameters: z.record(z.string(), z.any(), {
            required_error: 'The parameters attribute is required.',
          }),
        },
        {
          required_error: 'The function attribute is required.',
        }
      )

      const wrappedFunctionSchema = z.object({
        type: z.literal('function', {
          required_error: 'The type attribute must be `function`.',
        }),
        function: functionSchema,
      })

      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const json_definition = JSON.parse(data.value)

        let parsed = null
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!json_definition.type) {
          parsed = functionSchema.safeParse(json_definition)
        } else {
          parsed = wrappedFunctionSchema.safeParse(json_definition)
        }

        if (!parsed.success) {
          console.log('parsed.error', parsed.error.format())
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['value'],
            message: parsed.error?.issues.map((issue) => issue.message).join('\n'),
          })
        }
      } catch (e) {
        if (/%\{[^}]+\}/.test(data.value)) {
          // contains a %{var}, so just let it go through
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['value'],
            message: 'Definition JSON is not valid',
          })
        }
      }
    }

    return {}
  })

export const skillMetadataSchema = z.array(skillMetadataItemSchema).default([])

export const createSkillFormSchema = z.object({
  name: z.string({ required_error: 'This field is required' }),
  typeId: z.number({ required_error: 'This field is required' }).min(1, 'Please select a type'),
  databaseProviderId: z.number().optional(),
  description: z.string().optional(),
  metadata: skillMetadataSchema,
})
