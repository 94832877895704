import { RetrievedDocumentNode } from '@/common/components/contexts/RetrievedDocumentNode'
import type { ContextNode } from '@/common/components/tables/v2/types'

export const RetrievedContextCell = ({ value: nodes }: { value: ContextNode[] }) => {
  if (!nodes || nodes.length < 1) return null

  return (
    <div className="whitespace-pre-wrap relative items-start py-3 leading-normal max-h-[200px] overflow-y-auto overflow-x-hidden flex flex-col gap-4">
      {nodes.map((node) => (
        <RetrievedDocumentNode key={node.guid} node={node} />
      ))}
    </div>
  )
}
