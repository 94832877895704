// import { AgGridReact } from 'ag-grid-react' // React Grid Logic
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import type {
  ColDef,
  GridApi,
  GridState,
  StateUpdatedEvent,
  ValueGetterParams,
} from '@ag-grid-community/core'
import type { CustomNoRowsOverlayProps } from '@ag-grid-community/react'
import { AgGridReact } from '@ag-grid-community/react'
import type { ActionVersionMetadata } from '@/server/service/types'
import '@ag-grid-community/styles/ag-grid.css' // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ArrowDownTrayIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Icon, Popover } from '@/client/components'
import { useFetchEvalsOnEvalTypesForEval } from '@/common/hooks'
import type { DetailedEval, DetailedEvalRunItem, EvalTypeMetadata } from '@/common/types/eval'
import { TagsCellRenderer } from '../../datasets/DatasetItemsGrid'
import { useWorkspace } from '../../ui/context'
import { ActualOutputCell } from './cells/ActualOutputCell'
import { ExpectedOutputCell } from './cells/ExpectedOutputCell'
import { HumanEvalResultRenderer } from './cells/HumanEvalResultRenderer'
import { InputCell } from './cells/InputCell'
import { PassFailCell } from './cells/PassFailCell'
import { ReasonCell, ScoreCell } from './cells/ResultCell'
import { RetrievedContextCell } from './cells/RetrievedContextCell'
import type { DynamicGridRow, DynamicResult } from './types'

export const EvalRunItemsGrid = ({
  evalRecord,
  evalRunItems,
  showEvalRunNumber,
}: {
  evalRecord: DetailedEval
  evalRunItems: DetailedEvalRunItem[]
  showEvalRunNumber?: boolean
}) => {
  const { evalsOnEvalTypes } = useFetchEvalsOnEvalTypesForEval({ evalId: evalRecord.id })

  const usesContextEvalType = useMemo(() => {
    if (!evalsOnEvalTypes) return false
    return (
      evalsOnEvalTypes?.some((x) => {
        const metadata = x.evalType?.metadata as EvalTypeMetadata
        return metadata.usesContext
      }) || false
    )
  }, [evalsOnEvalTypes])

  const workspace = useWorkspace()

  const gridRef = useRef<AgGridReact>(null)

  const rows: DynamicGridRow[] = useMemo(() => {
    if (!evalRunItems || !evalsOnEvalTypes) return []
    return evalRunItems.map((evalRunItem) => {
      const { modelName } = (evalRunItem.EvalRun?.actionVersion?.metadata ||
        {}) as ActionVersionMetadata

      const res = {
        id: evalRunItem.id,
        evalRunNumber: evalRunItem.EvalRun?.run_number,
        modelName,

        date: evalRunItem.data?.createdAt.toISOString().split('T')[0] || 'unknown',

        data: evalRunItem.data,
        evalRunItem,
        context: evalRunItem.context as string[],
        output: evalRunItem.output || evalRunItem.data?.output || '',

        latency: (evalRunItem.data?.latency || 0) / 1000,
        cost: evalRunItem.data?.generation_cost || 0,

        input: evalRunItem.input || evalRunItem.data?.input || '',
        // datasetItem: evalRunItem.DatasetItem,
        output_expected: evalRunItem.DatasetItem?.output,
        results: {} as DynamicResult,
        tags: evalRunItem.DatasetItem?.tags || [],
        evalsOnEvalTypes,
      }

      evalsOnEvalTypes.forEach((evalOnEvalType) => {
        const result = evalRunItem.results.find((x) => x.evalOnEvalTypeId === evalOnEvalType.id)

        if (result) {
          res.results[`${evalOnEvalType.id}-reason`] = result
            ? result.reason
            : evalRunItem.metadata?.error
          res.results[`${evalOnEvalType.id}-score`] = result
            ? result.score
            : evalRunItem.metadata?.status
          res.results[`${evalOnEvalType.id}-verdict`] = result?.passed
        } else {
          // human evals don't have a result to start, but need the cells to exist
          res.results[`${evalOnEvalType.id}-reason`] = null
          res.results[`${evalOnEvalType.id}-score`] = null
          res.results[`${evalOnEvalType.id}-verdict`] = null
        }
      })

      return res
    })
  }, [evalRunItems, evalsOnEvalTypes])

  const columns: ColDef[] = useMemo((): ColDef[] => {
    if (!evalsOnEvalTypes || !workspace || !rows || rows.length < 1) return []

    return (
      [
        showEvalRunNumber && {
          pinned: 'left',
          field: 'evalRunNumber',
          headerName: 'Eval Run #',
          flex: 1,
        },
        showEvalRunNumber && {
          pinned: 'left',
          field: 'modelName',
          headerName: 'Model',
          flex: 1,
        },
        {
          field: 'input',
          headerName: 'Input',
          cellRenderer: InputCell,
          pinned: 'left',
          flex: 3,
          minWidth: 250,
          filterValueGetter: (params) => {
            return (params.data as DynamicGridRow).input
          },
          useValueFormatterForExport: true,
          valueFormatter: (params) => {
            //for export
            return (params.data as DynamicGridRow).input.toString()
          },
        },

        evalRecord.dataType == 'TEST' && {
          field: 'output_expected',
          headerName: 'Expected Output',
          cellRenderer: ExpectedOutputCell,
          sortable: false,
          flex: 2,
          minWidth: 250,
          filterValueGetter: (params) => {
            return (params.data as DynamicGridRow).output_expected
          },
          useValueFormatterForExport: true,
          valueFormatter: (params) => {
            //for export
            return (params.data as DynamicGridRow).output_expected?.toString()
          },
        },

        evalRecord.dataType !== 'TEST' && {
          field: 'date',
          headerName: 'Date',
          flex: 1,
        },

        {
          field: 'evalRunItem',
          headerName: 'Actual Output',
          flex: 2,
          sortable: false,
          cellRenderer: ActualOutputCell,
          cellRendererParams: { workspace },
          minWidth: 250,
          filterValueGetter: (params) => {
            return (params.data as DynamicGridRow).output
          },
          useValueFormatterForExport: true,
          valueFormatter: (params) => {
            return (params.data as DynamicGridRow).output?.toString()
          },
        },

        usesContextEvalType && {
          field: 'context',
          headerName: 'Context',
          flex: 2,
          cellRenderer: RetrievedContextCell,
          sortable: false,
          minWidth: 250,
          filterValueGetter: (params) => {
            return (params.data as DynamicGridRow).context.join('\n')
          },
          useValueFormatterForExport: true,
          valueFormatter: (params) => {
            return (params.data as DynamicGridRow).context?.join('\n')
          },
        },

        ...Object.keys(rows[0]?.results ?? {}).map((key) => {
          const results = (rows[0] as DynamicGridRow).results

          const evalOnEvalType = evalsOnEvalTypes.find((x) => x.id.toString() == key.split('-')[0])
          const evalTypeName = evalOnEvalType?.name

          let cellRenderer = null
          if (evalOnEvalType?.evalType?.type === 'HUMAN') {
            if (key.includes('score')) {
              return null // don't show score for human evals
            } else {
              cellRenderer = HumanEvalResultRenderer
            }
          } else if (key.includes('score')) {
            cellRenderer = ScoreCell
          } else if (key.includes('verdict')) {
            cellRenderer = PassFailCell
          } else {
            cellRenderer = ReasonCell
          }

          const headerName = evalTypeName ? `${evalTypeName} ${key.split('-')[1] || ''}` : key

          return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            valueGetter: (params: ValueGetterParams) => params.data.results[key],
            cellRenderer,
            type: key.includes('score') ? 'numericColumn' : 'stringColumn',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            cellDataType: typeof results[key],
            headerName: headerName,
            hide: key.match(/reason|verdict/),
            wrapText: true,
            // autoHeight: true,
            field: key,
          }
        }),

        { field: 'latency', headerName: 'Latency', hide: true, width: 100 },
        {
          field: 'cost',
          headerName: 'Cost',
          type: 'numericColumn',
          resizable: true,
          hide: true,
          width: 100,
        },

        {
          field: 'tags',
          headerName: 'Tags',
          hide: true,
          cellRenderer: TagsCellRenderer,
          checkboxSelection: false,
        },

        {
          field: 'spacer',
          headerName: '',
          resizable: false,
          suppressMenu: true,
          suppressNavigable: true,
          sortable: false,
          flex: 1,
          width: 1,
          minWidth: null,
        },
        {
          field: 'actions',
          headerComponent: TableColumnSelector,
          sortable: false,
          resizable: false,
          width: 50,
          minWidth: null,
          suppressNavigable: true,
          lockPosition: 'right',
          suppressMovable: true,
        },
      ] as ColDef[]
    ).filter((x) => !!x)
  }, [
    workspace,
    rows,
    evalRecord.dataType,
    evalsOnEvalTypes,
    usesContextEvalType,
    showEvalRunNumber,
  ])

  const defaultColDef = useMemo(() => {
    return {
      headerComponentParams: {},
      filter: true,
      minWidth: 100,
      useValueFormatterForExport: false,
    }
  }, [])

  const components = useMemo(() => {
    return {}
  }, [])

  const gridOptions = useMemo(() => {
    return {}
  }, [])

  function onStateUpdated(event: StateUpdatedEvent): void {
    localStorage.setItem(`evalRunItemsGridState:${evalRecord.id}`, JSON.stringify(event.state))
  }

  const initialState = JSON.parse(
    localStorage.getItem(`evalRunItemsGridState:${evalRecord.id}`) || '{}'
  ) as GridState

  const onRowDataUpdated = useCallback(() => {
    const count = gridRef.current!.api.getDisplayedRowCount()
    if (count < 1) {
      gridRef.current!.api.showNoRowsOverlay()
    } else {
      gridRef.current!.api.hideOverlay()
    }
  }, [gridRef])

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay
  }, [])

  return (
    <>
      <div className="ag-theme-quartz">
        <AgGridReact
          initialState={initialState}
          ref={gridRef}
          rowData={rows}
          columnDefs={columns}
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          components={components}
          suppressRowHoverHighlight={true}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          gridOptions={gridOptions}
          pagination={true}
          rowHeight={200}
          modules={[ClientSideRowModelModule, CsvExportModule]}
          onStateUpdated={onStateUpdated}
          onModelUpdated={onRowDataUpdated}
          noRowsOverlayComponent={noRowsOverlayComponent}
        />
        {gridRef.current && <ExportButton grid={gridRef.current} />}
      </div>
    </>
  )
}

const CustomNoRowsOverlay = (props: CustomNoRowsOverlayProps) => {
  const { api } = props

  const isAnyFilterPresent = api.isAnyFilterPresent()

  return (
    <div role="presentation" className="ag-overlay-loading-center pointer-events-auto">
      No rows found
      {isAnyFilterPresent && (
        <span>
          <button
            type="button"
            className="text-blue-600 underline cursor-pointer ml-2"
            onClick={() => {
              api.hideOverlay()
              api.setFilterModel(null)
            }}
          >
            Clear Filters
          </button>
        </span>
      )}
    </div>
  )
}

const TableColumnSelector = ({ api }: { api: GridApi }) => {
  const [open, setOpen] = useState(false)
  const [refresh, setRefresh] = useState('')

  if (!api) return null

  // eslint-disable-next-line
  api.addEventListener('columnVisible', () => {
    setRefresh(Math.random().toString())
  })

  const columns: ColDef[] =
    api
      .getColumns()
      ?.map((x) => x.getColDef())
      ?.filter((x) => !['spacer', 'actions'].includes(x.field!) && !x.pinned)
      .filter(Boolean) || []

  const visibleColumns = api
    .getAllDisplayedColumns()
    .map((x) => x.getColDef())
    .map((x) => x.field)

  const toggleColumn = (field: string) => {
    // api.setColumnVisible(field, !api.getColumn(field)?.isVisible())
    api.setColumnsVisible([field], !api.getColumn(field)?.isVisible())
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal={true}>
      <Popover.Trigger>
        <Icon size="md" component={ChevronDownIcon} />
      </Popover.Trigger>

      <Popover.Content collisionPadding={20} sticky="always">
        <ul className="flex flex-col gap-2" key={refresh}>
          {columns.map((column) => (
            <li key={column.field} className="text-xs text-grey-800">
              <label className="flex gap-2 items-center cursor-pointer select-none">
                <Checkbox
                  checked={visibleColumns.includes(column.field)}
                  onCheckedChange={() => {
                    toggleColumn(column.field!)
                  }}
                />
                {column.headerName}
              </label>
            </li>
          ))}
        </ul>
      </Popover.Content>
    </Popover.Root>
  )
}

const ExportButton = ({ grid }: { grid: AgGridReact }) => {
  const handleExport = useCallback(() => {
    grid.api.exportDataAsCsv({
      skipPinnedTop: true,
      skipPinnedBottom: true,
      columnKeys:
        grid.api
          .getColumns()
          ?.map((x) => x.getColId())
          .filter((id) => id !== 'spacer' && id !== 'actions') || [],
    })
  }, [grid])

  return (
    <Button
      className="mt-3"
      startIcon={ArrowDownTrayIcon}
      variant="outline"
      onClick={() => {
        handleExport()
      }}
    >
      Export Results
    </Button>
  )
}
