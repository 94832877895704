import MarkdownRenderer from './markdown'

export function CompletionRenderer({
  completion,
  numOutputTokens,
  metadata,
}: {
  completion: string
  numOutputTokens?: number
  metadata: Record<string, unknown>
}) {
  let outputJson: Record<string, unknown> = {}
  try {
    outputJson = JSON.parse(completion) as Record<string, unknown>
    completion = '```json\n' + `${JSON.stringify(outputJson, null, 2)}`
  } catch (error) {
    console.error(error)
  }

  return (
    <>
      {numOutputTokens && (
        <div className="mb-3 mt-3">
          <span className="rounded bg-grey-300 px-2 py-1 text-xs text-grey-800">
            Tokens Out: {numOutputTokens}
          </span>
        </div>
      )}
      <MarkdownRenderer markdownText={completion} />
      {metadata && metadata.debug_log && (
        <details>
          <summary className="mt-3 cursor-pointer text-xs text-grey-600">Chain of thought</summary>
          <div className="text-2xs mt-3 rounded-md bg-black p-2 text-grey-200">
            <pre className="whitespace-pre-wrap">{metadata.debug_log as string}</pre>
          </div>
        </details>
      )}
      {metadata && metadata.error_log && (
        <details>
          <summary className="mt-3 cursor-pointer text-xs text-red-400">Error log</summary>
          <div className="text-2xs mt-3 rounded-md bg-black p-2 text-grey-200">
            <pre className="whitespace-pre-wrap">{metadata.error_log as string}</pre>
          </div>
        </details>
      )}
    </>
  )
}
