import { XCircleIcon } from '@heroicons/react/24/outline'
import type { Action } from '@prisma/client'
import { DropdownMenu } from '@/client/components'
import { useDisableAction } from './form'
import type { UseDisableActionProps } from './types'

interface DisableButtonProps extends UseDisableActionProps {
  action: Action
}

export function DisableButton({ action, workspace, onSuccess }: DisableButtonProps) {
  const { onSubmit } = useDisableAction({
    workspace,
    onSuccess,
  })

  return (
    <DropdownMenu.Item
      variant="destructive"
      onClick={() => void onSubmit(action.id)}
      className="flex items-center gap-2"
    >
      <XCircleIcon className="w-4 h-4" />
      <span>Deactivate</span>
    </DropdownMenu.Item>
  )
}
