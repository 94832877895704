import { Loader } from '@/common/components'
import { useFetchIndexStatus } from '@/common/hooks'
import type { DetailedContext } from '@/common/types/context'
import { classNames } from '@/utils'
import { ProcessingLog } from './ContextProcessingLog'

function Badge({ label }: { label: string }) {
  function getColor() {
    switch (label) {
      case 'Ready':
        return 'bg-green-100 text-green-800 ring-green-200'
      default:
        return 'bg-grey-100 text-grey-800 ring-grey-200'
    }
  }

  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-full px-3 py-0.5 text-xs font-mono font-regular ring-1 ',
        getColor()
      )}
    >
      {label}
    </span>
  )
}

export function ProcessingStatus({
  context,
  showLog,
}: {
  context: DetailedContext
  showLog?: boolean
}) {
  const { isLoading } = useFetchIndexStatus(context)

  if (isLoading) {
    return (
      <>
        <Loader className="ml-5 h-5 w-5 text-grey-500" />
      </>
    )
  }

  return (
    <div className="inline-flex items-center">
      {/* rounded-full bg-blue-50 px-3 py-0.5 text-sm font-medium text-blue-800 ring-1 ring-blue-300/50 */}
      <Badge label={context.processed ? 'Ready' : 'Processing'} />
      {showLog && <ProcessingLog index={context} />}
    </div>
  )
}
