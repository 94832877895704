import { Select } from '../forms'

export type Splitters = {
  [key: string]: {
    name: string
    description: string
  }
}

export const splitters: Splitters = {
  token: {
    name: 'Token',
    description: 'Split text by tokens. May create documents with incomplete words.',
  },
  sentence: {
    name: 'Sentence',
    description: 'Split text by sentences. May underutilize document length.',
  },
  character: {
    name: 'Character',
    description: 'Split text by characters. May create documents with incomplete words.',
  },
  code: {
    name: 'Code',
    description: 'Split text by code blocks. May underutilize document length.',
  },
}

export const TextSplitterSelect = ({
  setSplitter,
  splitter,
  formik,
}: {
  setSplitter: (mode: string) => void
  splitter: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any
}) => {
  return (
    <div className="items-center space-y-3">
      {splitters && (
        <div className="w-80">
          <Select.Single
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            formik={formik}
            fieldName="splitter"
            options={Object.keys(splitters).map((mode) => ({
              id: mode,
              name: splitters[mode]?.name || '',
            }))}
            defaultOption={
              splitter ? { id: splitter, name: splitters[splitter]?.name || '' } : null
            }
            onSelect={(option) => setSplitter(option?.id as string)}
            placeholder="Select a splitter"
          />
        </div>
      )}
      {splitters[splitter] && (
        <div>
          <p className="font-mono w-80 text-xs text-grey-700">{splitters[splitter]?.description}</p>
        </div>
      )}
    </div>
  )
}
