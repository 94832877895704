import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Button, Label, Slider } from '@/client/components'
import { FieldError, Loader, Select } from '@/common/components/forms'
import { SelectAllRows } from '../../context'
import { useDeduplicateDatasetForm } from './form'
import type { FormProps } from './types'

export const DeduplicateDatasetForm = ({
  onIsOpenChange,
  isOpen,
  onUpdateSuccess,
}: FormProps & {
  onDeleteSuccess?: () => void
}) => {
  const { formik, loading, selectedIds, selectAllRows, dataCount } = useDeduplicateDatasetForm({
    onIsOpenChange,
    isOpen,
    onUpdateSuccess,
  })

  const attributeOptions = [
    {
      id: 'input',
      name: 'Input',
    },
    {
      id: 'output',
      name: 'Output',
    },
  ]

  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        <div>
          <div className="mt-1 flex items-center justify-between pb-2">
            <label htmlFor="user" className="block text-sm text-grey-600">
              {selectAllRows ? (
                <span className="text-grey-600">All rows selected ({dataCount} items)</span>
              ) : (
                <>
                  {selectedIds && (
                    <>
                      <span className="font-medium text-grey-800">{selectedIds.length}</span>{' '}
                      currently selected.
                    </>
                  )}
                </>
              )}
            </label>
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 pt-4">
            {!loading && (
              <div className="py-3 flex flex-col space-y-6">
                <div className="space-y-3">
                  <Label
                    htmlFor="attribute"
                    title="Attribute"
                    description="Which dataset item attribute to use for deduplication"
                  />
                  <Select.Single
                    defaultOption={{ name: 'Input', id: 'input' }}
                    fieldName="attribute"
                    placeholder="Select attribute"
                    position="below"
                    options={attributeOptions}
                    showAvatars={false}
                    onSelect={(val) => {
                      if (val) {
                        void formik.setFieldValue('attribute', val.id)
                      }
                    }}
                  />

                  <FieldError fieldName="attribute" formik={formik} />
                </div>

                <div className="space-y-3">
                  <Label htmlFor="attribute" title="Duplicate Removal" />
                  <Slider
                    min={1}
                    max={3}
                    step={1}
                    onValueChange={([value]) => {
                      switch (value) {
                        case 1:
                          void formik.setFieldValue('threshold', 0.9)
                          break
                        case 2:
                          void formik.setFieldValue('threshold', 0.95)
                          break
                        case 3:
                          void formik.setFieldValue('threshold', 0.98)
                          break
                      }
                    }}
                  />
                  <div className="grid grid-cols-3  text-xs text-grey-500">
                    <div>
                      <p>More</p>
                    </div>
                    <div className="text-center">
                      <p>Balanced</p>
                    </div>
                    <div className="text-right">
                      <p>Fewer</p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center space-x-3 border-[1px] p-3 border-yellow-400 bg-yellow-100 text-orange-800 rounded-md">
                  <ExclamationTriangleIcon className="w-4 h-4 " />
                  <p className="font-mono text-xs">This cannot be undone, proceed with caution</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 pt-4">
            <SelectAllRows />

            {loading && <Loader className="h-6 w-6" />}
          </div>
        </div>
      </div>
      <div className="mt-3 flex items-center space-x-4 justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
        <Button className="w-full" onClick={() => onIsOpenChange(false)} variant="outline">
          Cancel
        </Button>
        <Button className="w-full" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </div>
    </div>
  )
}
