import { useEffect } from 'react'

export const useCmdEnter = (callback: () => void | Promise<void>) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      const isEnterPressed = event.code === 'Enter' || event.code === 'NumpadEnter'
      const isCmdKeyPressed = event.ctrlKey || event.metaKey
      if (isEnterPressed && isCmdKeyPressed) {
        void callback()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [callback])
}

type callback = (e: KeyboardEvent) => boolean

export const useCmdCallback = (
  callback: () => void | Promise<void>,
  keys: callback[],
  useCmd = false
) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      const allKeysPressed = keys.every((isPressed) => isPressed(event))
      const isCmdKeyPressed = event.ctrlKey || event.metaKey
      if (allKeysPressed) {
        if (!useCmd) {
          void callback()
        } else {
          if (isCmdKeyPressed) {
            void callback()
          }
        }
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [callback, keys, useCmd])
}
