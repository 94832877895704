import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import type { Tool, Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function DeleteSkillForm({
  setOpen,
  skill,
  workspace,
  callbacks,
}: {
  workspace: Workspace
  skill: Tool
  setOpen: (val: boolean) => void
  callbacks?: { onSuccess?: () => Promise<void> | void }
}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteSkill = api.tool.delete.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  async function onSuccess() {
    await utils.tool.getAll.invalidate({ workspaceId: workspace.id })
    toast.success({
      title: 'Skill deleted',
      description: 'Successfully deleted the skill',
    })
    setOpen(false)
    void callbacks?.onSuccess?.()
    const toolId = router.query.toolId
      ? typeof router.query.toolId === 'string'
        ? parseInt(router.query.toolId)
        : undefined
      : undefined
    /** Re-route back to the /skills page only if the user deleting the skill from the /skills page, by matching the URL query */
    if (!toolId) return
    await router.push(`/${workspace.slug}/skills`)
  }

  async function onDelete() {
    try {
      setLoading(true)
      await deleteSkill.mutateAsync({ toolId: skill.id })
      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.skill_deleted, {
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
        'Skill Name': skill.name,
        'Skill Type': skill.typeId,
        'Workspace ID': workspace.id,
        'Workspace Name': workspace.name,
      })
      await onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Delete {skill.name}</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Are you sure you want to delete {skill.name}?</p>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 bg-gray-50 px-6 py-5 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => void onDelete()}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Deleting...' : 'Delete Skill'}
        </Button>
      </div>
    </>
  )
}

export const DeleteSkillModal = ({
  open,
  setOpen,
  skill,
  workspace,
  callbacks,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  skill: Tool | null
  workspace: Workspace
  callbacks?: { onSuccess?: () => Promise<void> | void }
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {skill && (
            <DeleteSkillForm
              setOpen={setOpen}
              skill={skill}
              workspace={workspace}
              callbacks={callbacks}
            />
          )}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
