/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */

import { syntaxTree } from '@codemirror/language'
import type { Range } from '@codemirror/state'
import type { DecorationSet, ViewUpdate } from '@codemirror/view'
import { Decoration, EditorView, MatchDecorator, ViewPlugin, WidgetType } from '@codemirror/view'
import { usePresignedUpload } from 'next-s3-upload'
import { useCallback, useMemo, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { FileUpload } from '@/common/components'
import { getMimeType } from '@/common/lib/getMimeType'

interface ImageWidgetParams {
  url: string
  editorView: EditorView
  from: number
  to: number
}

export const EditableImage = ({
  index,
  url,
  onChange,
}: {
  index?: number
  url: string
  onChange: (url: string, index: number) => void
}) => {
  const { uploadToS3 } = usePresignedUpload()
  const [isUploading, setIsUploading] = useState(false)

  const workspace_slug = window?.location?.pathname?.slice(1).split('/').shift()

  const handleFilesChange = async (files: File[]) => {
    const file = files[0]
    if (!file) return
    setIsUploading(true)
    const { url } = await uploadToS3(file, {
      endpoint: {
        request: {
          body: {
            workspaceGuid: workspace_slug,
          },
        },
      },
    })
    onChange(url, index || 0)
    setIsUploading(false)
  }

  const onChangeFiles = useCallback((file: File[]) => {
    void handleFilesChange(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const imageSrc = useMemo(() => {
    let src = url
    if (url?.match(/https?:\/\//)) {
      src = url.includes('amazonaws.com') ? `/api/files/download?fileUrl=${url}` : url
    } else if (url.startsWith('data:')) {
      src = url
    } else {
      src = `data:${getMimeType(url)!};base64,${url}`
    }
    return src
  }, [url])

  if (isUploading) {
    return <div>Uploading...</div>
  }

  return (
    <div className="overflow-hidden space-y-1">
      <img src={imageSrc} className="max-h-[100px] inline-block rounded-md" />
      <FileUpload multiple={false} label="Change Image" onChangeFiles={onChangeFiles} />
    </div>
  )
}

class ImageWidget extends WidgetType {
  url: string
  editorView: EditorView
  from: number
  to: number

  constructor({ url, editorView, from, to }: ImageWidgetParams) {
    super()

    this.url = url
    this.editorView = editorView
    this.from = from
    this.to = to
  }

  get estimatedHeight(): number {
    return 100
  }

  eq(imageWidget: ImageWidget) {
    return imageWidget.url === this.url
  }

  toDOM() {
    const container = document.createElement('div')
    const root = createRoot(container)
    root.render(
      <EditableImage
        url={this.url}
        key={new Date().getTime()}
        onChange={(url) => {
          this.dispatchImageUrlChange(url)
        }}
      />
    )

    return container
  }

  dispatchImageUrlChange(newUrl: string) {
    if (!this.editorView) return
    this.editorView.dispatch(
      this.editorView.state.update({
        changes: { from: this.from, to: this.to, insert: newUrl },
        selection: { anchor: this.from + newUrl.length, head: this.from + newUrl.length },
      })
    )

    this.editorView.requestMeasure()
  }
}

const imageDecoration = (imageWidgetParams: ImageWidgetParams) =>
  Decoration.replace({
    widget: new ImageWidget(imageWidgetParams),
  })

const imagesDeco = (view: EditorView) => {
  const widgets: Range<Decoration>[] = []

  syntaxTree(view.state).iterate({
    enter: (node) => {
      const { name, from, to } = node
      const text = view.state.doc.sliceString(from, to)

      if (name === 'PropertyName' && text === '"image_url"') {
        const sibling = node.node.nextSibling
        if (sibling) {
          try {
            const urlRegex = /"url"\s*:\s*"([^"]+)"/

            if (view.state.doc.sliceString(sibling.from, sibling.to).match(urlRegex)) {
              const cursor = sibling.cursor()
              cursor.firstChild()
              do {
                if (cursor.name === 'String') {
                  const imageFrom = cursor.from + 1
                  const imageTo = cursor.to - 1
                  const imageUrl = view.state.doc.sliceString(imageFrom, imageTo)
                  const deco = imageDecoration({
                    url: imageUrl,
                    editorView: view,
                    from: imageFrom,
                    to: imageTo,
                  })
                  widgets.push(deco.range(imageFrom, imageTo))
                  break
                }
              } while (cursor.next())
            }
          } catch (error) {
            console.log('error:', error)
          }
        }
      }
    },
  })

  return Decoration.set(widgets)
}

export const imagesPlugin = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet

    constructor(view: EditorView) {
      this.decorations = imagesDeco(view)
    }

    update(update: ViewUpdate) {
      if (
        update.docChanged ||
        update.viewportChanged ||
        syntaxTree(update.startState) != syntaxTree(update.state)
      )
        this.decorations = imagesDeco(update.view)
    }
  },
  {
    decorations: (v) => v.decorations,
    // provide: (f) => DecorationSet.empty,
  }
)

const imageMatcher = new MatchDecorator({
  regexp: /https:\/\/[\w\-\.\/]*amazonaws\.com[^\s]*/gm,
  // decoration: match => Decoration.replace({
  //   widget: new ImageWidget({url, editorView: view, from: match.from, to: match.to}),
  // })
  decoration: (match, view, pos) => {
    const imageUrl = match[0]
    return imageDecoration({
      url: imageUrl,
      editorView: view,
      from: pos,
      to: pos + imageUrl.length,
    })
  },
})

export const imagesRegexPlugin = ViewPlugin.fromClass(
  class {
    placeholders: DecorationSet
    constructor(view: EditorView) {
      this.placeholders = imageMatcher.createDeco(view)
    }
    update(update: ViewUpdate) {
      this.placeholders = imageMatcher.updateDeco(update, this.placeholders)
    }
  },
  {
    decorations: (instance) => instance.placeholders,
    provide: (plugin) =>
      EditorView.atomicRanges.of((view) => {
        return view.plugin(plugin)?.placeholders || Decoration.none
      }),
  }
)
