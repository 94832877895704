import type { Action, Workspace } from '@prisma/client'
import Link from 'next/link'
import React from 'react'
import { ScrollArea } from '@/client/components'
import { ActionVersionTable } from '@/common/components/actions/ActionCard'

export function ActionVersions({
  action,
  workspace,
  appslug,
}: {
  action: Action
  workspace: Workspace
  appslug: string
  onIsOpenChange?: (isOpen: boolean) => void
}) {
  return (
    <ScrollArea className="max-h-[calc(100vh-30rem)]">
      <div className="m-1 shadow ring-1 ring-grey-200 sm:rounded-lg">
        <Link
          href={`/${workspace.slug}/apps/${appslug}/actions/${action.slug}/versions`}
          className="min-w-full flex flex-row items-center rounded-t-md bg-grey-25 p-3 text-xs font-medium uppercase tracking-wider text-primary-800"
        >
          <span>Versions &rarr;</span>
        </Link>
        <div className="bg-white">
          <ActionVersionTable action={action} workspace={workspace} />
        </div>
      </div>
    </ScrollArea>
  )
}
