import React from 'react'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import type { UseDisableActionProps } from './types'

export const useDisableAction = ({
  workspace,
  onSuccess: onSuccessCallback,
}: UseDisableActionProps) => {
  const disableAPI = api.action.disableAPI.useMutation()
  const utils = api.useContext()

  const { user } = useUser()

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'API deactivated',
      description: `API access is disabled`, // need to pipe in Action.name
    })
    if (typeof onSuccessCallback === 'function') {
      onSuccessCallback()
    }
  }, [onSuccessCallback])

  const onSubmit = React.useCallback(
    async (actionId: number) => {
      await disableAPI.mutateAsync(
        {
          actionId,
        },
        {
          onError: (err) => {
            toast.error({
              title: 'An error coccured',
              description: err.message,
            })
          },
        }
      )
      await utils.action.get.invalidate({ id: actionId })
      await utils.actionVersion.getAllForAction.invalidate({ actionId })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.action_updated, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'Action ID': actionId,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      onSuccess()
    },
    [
      disableAPI,
      utils.action.get,
      utils.actionVersion.getAllForAction,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onSuccess,
    ]
  )

  return {
    onSubmit,
  }
}
