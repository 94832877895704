import { json } from '@codemirror/lang-json'
import { python } from '@codemirror/lang-python'
import { InformationCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import type { DatabaseProvider, ToolType } from '@prisma/client'
import CodeMirror from '@uiw/react-codemirror'
import type { FieldArrayRenderProps, FieldProps, FormikProps } from 'formik'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { Button, Switch } from '@/client/components'
import type {
  CreateSkillFormInputs,
  SkillMetadata,
  SkillMetadataItem,
} from '../CreateSkill/Form/types'

export function SkillMetadataForm({
  formik,
  databaseProviders,
  toolType,
}: {
  formik: FormikProps<CreateSkillFormInputs>
  databaseProviders: DatabaseProvider[]
  toolType: ToolType
}) {
  const providerId = formik.values.databaseProviderId
  const provider = databaseProviders.find((provider) => provider.id === providerId)

  if (provider) {
    return null
  }

  const allMetadata = toolType.required_metadata as SkillMetadata
  let optionalMetadata: SkillMetadata = []
  if (allMetadata && allMetadata.length > 0) {
    optionalMetadata = allMetadata.filter((metadata) => {
      return !metadata.required && !formik.values.metadata.find((m) => m.name === metadata.name)
    })
  }

  const addMetadata = (metadata: SkillMetadataItem) => {
    void formik.setFieldValue('metadata', [
      ...formik.values.metadata,
      { name: metadata.name, value: metadata.value },
    ])
  }

  return (
    <>
      {formik.values.metadata.length > 0 && (
        <div className="flex flex-col gap-6">
          {formik.values.metadata.length > 0 && formik.values.typeId === 21 && (
            <div className="flex items-center rounded-lg ring-1 ring-blue-500 p-2 text-xs">
              <InformationCircleIcon className="mr-2 h-4 w-4 whitespace-nowrap text-blue-500" />
              <span>Specify API route, request type, payload, and headers</span>
            </div>
          )}

          <FieldArray name="metadata">
            {({ remove }: FieldArrayRenderProps) => (
              <div className="space-y-6">
                {formik.values.metadata.length > 0 &&
                  formik.values.metadata.map((item, index) => (
                    <MetadataItemInput
                      key={index}
                      toolType={toolType}
                      item={item}
                      index={index}
                      formik={formik}
                      remove={remove}
                    />
                  ))}

                {optionalMetadata.map((item, index) => (
                  <AddMetadataButton key={index} item={item} addMetadata={addMetadata} />
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      )}
    </>
  )
}

const AddMetadataButton = ({
  item,
  addMetadata,
}: {
  item: SkillMetadataItem
  addMetadata: (metadata: SkillMetadataItem) => void
}) => {
  const { label } = getMetadataItemDetails(item.name)
  return (
    <Button
      className="mr-2"
      variant="outline"
      onClick={() => addMetadata(item)}
      startIcon={PlusIcon}
    >
      {label}
    </Button>
  )
}

const getMetadataItemDetails = (name: string) => {
  let showKeyInput = false
  let label = name
  let valuePlaceholder = 'value'

  let useTextarea = false
  let useCodeMirror = ['source_code', 'function_code', 'definition'].includes(name)

  switch (name) {
    case 'url':
      label = 'URL'
      valuePlaceholder = 'https://klu.ai'
      break
    case 'HD':
      showKeyInput = false
      label = 'Use HD output?'
      break
    case 'json':
      label = 'JSON'
      useTextarea = true
      useCodeMirror = true
      valuePlaceholder = '{"model":"gpt-3.5-turbo","prompt":"Translate \'taco\' to French."}'
      break
    case 'method':
      label = 'Method'
      valuePlaceholder = 'POST'
      break
    case 'headers':
      label = 'Headers'
      useTextarea = true
      useCodeMirror = true
      valuePlaceholder = '{"Authorization":"Bearer 123"}'
      break
    case 'api_docs':
      label = 'API Docs'
      valuePlaceholder = 'https://api.klu.ai/redoc'
      break
    case 'source_code':
      label = 'Source Code'
      valuePlaceholder = 'print("Hello, World!")'
      break
    case 'tmdb_bearer_token':
      label = 'TMBD Bearer Token'
      break
    case 'wolfram_alpha_appid':
      label = 'Wolfram Alpha API Key'
      break
    case 'zapier_nla_api_key':
      label = 'Zapier NLA API Key'
      break
    case 'function_code':
      label = 'Function Code'
      useTextarea = true
      valuePlaceholder = 'print("Hello, World!")'
      break
    case 'source_code':
      useTextarea = true
      label = 'Source Code'
      valuePlaceholder = 'print("Hello, World!")'
      break
    case 'definition':
      useTextarea = true
      useCodeMirror = true
      break
    case 'return_direct':
      label = 'Return tool call(s) only'
      break
    default:
      showKeyInput = true
      label = 'Value'
      valuePlaceholder = 'value'
      break
  }

  return { label, valuePlaceholder, showKeyInput, useTextarea, useCodeMirror }
}

const MetadataItemInput = ({
  item,
  index,
  formik,
  remove,
  toolType,
}: {
  item: SkillMetadataItem
  index: number
  formik: FormikProps<CreateSkillFormInputs>
  remove: (index: number) => void
  toolType: ToolType
}) => {
  const { name } = item

  const { label, valuePlaceholder, showKeyInput, useTextarea, useCodeMirror } =
    getMetadataItemDetails(name)

  const toolTypeMetadata = toolType.required_metadata as SkillMetadataItem[] | undefined
  const requiredMetadata =
    toolTypeMetadata?.filter((metadata) => metadata.required).map((m) => m.name) || []

  const readOnlyMetaData =
    toolTypeMetadata?.filter((metadata) => metadata.readOnly).map((m) => m.name) || []

  const required = requiredMetadata.includes(item.name) || false
  const readOnly = readOnlyMetaData.includes(item.name) || false

  const isBoolean =
    toolTypeMetadata?.find((metadata) => metadata.name === item.name)?.type === 'boolean'

  const onChangeValue = (value: string) => {
    formik.handleChange({
      target: {
        name: `metadata.${index}.value`,
        value,
      },
    })
    formik.setFieldTouched(`metadata.${index}.value`, true)
  }

  return (
    <>
      <div>
        <div className="flex flex-row gap-3 items-end w-full">
          {showKeyInput && (
            <div>
              <label htmlFor={`metadata.${index}.name`} className="text-sm sentence-case">
                Key
              </label>
              <input
                id={`metadata.${index}.name`}
                name={`metadata.${index}.name`}
                value={formik.values.metadata[index]?.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                placeholder="Key"
                type="text"
                disabled={required}
              />

              <ErrorMessage
                name={`metadata.${index}.name`}
                component="div"
                className="mt-2 text-xs text-red-500"
              />
            </div>
          )}

          <div className="w-full">
            <label htmlFor={`metadata.${index}.value`} className="text-sm sentence-case">
              {label}
            </label>

            {isBoolean ? (
              <Switch
                id={`metadata.${index}.value`}
                name={`metadata.${index}.value`}
                className="ml-3"
                disabled={readOnly}
                checked={formik.values.metadata[index]?.value == 'true'}
                onCheckedChange={(value) => {
                  onChangeValue(value.toString())
                }}
              />
            ) : (
              <>
                {useTextarea ? (
                  <>
                    {useCodeMirror ? (
                      <Field name={`metadata.${index}.value`}>
                        {({ field }: FieldProps) => (
                          <div className="border border-grey-300">
                            <CodeMirror
                              id={`metadata.${index}.value`}
                              readOnly={readOnly}
                              placeholder={valuePlaceholder || 'Source code'}
                              height={'500px'}
                              {...field}
                              onChange={(editor) => {
                                // override onChange because CodeMirror callback's parameter is string, while formik expects event
                                onChangeValue(editor)
                              }}
                              extensions={[
                                ['source_code', 'function_code'].includes(item.name)
                                  ? python()
                                  : json(),
                              ]}
                            />
                          </div>
                        )}
                      </Field>
                    ) : (
                      <textarea
                        name={`metadata.${index}.value`}
                        placeholder="Use natural language, JSON, or XML"
                        className="block w-full min-w-0 flex-1 font-inter rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                        onChange={(e) => {
                          onChangeValue(e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.metadata[index]?.value}
                        rows={10}
                        disabled={readOnly}
                      />
                    )}
                  </>
                ) : (
                  <input
                    id={`metadata.${index}.value`}
                    name={`metadata.${index}.value`}
                    onChange={(e) => {
                      onChangeValue(e.target.value)
                    }}
                    value={formik.values.metadata[index]?.value}
                    onBlur={formik.handleBlur}
                    className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                    placeholder={valuePlaceholder}
                    type="text"
                    disabled={readOnly}
                  />
                )}
              </>
            )}
          </div>

          {!required && (
            <Button variant="outline" startIcon={XMarkIcon} onClick={() => remove(index)}></Button>
          )}
        </div>
        <ErrorMessage
          name={`metadata.${index}.value`}
          component="div"
          className="mt-2 text-xs text-red-500"
        />
      </div>
    </>
  )
}
