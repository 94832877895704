import React from 'react'

/**
 * Custom hook that adjusts the height of a textarea element based on its content.
 *
 * @template T
 * @param {React.RefObject<HTMLTextAreaElement>} ref - The ref object pointing to the textarea element.
 * @param {T} data - The data that influences the textarea content and height.
 * @param {Array<boolean | string | object | number>} deps - An array of dependencies that trigger the effect. Typically empty.
 *
 * @returns {void}
 *
 * @example
 * // Usage in a functional component
 * const MyComponent = () => {
 *   const textareaRef = React.useRef(null);
 *   const data = "Some text content";
 *
 *   useTextareaWithAdaptiveHeight(textareaRef, data, []);
 *
 *   return <TextareaWithAdaptiveHeight ref={textareaRef} />;
 * }
 */
const useTextareaWithAdaptiveHeight = <T>(
  ref: React.RefObject<HTMLTextAreaElement>,
  data: T,
  deps: Array<boolean | string | object | number>
): void => {
  React.useEffect(() => {
    /* Adaptive textarea height based on the content */
    if (data && ref.current) {
      ref.current.style.height = 'auto'
      ref.current.style.height = `${ref.current.scrollHeight}px`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, ...deps])
}

export default useTextareaWithAdaptiveHeight
