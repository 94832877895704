export const tryParseJSON = <T extends object = Record<string, unknown>>(maybeJson: string) => {
  try {
    return JSON.parse(maybeJson) as T
  } catch (error) {
    return maybeJson
  }
}

export const isJSONParsable = (jsonString: string): boolean => {
  try {
    const obj = JSON.parse(jsonString) as Record<string, string>
    return typeof obj === 'object' && obj !== null
  } catch (error) {
    return false
  }
}
