import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import type { ContextSource } from '@prisma/client'
import { Fragment, useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { api } from '@/utils'

function DeleteContextSourceForm({
  setOpen,
  contextSource,
  onDeletedCallback,
}: {
  contextSource: ContextSource
  setOpen: (val: boolean) => void
  onDeletedCallback?: () => void
}) {
  const [loading, setLoading] = useState(false)
  const deleteContextSource = api.contextSource.delete.useMutation()
  const utils = api.useContext()

  async function onSuccess() {
    await utils.contextSource.getAll.invalidate()
    toast.success({
      title: 'Context source deleted',
      description: "Successfully deleted the context's source",
    })
    setOpen(false)
    if (typeof onDeletedCallback === 'function') {
      onDeletedCallback()
    }
  }

  async function onDelete() {
    setLoading(true)
    await deleteContextSource.mutateAsync(
      { id: contextSource.id },
      {
        onSuccess: () => {
          void onSuccess()
        },
        onError: (error) => {
          toast.error({
            title: 'Error',
            description: error.message,
          })
        },
        onSettled: () => {
          setLoading(false)
        },
      }
    )
  }

  return (
    <>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">
              Delete this Context Source
            </h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700">Are you sure you want to delete this source?</p>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between px-4 py-5 border-t border-grey-300 bg-grey-50 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          variant="destructive"
          onClick={() => void onDelete()}
        >
          {loading ? 'Deleting...' : 'Delete'}
        </Button>
      </div>
    </>
  )
}

export const DeleteContextSourceModal = ({
  open,
  setOpen,
  contextSource,
  onDeletedCallback,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  contextSource: ContextSource | null
  onDeletedCallback?: () => void
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {contextSource && (
            <DeleteContextSourceForm
              setOpen={setOpen}
              contextSource={contextSource}
              onDeletedCallback={onDeletedCallback}
            />
          )}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
