export const databaseProviders = [
  {
    id: 1,
    name: 'PostgreSQL',
    requiredMetadata: [
      {
        name: 'url',
        value: '',
        placeholder: 'postgres://user:password@host:port/dbname',
      },
    ],
  },
  {
    id: 2,
    name: 'MySQL',
    requiredMetadata: [
      {
        name: 'url',
        value: '',
        placeholder: 'mysql://user:password@host:port/dbname',
      },
    ],
  },
  {
    id: 3,
    name: 'Redis',
    requiredMetadata: [
      {
        name: 'uri',
        value: '',
        placeholder: 'redis://user:password@host:port/dbname',
      },
    ],
  },
  {
    id: 4,
    name: 'MongoDB',
    requiredMetadata: [
      {
        name: 'connection_string',
        value: '',
        placeholder: 'mongodb+srv://user:password@clusterurl.com/?retryWrites=true&w=majority',
      },
    ],
  },
  {
    id: 5,
    name: 'Snowflake',
    requiredMetadata: [
      {
        name: 'username',
        value: '',
        placeholder: 'username',
      },
      {
        name: 'password',
        value: '',
        placeholder: 'password',
      },
      {
        name: 'account',
        value: '',
        placeholder: 'account',
      },
      {
        name: 'key',
        value: '',
        placeholder: 'key',
      },
    ],
  },
  {
    id: 6,
    name: 'Elastic',
    requiredMetadata: [
      {
        name: 'endpoint',
        value: '',
        placeholder: 'https://host:port',
      },
      {
        name: 'index',
        value: '',
        placeholder: 'index',
      },
      {
        name: 'field',
        value: '',
        placeholder: 'field',
      },
    ],
  },
  {
    id: 7,
    name: 'Pinecone',
    requiredMetadata: [
      {
        name: 'api_key',
        value: '',
        placeholder: 'api_key',
      },
      {
        name: 'environment',
        value: '',
        placeholder: 'environment',
      },
      {
        name: 'index_name',
        value: '',
        placeholder: 'index_name',
      },
    ],
  },
  // {
  //   id: 8,
  //   name: 'MS SQL',
  //   requiredMetadata: [],
  // },
  // {
  //   id: 9,
  //   name: 'Oracle DB',
  //   requiredMetadata: [],
  // },
  // {
  //   id: 10,
  //   name: 'AWS Redshift',
  //   requiredMetadata: [],
  // },
  // {
  //   id: 11,
  //   name: 'GCP BigQuery',
  //   requiredMetadata: [],
  // },
  // {
  //   id: 12,
  //   name: 'Databricks',
  //   requiredMetadata: [],
  // },
  // {
  //   id: 13,
  //   name: 'Google Sheets',
  //   requiredMetadata: [],
  // },
  // {
  //   id: 14,
  //   name: 'Firebase',
  //   requiredMetadata: [],
  // },
]
