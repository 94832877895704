import type {
  Action,
  ActionSession,
  ActionVersion,
  App,
  Block,
  Context,
  Data,
  Environment,
  EnvironmentVersion,
  IndexOnAgent,
  Model,
  Tool,
  ToolOnAgent,
  User,
  Workflow,
  Workspace,
} from '@prisma/client'
import { z } from 'zod'
import type { WorkspaceModelProviderWithProvider } from '@/common/types/workspaceModelProvider'

export const actionType = z.enum(['legacy', 'prompt', 'chat', 'workflow', 'worker'] as const)

export type ActionCreationMode = 'option' | 'ai' | 'manual'

export type ActionType = z.infer<typeof actionType>
export type SelectedActionType = ActionType | null

export const blockTypes = {
  action: 'Action',
  skill: 'Skill',
  code: 'Code',
  context: 'Context',
  output: 'Output',
}

export const actionTypes = {
  legacy: 'legacy',
  prompt: 'prompt',
  chat: 'chat',
  workflow: 'workflow',
  // worker: 'Worker',
}

export const activeActionTypes = {
  prompt: 'prompt',
  chat: 'chat',
  workflow: 'workflow',
}

export enum ResponseFormat {
  json = 'json',
  json_schema = 'json_schema',
  text = 'text',
}

export interface ModelConfig {
  temperature: number
  maxResponseLength: number
  stopSequence: string[] | null
  topP: number
  presencePenalty: number
  frequencyPenalty: number
  numRetries: number
  timeout: number
  responseFormat: ResponseFormat | null
  json_schema: string | undefined
  seed: number | null
  logitBias: Record<string, number>[] | null
  parallel_tool_calls?: boolean
}

export type DetailedAgent = Action & {
  app: App & {
    workspace: Workspace
  }
  model: Model & {
    workspaceModelProvider: WorkspaceModelProviderWithProvider
  }
  datapointCount?: Data
  createdBy: User
  lastUpdatedBy: User | null
  indices: (IndexOnAgent & {
    createdBy: User
    index: Context
  })[]
  tools: (ToolOnAgent & {
    createdBy: User
    tool: Tool
  })[]
}

export type AppIncludingUser = App & { createdBy: User }

export const agentLabels = {
  legacy: 'legacy',
  prompt: 'prompt',
  chat: 'chat',
  worker: 'worker',
  workflow: 'workflow',
}

export type AgentType = keyof typeof agentLabels

export type BlockWithAction = Block & { action?: Action | null }

export type DetailedWorkflow = Workflow & {
  createdBy: User
  lastUpdatedBy: User | null
  blocks: BlockWithAction[]
}

export const triggerTypes = z.enum(['Manual', 'Cron Job', 'Webhook', 'API'])
export type TriggerType = z.infer<typeof triggerTypes>

export const triggerConfigSchema = z.object({
  type: triggerTypes,
  config: z.object({
    cron: z.string(),
  }),
})

export type TriggerConfig = z.infer<typeof triggerConfigSchema>

export type DetailedActionVersion = ActionVersion & {
  deployments: (EnvironmentVersion & {
    environment: Environment
  })[]
  createdBy: User
}

export type DetailedActionSession = ActionSession & {
  createdBy: User
  Data: Data[]
}
