import { toast } from '@/client/components'
import type { DetailedContext } from '@/common/types/context'
import { api } from '@/utils'

export const RefreshContextButton = ({
  context,
  children,
}: {
  context: DetailedContext
  children: React.ReactNode
}) => {
  const refreshContext = api.context.refresh.useMutation()
  const utils = api.useContext()

  const handleRefreshContext = async () => {
    if (confirm(`Are you sure you want to refresh ${context.name}?`)) {
      await refreshContext.mutateAsync({ id: context.id })

      await utils.context.get.invalidate({
        id: context.id,
      })
      await utils.context.getStatus.invalidate({
        id: context.id,
      })

      toast.success({
        title: 'Context refresh started',
        description: 'Applying library settings and refreshing sources. Please wait a few minutes',
      })
    }
  }

  return (
    <div
      onClick={() => {
        void handleRefreshContext()
      }}
    >
      {children}
    </div>
  )
}
