import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { CreateEmptyAppModal, CreateModelProviderModal } from '@/common/components'
import { CreateContextModal } from '../modals/contexts/CreateContext'
import { CreateSkillDrawer } from '../modals/tools/CreateSkill'
import { CreateWorkspaceModal } from '../modals/workspaces/CreateWorkspaceModal'
import { useApplication } from './context'

export function GlobalModals() {
  const { workspace, createWorkspaceModalOpen, setCreateWorkspaceModalOpen } = useApplication()
  const router = useRouter()
  const [userAction, setUserAction] = useState('')

  const [open, setOpen] = useState(false)

  useEffect(() => {
    const { userAction: newUserAction } = router.query
    setUserAction(newUserAction as string)
    setOpen(true)
  }, [userAction, router.query])

  if (!workspace) return null

  return (
    <>
      <CreateWorkspaceModal open={createWorkspaceModalOpen} setOpen={setCreateWorkspaceModalOpen} />
      <div key={userAction}>
        {userAction === 'createApp' && (
          <CreateEmptyAppModal workspace={workspace} isOpen={open} onIsOpenChange={setOpen} />
        )}
        {userAction === 'createModelProvider' && (
          <CreateModelProviderModal
            open={open}
            setOpen={setOpen}
            workspace={workspace}
            forced={true}
          />
        )}
        {userAction === 'createSkill' && (
          <CreateSkillDrawer workspace={workspace} isOpen={open} setIsOpen={setOpen} />
        )}
        {userAction === 'createContext' && (
          <CreateContextModal workspace={workspace} isOpen={open} onIsOpenChange={setOpen} />
        )}
      </div>
    </>
  )
}
