import { CheckIcon, ChevronUpDownIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import type { Workspace } from '@prisma/client'
import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { SignoutIcon } from '@/client/assets/icons/icons'
import { Button, Command, Popover } from '@/client/components'
import { cn } from '@/client/utils'
import { Logo } from '@/common/components'
import { useApplication } from '@/common/components/ui/context'
import { WorkspaceLogo } from '@/common/components/workspace/workspace-logo'
import { useCurrentWorkspace, useFetchWorkspacesForMember } from '@/common/hooks'

type WorkspaceSwitcherProps = React.ComponentPropsWithoutRef<typeof Popover.Trigger>

function WorkspaceLogoWrapper({ workspace }: { workspace: Workspace }) {
  // if there is no logo then we wrap it in a black div
  if (!workspace.logoUrl) {
    return (
      <div className="flex items-center rounded-full bg-black p-1 dark:bg-grey-50">
        <Logo className="h-4 w-4 fill-white dark:fill-black" />
      </div>
    )
  }
  return <WorkspaceLogo workspace={workspace} className="h-6 w-6" />
}

export function WorkspaceSwitcher({ className }: WorkspaceSwitcherProps) {
  const [open, setOpen] = useState(false)
  const { setCreateWorkspaceModalOpen } = useApplication()
  const { workspace } = useCurrentWorkspace()
  const { workspaces } = useFetchWorkspacesForMember()

  const router = useRouter()

  async function handleSignout() {
    await signOut({
      redirect: false,
      callbackUrl: '/signin',
    })
    await router.push('/signin')
  }

  if (!workspace || !workspaces) {
    return null
  }

  function onSelect(workspace: Workspace) {
    void router.push(`/${workspace.slug}`)
    setOpen(false)
  }

  return (
    <>
      <Popover.Root open={open} onOpenChange={setOpen} modal={false}>
        <Popover.Trigger asChild>
          <Button
            size="sm"
            variant="ghost"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a workspace"
            className={cn('w-4/5 md:w-full justify-between font-medium truncate', className)}
          >
            <div className="flex items-center justify-center dark:text-grey-200">
              <div className="flex-shrink-0">
                {workspace.logoUrl ? (
                  <WorkspaceLogo workspace={workspace} className="h-5 w-5" />
                ) : (
                  <div className="flex items-center justify-center rounded-full bg-black h-5 w-5 dark:bg-grey-50">
                    <Logo className="h-3.5 w-3.5 fill-white dark:fill-black" />
                  </div>
                )}
              </div>
              <span className="ml-3 font-everett font-medium">{workspace.name}</span>
            </div>
            <ChevronUpDownIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </Popover.Trigger>
        <Popover.Content className="w-48 p-0">
          <Command.Root>
            <Command.List>
              <Command.Empty>No workspace found.</Command.Empty>

              <WorkspaceSelectionList
                onSelect={onSelect}
                workspaces={workspaces}
                currentWorkspace={workspace}
              />
              <Command.List>
                <Command.Group>
                  <Command.Item
                    onSelect={() => {
                      setCreateWorkspaceModalOpen(true)
                    }}
                  >
                    <PlusCircleIcon className="mr-2 h-5 w-5" />
                    <span className="font-light text-sm">Create Workspace</span>
                  </Command.Item>
                </Command.Group>
                {/* <Command.Input placeholder="Search workspaces..." /> */}
              </Command.List>
            </Command.List>
            <Command.Separator />
            <Command.Group>
              {/* {user && (
                <Command.Group heading="Settings">
                  <Command.Item
                    onSelect={() => {
                      setOpen(false)
                      void router.push(`/${workspace.slug}/settings`)
                    }}
                  >
                    <UserAvatar user={user} className="mr-2 h-5 w-5 rounded-md" />
                    Workspace Settings
                  </Command.Item>
                </Command.Group>
              )} */}
              <Command.Item
                onSelect={() => {
                  setOpen(false)
                  void handleSignout()
                }}
              >
                <SignoutIcon className="mr-2 h-5 w-5" />
                <span className="font-light text-sm">Sign out</span>
              </Command.Item>
            </Command.Group>
          </Command.Root>
        </Popover.Content>
      </Popover.Root>
    </>
  )
}

export function WorkspaceSelectionList({
  workspaces,
  currentWorkspace,
  onSelect,
}: {
  workspaces: Workspace[]
  currentWorkspace: Workspace
  onSelect: (workspace: Workspace) => void
}) {
  if (!workspaces) {
    return <></>
  }

  // Sort workspaces to ensure the current workspace is first
  const sortedWorkspaces = [...workspaces].sort((a, b) =>
    a.id === currentWorkspace.id ? -1 : b.id === currentWorkspace.id ? 1 : 0
  )

  return (
    <>
      <Command.Group>
        {sortedWorkspaces.map((workspace, i) => (
          <Command.Item
            key={workspace.id}
            onSelect={() => {
              onSelect(workspace)
            }}
            className="text-sm"
          >
            <div className="mr-2 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-lg font-medium">
              <WorkspaceLogoWrapper workspace={workspace} />
            </div>
            {workspace.name}
            <span className="hidden">{i}</span>
            <CheckIcon
              className={cn(
                'ml-auto h-4 w-4',
                currentWorkspace.id === workspace.id ? 'opacity-100' : 'opacity-0'
              )}
            />
          </Command.Item>
        ))}
      </Command.Group>
    </>
  )
}
