import type { Context, CrontabSchedule, PeriodicTask } from '@prisma/client'
import { getSchedule, stringToArray } from 'cron-converter'
import cronstrue from 'cronstrue'
import { format } from 'date-fns'
import { Form, Formik, useFormikContext, type FormikHelpers } from 'formik'
import type { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, toast } from '@/client/components'
import { FieldError } from '@/common/components'
import { useFetchContextSchedule } from '@/common/hooks'
import type { DetailedContext } from '@/common/types/context'
import { api } from '@/utils'

export const contextScheduleSchema = z.object({
  config: z.object({
    cron: z.string(),
  }),
})

export type ContextScheduleSchema = z.infer<typeof contextScheduleSchema>

export function CronField() {
  const formik = useFormikContext<ContextScheduleSchema>()
  const [next, setNext] = useState<DateTime[]>([])
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    try {
      const arr = stringToArray(formik.values.config.cron)
      let schedule = getSchedule(arr)
      const reference = new Date()
      schedule = getSchedule(arr, reference, Intl.DateTimeFormat().resolvedOptions().timeZone)
      setNext([schedule.next(), schedule.next(), schedule.next()])
      setError(null)
    } catch (e) {
      setError('Cron is invalid. Format should be: */5 * * * *')
    }
  }, [formik.values.config.cron])

  async function handleUpdateCron(cron: string) {
    await formik.setFieldValue('config', {
      cron,
    })
  }

  const presets = [
    { label: 'Monthly', value: '0 1 1 * *' },
    { label: 'Daily', value: '0 1 * * *' },
    { label: 'Weekly', value: '0 1 * * 1' },
    { label: 'Hourly', value: '0 * * * *' },
  ]

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value != '') {
      void handleUpdateCron(e.target.value)
    }
  }

  return (
    <div className="my-6">
      <p className="text-sm text-grey-700">Choose a schedule</p>

      <div className="mt-2">
        <div className="grid grid-cols-4">
          {/* hiding for now */}
          {false && (
            <>
              <div className="col-span-1 flex-row rounded-md hidden">
                <input
                  name="cron"
                  placeholder="*/5 * * * *"
                  type="text"
                  maxLength={30}
                  className="block w-full min-w-0 flex-1 rounded-md rounded-br-none rounded-tr-none border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                  onChange={(e) => {
                    void handleUpdateCron(e.target.value)
                  }}
                  value={formik.values.config.cron}
                />
              </div>
            </>
          )}

          <div className="col-span-2">
            <select
              name="cronPresets"
              className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
              onChange={handleSelectChange}
              value={formik.values.config.cron}
            >
              {!formik.values.config.cron && <option value="">None</option>}

              {presets.map((preset, i) => (
                <option key={`cron-preset-${i}`} value={preset.value}>
                  {preset.label}
                </option>
              ))}
            </select>

            <FieldError fieldName="cron" formik={formik} />
            {formik.values.config.cron && error && (
              <p className="mt-2 font-medium text-red-600">{error}</p>
            )}
            {formik.values.config.cron && next && (
              <div className="mt-2 flex flex-col space-y-1 text-grey-800 bg-grey-200 rounded-md p-3 text-sm">
                <p>
                  Schedule:{' '}
                  {cronstrue.toString(formik.values.config.cron, {
                    throwExceptionOnParseError: false,
                  })}
                </p>
                <p>Will execute next at:</p>

                <ul className="list-inside list-disc">
                  {next.map((n: DateTime, i) => (
                    <li key={i}>
                      {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                        format(new Date(n.toJSDate()), 'MMMM dd yyyy HH:mm')
                      }
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function ScheduleForm({
  context,
  scheduledTask,
}: {
  context: Context
  scheduledTask?: {
    task: PeriodicTask
    schedule: CrontabSchedule | null
  } | null
}) {
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const createSchedule = api.context.createSchedule.useMutation()
  const deleteSchedule = api.context.deleteSchedule.useMutation()
  const utils = api.useContext()

  const cronString =
    scheduledTask && scheduledTask.schedule
      ? `${scheduledTask.schedule.minute} ${scheduledTask.schedule.hour} ${scheduledTask.schedule.day_of_week} ${scheduledTask.schedule.day_of_month} ${scheduledTask.schedule.month_of_year}`
      : ''

  const formikConfig = {
    initialValues: {
      config: {
        cron: cronString,
      },
    },
    validationSchema: toFormikValidationSchema(contextScheduleSchema),
    onSubmit: async (values: ContextScheduleSchema) => {
      setLoading(true)
      await createSchedule.mutateAsync(
        { ...values, contextId: context.id },
        {
          onSuccess: () => {
            toast.success({
              title: 'Context refresh schedule updated',
              description: "Successfully updated the context's refresh schedule",
            })
          },
          onError: (error) => {
            toast.error({
              title: 'Something went wrong',
              description: error.message,
            })
          },
          onSettled: () => {
            setLoading(false)
          },
        }
      )
      await utils.context.getSchedule.invalidate({ contextGuid: context.guid })
    },
  }

  async function clearSchedule(formik: FormikHelpers<ContextScheduleSchema>) {
    setDeleting(true)
    await deleteSchedule.mutateAsync(
      { contextId: context.id },
      {
        onSuccess: () => {
          toast.success({
            title: 'Context refresh scheduld deleted',
            description: "Successfully deleted the context's refresh schedule",
          })
        },
        onError: (error) => {
          toast.error({
            title: 'Something went wrong',
            description: error.message,
          })
        },
        onSettled: () => {
          setDeleting(false)
          void formik.setFieldValue('config.cron', '')
        },
      }
    )
    await utils.context.getSchedule.invalidate({ contextGuid: context.guid })
  }

  return (
    <Formik
      initialValues={formikConfig.initialValues}
      onSubmit={formikConfig.onSubmit}
      validationSchema={formikConfig.validationSchema}
      validateOnBlur={false}
      isInitialValid={true}
      initialTouched={false}
    >
      {(formik) => (
        <Form>
          <CronField />
          <div className="space-x-3">
            <Button
              type="submit"
              disabled={loading || formik.values.config.cron === ''}
              loading={loading}
            >
              {cronString ? 'Update' : 'Save'}
            </Button>
            {cronString && (
              <Button
                onClick={() => {
                  void clearSchedule(formik)
                }}
                variant="destructive"
                disabled={deleting}
                loading={deleting}
              >
                {deleting ? 'Deleting schedule...' : 'Delete schedule'}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export function ContextSchedule({ context }: { context: DetailedContext }) {
  const { scheduledTask } = useFetchContextSchedule(context)
  const cronString =
    scheduledTask && scheduledTask.schedule
      ? `${scheduledTask.schedule.minute} ${scheduledTask.schedule.hour} ${scheduledTask.schedule.day_of_week} ${scheduledTask.schedule.day_of_month} ${scheduledTask.schedule.month_of_year}`
      : ''
  return (
    <div className="p-6">
      <div className="max-w-lg">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <h3 className="text-lg font-medium text-grey-900">Schedule {context.name} Loading</h3>
        </div>
      </div>
      {cronString && <p>Current schedule: {cronstrue.toString(cronString)}</p>}
      {scheduledTask !== undefined && (
        <ScheduleForm context={context} scheduledTask={scheduledTask} />
      )}
    </div>
  )
}
