import { createClient } from '@liveblocks/client'
import { createRoomContext } from '@liveblocks/react'
import type { Member, User } from '@prisma/client'
import type { ContextStatusEvent } from '@/common/types/context'
import type { EvalRunEvent } from '@/common/types/eval'

const resolveUsers = async ({ userIds }: { userIds: string[] }) => {
  const res = await fetch(`/api/users?memberGuids=${userIds.join(',')}`)

  return (await res.json()) as UserMeta['info'][]
}

type MemberWithUser = Member & { user: User }

const resolveMentionSuggestions = async ({ text, roomId }: { text: string; roomId: string }) => {
  const res = await fetch(`/api/users/${roomId.split(':')[0] as string}`)

  let members = (await res.json()) as MemberWithUser[]

  if (text) {
    // Filter any way you'd like, e.g. checking if the name matches
    members = members.filter((member) => {
      const name = member.user?.name?.toLowerCase()
      const email = member.user?.email?.toLowerCase()
      return name?.includes(text) || email?.includes(text)
    })
  }

  return members.map((m) => m.guid)
}

export const client = createClient({
  authEndpoint: '/api/liveblocks/auth',
  resolveUsers,
  resolveMentionSuggestions,
})

// Presence represents the properties that exist on every user in the Room
// and that will automatically be kept in sync. Accessible through the
// `user.presence` property. Must be JSON-serializable.
type Presence = {
  // cursor: { x: number, y: number } | null,
  // ...
}

// Optionally, Storage represents the shared document that persists in the
// Room, even after all users leave. Fields under Storage typically are
// LiveList, LiveMap, LiveObject instances, for which updates are
// automatically persisted and synced to all connected clients.
type Storage = {
  // author: LiveObject<{ firstName: string, lastName: string }>,
  // ...
}

// Optionally, UserMeta represents static/readonly metadata on each user, as
// provided by your own custom auth back end (if used). Useful for data that
// will not change during a session, like a user's name or avatar.
export type UserMeta = {
  id: string // this is actually the Member.guid
  info: {
    userId: string
    name: string
    avatar: string
    email?: string
    isMember?: boolean
    workspaceGuid: string
  } // Accessible through `user.info`
}

// Optionally, the type of custom events broadcast and listened to in this
// room. Use a union for multiple events. Must be JSON-serializable.
type RoomEvent =
  | {
      // type: "NOTIFICATION",
      // ...
    }
  | EvalRunEvent
  | ContextStatusEvent

// Optionally, when using Comments, ThreadMetadata represents metadata on
// each thread. Can only contain booleans, strings, and numbers.
export type ThreadMetadata = {
  // resolved: boolean;
  // quote: string;
  // time: number;
}

export const {
  suspense: {
    RoomProvider,
    useRoom,
    useMyPresence,
    useUpdateMyPresence,
    useSelf,
    useOthers,
    useOthersMapped,
    useOthersConnectionIds,
    useOther,
    useBroadcastEvent,
    useEventListener,
    useErrorListener,
    useStorage,
    useObject,
    useMap,
    useList,
    useBatch,
    useHistory,
    useUndo,
    useRedo,
    useCanUndo,
    useCanRedo,
    useMutation,
    useStatus,
    useLostConnectionListener,
    useThreads,
    useUser,
    useCreateThread,
    useEditThreadMetadata,
    useCreateComment,
    useEditComment,
    useDeleteComment,
    useAddReaction,
    useRemoveReaction,
  },
} = createRoomContext<Presence, Storage, UserMeta, RoomEvent, ThreadMetadata>(client)
