import React, { useEffect } from 'react'
import { Loader } from '@/client/components'
import { usePersistStudioState } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { STUDIO_TABS } from '@/client/containers/views/Studio/constants'
import { useFetchDefaultModelProvider } from '@/common/hooks'
import { StudioContext } from '.'
import type {
  StudioContextAction,
  StudioContextProviderValue,
  StudioContextState,
  StudioProviderProps,
} from './types'

export default function StudioProvider({
  workspace,
  children,
  action,
  sessionId,
}: StudioProviderProps) {
  const { selectTab } = usePersistStudioState()

  const { provider: defaultProvider, isLoading: isDefaultProviderLoading } =
    useFetchDefaultModelProvider(workspace.id)

  const [state, dispatch] = React.useReducer(
    (state: StudioContextState, action: StudioContextAction): StudioContextState => {
      const { type, payload } = action

      switch (type) {
        case 'change-session': {
          return {
            ...state,
            sessionId: payload,
          }
        }
        case 'add-history-item': {
          return {
            ...state,
            completion: { promptResults: [...state.completion.promptResults, payload] },
          }
        }
        case 'change-version': {
          return {
            ...state,
            version: payload,
          }
        }

        default: {
          throw new Error(`Unhandled action type`)
        }
      }
    },
    {
      workspace,
      sessionId,
      action: undefined,
      defaultProvider: undefined,
      version: undefined,
      completion: {
        promptResults: [],
      },
    }
  )

  useEffect(() => {
    if (!action?.action_type) return

    const tabToSelect = action.action_type === 'chat' ? STUDIO_TABS.CHAT : STUDIO_TABS.COMPLETION
    void selectTab(tabToSelect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action?.action_type])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value: StudioContextProviderValue = React.useMemo(
    () => ({
      state: {
        ...state,
        sessionId: state.sessionId ?? sessionId, // Use the sessionId from the state if it exists
        action,
        version: state.version ?? action?.versions[0], // Use the version from the state if it exists
        defaultProvider,
      },
      dispatch,
    }),
    [action, sessionId, defaultProvider, state]
  )

  if (isDefaultProviderLoading) {
    return <Loader className="w-6 h-6 grey-400 grid h-screen place-items-center mx-auto" />
  }

  return <StudioContext.Provider value={value}>{children}</StudioContext.Provider>
}
