import type { MessageType } from '@/client/containers/views/Studio/components/Tabs/Chat/types'
import type { StreamingData } from '@/common/stream/stream/streamStore'
import { tryParseJSON } from '@/utils/json'
import type { StreamingMessage } from './types'

export const convertStreamEvent = (
  event: MessageEvent
): Pick<StreamingData, 'status' | 'value' | 'tool_calls'> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const parsedData = tryParseJSON<StreamingMessage>(event.data)

  switch (typeof parsedData) {
    case 'string':
      if (parsedData === 'BEGIN_STREAM') {
        return { status: 'waiting-on-first-data', value: '' }
      } else if (parsedData === 'END_STREAM' || parsedData === '[DONE]') {
        return { status: 'end-stream', value: '' }
      }
      return { status: 'not-started', value: '' }
    case 'object':
      if (parsedData.type === 'tool_calls_request') {
        const tool_calls = JSON.parse(parsedData.token || '[]') as MessageType['tool_calls']
        return { status: 'streaming', value: '', tool_calls }
      } else if (parsedData.type === 'tool_calls_response') {
        return { status: 'not-started', value: '' }
      } else {
        return {
          status: 'streaming',
          value: parsedData.token,
        }
      }
  }

  return { status: 'not-started', value: '' }
}
