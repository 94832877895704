import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import type { Context, Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { api } from '@/utils'

function DeleteContextForm({
  setOpen,
  context,
  workspace,
}: {
  workspace: Workspace
  context: Context
  setOpen: (val: boolean) => void
}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteIndex = api.context.delete.useMutation()
  const utils = api.useContext()

  async function onDelete() {
    setLoading(true)
    await deleteIndex.mutateAsync(
      { contextId: context.id },
      {
        onSuccess: () => {
          toast.success({
            title: `Context ${context.name} deleted`,
            description: 'Context deleted successfully',
          })
          setOpen(false)
        },
        onSettled: () => {
          setLoading(false)
        },
        onError: (err) => {
          toast.error({
            title: 'Error',
            description: err.message,
          })
        },
      }
    )
    await utils.context.getAll.invalidate()
    await router.push(`/${workspace.slug}/context`)
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">Delete Context</h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700">
                Are you sure you want to delete {context?.name}?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-300 bg-grey-50 px-4 py-5 sm:flex ">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => void onDelete()}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Deleting...' : 'Delete Context'}
        </Button>
      </div>
    </>
  )
}

export const DeleteContextModal = ({
  open,
  setOpen,
  context,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  context: Context | null
  workspace: Workspace
}) => {
  const handleOpenChange = (e: boolean) => setOpen(e)

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {context && (
            <DeleteContextForm setOpen={setOpen} context={context} workspace={workspace} />
          )}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
