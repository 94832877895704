import type { DetailedEval } from '@/common/types/eval'
import type { ActionVersionMetadata } from '@/server/service/types'

export const EvalActionName = ({ evalRecord }: { evalRecord: DetailedEval }) => {
  if (!evalRecord.action) {
    return evalRecord.name
  }
  const action = evalRecord.action
  const version = evalRecord.actionVersion

  let actionName = action.name

  let modelName = ''
  if (version) {
    const metadata = version.metadata as ActionVersionMetadata
    if (metadata.modelName) {
      modelName = metadata.modelName
    }
    actionName =
      version.name == actionName ? `${actionName} v${version.version_number}` : version.name
  }

  return (
    <div className="flex items-center">
      {actionName}
      {modelName && <span className="ml-1 text-xs">{modelName}</span>}
    </div>
  )
}
