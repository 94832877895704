// THIS IS NO LONGER USED

import type { ToolType } from '@prisma/client'
import type { FormikProps } from 'formik'
import { type FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { createSkillFormSchema } from './schema'
import type { CreateSkillFormInputs, FormProps, SkillMetadata } from './types'

export const useCreateSkillForm = ({ workspace, onIsOpenChange, setNewSkill }: FormProps) => {
  const createTool = api.tool.create.useMutation()
  const utils = api.useContext()
  const [isLoading, setLoading] = useState(false)
  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: CreateSkillFormInputs = {
      name: '',
      description: undefined,
      typeId: 0,
      metadata: [],
    }

    return initialValues
  }, [])

  const onSuccess = React.useCallback((skillName: string) => {
    toast.success({
      title: `${skillName} created`,
      description: 'Your skill was created successfully',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (values: CreateSkillFormInputs, { resetForm }: FormikHelpers<CreateSkillFormInputs>) => {
      setLoading(true)
      await createTool.mutateAsync(
        {
          workspaceId: workspace.id,
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'An error occurred',
              description: error.message,
            })
          },
          onSettled: () => {
            setLoading(true)
          },
          onSuccess: (res) => {
            if (setNewSkill) {
              setNewSkill(res)
            }

            if (user) {
              const id = user.id ? user.id : ''
              const email = user.email ? user.email : ''
              const uname = user.name ? user.name : ''
              identify_and_group(id, email, uname, workspace?.slug)
            }

            track(EVENT_NAMES.skill_created, {
              'User ID': user?.id,
              'User Email': user?.email,
              'User Name': user?.name,
              'Skill Name': values.name,
              'Skill Type': values.typeId,
              'Workspace ID': workspace.id,
              'Workspace Name': workspace.name,
            })
            resetForm()
            onSuccess(values.name)
          },
        }
      )
      await utils.tool.getAll.invalidate({ workspaceId: workspace.id })

      resetForm()

      onIsOpenChange(false)
    },
    [
      createTool,
      onIsOpenChange,
      onSuccess,
      setNewSkill,
      user,
      utils.tool.getAll,
      workspace.id,
      workspace.name,
      workspace.slug,
    ]
  )

  const formikConfig = {
    initialValues,
    validationSchema: toFormikValidationSchema(createSkillFormSchema),
    onSubmit,
  }

  async function setRequiredMetadata(
    optionName: string,
    formik: FormikProps<CreateSkillFormInputs>,
    toolTypes?: ToolType[]
  ) {
    const selectedToolType = toolTypes?.find((type) => type.name === optionName)
    if (selectedToolType?.required_metadata) {
      const requiredMetadata = selectedToolType.required_metadata as SkillMetadata
      await formik.setFieldValue(
        'metadata',
        requiredMetadata.filter((m) => m.required)
      )
    } else {
      await formik.setFieldValue('metadata', [])
    }
  }

  const saveDisabled = isLoading

  return {
    formikConfig,
    saveDisabled,
    setRequiredMetadata,
  }
}
