import { useFormik, type FormikConfig } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editDatasetFormSchema } from './schema'
import type { EditDatasetFormInputs, FormProps } from './types'

export const useEditDatasetForm = ({ dataset, workspace, app, onIsOpenChange }: FormProps) => {
  const editDataset = api.dataset.update.useMutation()
  const utils = api.useContext()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: EditDatasetFormInputs = {
      name: dataset.name,
    }

    return initialValues
  }, [dataset])

  const onSuccess = React.useCallback((datasetName: string) => {
    toast.success({
      title: `Dataset renamed to ${datasetName}`,
      description: 'Your dataset was updated successfully',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (values: EditDatasetFormInputs) => {
      await editDataset.mutateAsync(
        {
          datasetId: dataset.id,
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error updating dataset',
              description: error.message,
            })
          },
        }
      )
      await utils.dataset.getAll.invalidate({ appId: app.id })
      await utils.dataset.get.invalidate({ id: dataset.id })
      await utils.dataset.getByGuid.invalidate({ guid: dataset.guid })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.dataset_edited, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'Dataset ID': dataset.id,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      onSuccess(values.name)
      onIsOpenChange(false)
    },
    [
      editDataset,
      dataset.id,
      dataset.guid,
      utils.dataset.getAll,
      utils.dataset.get,
      utils.dataset.getByGuid,
      app.id,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onSuccess,
      onIsOpenChange,
    ]
  )

  const formikConfig: FormikConfig<EditDatasetFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(editDatasetFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
