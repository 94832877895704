import type { App, Dataset } from '@prisma/client'
import { useState } from 'react'
import { FinetuneIcon } from '@/client/assets/icons/icons'
import { Button, Dialog } from '@/client/components'
import { CreateModelProviderModal } from '../../modals'
import { useWorkspace } from '../../ui/context'
import { CreateFinetuneForm } from './Form'
import type { UseCreateFinetuneFormProps } from './Form/types'

export const CreateFinetune = ({ app, dataset }: { app: App; dataset?: Dataset }) => {
  const [isOpen, onIsOpenChange] = useState(false)
  const workspace = useWorkspace()
  const [openCreateModelProviderModal, setOpenCreateModelProviderModal] = useState(false)

  return (
    <>
      <Button
        onClick={() => {
          onIsOpenChange(true)
        }}
        startIcon={FinetuneIcon}
      >
        Fine-tune Model
      </Button>
      <CreateModelProviderModal
        open={openCreateModelProviderModal}
        setOpen={setOpenCreateModelProviderModal}
        workspace={workspace}
      />
      <CreateFinetuneModal
        dataset={dataset}
        workspace={workspace}
        app={app}
        isOpen={isOpen}
        onIsOpenChange={onIsOpenChange}
        toggleCreateWorkspaceModelProviderModal={setOpenCreateModelProviderModal}
      />
    </>
  )
}

export const CreateFinetuneModal = ({
  dataset,
  workspace,
  app,
  isOpen,
  onIsOpenChange,
  toggleCreateWorkspaceModelProviderModal,
}: UseCreateFinetuneFormProps) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <div className="px-6 pt-6 flex items-center justify-between">
            <div className="flex flex-col">
              <Dialog.Title asChild>
                <h3 className="title3 grow">Fine-tune Model</h3>
              </Dialog.Title>
            </div>
          </div>
          <CreateFinetuneForm
            dataset={dataset}
            workspace={workspace}
            app={app}
            onIsOpenChange={onIsOpenChange}
            isOpen={isOpen}
            toggleCreateWorkspaceModelProviderModal={toggleCreateWorkspaceModelProviderModal}
          />
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
