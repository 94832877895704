import type { Action } from '@prisma/client'
import { useState } from 'react'
import { Button } from '@/client/components'
import { Dialog } from '@/client/components/Dialog'
import { agentLabels } from '@/common/components/actions/types'
import MarkdownRenderer from '@/common/components/tables/markdown'
import { env } from '@/env.mjs'

function ActionDeployForm({
  setOpenAPIModal,
  action,
}: {
  setOpenAPIModal: (val: boolean) => void
  action: Action
}) {
  interface actionInputs {
    [key: string]: string
  }
  const actionInputs: actionInputs = {}

  const promptVariables = action.prompt.match(/{{(.*?)}}/g)?.map((match) => match.slice(2, -2))
  if (promptVariables && promptVariables?.length > 0) {
    promptVariables.forEach((variable) => {
      actionInputs[variable] = `${variable.toUpperCase()} VALUE`
    })
  } else {
    actionInputs['input'] = 'YOUR PROMPT GOES HERE'
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEffect] = useState(false)

  const [contentType, setContentType] = useState('url')

  const curlActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{${Object.keys(actionInputs)
        .map((k) => `  "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}`

  const curlCommand = `curl --location --request POST '${env.NEXT_PUBLIC_ENGINE_URL}/v1/actions/' \\
--header 'Authorization: Bearer YOUR_TOKEN' \\
--header 'Content-Type: application/json' \\
--data-raw '{
  "action": "${action.guid}", 
  "input": ${curlActionInputs}
  }'`

  const pythonActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{\n${Object.keys(actionInputs)
        .map((k) => `        "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}\n    }`

  const pythonSDKLine = `from klu import Klu
 
client = Klu('apikey') 
result = await client.actions.prompt(
    '${action.guid}', 
    ${pythonActionInputs}
)
`

  const tsActionInputs = actionInputs['input']
    ? `"INPUT VALUE"`
    : `{\n${Object.keys(actionInputs)
        .map((k) => `        "${k}": "${k.toUpperCase()} VALUE"`)
        .join(',\n')}\n    }`

  const typescriptString = `import Klu from '@kluai/core';

  const klu = new Klu('apikey');

  async function prompt() {
    const result = await klu.actions.prompt(
      '${action.guid}', 
      ${tsActionInputs}
    )
    console.log(result.msg);
  };

  prompt();
  `
  const origin = window.location.origin

  const shareableUrl = `${origin}/public/actions/${action.guid}`

  const aGUID = `${action.guid}`

  const getGUID = () => {
    return `\`\`\`md\n${aGUID}`
  }

  const getContent = () => {
    if (contentType === 'bash') {
      return curlCommand
    } else if (contentType === 'Python') {
      return pythonSDKLine
    } else if (contentType === 'Typescript') {
      return typescriptString
    } else if (contentType === 'url') {
      return shareableUrl
    }
  }

  const getMarkdownContent = () => {
    const content = getContent()
    if (!content) {
      return ''
    }

    return `
\`\`\`${contentType.toLocaleLowerCase()}
${content}
\`\`\`
`
  }

  return (
    <>
      <div className="rounded-lg bg-white p-4 shadow-sm">
        <div className="mb-4 flex items-center pb-4">
          <h3 className="w-full items-center pt-1 text-lg font-medium">
            Deploy <span className="text-grey-900">{action.name}</span>
          </h3>
          <div className="ml-auto w-full">
            <select
              className="mt-2 w-full rounded-md border border-grey-300 bg-white p-2 px-4 py-2 text-sm hover:border-grey-400 focus:border-grey-500 focus:ring-grey-500"
              value={contentType}
              onChange={(e) => setContentType(e.target.value)}
            >
              <option value="Python">Python SDK</option>
              <option value="Typescript">Typescript SDK</option>
              <option value="bash">cURL Request</option>
              {action.action_type !== agentLabels.workflow && (
                <option value="url">Shareable URL</option>
              )}
            </select>
          </div>
        </div>

        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <div className="">
            <div className="mt-3">
              {contentType === 'url' && (
                <div className="mt-3">
                  <p className="pl-3 font-mono text-xs">Public URL for {action.name}</p>
                </div>
              )}
              {contentType === 'Python' && (
                <div className="mt-3">
                  <p className="pl-3 font-mono text-xs">Python SDK for {action.name}</p>
                </div>
              )}
              {contentType === 'Typescript' && (
                <div className="mt-3">
                  <p className="pl-3 font-mono text-xs">Typescript SDK for {action.name}</p>
                </div>
              )}
              {contentType === 'bash' && (
                <div className="mt-3">
                  <p className="pl-3 font-mono text-xs">cURL Request for {action.name}</p>
                </div>
              )}
              <MarkdownRenderer markdownText={getMarkdownContent()} />

              {/* <pre
                id="key"
                className="mt-3 block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-xs p-2 bg-grey-100 overflow-auto"
              >
                <code className={`language-${contentType.toLowerCase()}`}>{getContent()}</code>
              </pre> */}
            </div>
            <div className="mt-4 pt-4">
              <div className="pl-3 font-mono text-xs">{action.name} Action GUID</div>
              <span className="w-full items-center">
                <MarkdownRenderer markdownText={getGUID()} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div className="items-center justify-between rounded-b-lg border-t border-grey-200 bg-grey-50 px-4 py-5 sm:flex sm:px-6">
        <Button
          onClick={() => setOpenAPIModal(false)}
          onAnimationEnd={() => setEffect(false)}
          className="flex w-full justify-center"
        >
          Close
        </Button>
      </div>
    </>
  )
}

export const ActionAPIDetailsModal = ({
  open,
  setOpen,
  action,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  action: Action | null
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            {action && <ActionDeployForm action={action} setOpenAPIModal={setOpen} />}
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
