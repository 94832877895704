// rest of them were not needed at this time - this should reflect https://github.com/klu-ai/klu-engine/blob/be8738e0dc560553352e5239572a73d63fa40036/app/constants.py#L6
import { llmProviders } from '@/utils'

// only OpenAI and Azure OpenAI for now
export const MODEL_PROVIDER_IDS_WITH_SYSTEM_MESSAGE = llmProviders
  .filter(
    (llm) =>
      llm.name === 'OpenAI' ||
      llm.name === 'Azure OpenAI' ||
      llm.name === 'Klu' ||
      llm.name == 'Perplexity AI' ||
      llm.name == 'Mistral' ||
      llm.name == 'Anthropic' ||
      llm.name == 'Groq' ||
      llm.name == 'Cohere' ||
      llm.name == 'Google AI' ||
      llm.name == 'Amazon Bedrock' ||
      llm.name == 'API Endpoint'
  )
  .map((llm) => llm.id)

export const MODEL_PROVIDER_IDS_WITH_TOOL_MESSAGE = llmProviders
  .filter((llm) => llm.name === 'OpenAI' || llm.name === 'Azure OpenAI' || llm.name === 'Klu')
  .map((llm) => llm.id)

export const MODEL_WITH_SYSTEM_MESSAGE = [
  'gpt-35',
  'gpt-3.5',
  'gpt-4',
  'pplx-7b-online',
  'pplx-70b-online',
  'pplx-7b-chat-alpha',
  'pplx-70b-chat-alpha',
  'mistral-7b-instruct',
  'mixtral-8x7b-instruct',
  'codellama-34b-instruct',
  'llama-2-13b-chat',
  'llama-2-70b-chat',
  'mistral',
  'open-mistral',
  'mixtral',
  'llama-70b',
  'llama-405b',
  'claude-3',
  'anthropic.claude-3',
  'llama3',
  'gemma',
  'gemini-1.5',
  'llama',
  'command-r',
]

export const MODEL_WITH_VISION = [
  'gpt-4o',
  'gpt-4o-2024-05-13',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-4o-mini-2024-07-18',
  'gpt-4-turbo',
  'gpt-4-vision-preview',
  'gpt-4-1106-vision-preview',
  'gpt-4-turbo-2024-04-09',
  'gemini-pro-vision',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-5-sonnet-20240620',
  'claude-3-haiku-20240307',
  'gemini-1.5-pro-latest',
  'gemini-1.5-flash-latest',
  'gemini-1.0-pro-vision',
  'gemini-1.0-pro-vision-latest',
  'gemini-1.5-pro-2m-latest',
  'gemini-1.5-pro-001',
  'gemini-1.5-pro',
  'gemini-1.5-flash-001',
  'gemini-1.5-flash',
]

export const KLU_MODEL_PROVIDER_ID = 14
export const API_ENDPOINT_PROVIDER_ID = 24

export const MODEL_COSTS: Record<string, { input: number; output: number }> = {
  'gpt-4-1106-preview': {
    input: 0.01,
    output: 0.03,
  },
  'gpt-4-0125-preview': {
    input: 0.01,
    output: 0.03,
  },
  'gpt-4-turbo-preview': {
    input: 0.01,
    output: 0.03,
  },
  'gpt-4-turbo': {
    input: 0.01,
    output: 0.03,
  },
  'gpt-4-1106-vision-preview': {
    input: 0.02, // this is hack for avg img price
    output: 0.03,
  },
  'gpt-4-vision-preview': {
    input: 0.02, // this is hack for avg img price
    output: 0.03,
  },
  'gpt-4-turbo-2024-04-09': { input: 0.01, output: 0.03 },
  'gpt-4-0314': {
    input: 0.03,
    output: 0.06,
  },
  'gpt-4-0613': {
    input: 0.03,
    output: 0.06,
  },
  'gpt-4': {
    input: 0.03,
    output: 0.06,
  },
  'gpt-4-32k': {
    input: 0.06,
    output: 0.12,
  },
  'gpt-3.5': {
    input: 0.0015,
    output: 0.002,
  },
  'gpt-3.5-turbo': {
    input: 0.0015,
    output: 0.002,
  },
  'klu-us-east': {
    input: 0,
    output: 0,
  },
  'gpt-3.5-turbo-1106': {
    input: 0.001,
    output: 0.002,
  },
  'gpt-3.5-turbo-instruct': {
    input: 0.0015,
    output: 0.002,
  },
  'gpt-35-turbo': {
    input: 0.0015,
    output: 0.002,
  },
  'gpt-3.5-turbo-0301': {
    input: 0.0015,
    output: 0.002,
  },
  'gpt-3.5-turbo-16k': {
    input: 0.003,
    output: 0.004,
  },
  'gpt-3.5-turbo-0125': {
    input: 0.0005,
    output: 0.0015,
  },
  'gpt-35-turbo-16k': {
    input: 0.003,
    output: 0.004,
  },
  'gpt-3.5-turbo-16k-0613': {
    input: 0.003,
    output: 0.004,
  },
  'gpt-3.5-16k': {
    input: 0.003,
    output: 0.004,
  },
  'claude-instant-v1': {
    input: 0.00163,
    output: 0.00551,
  },
  'claude-instant-v1-100k': {
    input: 0.00163,
    output: 0.00551,
  },
  'claude-2': {
    input: 0.01102,
    output: 0.03268,
  },
  'claude-2.1': {
    input: 0.008,
    output: 0.024,
  },
  'claude-v1': {
    input: 0.00163,
    output: 0.00551,
  },
  'claude-v1-100k': {
    input: 0.00163,
    output: 0.00551,
  },
  'claude-3-opus-20240229': {
    input: 0.015,
    output: 0.075,
  },
  'claude-3-sonnet-20240229': {
    input: 0.003,
    output: 0.015,
  },
  'claude-3-haiku-20240307': {
    input: 0.00025,
    output: 0.00125,
  },
  'claude-3-5-sonnet-20240620': {
    input: 0.003,
    output: 0.015,
  },
  'open-mistral-7b': {
    input: 0.00025,
    output: 0.00025,
  },
  'open-mixtral-8x7b': {
    input: 0.0007,
    output: 0.0007,
  },
  'mistral-small-latest': {
    input: 0.002,
    output: 0.006,
  },
  'mistral-small-2312': {
    input: 0.002,
    output: 0.006,
  },
  'mistral-small-2402': {
    input: 0.002,
    output: 0.006,
  },
  'mistral-medium-latest': {
    input: 0.0027,
    output: 0.0081,
  },
  'mistral-medium-2312': {
    input: 0.0027,
    output: 0.0081,
  },
  'mistral-large-latest': {
    input: 0.003,
    output: 0.009,
  },
  'mistral-large-2402': {
    input: 0.008,
    output: 0.024,
  },
  'mistral-large-2407': {
    input: 0.003,
    output: 0.009,
  },
  'open-mistral-nemo-2407': {
    input: 0.0003,
    output: 0.0003,
  },
  'open-mistral-nemo': {
    input: 0.0003,
    output: 0.0003,
  },
  'mistral-tiny': {
    input: 0.00025,
    output: 0.00025,
  },
  'llama-70b-4096': {
    input: 0.0007,
    output: 0.0008,
  },
  'gemma-7b-it': {
    input: 0.0001,
    output: 0.0001,
  },
  'mixtral-8x7b-32768': {
    input: 0.00027,
    output: 0.00027,
  },
  'llama3-70b-8k': {
    input: 0.00059,
    output: 0.00079,
  },
  'mixtral-8x7b-32k-instruct': {
    input: 0.00024,
    output: 0.00024,
  },
  'llama3-8b-8k': {
    input: 0.00005,
    output: 0.00008,
  },
  'llama3-groq-8b-8192-tool-use': {
    input: 0.00019,
    output: 0.00019,
  },
  'llama3-groq-70b-8192-tool-use': {
    input: 0.00089,
    output: 0.00089,
  },
  'gemma-7b-instruct': {
    input: 0.00007,
    output: 0.00007,
  },
  'gemma2-9b-it': {
    input: 0.0002,
    output: 0.0002,
  },
  command: {
    input: 0.003,
    output: 0.015,
  },
  'command-nightly': {
    input: 0.003,
    output: 0.015,
  },
  'command-light': {
    input: 0.003,
    output: 0.015,
  },
  'command-light-nightly': {
    input: 0.003,
    output: 0.015,
  },
  'command-r': {
    input: 0.00005,
    output: 0.0015,
  },
  'command-r-plus': {
    input: 0.003,
    output: 0.015,
  },
  'gpt-4o': {
    input: 0.005,
    output: 0.015,
  },
  'gpt-4o-2024-05-13': {
    input: 0.005,
    output: 0.015,
  },
  'gpt-4o-2024-08-06': {
    input: 0.0025,
    output: 0.01,
  },
  'gpt-4o-mini': {
    input: 0.000015,
    output: 0.00006,
  },
  'gpt-4o-mini-2024-07-18': {
    input: 0.000015,
    output: 0.00006,
  },
  'gemini-1.5-pro-latest': {
    input: 0.0035,
    output: 0.0105,
  },
  'gemini-1.0-pro-latest': {
    input: 0.0005,
    output: 0.0015,
  },
  'gemini-1.0-pro': {
    input: 0.0005,
    output: 0.0015,
  },
  'gemini-1.0-pro-001': {
    input: 0.0005,
    output: 0.0015,
  },
  'gemini-1.0-pro-vision-latest': {
    input: 0.0005,
    output: 0.0015,
  },
  'gemini-1.5-pro-2m-latest': {
    input: 0.0035,
    output: 0.0105,
  },
  'gemini-1.5-pro-001': {
    input: 0.0035,
    output: 0.0105,
  },
  'gemini-1.5-pro': {
    input: 0.0035,
    output: 0.0105,
  },
  'gemini-1.5-flash-latest': {
    input: 0.000075,
    output: 0.0003,
  },
  'gemini-1.5-flash-001': {
    input: 0.000075,
    output: 0.0003,
  },
  'gemini-1.5-flash': {
    input: 0.000075,
    output: 0.0003,
  },
  'llama-3.1-sonar-small-128k-online': {
    input: 0.0002,
    output: 0.0002,
  },
  'llama-3.1-sonar-small-128k-chat': {
    input: 0.0002,
    output: 0.0002,
  },
  'llama-3.1-sonar-large-128k-online': {
    input: 0.001,
    output: 0.001,
  },
  'llama-3.1-sonar-large-128k-chat': {
    input: 0.001,
    output: 0.001,
  },
}

export const FT_MODEL_COSTS: Record<string, { input: number; output: number }> = {
  'gpt-3.5-turbo-1106': {
    input: 0.003,
    output: 0.006,
  },
  'davinci-002': {
    input: 0.012,
    output: 0.012,
  },
  'babbage-002': {
    input: 0.0016,
    output: 0.0016,
  },
  'gpt-3.5-turbo-0613': {
    input: 0.003,
    output: 0.006,
  },
  'gpt-4-1106-preview': {
    input: 0.003,
    output: 0.006,
  },
  'gpt-4-0613': {
    input: 0.045,
    output: 0.09,
  },
  'gpt-3.5-turbo-0125': {
    input: 0.003,
    output: 0.006,
  },
  'gpt-4o': {
    input: 0.0075,
    output: 0.0225,
  },
  'gpt-4o-2024-05-13': {
    input: 0.0075,
    output: 0.0225,
  },
  'gpt-4o-2024-08-06': {
    input: 0.00375,
    output: 0.015,
  },
  'gpt-4o-mini': {
    input: 0.000015,
    output: 0.00006,
  },
  'gpt-4o-mini-2024-07-18': {
    input: 0.000015,
    output: 0.00006,
  },
}

export const subscriptionNotRequiredPaths = [
  '/settings/plans',
  '/settings/members',
  '/settings',
  '/onboarding',
  '/settings/billing',
]
