import type { Action, Workspace } from '@prisma/client'
import { useFormik } from 'formik'
import { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Badge, Button, Dialog, Select, toast } from '@/client/components'
import { FieldError } from '@/common/components/forms'
import { useFetchApps, useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function MoveActionForm({
  action,
  workspace,
  setOpen,
}: {
  action: Action
  workspace: Workspace
  setOpen: (val: boolean) => void
}) {
  const { apps, isLoading: isLoadingApps } = useFetchApps(workspace.id)
  const moveAction = api.action.move.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  const [isLoading, setLoading] = useState(false)

  function onSuccess(newApp: string) {
    toast.success({
      title: `${action.name} moved`,
      description: `Action moved successfully to ${newApp}`,
    })
    setOpen(false)
  }

  const schema = z.object({
    name: z.string().max(30, { message: 'Name must be less than 30 characters' }),
    appId: z.number().min(1, { message: 'Please select an app' }),
  })

  type FormSchema = z.infer<typeof schema>
  const formik = useFormik<FormSchema>({
    initialValues: {
      name: action.name,
      appId: 0,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      const currentAppId = action.appId
      await moveAction.mutateAsync(
        { ...values, agentId: action.id },
        {
          onSettled: () => {
            setLoading(false)
          },
          onSuccess: () => {
            if (user) {
              const id = user.id ? user.id : ''
              const email = user.email ? user.email : ''
              const uname = user.name ? user.name : ''
              identify_and_group(id, email, uname, workspace?.slug)
            }
            track(EVENT_NAMES.action_moved, {
              'Workspace ID': workspace.id,
              'Workspace Name': workspace.name,
              'Original Action Name': action.name,
              'Original Action ID': values.appId,
              'Action Name': values.name,
              'Action Type': action.action_type,
              'User ID': user?.id,
              'User Email': user?.email,
            })
            resetForm()
            onSuccess(values.name)
          },
          onError: (err) => {
            toast.error({
              title: 'Error moving action',
              description: err.message,
            })
          },
        }
      )
      await utils.action.getAllForApp.invalidate({ appId: currentAppId })
      await utils.action.getAllForApp.invalidate({ appId: values.appId })
      await utils.app.get.invalidate({ appId: currentAppId })
      await utils.app.get.invalidate({ appId: values.appId })
    },
  })

  const filteredApps = apps?.filter((app) => app.id !== action.appId)

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-6">
        <div>
          <div>
            <h3 className="text-lg font-medium leading-6">Move Action</h3>
            <p className="text-sm pt-3 text-grey-600">
              Moving <Badge>{action.name}</Badge> to new App will move related data and logs, but
              not datasets, experiments, or fine-tune models.
            </p>
            <div className="mt-6">
              <p className="text-sm">Action name</p>
              <div className="mt-2">
                <div className="flex flex-row rounded-md">
                  <input
                    name="name"
                    placeholder="Detect Bias in Feature Request"
                    type="text"
                    autoComplete="off"
                    id="key"
                    maxLength={50}
                    className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                </div>
                <FieldError fieldName="name" formik={formik} />
              </div>
            </div>
            <div className="mt-6">
              <p className="text-sm">Move Action to...</p>
              <div className="mt-2">
                {isLoadingApps ? (
                  <div className="flex animate-pulse space-x-4">
                    <div className="flex-1 space-y-2">
                      <div className="h-2 rounded bg-grey-700"></div>
                      <div className="h-2 rounded bg-grey-700"></div>
                    </div>
                  </div>
                ) : (
                  <Select.Field
                    id="appId"
                    hasError={!!formik.errors.appId}
                    message={formik.errors.appId}
                    placeholder={'Select an app'}
                    onChange={(newValue) => {
                      void formik.setFieldValue('appId', parseInt(newValue, 10))
                    }}
                    value={formik.values.appId.toString()}
                    options={
                      filteredApps
                        ? filteredApps.map((app) => {
                            return {
                              value: app.id.toString(),
                              label: app.name,
                            }
                          })
                        : []
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between rounded-b-lg border-t border-grey-200 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
        <div>
          <Button
            variant="outline"
            className="inline-flex w-full justify-center rounded-md border border-grey-300 bg-white px-4 py-2 text-base font-medium text-black shadow-sm ease-in-out hover:border-grey-400 dark:bg-grey-700 sm:w-auto sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </div>
        <Button type="submit" disabled={isLoading} loading={isLoading}>
          {isLoading ? 'Moving...' : 'Move'}
        </Button>
      </div>
    </form>
  )
}

export const MoveActionModal = ({
  open,
  setOpen,
  workspace,
  action,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
  action: Action | null
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {action && <MoveActionForm action={action} workspace={workspace} setOpen={setOpen} />}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
