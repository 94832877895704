export function GoogleIcon({ className }: { className: string }) {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" />
      <path
        d="M24 17.6213C25.9529 17.6213 27.2702 18.4649 28.0214 19.1698L30.9565 16.304C29.1538 14.6284 26.808 13.6 24 13.6C19.9325 13.6 16.4196 15.9342 14.7094 19.3315L18.072 21.9431C18.9156 19.4355 21.2498 17.6213 24 17.6213V17.6213Z"
        fill="#EA4335"
      />
      <path
        d="M33.984 24.2311C33.984 23.376 33.9147 22.752 33.7644 22.1049H24V25.9644H29.7316C29.616 26.9235 28.992 28.368 27.6053 29.3386L30.8871 31.8809C32.8516 30.0666 33.984 27.3973 33.984 24.2311V24.2311Z"
        fill="#4285F4"
      />
      <path
        d="M18.0836 26.0569C17.864 25.4098 17.7369 24.7164 17.7369 24C17.7369 23.2835 17.864 22.5902 18.072 21.9431L14.7093 19.3315C14.0045 20.7413 13.6 22.3244 13.6 24C13.6 25.6755 14.0045 27.2587 14.7093 28.6684L18.0836 26.0569V26.0569Z"
        fill="#FBBC05"
      />
      <path
        d="M24 34.4C26.808 34.4 29.1653 33.4756 30.8871 31.8809L27.6053 29.3387C26.7271 29.9511 25.5484 30.3787 24 30.3787C21.2498 30.3787 18.9156 28.5644 18.0836 26.0569L14.7209 28.6684C16.4311 32.0658 19.9324 34.4 24 34.4V34.4Z"
        fill="#34A853"
      />
    </svg>
  )
}
