import type { ColDef } from '@ag-grid-community/core'
import { InfoTooltip } from '@/client/components/InfoTooltip/InfoTooltip'
import type { EvalRunMetadata } from '@/common/types/eval'
import { MetricBubble } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]/runs/[runId]'
import type { EvalRunGridRow } from '../EvalRunsGrid'

export const ResultCell = ({
  value,
  colDef,
  data,
}: {
  value: number
  colDef: ColDef
  data: EvalRunGridRow
}) => {
  const { evalRun } = data
  const { status } = (evalRun.metadata as EvalRunMetadata) || {}

  if (status != 'completed' && status != 'cancelled') {
    return (
      <div className="flex flex-col gap-2 mb-3 pt-3 p-1">
        <div className="w-full h-3 bg-gray-200 rounded animate-pulse"></div>
      </div>
    )
  }

  let metricLabel = (colDef.field || '').includes('score')
    ? 'score'
    : (colDef.field || '').includes('cost')
      ? '$'
      : 'ms'
  if (colDef.field?.includes('Latency')) {
    metricLabel = '% passed'
  }

  // Determine the correct format based on metricLabel
  let formattedValue
  if (metricLabel === '% passed') {
    // Assuming '% passed' is set for 'Latency'
    formattedValue = Number(value).toFixed(0)
  } else if (metricLabel === 'ms') {
    // Assuming 'ms' might relate to 'Time' or similar
    formattedValue = Number(value).toFixed(0) // Adjust this based on your specific needs
  } else {
    formattedValue = Number(value).toFixed(2)
  }

  if (value === null || typeof value === 'undefined') {
    return (
      <div className="text-grey-400 flex item-center gap-1">
        <InfoTooltip>No data for the &quot;{colDef.headerName}&quot; evaluation metric</InfoTooltip>
        No data
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-2 mb-3 pt-3 p-1">
      <MetricBubble label={metricLabel} value={formattedValue} />
    </div>
  )
}
