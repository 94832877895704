import { Dialog, ScrollArea } from '@/client/components'
import { useAppsWithActions } from '@/client/containers/views/Studio/components/Topbar/components/OpenAction/components/SelectActionDialog/components/Apps/logic'
import { Apps } from './components'
import type { SelectActionDialogProps } from './types'

export const SelectActionDialog = ({
  isOpen,
  trigger,
  onIsOpenChange,
  onActionOpened,
  filter,
}: SelectActionDialogProps) => {
  const { isLoadingApps, apps } = useAppsWithActions(filter?.actionType)

  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}

      <Dialog.Content className={'p-6 ring-1 ring-grey-300/50 max-w-2xl'}>
        <Dialog.Header>
          <Dialog.Title asChild>
            <h3 className="title3">Open Action</h3>
          </Dialog.Title>
        </Dialog.Header>

        <ScrollArea className="max-h-[calc(100vh-30rem)]">
          <Apps
            onIsOpenChange={onIsOpenChange}
            apps={apps}
            isLoadingApps={isLoadingApps}
            onActionOpened={onActionOpened}
          />
        </ScrollArea>
      </Dialog.Content>
    </Dialog.Root>
  )
}
