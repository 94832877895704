import React from 'react'

export const useNewActionDialog = () => {
  const [isOpenNewActionDialog, setIsOpenNewActionDialog] = React.useState(false)

  const onIsOpenNewActionDialogChange = (open: boolean) => {
    setIsOpenNewActionDialog(open)
  }

  return {
    isOpenNewActionDialog,
    onIsOpenNewActionDialogChange,
  }
}
