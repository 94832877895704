import React from 'react'

export const usePublishDialog = () => {
  const [isOpenPublishDialog, setIsOpenPublishDialog] = React.useState(false)

  const onIsOpenPublishDialogChange = (open: boolean) => {
    setIsOpenPublishDialog(open)
  }

  return {
    isOpenPublishDialog,
    onIsOpenPublishDialogChange,
  }
}
