import MarkdownRenderer from './markdown'

export function InputRenderer({ input }: { input: string }) {
  let completion: string = input
  let originalInputJson: Record<string, string> = {}
  try {
    originalInputJson = JSON.parse(input) as Record<string, string>
    completion = '```json\n' + `${JSON.stringify(originalInputJson, null, 2)}`
  } catch (error) {
    console.error(error)
    originalInputJson = { input: input }
  }

  if (!originalInputJson) {
    return null
  }

  const firstKey = Object.keys(originalInputJson)[0]

  const isNested = Object.values(originalInputJson).some(
    (value) => typeof value === 'object' && value !== null
  )

  if (!firstKey) {
    return null
  }

  if (firstKey === 'input' && !isNested) {
    completion = input
  } else if (isNested) {
    completion = '```json\n' + `${JSON.stringify(originalInputJson, null, 2)}`
  } else {
    completion = Object.entries(originalInputJson)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\\\n')
  }

  return (
    <div className="mb-3">
      <MarkdownRenderer markdownText={completion} />
    </div>
  )
}
