import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { ParsedUrlQueryInput } from 'querystring';
import { ArrowPathIcon, ArrowPathRoundedSquareIcon, CalendarDaysIcon } from '@heroicons/react/24/solid';
import type { App, Dataset, Finetune, User, Workspace } from '@prisma/client';
import { format } from 'date-fns';
import { type NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FineTuningJob } from 'openai/resources/fine-tuning/jobs';
import { DocsIcon } from '@/client/assets/icons/icons';
import { Button, Tooltip } from '@/client/components';
import { Loader, OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { CreateFinetune } from '@/common/components/finetunes/CreateFinetune';
import { App404 } from '@/common/components/notFound/404';
import { useCurrentWorkspace, useFetchAppBySlug, useFetchFinetunes } from '@/common/hooks';
import { withApp } from '@/common/lib';
import type { DatasetMetadata } from '@/server/service/types';
import { classNames } from '@/utils';
type FinetuneStatus = 'succeeded' | 'failed' | 'validating_files' | 'queued' | 'running' | 'cancelled' | 'pending';
function FinetuneStatusBadge({
  finetune
}: {
  finetune: Finetune;
}) {
  const statusStyles = {
    running: 'bg-green-100 text-green-800 ring-1 ring-green-800/20',
    pending: 'bg-grey-100 text-grey-800 ring-1 ring-grey-800/20',
    failed: 'bg-red-100 text-red-800 ring-1 ring-red-800/20',
    completed: 'bg-blue-100 text-blue-800 ring-1 ring-blue-800/20',
    validating_files: 'bg-yellow-100 text-yellow-800 ring-1 ring-yellow-800/20',
    queued: 'bg-blue-100 text-blue-800 ring-1 ring-blue-800/20',
    cancelled: 'bg-red-100 text-red-800 ring-1 ring-red-800/20',
    succeeded: 'bg-green-100 text-green-800 ring-1 ring-green-800/20'
  };
  function getColor(label: FinetuneStatus) {
    return statusStyles[(label as keyof typeof statusStyles)] || 'bg-blue-100 text-blue-800 ring-1 ring-blue-800/20';
  }
  const status = (finetune.status as FinetuneStatus) || 'pending';
  return <div className={classNames('inline-flex items-center rounded-full p-1 text-xs font-medium ', getColor(status))}></div>;
}
type DetailedFinetune = Finetune & {
  dataset: Dataset;
  createdBy: User;
};
export function FinetuneRow({
  workspace,
  app,
  finetune
}: {
  workspace: Workspace;
  app: App;
  finetune: DetailedFinetune;
}) {
  const datasetMetadata = (finetune.dataset.metadata as DatasetMetadata | undefined);
  const finetuneMetadata = (finetune.metadata as {
    result?: FineTuningJob;
  } | undefined);
  const count = datasetMetadata?.dataCountForFinetune;
  const totalCount = datasetMetadata?.dataCount;
  const router = useRouter();
  return <tr key={finetune.id} className="whitespace-nowrap text-sm font-inter hover:bg-grey-50 cursor-pointer" onClick={() => void router.push(`/${workspace.slug}/apps/${app.slug}/optimization/${finetune.slug}`)}>
      <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium space-x-2">
        {!['completed', 'failure'].includes(finetune.status) && <FinetuneStatusBadge finetune={finetune} />}
        <Link href={`/${workspace.slug}/apps/${app.slug}/optimization/${finetune.slug}`} className="hover:text-blue-800">
          {finetune.name || '-'}
        </Link>
      </td>
      <td className="hidden py-4 pl-6 pr-3 sm:table-cell font-mono text-xs">
        {finetune.base_model}
      </td>
      <td className="hidden py-4 pl-6 pr-3 sm:table-cell font-mono text-xs">
        {finetuneMetadata?.result?.fine_tuned_model || '-'}
      </td>

      <td className="hidden py-4 pl-6 pr-3 sm:table-cell">{finetune.dataset.name}</td>
      <td className="hidden py-4 pl-6 pr-3 sm:table-cell">
        {count ? <>{count > 1000 ? `${Math.round(count / 1000)}k` : count}</> : <>{totalCount}</>}
      </td>
      <td className="hidden py-4 pl-6 pr-3 sm:table-cell">
        {format(new Date(finetune.createdAt), 'MMMM dd yyyy HH:mm')}
      </td>
    </tr>;
}
function FinetuneTable({
  workspace,
  app
}: {
  workspace: Workspace;
  app: App;
}) {
  const {
    finetunes,
    isLoading
  } = useFetchFinetunes(app.id);
  if (isLoading) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400" />
      </div>;
  }
  if (!finetunes || finetunes.length === 0) {
    return <div className="rounded-lg border border-grey-200 bg-white py-8 text-center shadow-sm">
        <ArrowPathIcon className="mx-auto h-12 w-12 text-grey-400" />
        <h3 className="mt-2 text-sm font-semibold">No fine-tune models created yet</h3>
        <p className="mt-1 text-sm text-grey-500">
          Create fine-tune models with your best {app?.name} data.
        </p>

        <div className="mt-6">
          <Link type="button" href={`https://cal.com/stephenwalker/klu-optimization-services`} className="mr-3 inline-flex items-center rounded-md border border-grey-200 bg-grey-50 px-3 py-2 text-sm font-semibold hover:border-grey-300 hover:bg-grey-100 hover:text-grey-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-600">
            <CalendarDaysIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Schedule Session
          </Link>
          <Link type="button" href={`/${workspace.slug}/apps/${app.slug}/data`} className="inline-flex items-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white hover:bg-grey-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-600">
            <ArrowPathRoundedSquareIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Select Data
          </Link>
        </div>
      </div>;
  }
  return <div className="flex items-center justify-between overflow-clip rounded-lg border border-grey-200 dark:border-grey-600">
      <div className="w-full">
        <div className="inline-block w-full align-middle">
          <div className="">
            <table className="w-full min-w-full bg-white dark:bg-black divide-y divide-grey-300 dark:divide-grey-600">
              <thead className="bg-grey-50 dark:bg-grey-900">
                <tr>
                  <th scope="col" className="py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200">
                    Name
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Base Model
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Finetuned Model
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Training Dataset
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Data Points
                  </th>
                  <th scope="col" className="hidden py-2.5 pl-6 pr-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-grey-200 sm:table-cell">
                    Date Created
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 dark:divide-grey-600">
                {finetunes?.map(finetune => <FinetuneRow key={finetune.id} finetune={finetune} workspace={workspace} app={app} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>;
}
interface IParams extends ParsedUrlQueryInput {
  slug: string;
}
const OptimizationPage: NextPage = () => {
  const router = useRouter();
  const {
    slug
  } = (router.query as IParams);
  const {
    workspace
  } = useCurrentWorkspace();
  const {
    app
  } = useFetchAppBySlug(slug);
  return <Sidebar>
      <Head>
        <title>{app?.name} Fine-tuning / Klu.ai</title>
        <meta name="description" content={app?.description ? `Model finetuning: ${app.description}` : 'Model finetuning'} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 flex h-16 flex-shrink-0 border-b border-grey-200 bg-white bg-opacity-50 backdrop-blur dark:bg-black">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-4 sm:px-6">
            <OpenMobileSidebarButton />
            <div className="flex flex-1 items-center">
              <h1 className="min-w-fit text-xl font-semibold text-grey-800">Finetuning</h1>
            </div>
            <div className="flex items-center space-x-4">
              <Tooltip.Root delayDuration={333}>
                <Tooltip.Trigger asChild>
                  <Link href={`https://docs.klu.ai/guides/fine-tune`} target="new">
                    <Button variant="outline" startIcon={DocsIcon}>
                      Learn more
                    </Button>
                  </Link>
                </Tooltip.Trigger>
                <Tooltip.Content side={'bottom'}>Learn more about evals</Tooltip.Content>
              </Tooltip.Root>
              <Tooltip.Root delayDuration={333}>
                <Tooltip.Trigger asChild>{app && <CreateFinetune app={app} />}</Tooltip.Trigger>
                <Tooltip.Content side={'bottom'}>Fine-tune a model</Tooltip.Content>
              </Tooltip.Root>
            </div>
          </div>
        </div>
        <main>
          <div>
            <div className="max-w-8xl mx-auto px-4 sm:px-6">
              {workspace && app && <>
                  {/* <OptimizationNav workspace={workspace} app={app} /> */}
                  <div className="py-6">
                    <FinetuneTable workspace={workspace} app={app} />
                  </div>
                </>}
            </div>
          </div>
        </main>
      </div>
    </Sidebar>;
};
const AppDetail404Wrapper: NextPage = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <App404 />;
  }
  return <OptimizationPage {...props} />;
};
export default _withSuperJSONPage(AppDetail404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withApp(), []);