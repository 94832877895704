import { ArrowDownCircleIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'
import type { Dataset, Workspace } from '@prisma/client'
import Link from 'next/link'
import { useState } from 'react'
import { Button, Dialog } from '@/client/components'
import { DataImportForm } from './Form'

export const DataImport = ({ workspace, dataset }: { workspace: Workspace; dataset?: Dataset }) => {
  const [isOpen, onIsOpenChange] = useState(false)

  return (
    <>
      <Button
        variant={'outline'}
        startIcon={CloudArrowUpIcon}
        onClick={() => {
          onIsOpenChange(true)
        }}
      >
        Import Data
      </Button>
      <DataImportModal
        workspace={workspace}
        isOpen={isOpen}
        onIsOpenChange={onIsOpenChange}
        dataset={dataset}
      />
    </>
  )
}

export const DataImportModal = ({
  workspace,
  isOpen,
  onIsOpenChange,
  dataset,
}: {
  workspace: Workspace
  isOpen: boolean
  onIsOpenChange: (val: boolean) => void
  dataset?: Dataset
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <div className="px-6 pt-6 flex items-center justify-between">
              <div className="flex flex-col">
                <Dialog.Title asChild>
                  <h3 className="title3 grow">Import Data</h3>
                </Dialog.Title>
                {/* <p className="text-grey-800 mt-2 text-sm">Import data from a CSV file.</p> */}
              </div>
              <div className="inline-flex rounded-md">
                <Link href="/data/example-import.jsonl">
                  <Button variant="outline">
                    Download Example File
                    <ArrowDownCircleIcon className="h-4 w-4 " aria-hidden="true" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Header>
        <Dialog.Description>
          <DataImportForm workspace={workspace} onIsOpenChange={onIsOpenChange} dataset={dataset} />
        </Dialog.Description>
      </Dialog.Content>
    </Dialog.Root>
  )
}
