import { api } from '@/utils'
import { useUser } from './user'

export function useFetchInsightsForData(dataId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.insight.getByDataId.useQuery(
    { dataId: dataId },
    { enabled: user !== undefined, refetchInterval: 100000 }
  )
  return {
    insights: data,
    isLoading,
    error,
  }
}

// type InsightData = {
//   name: string
//   value: number
// }

export function useFetchInsightsData(
  workspaceId: number,
  range: { minDate: Date; maxDate: Date },
  appId?: number,
  actionId?: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.insight.getInsightDataCount.useQuery(
    { workspaceId: workspaceId, appId: appId, actionId: actionId, range: range },
    { enabled: user !== undefined, refetchInterval: 100000 }
  )
  return {
    insightsData: data,
    isLoading,
    error,
  }
}

export function useFetchCategories(
  workspaceId: number,
  range: { minDate: Date; maxDate: Date },
  appId?: number,
  actionId?: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.insight.getCategories.useQuery(
    { workspaceId: workspaceId, appId: appId, actionId: actionId, range: range },
    { enabled: user !== undefined, refetchInterval: 100000 }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchTopicsAndCategories(
  workspaceId: number,
  range: { minDate: Date; maxDate: Date },
  appId?: number,
  actionId?: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.insight.getTopicsAndCategories.useQuery(
    { workspaceId: workspaceId, appId: appId, actionId: actionId, range: range },
    { enabled: user !== undefined, refetchInterval: 100000 }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchSentiment(
  workspaceId: number,
  range: { minDate: Date; maxDate: Date },
  appId?: number,
  actionId?: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.insight.getSentiment.useQuery(
    { workspaceId: workspaceId, appId: appId, actionId: actionId, range: range },
    { enabled: user !== undefined, refetchInterval: 100000 }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchHelpfulness(
  workspaceId: number,
  range: { minDate: Date; maxDate: Date },
  appId?: number,
  actionId?: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.insight.getHelpfulness.useQuery(
    { workspaceId: workspaceId, appId: appId, actionId: actionId, range: range },
    { enabled: user !== undefined, refetchInterval: 100000 }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchModeration(
  workspaceId: number,
  range: { minDate: Date; maxDate: Date },
  appId?: number,
  actionId?: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.insight.getModeration.useQuery(
    { workspaceId: workspaceId, appId: appId, actionId: actionId, range: range },
    { enabled: user !== undefined, refetchInterval: 100000 }
  )
  return {
    data,
    isLoading,
    error,
  }
}
