import type { Workspace } from '@prisma/client'
import React from 'react'
import { Button, TextareaWithAdaptiveHeight } from '@/client/components'
import DiffViewer from '@/client/components/DiffViewer'
import useTextareaWithAdaptiveHeight from '@/client/components/Textarea/hook'
import { cn } from '@/client/utils'
import type { useFetchFeedbackForData } from '@/common/hooks'
import { useUpdateDataForm } from '../../modals'
import type { DetailedData } from '../types'

interface FeedbackFormProps {
  data: DetailedData
  workspace: Workspace
  feedback: ReturnType<typeof useFetchFeedbackForData>['feedback']
  onSuccess: () => Promise<void> | void
}

const useFeedbackForm = ({ data, workspace, feedback, onSuccess }: FeedbackFormProps) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const [correctionValue, setCorrectionValue] = React.useState('')
  const [correctionState, setCorrectionState] = React.useState<'write' | 'review'>('write')

  const {
    formik,
    render: primitiveRender,
    refresh,
    state,
  } = useUpdateDataForm({
    workspace,
    data: {
      ...data,
      Feedback:
        feedback && feedback.length > 0
          ? feedback.map((f) => ({
              id: f.id,
              type: f.type,
              value: f.value,
            }))
          : data.Feedback,
    },
    onSuccess: () => void onSuccess(),
  })

  useTextareaWithAdaptiveHeight(textareaRef, data, [
    state.isRefresh,
    correctionValue,
    correctionState,
  ])

  const reset = () => {
    void formik.resetForm()
    void refresh()
    setCorrectionValue('')
  }

  const render = () => {
    return (
      <dd className="col-span-4 text-sm leading-6 select-text text-grey-900 font-inter">
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-white rounded-lg">
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <div className="py-6">
                <p className="text-sm font-medium">User behavior signal</p>
                <div className="mt-2">{primitiveRender.select.action()}</div>
              </div>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <div className="mb-6">
                <p className="text-sm font-medium">Model generation issue</p>
                <div className="mt-2">{primitiveRender.select.issue()}</div>
              </div>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <div>
                <span className="text-sm font-medium">Response correction</span>
              </div>
              <div className="flex flex-col w-full gap-3 mt-1">
                {correctionValue.length > 0 ? (
                  <div className="grid grid-cols-2 p-1.5 gap-2 text-[12px] rounded-lg bg-gray-100">
                    <button
                      onClick={() => setCorrectionState('write')}
                      className={cn('w-full py-1 rounded-md transition', {
                        'bg-white font-semibold': correctionState === 'write',
                        'bg-transparent hover:bg-gray-200/50 font-medium text-black/50':
                          correctionState !== 'write',
                      })}
                      type="button"
                    >
                      Write
                    </button>
                    <button
                      onClick={() => setCorrectionState('review')}
                      className={cn('w-full py-1 rounded-md transition', {
                        'bg-white font-semibold': correctionState === 'review',
                        'bg-transparent hover:bg-gray-200/50 font-medium text-black/50':
                          correctionState !== 'review',
                      })}
                      type="button"
                    >
                      Review
                    </button>
                  </div>
                ) : null}
                <div className="flex w-full rounded-md shadow-sm">
                  {correctionState === 'write' ? (
                    <>
                      <TextareaWithAdaptiveHeight
                        key={data.id}
                        ref={textareaRef}
                        name="correction"
                        id="correction"
                        onChange={(e) => {
                          formik.handleChange(e)
                          setCorrectionValue(e.target.value)
                          e.target.style.height = 'auto'
                          e.target.style.height = `${e.target.scrollHeight}px`
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e)
                        }}
                        defaultValue={formik.values.correction}
                        placeholder="Add a correction"
                        className="flex-1 block w-full rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                      />
                      {primitiveRender.error.correction()}
                    </>
                  ) : (
                    <div className="flex w-full rounded-md shadow-sm h-fit">
                      <DiffViewer
                        title={{ old: 'Response', new: 'Correction' }}
                        data={{ old: data.output, new: correctionValue }}
                        className="max-h-[800px] h-fit overflow-y-auto border-t-[1px] border-black/10"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-4 mt-5">
            <Button
              type="submit"
              onClick={() => setCorrectionValue('')}
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Saving Feedback...' : 'Save Feedback'}
            </Button>
          </div>
        </form>
      </dd>
    )
  }

  return { render, reset, state }
}

export default useFeedbackForm
