import { Logo } from '@/common/components'

export const FullLoaderPage = () => {
  return (
    <div className="mb-8 flex h-full w-full items-center justify-center">
      <div className="relative -left-4">
        <div className="absolute -left-2 -top-2 h-12 w-12 rounded-full ring-1 ring-black/10 dark:ring-white animate-ping"></div>
        <Logo className="h-8 w-8 fill-black dark:fill-white absolute" />
      </div>
    </div>
  )
}
