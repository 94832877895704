import { z } from 'zod'
import { messageSchema } from '@/client/containers/views/Studio/components/Tabs/Chat/schemas'
import { modelConfigFormSchema } from '../../../../../Formik/schemas'

export const saveActionFormSchema = z.object({
  systemMessage: z.string().default(''),
  outputFormat: z.string().optional(),
  outputInstructions: z.string().optional(),
  promptTemplate: z.string().default(''),
  templateMessages: z.array(messageSchema),
  workspaceModelProviderId: z.number().min(0, 'Select a model provider'),
  modelName: z.string({ required_error: 'Select a model' }),
  toolIds: z.array(z.number()),
  indexIds: z.array(z.number()),
  modelConfig: modelConfigFormSchema,
})

export const newActionFormSchemaBuilder = (existingAppsCount?: number) =>
  z
    .object({
      workspaceId: z.number().min(0, 'Select a workspace'),
      newApp: z.boolean(),
      name: z.string().max(200, 'Name must be less than 200 characters').optional(),
      appId: z.number().optional(),
      appName: z.string().optional(),
      ...saveActionFormSchema.shape,
    })
    .superRefine((values, ctx) => {
      if (values.newApp && !values.appName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['appName'],
          message: 'App name is required',
        })
      }

      if (!values.newApp) {
        if (!values.appId && existingAppsCount) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['appId'],
            message: 'Please select or create new app',
          })
        }

        if (!values.name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['name'],
            message: 'Name is required',
          })
        }
      }
    })
