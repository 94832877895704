import { Button } from '@/client/components'
import { useDeleteDatasetForm } from './form'
import type { FormProps } from './types'

export const DeleteDatasetForm = ({
  dataset,
  workspace,
  app,
  onIsOpenChange,
  isOpen,
}: FormProps) => {
  const { formik } = useDeleteDatasetForm({
    dataset,
    workspace,
    app,
    onIsOpenChange,
    isOpen,
  })
  const { handleSubmit } = formik

  return (
    <>
      <div className="flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            handleSubmit()
          }}
        >
          Delete
        </Button>
      </div>
    </>
  )
}
