import {
  ArrowRightIcon,
  Bars3BottomLeftIcon,
  ChartBarSquareIcon,
  Cog6ToothIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import type { Workspace } from '@prisma/client'
import { useRouter, type NextRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  AddContextIcon,
  APIKeyIcon,
  ConnectionIcon2,
  ContextIcon,
  GpuRequestIcon,
  HomeIcon,
  InsightsIcon,
  NetRequest,
  SkillIcon,
  StudioIcon,
  UserIcon,
  WorkspaceIcon,
} from '@/client/assets/icons/icons'
import { Command } from '@/client/components'
import { WorkspaceSelectionList } from '@/client/containers/views/WorkspaceSwitcher'
import { useCurrentApp, useCurrentWorkspace, useFetchWorkspacesForMember } from '@/common/hooks'
import {
  appMenuItems,
  useFetchCommandKMenuItems,
  type MenuItem,
  type MenuItems,
} from '@/common/hooks/commandk'

export default function CommandKDialog() {
  const router = useRouter()
  const { workspace } = useCurrentWorkspace()
  const [open, setOpen] = useState(false)

  const onOpenChange = (open: boolean) => {
    setOpen(open)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'k') {
        setOpen((o) => !o)
      }

      if (event.metaKey && event.key === ',') {
        event.preventDefault() // Prevent the default action
        if (workspace) {
          void router.push(`/${workspace.slug}/settings/`)
        }
      }

      if (event.metaKey && event.key === 'l') {
        event.preventDefault() // Prevent the default action
        if (workspace) {
          void router.push(`/${workspace.slug}/settings/logs`)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [router, workspace])

  return (
    <>
      <Command.Dialog open={open} onOpenChange={onOpenChange}>
        <CommandKMenu setOpen={setOpen} />
      </Command.Dialog>
    </>
  )
}

interface CommandKMenuProps {
  setOpen: (open: boolean) => void
}

export function workspaceMenuItems(workspace: Workspace) {
  return {
    create_action: {
      label: 'Studio',
      category: 'Navigation',
      value: `/${workspace.slug}/studio`,
      icon: StudioIcon, // directly assign the React component
      shortcut: ['shift', 'S'],
    },
    create_app: {
      label: 'Create App',
      category: 'Create',
      value: {
        userAction: 'createApp',
      },
      icon: PlusCircleIcon,
      shortcut: ['shift', 'A'],
    },
    apps: {
      label: 'Apps',
      category: 'Navigation',
      value: `/${workspace.slug}`,
      icon: HomeIcon,
      shortcut: ['G', 'A'],
    },
    // studio: {
    // label: 'Studio',
    // category: 'Navigation',
    // value: `/${workspace.slug}/studio`,
    // icon: StudioIcon,
    // shortcut: ['G', 'T'],
    // },
    insight: {
      label: 'Insights',
      category: 'Navigation',
      value: `/${workspace.slug}/insights`,
      icon: InsightsIcon,
      shortcut: ['G', 'I'],
    },
    // integrations: {
    //   label: 'Integrations',
    //   category: 'Navigation',
    //   value: `/${workspace.slug}/connections`,
    //   icon: SquaresPlusIcon,
    //   shortcut: ['G', 'N'],
    // },
    llm_providers: {
      label: 'LLM Providers',
      category: 'Navigation',
      value: `/${workspace.slug}/connections`,
      icon: GpuRequestIcon,
      shortcut: ['G', 'L'],
    },
    create_llm_provider: {
      label: 'Create LLM Provider',
      category: 'Create',
      value: {
        userAction: 'createModelProvider',
      },
      icon: PlusCircleIcon,
      shortcut: ['C', 'L'],
    },
    db_providers: {
      label: 'Database Connections',
      category: 'Navigation',
      value: `/${workspace.slug}/connections/databases`,
      icon: NetRequest,
      shortcut: ['G', 'D'],
    },
    skills: {
      label: 'Skills',
      category: 'Navigation',
      icon: SkillIcon,
      value: `/${workspace.slug}/skills`,
      shortcut: ['G', 'S'],
    },
    create_skill: {
      label: 'Create Skill',
      category: 'Create',
      value: {
        userAction: 'createSkill',
      },
      icon: PlusCircleIcon,
      shortcut: ['C', 'S'],
    },
    context_library: {
      label: 'Context Library',
      category: 'Navigation',
      icon: ContextIcon,
      value: `/${workspace.slug}/context`,
      shortcut: ['G', 'C'],
    },
    create_context: {
      label: 'Create Context',
      category: 'Create',
      value: {
        userAction: 'createContext',
      },
      icon: AddContextIcon,
      shortcut: ['shift', 'C'],
    },
    settings: {
      label: 'Settings',
      icon: Cog6ToothIcon,
      category: 'Navigation',
      value: `/${workspace.slug}/settings`,
      shortcut: ['cmd', ','],
    },
    members: {
      label: 'Members',
      icon: UserIcon,
      category: 'Navigation',
      value: `/${workspace.slug}/settings/members`,
      shortcut: ['shift', 'm'],
    },
    api_keys: {
      label: 'API Keys',
      icon: APIKeyIcon,
      category: 'Navigation',
      value: `/${workspace.slug}/settings/developers`,
      description: 'Manage your API keys',
      shortcut: ['shift', 'D'],
    },
    logs: {
      label: 'Activity Log',
      icon: Bars3BottomLeftIcon,
      category: 'Navigation',
      value: `/${workspace.slug}/settings/logs`,
      shortcut: ['shift', 'L'],
    },
    billing: {
      label: 'Billing',
      icon: ChartBarSquareIcon,
      category: 'Navigation',
      value: `/${workspace.slug}/settings/billing`,
    },
    authorized_integrations: {
      label: 'Integrations',
      icon: ConnectionIcon2,
      category: 'Navigation',
      value: `/${workspace.slug}/connections/third-party`,
    },
  } as MenuItems
}

interface MenuItemWithKey extends MenuItem {
  key: string
}

function sortAndGroupItems(menuItems: MenuItems) {
  const groupedItems = Object.keys(menuItems).reduce(
    (acc, key) => {
      const item = menuItems[key]
      if (!item) {
        return acc
      }

      if (item.category) {
        if (!acc[item.category]) {
          acc[item.category] = []
        }
        acc[item.category]?.push({ ...item, key })
      } else {
        acc['Misc.'] = acc['Misc.'] || []
      }

      return acc
    },
    {} as { [key: string]: MenuItemWithKey[] }
  )

  const sortedKeys = Object.keys(groupedItems).sort((a, b) => {
    if (a.startsWith('Apps: ') && !b.startsWith('Apps: ')) {
      return -1
    } else if (a.startsWith('Actions: ') && !b.startsWith('Actions: ')) {
      return -1
    } else if (!a.startsWith('Apps: ') && b.startsWith('Apps: ')) {
      return 1
    } else if (!a.startsWith('Actions: ') && b.startsWith('Actions: ')) {
      return 1
    } else {
      return a.localeCompare(b)
    }
  })

  const sortedGroupedItems = sortedKeys.reduce(
    (acc, key) => {
      acc[key] = groupedItems[key] || []

      return acc
    },
    {} as { [key: string]: MenuItemWithKey[] }
  )

  return sortedGroupedItems
}

function handleCommandKSelect(
  value: string | object,
  menuItems: MenuItems,
  pages: MenuItem[],
  setOpen: (open: boolean) => void,
  setPages: (pages: MenuItem[]) => void,
  setSearch: (search: string) => void,
  router: NextRouter
) {
  if (!router) return

  let routerParams = {}
  const pathname = router.asPath.split('?')[0]

  if (typeof value === 'object') {
    // eg the MenuItem value is `{ userAction: 'createApp' }`, just update the router query
    routerParams = { query: value, pathname }
  } else {
    // the MenuItem value is a string, check if it matches a key in the top level menuItems object

    const menuItem = menuItems?.[value]

    if (menuItem) {
      if (menuItem.items || menuItem.component) {
        // we have sub-items, or else the MenuItem has a component we should render
        setPages([...pages, menuItem])
        setSearch('')
        return
      } else if (menuItem.value) {
        if (typeof menuItem.value === 'object') {
          routerParams = { query: menuItem.value, pathname }
        } else {
          routerParams = { pathname: menuItem.value }
        }
      }
    } else {
      // just a string, so treat it as a href
      // sub menu items aren't keys (just an array)
      routerParams = { pathname: value }
    }
  }

  router.replace({ ...routerParams }).catch((error) => {
    console.error(error)
  })
  setOpen(false)
}

export function CommandKMenu({ setOpen }: CommandKMenuProps) {
  const router = useRouter()
  const { workspace } = useCurrentWorkspace()
  const { workspaces } = useFetchWorkspacesForMember()

  let menuItems = useFetchCommandKMenuItems(workspace)

  const [pages, setPages] = useState<MenuItem[]>([])
  const [search, setSearch] = useState('')
  const page = pages[pages.length - 1]

  if (workspace) {
    menuItems = { ...menuItems, ...workspaceMenuItems(workspace) }
  }

  const handleSelect = (value: string | object) => {
    handleCommandKSelect(value, menuItems, pages, setOpen, setPages, setSearch, router)
  }

  if (workspaces && workspace) {
    const workspacesMenuItem = {
      label: 'Switch Workspace',
      icon: WorkspaceIcon,
      category: 'Navigation',
      value: '/',
      component: (
        <WorkspaceSelectionList
          onSelect={(w) => {
            void router.push(`/${w.slug}`)
            setOpen(false)
          }}
          workspaces={workspaces}
          currentWorkspace={workspace}
        />
      ),
      // items: workspaces.map((w) => ({
      //   label: w.name,
      //   value: `/${w.slug}`,
      // })),
    }

    menuItems = { ...menuItems, workspacesMenuItem }
  }

  const sortedGroupedItems = sortAndGroupItems(menuItems)

  return (
    <>
      <Command.Root
        key={`workspace-cmdk-${workspace?.id || 'no-workspace'}`}
        className="rounded-lg border shadow-md"
        onKeyDown={(e) => {
          // Escape/backspace goes to previous page when search is empty
          // Escape/backspace closes dialog when pages are empty
          if (e.key === 'Escape' || (e.key === 'Backspace' && !search)) {
            e.preventDefault()
            if (pages.length === 0) {
              setOpen(false)
            } else {
              setPages((pages) => pages.slice(0, -1))
            }
          }
        }}
      >
        <Command.Input
          placeholder="Type a command or search..."
          value={search}
          onValueChange={setSearch}
        />

        <Command.List>
          <Command.Empty>No results found.</Command.Empty>

          {!page &&
            Object.keys(sortedGroupedItems).map((category) => (
              <CommandCategory
                category={category}
                key={category}
                search={search}
                items={sortedGroupedItems[category] || []}
                handleSelect={handleSelect}
              />
            ))}

          {page && <CommandPage page={page} handleSelect={(value) => handleSelect(value)} />}
        </Command.List>
      </Command.Root>
    </>
  )
}

function CommandPage({
  page,
  handleSelect,
}: {
  page: MenuItem
  handleSelect: (value: string | object) => void
}) {
  if (page.component) {
    return page.component
  }

  return (
    <>
      <Command.Group heading={page.label}>
        {page.items?.map((item, i) => (
          <Command.Item
            key={item.label}
            onSelect={() => {
              handleSelect(item.value)
            }}
          >
            {item.icon && (
              <item.icon
                className={
                  'mr-2 h-6 w-6 flex-shrink-0  text-grey-800 group-hover:text-grey-100 dark:text-grey-300 dark:group-hover:text-grey-100'
                }
                aria-hidden="true"
              />
            )}
            {!item.icon && (
              <ArrowRightIcon
                className={
                  'mr-2 h-6  w-6 flex-shrink-0   text-grey-800 group-hover:text-grey-100 dark:text-grey-300 dark:group-hover:text-grey-100'
                }
                aria-hidden="true"
              />
            )}
            {item.label}
            <span className="hidden">{i}</span>
          </Command.Item>
        ))}
      </Command.Group>
    </>
  )
}

function CommandCategory({
  category,
  search,
  items,
  handleSelect,
}: {
  category: string
  search: string
  items: MenuItemWithKey[]
  handleSelect: (value: string) => void
}) {
  function shouldHideCategory(category: string) {
    if (search.length > 0) {
      return false
    }
    const hiddenCategories = ['Actions', 'Apps', 'Create']
    return hiddenCategories.includes(category)
  }

  if (shouldHideCategory(category)) {
    return null
  }

  return (
    <>
      <Command.Group heading={category}>
        {items?.map((item) => (
          <CommandItem key={item.key} item={item} handleSelect={handleSelect} />
        ))}
      </Command.Group>
    </>
  )
}

function CommandItem({
  item,
  handleSelect,
}: {
  item: MenuItemWithKey
  handleSelect: (value: string) => void
}) {
  return (
    <>
      <Command.Item
        onSelect={() => {
          handleSelect(item.key)
        }}
      >
        {item.icon && (
          <item.icon
            className={
              'mr-2 h-6  w-6 flex-shrink-0 text-grey-800 group-hover:text-grey-100 dark:text-grey-300 dark:group-hover:text-grey-100'
            }
            aria-hidden="true"
          />
        )}
        {!item.icon && (
          <ArrowRightIcon
            className={
              'mr-2 h-6  w-6 flex-shrink-0 text-grey-800 group-hover:text-grey-100 dark:text-grey-300 dark:group-hover:text-grey-100'
            }
            aria-hidden="true"
          />
        )}
        {item.label}
        <span className="hidden">{item.key}</span>
        {/* we need this to avoid duplicates */}
        {item.shortcut && (
          <Command.Shortcut>
            {item.shortcut?.map((key) => (
              <div
                key={key}
                className="mr-1 inline-block min-w-[2em] rounded-md bg-grey-100 ring-1 ring-grey-200 px-1 py-1 text-center text-xs"
              >
                {key}
              </div>
            ))}
          </Command.Shortcut>
        )}
      </Command.Item>
    </>
  )
}

export function Hotkeys() {
  const { workspace } = useCurrentWorkspace()
  const { app } = useCurrentApp()

  if (!workspace) {
    return null
  }
  let menuItems = { ...workspaceMenuItems(workspace) }
  if (!menuItems) {
    return null
  }

  if (app) {
    menuItems = { ...menuItems, ...appMenuItems(workspace, app) }
  }

  const menuItemsWithShortcuts: MenuItemWithShortcut[] = []

  Object.keys(menuItems).forEach((key) => {
    const menuItem = menuItems[key]
    if (menuItem?.shortcut) {
      menuItemsWithShortcuts.push(menuItem as MenuItemWithShortcut)
    }
  })

  return (
    <>
      {menuItemsWithShortcuts.map(
        (item, i) =>
          item.shortcut && (
            <Hotkey key={`shortcut-${i}-${item.shortcut.join('-')}`} menuItem={item} />
          )
      )}
    </>
  )
}

interface MenuItemWithShortcut extends MenuItem {
  shortcut: string[]
}

export function Hotkey({
  menuItem,
  visible,
  className,
}: {
  menuItem: MenuItemWithShortcut
  visible?: boolean
  className?: string
}) {
  const router = useRouter()

  const shortcut = menuItem.shortcut.join(' + ')

  useHotkeys(shortcut, () => {
    const pathname = router.asPath.split('?')[0]

    let routerParams = {}

    if (menuItem.value) {
      if (typeof menuItem.value === 'object') {
        routerParams = { query: menuItem.value, pathname }
      } else {
        routerParams = { pathname: menuItem.value }
      }
    }

    router.replace({ ...routerParams }).catch((error) => {
      console.error(error)
    })
  })

  return (
    <div
      className={`${className || ''} ${
        visible ? '' : 'hidden'
      } inline-block opacity-30 transition-opacity duration-200 ease-in-out hover:opacity-100`}
    >
      {menuItem.shortcut.map((k, i) => (
        <div
          key={`${k}-${i}`}
          className="
            mr-1 inline-block min-w-[2em] rounded-md bg-gray-200 px-1 py-1 text-center text-xs text-gray-700"
        >
          {k}
        </div>
      ))}
    </div>
  )
}
