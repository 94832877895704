import { Button } from '@/client/components'
import { useDeleteDatasetItemsForm } from './form'
import type { FormProps } from './types'

export const DeleteDatasetItemsForm = ({
  onIsOpenChange,
  isOpen,
  onDeleteSuccess,
}: FormProps & {
  onDeleteSuccess?: () => void
}) => {
  const { formik } = useDeleteDatasetItemsForm({
    onIsOpenChange,
    isOpen,
    onDeleteSuccess,
  })
  const { handleSubmit } = formik

  return (
    <>
      <div className="flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            handleSubmit()
          }}
        >
          Delete
        </Button>
      </div>
    </>
  )
}
