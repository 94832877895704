import { z } from 'zod'

export const editVersionFormSchema = z
  .object({
    name: z.string(),
    description: z.string(),
  })
  .partial({
    description: true,
  })
