import type { Workspace } from '@prisma/client'
import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { createAppFormSchema } from './schema'
import type { CreateAppFormInputs } from './types'

export const useCreateAppForm = ({
  workspace,
  onIsOpenChange,
}: {
  workspace: Workspace
  onIsOpenChange: (isOpen: boolean) => void
}) => {
  const createApp = api.app.createEmpty.useMutation()
  const appType = 'prompt'
  const utils = api.useContext()
  const router = useRouter()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: CreateAppFormInputs = {
      name: '',
    }

    return initialValues
  }, [])

  const onSuccess = React.useCallback((appName: string) => {
    toast.success({
      title: `${appName} created`,
      description: 'Your app is being created',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (values: CreateAppFormInputs, { resetForm }: FormikHelpers<CreateAppFormInputs>) => {
      const newApp = await createApp.mutateAsync({
        workspaceId: workspace.id,
        name: values.name,
      })
      await utils.app.getAll.invalidate({ workspaceId: workspace.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.app_created, {
        'App Name': values.name,
        'App Type': appType,
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess(values.name)
      onIsOpenChange(false)
      await router.push(`/${workspace.slug}/apps/${newApp.slug}`)
    },
    [onIsOpenChange, createApp, user, onSuccess, utils.app.getAll, workspace, router]
  )

  const formikConfig: FormikConfig<CreateAppFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(createAppFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
