import { z } from 'zod'

export const importDataFormSchema = z
  .object({
    datasetName: z.string().optional(),
    datasetId: z.number().optional(),
    labels: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (!values.datasetId) {
      if (!values.datasetName || values.datasetName === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['datasetName'],
          message: 'Name your data set.',
        })
      }
    }
  })

export const importDataFromOpenAIFileFormSchema = z
  .object({
    datasetName: z.string().optional(),
    datasetId: z.number().optional(),
    fileId: z.string(),
  })
  .superRefine((values, ctx) => {
    if (!values.datasetId) {
      if (!values.datasetName || values.datasetName === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['datasetName'],
          message: 'Name your data set.',
        })
      }
    }
  })
