import { generateTemporaryUrl } from 'next-s3-upload'
import { env } from '@/env.mjs'

export const getSignedUrl = async (url: string): Promise<string> => {
  const parsedUrl = new URL(url)
  const parts = parsedUrl.pathname.split('/')
  parts.shift() // remove first slash
  const key = parts.join('/')

  return key
    ? generateTemporaryUrl(key, {
        bucket: env.AWS_S3_BUCKET_NAME,
        region: env.AWS_S3_REGION,
        accessKeyId: env.AWS_ACCESS_KEY,
        secretAccessKey: env.AWS_SECRET_KEY,
      })
    : Promise.resolve('')
}
