import type { FormikProps } from 'formik'
import { type FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { api } from '@/utils'
import { databaseProviders } from '@/utils/databases'
import { createDatabaseProviderFormSchema } from './schema'
import type { CreateDatabaseProviderFormInputs, FormProps } from './types'

export const useCreateDatabaseProviderForm = ({ workspace, onIsOpenChange }: FormProps) => {
  const createDatabaseProvider = api.connection.createDatabaseProvider.useMutation()
  const utils = api.useContext()
  const [isLoading, setLoading] = useState(false)

  const initialValues = React.useMemo(() => {
    const initialValues: CreateDatabaseProviderFormInputs = {
      provider: '',
      name: '',
      metadata: [{ name: 'url', value: 'postgres://' }],
    }

    return initialValues
  }, [])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Database connection created',
      description: 'Your database connection has been created successfully',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: CreateDatabaseProviderFormInputs,
      { resetForm }: FormikHelpers<CreateDatabaseProviderFormInputs>
    ) => {
      setLoading(true)
      await createDatabaseProvider.mutateAsync(
        {
          workspaceId: workspace.id,
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'An error occurred',
              description: error.message,
            })
          },
          onSettled: () => {
            setLoading(true)
          },
          onSuccess: () => {
            onSuccess()
          },
        }
      )
      await utils.connection.getDatabaseProviders.invalidate({ workspaceId: workspace.id })

      resetForm()

      onIsOpenChange(false)
    },
    [
      createDatabaseProvider,
      onIsOpenChange,
      onSuccess,
      utils.connection.getDatabaseProviders,
      workspace.id,
    ]
  )

  const formikConfig = {
    initialValues,
    validationSchema: toFormikValidationSchema(createDatabaseProviderFormSchema),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit,
  }

  async function setRequiredMetadata(
    providerName: string,
    formik: FormikProps<CreateDatabaseProviderFormInputs>
  ) {
    const databaseProvider = databaseProviders?.find((type) => type.name === providerName)
    if (!databaseProvider) throw new Error('Database provider not found')
    await formik.setFieldValue('metadata', databaseProvider.requiredMetadata)
  }

  const saveDisabled = isLoading

  return {
    formikConfig,
    saveDisabled,
    setRequiredMetadata,
  }
}
