import { api } from '@/utils'
import { useUser } from './user'

export function useFetchDatabaseProviders(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.connection.getDatabaseProviders.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    providers: data,
    isLoading,
    error,
  }
}
