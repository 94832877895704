import type { FC } from 'react'
import { useCallback } from 'react'
import { Switch } from '@/client/components'
import { usePersistStudioState } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'

export const CompareModeSwitch: FC = () => {
  const { setFieldValue, values } = useStudioFormikContext()
  const { selectedTab } = usePersistStudioState()

  const changeCompareMode = useCallback(
    (checked: boolean) => {
      if (checked) {
        const existingValues = {
          workspaceModelProviderId: values.workspaceModelProviderId,
          modelName: values.modelName,
          modelConfig: values.modelConfig,
          toolIds: values.toolIds,
          indexIds: values.indexIds,
          isSynced: true,
        }

        void setFieldValue('compare.tab.chat.0', {
          ...values.chat,
          ...existingValues,
        })

        if (values.chat.sessionMessages) {
          const lastMessage = values.chat.sessionMessages.at(-1)
          /** If the last conversation role is from a user, then move all the value to the message box */
          if (lastMessage?.role === 'user' || lastMessage?.role === 'tool') {
            const { sessionMessages, ...rest } = values.chat
            const existingUserInput = values.chat.sessionMessages.at(-1)
            void setFieldValue('compare.tab.chat.0', {
              ...rest,
              newMessage:
                lastMessage.role === 'user'
                  ? {
                      content: existingUserInput?.content,
                      role: 'user',
                    }
                  : {
                      content: existingUserInput?.content,
                      role: 'tool',
                      tool_call_id: existingUserInput?.tool_call_id,
                    },
              files: existingUserInput?.files,
              sessionMessages: sessionMessages.slice(0, -1),
              ...existingValues,
            })
          }
        }
      } else {
        void setFieldValue('modelName', values.compare.tab.chat[0]!.modelName)
        void setFieldValue('modelConfig', values.compare.tab.chat[0]!.modelConfig)
        void setFieldValue(
          'workspaceModelProviderId',
          values.compare.tab.chat[0]!.workspaceModelProviderId
        )
        void setFieldValue('toolIds', values.compare.tab.chat[0]!.toolIds)
        void setFieldValue('indexIds', values.compare.tab.chat[0]!.indexIds)
        if (values.compare.tab.chat[0]?.newMessage || values.compare.tab.chat[0]?.files) {
          const { sessionMessages, ...rest } = values.compare.tab.chat[0]
          void setFieldValue('chat', {
            ...rest,
            sessionMessages: [
              ...(sessionMessages ?? []),
              {
                role: values.compare.tab.chat[0]?.newMessage?.role ?? 'user',
                content: values.compare.tab.chat[0]?.newMessage?.content,
                files: values.compare.tab.chat[0]?.files,
              },
            ],
          })
        } else {
          void setFieldValue('chat', values.compare.tab.chat[0])
        }
      }
      void setFieldValue('compare.isActive', checked)
    },
    [values, setFieldValue]
  )

  // TODO: we dont support compare mode for completion tab yet
  if (selectedTab != 'chat') return null

  return (
    <div className="flex items-center gap-2 text-xs text-grey-700 whitespace-nowrap">
      <label htmlFor={'compare-switch'}>Compare Mode</label>
      <Switch
        id={'compare-switch'}
        checked={values.compare.isActive}
        onCheckedChange={(checked) => changeCompareMode(checked)}
      />
    </div>
  )
}
