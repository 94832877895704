import { CloudArrowDownIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Button, Dialog } from '@/client/components'
import { ExportDatasetForm } from './Form'

export const DataExport = () => {
  const [isOpen, onIsOpenChange] = useState(false)

  return (
    <>
      <Button
        variant={'outline'}
        startIcon={CloudArrowDownIcon}
        onClick={() => {
          onIsOpenChange(true)
        }}
      >
        Export Dataset
      </Button>
      <DataExportModal isOpen={isOpen} onIsOpenChange={onIsOpenChange} />
    </>
  )
}

export const DataExportModal = ({
  isOpen,
  onIsOpenChange,
}: {
  isOpen: boolean
  onIsOpenChange: (val: boolean) => void
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <div className="px-6 pt-6 flex items-center justify-between">
              <div className="flex flex-col">
                <Dialog.Title asChild>
                  <h3 className="title3 grow">Export Dataset Items</h3>
                </Dialog.Title>
              </div>
            </div>
            <ExportDatasetForm />
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
