import type { Data } from '@prisma/client'
import { z } from 'zod'
import { parseJSONPreprocessor } from '@/utils/zod'
import type { ChatStudioPayloadType, FullPromptSent } from '../engine/studio'
import { fullPromptSentSchema } from '../engine/studio'

export type SessionRawMessageDara = Pick<
  Data,
  'id' | 'sessionId' | 'output' | 'prompt_template' | 'full_prompt_sent'
>

const load = (data: SessionRawMessageDara | undefined) => {
  if (!data) {
    return {
      promptTemplate: [],
      sessionMessages: [],
    }
  }
  // if (!data.sessionId) {
  //   console.error(`${data.id} has no sessionId`)
  //   return {
  //     promptTemplate: [],
  //     sessionMessages: [],
  //   }
  // }

  const { promptTemplate, sessionMessages } = parse(
    data.prompt_template ?? '[]',
    data.full_prompt_sent ?? '[]',
    data.id
  )

  return {
    promptTemplate,
    sessionMessages: [
      ...sessionMessages,
      {
        role: 'assistant',
        content: data.output,
      },
    ],
  }
}

const prettify = (data: FullPromptSent) => {
  const handleRole = (role: FullPromptSent['role']) =>
    role === 'ai' ? 'assistant' : role === 'human' ? 'user' : role

  if (Array.isArray(data.content)) {
    return {
      role: handleRole(data.role),
      content: data.content
        .filter((x) => x.type === 'text')
        .map((item) => {
          if (item.type === 'text' && item.text && item.text) {
            return item.text || ''
          } else {
            return ''
          }
        })
        .toLocaleString(),
      files: data.content
        .filter((x) => x.type !== 'text')
        .map((item) => item.image_url?.url)
        .filter((x): x is string => !!x),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      tool_calls: data.tool_calls,
    }
  }

  return {
    role: handleRole(data.role),
    content: typeof data.content === 'string' ? data.content : '',
    files: [],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    tool_calls: data.tool_calls,
  }
}

const parser = z.preprocess(parseJSONPreprocessor, z.array(fullPromptSentSchema))

const parse = (
  promptTemplateString: string,
  fullPromptSentString: string,
  id?: string | number
) => {
  let promptTemplate: FullPromptSent[] = []
  let fullPromptSent: FullPromptSent[] = []

  try {
    promptTemplate = parser.parse(promptTemplateString ?? '[]')
    fullPromptSent = parser.parse(fullPromptSentString ?? '[]')
  } catch (e) {
    if (id) {
      console.error(`${id} Error parsing prompt template or full prompt sent'`)
    } else {
      console.error(`Error parsing prompt template or full prompt sent`)
    }
  }

  // the prompt template is the template messages
  // extract the session messages by removing the template messages from the full prompt sent
  const promptTemplateLength = promptTemplate.length
  // remove template messages from fullPromptSent to obtain only session messages
  const rawSessionMessages = fullPromptSent.slice(promptTemplateLength)

  let sessionMessages: ChatStudioPayloadType['templateMessages'] = []

  sessionMessages = rawSessionMessages.map((message) => prettify(message))

  return {
    promptTemplate,
    sessionMessages,
  }
}

export const sessionService = {
  load,
  parse,
  prettify,
  parser,
}
