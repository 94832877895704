import {
  ArrowPathIcon,
  CloudArrowDownIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import type { ContextSource, Workspace } from '@prisma/client'
import Link from 'next/link'
import { useState } from 'react'
import { Checkbox, toast } from '@/client/components'
import { Room } from '@/common/Room'
import type { DetailedContext } from '@/common/types/context'
import { api } from '@/utils/api'
import { ContextSourceDrawer } from './Drawer'
import { ProcessingStatus } from './ProcessingStatus'

export const ContextSourceRow = ({
  contextSource,
  workspace,
  context,
  selected,
  toggleSelected,
}: {
  contextSource: ContextSource
  workspace: Workspace
  context: DetailedContext
  selected: boolean
  toggleSelected: (id: number) => void
}) => {
  const refreshIndex = api.contextSource.process.useMutation()
  const utils = api.useContext()
  const deleteContextSource = api.contextSource.delete.useMutation()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const invalidate = async () => {
    await utils.contextSource.getAll.invalidate({ contextId: context.id })
    await utils.context.get.invalidate({ id: context.id })
    await utils.contextSource.getStatus.invalidate({ id: contextSource.id })
  }

  const handleProcessFile = async function (contextSource: ContextSource) {
    await refreshIndex.mutateAsync({ guid: contextSource.guid, workspaceId: workspace.id })
    await invalidate()
  }

  const removeFile = async (contextSource: ContextSource) => {
    if (confirm(`Are you sure you want to remove this contextSource?`)) {
      await deleteContextSource.mutateAsync({ id: contextSource.id })
      await invalidate()

      toast.info({
        title: 'Context source removed',
        description: 'Successfully removed the context source',
      })
    }
  }

  return (
    <tr className="group bg-white hover:bg-grey-25">
      <td className="pl-3">
        <Checkbox
          className={`${selected ? '' : 'opacity-30'} group-hover:opacity-100`}
          checked={selected}
          onCheckedChange={() => {
            toggleSelected(contextSource.id)
          }}
        />
      </td>
      <td className="max-w-xs whitespace-nowrap py-6 pl-6 pr-3 text-sm font-medium">
        <ContextSourceDrawer
          contextSource={contextSource}
          workspace={workspace}
          drawerIsOpen={drawerIsOpen}
          setDrawerIsOpen={setDrawerIsOpen}
        />
        <div className="flex flex-row items-center space-x-2">
          <span
            className="cursor-pointer hover:text-blue-700 hover:underline "
            onClick={() => {
              void setDrawerIsOpen(!drawerIsOpen)
            }}
          >
            {contextSource.name}
          </span>
          {contextSource.url && (
            <Link
              download
              target="_blank"
              href={`/api/indices/file-download?fileUrl=${contextSource.url}&workspaceGuid=${workspace.project_guid}`}
            >
              <CloudArrowDownIcon className="h-5 w-5 hover:text-primary-300"></CloudArrowDownIcon>
            </Link>
          )}
        </div>
      </td>

      <td className="px-3">
        <div className="flex flex-row items-center space-x-3 justify-end">
          <Room id={`contextSource-${contextSource.id}`} hideLoader={true}>
            <ProcessingStatus
              contextSource={contextSource}
              key={`${contextSource.guid}-${contextSource.indexedAt?.getTime() || ''}`}
            />
          </Room>
          {contextSource.indexingStatus !== 'PROCESSING' && (
            <button
              type="button"
              title="Reindex File"
              style={{
                cursor: contextSource.indexingStatus === 'PROCESSING' ? 'not-allowed' : 'pointer',
              }}
              disabled={contextSource.indexingStatus === 'PROCESSING'}
              onClick={() => {
                void handleProcessFile(contextSource)
              }}
            >
              <ArrowPathIcon className="h-5 w-5 hover:text-primary-300"></ArrowPathIcon>
            </button>
          )}

          <button
            type="button"
            title="Edit"
            onClick={() => {
              void setDrawerIsOpen(!drawerIsOpen)
            }}
          >
            <PencilSquareIcon className="h-5 w-5 hover:text-primary-300"></PencilSquareIcon>
          </button>
          <button
            type="button"
            title="Remove Source"
            onClick={() => {
              void removeFile(contextSource)
            }}
          >
            <TrashIcon className="h-5 w-5 hover:text-red-500"></TrashIcon>
          </button>
        </div>
      </td>
    </tr>
  )
}
