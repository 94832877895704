import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { api } from '@/utils'
import { useDatasetBulkAction } from '../../context'
import { updateDatasetItemsFormSchema } from './schema'
import type { UpdateDatasetItemsFormInputs, UseUpdateDatasetItemsFormProps } from './types'

export const useUpdateDatasetItemsForm = ({
  onIsOpenChange,
  onUpdateSuccess,
}: UseUpdateDatasetItemsFormProps & {
  onUpdateSuccess?: () => void
}) => {
  const { selectedIds, dataset, selectAllRows, clearSelectedItems, toggleSelectAllRows } =
    useDatasetBulkAction()
  const [loading, setLoading] = React.useState(false)

  const bulkUpdateDataSetItems = api.dataset.bulkUpdateDataSetItems.useMutation()
  const utils = api.useContext()

  const initialValues = React.useMemo(() => {
    const initialValues: UpdateDatasetItemsFormInputs = {
      datasetId: dataset.id,
      selectAll: selectAllRows,
      datasetItemIds: selectedIds ?? [],
      label: '',
      tags: [],
    }

    return initialValues
  }, [dataset.id, selectAllRows, selectedIds])

  const onSubmit = React.useCallback(
    async (
      values: UpdateDatasetItemsFormInputs,
      { resetForm }: FormikHelpers<UpdateDatasetItemsFormInputs>
    ) => {
      let label
      let tags

      if (values.label !== '') {
        label = values.label
      }
      if (values.tags && values.tags.length > 0) {
        tags = values.tags
      }

      setLoading(true)
      await bulkUpdateDataSetItems.mutateAsync(
        {
          datasetId: dataset.id,
          datasetItemIds: selectedIds ?? [],
          selectAll: selectAllRows,
          label: label,
          tags: tags,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error updating dataset items',
              description: error.message,
            })
          },
          onSuccess: () => {
            toast.success({
              title: 'Dataset items updated',
              description: 'Your dataset items were updated successfully',
            })
            onUpdateSuccess?.()
          },
        }
      )
      await utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
      setLoading(false)
      clearSelectedItems()

      resetForm()
      onIsOpenChange(false)
    },
    [
      bulkUpdateDataSetItems,
      dataset.id,
      selectedIds,
      selectAllRows,
      utils.datasetItem.getAll,
      clearSelectedItems,
      onIsOpenChange,
      onUpdateSuccess,
    ]
  )

  const formikConfig: FormikConfig<UpdateDatasetItemsFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(updateDatasetItemsFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
    loading,
    selectAllRows,
    selectedIds,
    clearSelectedItems,
    dataset,
    toggleSelectAllRows,
  }
}
