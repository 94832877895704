import { useUser } from '@/common/hooks'
import { UserAvatar } from './profile/UserAvatar'

export function Avatar() {
  const { user, status } = useUser()

  if (status === 'loading') {
    return (
      <div className="animate-pulse">
        <div className="h-8 w-8 rounded-full bg-grey-700"></div>
      </div>
    )
  }

  if (!user) {
    return null
  }

  return <UserAvatar user={user} />
}
