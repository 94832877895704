import type { Context, Workspace } from '@prisma/client'
import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import { api } from '@/utils'
import { createContextSchema } from './schema'
import type { CreatContextFormInputs } from './types'

export const useCreateContextForm = ({
  workspace,
  onIsOpenChange,
  redirectAfterCreate,
}: {
  workspace: Workspace
  onIsOpenChange: (isOpen: boolean) => void
  redirectAfterCreate?: boolean
}) => {
  const createContext = api.context.create.useMutation()
  const utils = api.useContext()
  const router = useRouter()

  const initialValues = React.useMemo(() => {
    const initialValues: CreatContextFormInputs = {
      name: '',
      description: '',
      typeId: 1, // Simple
    }

    return initialValues
  }, [])

  const onSuccess = React.useCallback((res: Context) => {
    if (redirectAfterCreate) {
      router.push(`/${workspace.slug}/context/${res.slug}?tab=sources`).catch(console.error)
    }
    toast.success({
      title: 'Context created',
      description: 'Your context was created successfully',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: CreatContextFormInputs,
      { resetForm }: FormikHelpers<CreatContextFormInputs>
    ) => {
      await createContext.mutateAsync(
        {
          ...values,
        },
        {
          onSuccess,
          onError: (error) => {
            toast.error({
              title: 'Error creating context',
              description: error.message,
            })
          },
        }
      )
      await utils.context.getAll.invalidate()
      resetForm()
      onIsOpenChange(false)
    },
    [createContext, utils.context.getAll, onIsOpenChange, onSuccess]
  )

  const formikConfig: FormikConfig<CreatContextFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(createContextSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
