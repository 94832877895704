import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

export const PassFailCell = ({ value }: { value: boolean }) => {
  return (
    <div className="whitespace-pre-wrap text-s relative items-center py-3 leading-tight flex gap-2">
      {value ? (
        <>
          <CheckCircleIcon className="text-green-500 h-5 w-5" />
          Passed
        </>
      ) : (
        <>
          <ExclamationCircleIcon className="text-red-500 h-5 w-5" />
          Failed
        </>
      )}
    </div>
  )
}
