import type { Action, App } from '@prisma/client'
import { Sheet, SheetContent, SheetDescription, SheetHeader } from '@/client/components'
import { AddMonitorForm } from '../../evals/AddMonitorForm'

export function AddActionMonitorDrawer({
  open,
  setOpen,
  app,
  action,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  app: App
  action: Action
}) {
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="max-w-4xl sm:max-w-5xl">
        <SheetHeader>
          <SheetDescription>
            <AddMonitorForm action={action} app={app} setOpen={setOpen} />
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}
