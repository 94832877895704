// NOTE THIS IS THE MONITOR VISUAL
import { AreaChart } from '@tremor/react'
import { format } from 'date-fns'
import { Loader } from '@/client/components'
import { useFetchEvalResultsByPeriod } from '@/common/hooks'
import type { DetailedEval } from '@/common/types/eval'

const dataFormatter = (number: number) => {
  // Check if the number is 0, return "0" directly to handle it as an acceptable value
  if (number === 0) return '0'
  return Intl.NumberFormat('us').format(Math.abs(number)).toString()
}
export const EvalPassFailChart = ({
  evalRecord,
  sinceDays,
  groupByPeriod,
}: {
  evalRecord: DetailedEval
  sinceDays?: number
  groupByPeriod?: 'hour' | 'day' | 'week' | 'month'
}) => {
  const { data, isLoading } = useFetchEvalResultsByPeriod({
    evalId: evalRecord.id,
    days: sinceDays || 7,
    groupByPeriod,
  })

  if (isLoading || !data) {
    return <Loader className="h-4 w-4 text-grey-400" />
  }

  interface EvalResultAccumulator {
    [key: string]: { Passed: number; Failed: number }
  }

  const chartData = data.reduce<EvalResultAccumulator>((acc, { period, passed, total }) => {
    const passedNumber = Number(passed)
    const totalNumber = Number(total)
    if (!acc[period]) {
      acc[period] = { Passed: passedNumber, Failed: totalNumber - passedNumber }
    }
    return acc
  }, {} as EvalResultAccumulator)
  const periodFormatString = groupByPeriod == 'hour' ? 'MMM dd hh:mm' : 'MMM dd'

  const chartDataMapped = Object.entries(chartData).map(([period, values]) => {
    return { period: format(new Date(period), periodFormatString), ...values }
  })

  return (
    <>
      <AreaChart
        showLegend={false}
        startEndOnly={true}
        showTooltip={true}
        index="period"
        colors={['success-400', 'error-400']}
        data={chartDataMapped}
        categories={['Passed', 'Failed']}
        valueFormatter={dataFormatter}
      />
    </>
  )
}

// const SingleActionChart = ({ evalRunItems }: { evalRunItems: DetailedEvalRunItem[] }) => {
//   const chartData: GraphData = useMemo(() => {
//     if (!evalRunItems) return {}

//     return evalRunItems.reduce((acc, item) => {
//       const createdAtDay = item.createdAt.toISOString().split('T')[0] || 'unknown'
//       const itemMetadata = item.metadata as EvalRunItemMetadata
//       const passed = itemMetadata.eval_result?.verdict == 'New' ? true : false
//       const passFail = passed ? 1 : 0

//       let dayStats = acc[createdAtDay]
//       if (!dayStats) {
//         dayStats = { total: 1, totalPassed: passFail }
//       } else {
//         dayStats = {
//           total: dayStats.total + 1,
//           totalPassed: dayStats.totalPassed + passFail,
//         }
//       }

//       acc[createdAtDay] = dayStats

//       return acc
//     }, {} as GraphData)
//   }, [evalRunItems])

//   const data = useMemo(() => {
//     if (Object.values(chartData).length == 0) return []

//     return Object.entries(chartData)
//       .map(([date, { total, totalPassed }]) => {
//         return {
//           date: format(new Date(date), 'MMM dd'),
//           Passed: totalPassed,
//           Failed: 0 - (total - totalPassed),
//         }
//       })
//       .reverse()
//   }, [chartData])

//   if (data.length == 0) {
//     return null
//   }

//   return (
//     <BarChart
//       className="h-[250px]"
//       showLegend={false}
//       startEndOnly={true}
//       showTooltip={true}
//       colors={['success-400', 'error-400']}
//       index="date"
//       stack={true}
//       data={data}
//       categories={['Passed', 'Failed']}
//       valueFormatter={dataFormatter}
//     />
//   )
// }
