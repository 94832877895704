import type { App, Workspace } from '@prisma/client'
import { useEffect, useState } from 'react'
import { NegativeFeedbackIcon, PositiveFeedbackIcon } from '@/client/assets/icons/icons'
import { toast, Tooltip } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, classNames, EVENT_NAMES, identify_and_group, track } from '@/utils'
import type { DetailedData } from '../types'

export function Vote({
  app,
  data,
  workspace,
}: {
  app: App
  workspace: Workspace
  data: DetailedData
}) {
  const addVote = api.feedback.create.useMutation()
  const { data: userFeedback, isLoading } = api.feedback.getByDataAndUser.useQuery({
    dataId: data.id,
  })
  const { user } = useUser()
  const [vote, setVote] = useState(parseInt(userFeedback?.value ?? '0'))

  useEffect(() => {
    if (userFeedback !== undefined && !isLoading) {
      if (userFeedback === null) {
        setVote(0)
      } else {
        setVote(parseInt(userFeedback.value))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFeedback, data.id])

  async function rateResponse(rating: number) {
    await addVote.mutateAsync(
      [
        {
          type: 'rating',
          value: rating.toString(),
          dataId: data.id,
          metadata: {},
          workspaceId: workspace.id,
          overrideIfExists: true,
        },
      ],
      {
        onSuccess: () => {
          setVote(rating)
          // await util.data.getAllForApp.invalidate({ appId: app.id })
          toast.success({
            title: 'Feedback saved',
            description: 'Feedback saved to data point',
          })
          if (user) {
            const id = user.id ? user.id : ''
            const email = user.email ? user.email : ''
            const uname = user.name ? user.name : ''
            identify_and_group(id, email, uname, workspace?.slug)
          }
          track(EVENT_NAMES.prompt_review, {
            'App ID': app.id,
            'Prompt ID': data.id,
            'Workspace ID': workspace?.id,
            'Workspace Name': workspace?.name,
            'User ID': user?.id,
            'User Name': user?.name,
            'User Email': user?.email,
          })
        },
        onError: (err: { message?: string }) => {
          toast.error({
            title: 'Something went wrong',
            description: err.message ?? "We couldn't save your feedback",
          })
        },
      }
    )
  }

  return (
    <div className="flex items-center space-x-3">
      <Tooltip.Root delayDuration={333}>
        <Tooltip.Trigger asChild>
          <button
            type="button"
            onClick={() => void rateResponse(2)}
            className={classNames(
              'inline-flex h-9 w-9 items-center justify-center rounded-md border',
              vote > 1
                ? 'bg-green-300/10 hover:border-green-400 border-green-300'
                : 'border-grey-300 bg-white hover:bg-grey-50 hover:border-grey-400'
            )}
          >
            <PositiveFeedbackIcon
              className={classNames('h-4 w-4', vote > 1 ? 'text-green-800' : 'text-grey-600')}
            />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Content sideOffset={5} side="bottom" align="end" className="font-normal">
          Give positive feedback
        </Tooltip.Content>
      </Tooltip.Root>
      <Tooltip.Root delayDuration={333}>
        <Tooltip.Trigger asChild>
          <button
            type="button"
            onClick={() => void rateResponse(1)}
            className={classNames(
              'inline-flex h-9 w-9 items-center justify-center rounded-md border',
              vote === 1
                ? 'bg-red-300/10 hover:border-red-400 border-red-300'
                : 'border-grey-300 bg-white hover:bg-grey-50 hover:border-grey-400'
            )}
          >
            <NegativeFeedbackIcon
              className={classNames('h-4 w-4', vote === 1 ? 'text-red-800' : 'text-grey-600')}
            />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Content sideOffset={5} side="bottom" align="end" className="font-normal">
          Give negative feedback
        </Tooltip.Content>
      </Tooltip.Root>
    </div>
  )
}
