import { ClientSideSuspense } from '@liveblocks/react'
import { RoomProvider } from 'liveblocks.config'
import type { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Loader } from '@/common/components'
import { useWorkspace } from './components/ui/context'

const RoomLoader = () => {
  return (
    <div className="flex justify-center w-full h-10">
      <Loader className="w-4 h-4 text-grey-400" />
    </div>
  )
}

export function Room({
  id,
  children,
  hideLoader,
}: {
  id: string
  children: ReactNode
  hideLoader?: boolean
}) {
  const workspace = useWorkspace()

  if (!workspace) {
    return null
  }
  return (
    <RoomProvider id={`${workspace.project_guid}:${id}`} initialPresence={{}}>
      <ErrorBoundary
        fallback={
          <div className="text-grey-500 p-4">
            There was an error loading room {workspace.project_guid}.
          </div>
        }
      >
        <ClientSideSuspense fallback={!hideLoader ? <RoomLoader /> : null}>
          {() => children}
        </ClientSideSuspense>
      </ErrorBoundary>
    </RoomProvider>
  )
}
