export function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 134.1"
      {...props}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Symbol_-_White"
          data-name="Symbol - White"
          className="cls-1"
          d="m126.04,91.91l-38.3-22.12,25.29,43.78-9.31,8.21L61.26,48.26c-2.77-4.8-2.77-12.66,0-17.46L79.04,0l11.77,3.96-19.29,33.41,36.58-21.12,8.21,9.31-33.61,19.4h44.83l2.46,12.17h-39.82l39.83,23.01-3.96,11.77ZM26.28,12.33l-9.31,8.21,25.29,43.78L3.96,42.2l-3.96,11.77,39.83,23.01H.01l2.46,12.17h44.83l-33.61,19.4,8.21,9.31,36.58-21.12-19.29,33.41,11.77,3.96,17.78-30.8c2.77-4.8,2.77-12.66,0-17.46L26.28,12.33Z"
        />
      </g>
    </svg>
  )
}
