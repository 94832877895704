import type { ContextSource, Workspace } from '@prisma/client'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@/client/components'
import { UpdateContextSource } from './UpdateContextSource'

export const ContextSourceDrawer = ({
  contextSource,
  workspace,
  drawerIsOpen,
  setDrawerIsOpen,
}: {
  contextSource: ContextSource
  workspace: Workspace
  drawerIsOpen: boolean
  setDrawerIsOpen: (isOpen: boolean) => void
}) => {
  return (
    <>
      <Sheet open={drawerIsOpen} onOpenChange={setDrawerIsOpen}>
        <SheetContent className="max-w-4xl sm:max-w-5xl">
          <SheetTitle className="p-4">Source Details: {contextSource.name}</SheetTitle>
          <SheetHeader>
            <SheetDescription>
              <UpdateContextSource
                contextSource={contextSource}
                workspace={workspace}
                setDrawerIsOpen={setDrawerIsOpen}
              />
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  )
}
