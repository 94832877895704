import { useMemo } from 'react'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'
import { useStudio } from '@/client/containers/views/Studio/context'
import { MODEL_WITH_VISION } from '@/common/config/constants'
import { useFetchModelProvider } from '@/common/hooks'

export interface IsVisionSupportedOptions {
  workspaceModelProviderId: string
  modelName: string
}

export const useIsVisionSupported = (options?: IsVisionSupportedOptions) => {
  const {
    state: { action, defaultProvider },
  } = useStudio()

  const { values: formikState } = useStudioFormikContext()
  const { provider: selectedModelProvider } = useFetchModelProvider(
    Number(options?.workspaceModelProviderId || formikState.workspaceModelProviderId)
  )

  return useMemo(() => {
    const providerId =
      selectedModelProvider?.providerId ??
      action?.model.providerId ??
      defaultProvider?.model?.providerId

    const modelName = options?.modelName ?? formikState.modelName
    if (!providerId || !modelName) return undefined

    return Boolean(modelName && MODEL_WITH_VISION.some((model) => modelName.includes(model)))
  }, [
    action?.model.providerId,
    defaultProvider?.model?.providerId,
    formikState.modelName,
    options?.modelName,
    selectedModelProvider?.providerId,
  ])
}
