import type { ActionVersion, Workspace } from '@prisma/client'
import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import { useCallback, useMemo } from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editVersionFormSchema } from './schema'
import type { EditVersionFormInputs } from './types'

export const useEditVersionForm = ({
  workspace,
  version,
  onIsOpenChange,
}: {
  workspace: Workspace
  version: ActionVersion
  onIsOpenChange: (isOpen: boolean) => void
}) => {
  const editActionVersion = api.actionVersion.edit.useMutation()
  const utils = api.useContext()

  const { user } = useUser()

  const initialValues = useMemo(() => {
    const initialValues: EditVersionFormInputs = {
      name: version.name ?? '',
      description: version.description ?? '',
    }

    return initialValues
  }, [version.description, version.name])

  const onSuccess = useCallback(() => {
    toast.success({
      title: `${version.name} updated`,
      description: 'Your version was updated successfully',
    })
  }, [version.name])

  const onSubmit = useCallback(
    async (values: EditVersionFormInputs, { resetForm }: FormikHelpers<EditVersionFormInputs>) => {
      await editActionVersion.mutateAsync({
        id: version.id,
        name: values.name ?? '',
        description: values.description ?? '',
      })
      await utils.actionVersion.getAllForAction.invalidate({ actionId: version.actionId })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.action_version_updated, {
        'Action ID': version.actionId,
        'Version ID': version.id,
        'App Name': values.name,
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      editActionVersion,
      version.id,
      version.actionId,
      utils.actionVersion.getAllForAction,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onSuccess,
      onIsOpenChange,
    ]
  )

  const formikConfig: FormikConfig<EditVersionFormInputs> = useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(editVersionFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
