import Link from 'next/link'
import { Button, Dialog } from '@/client/components'

export function FeatureGate({
  open,
  setOpen,
  loading,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  loading: boolean
}) {
  const onOpenChange = (open: boolean) => {
    setOpen(open)
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {loading && <div className="hidden">Contexts Loading ...</div>}
      {!loading && <FeatureGateContent onOpenChange={onOpenChange} />}
    </Dialog.Root>
  )
}

export const FeatureGateContent = ({ onOpenChange }: { onOpenChange: (open: boolean) => void }) => {
  return (
    <Dialog.Content>
      <Dialog.Header className="px-6 pt-6">
        <Dialog.Title>Context Limit Reached</Dialog.Title>

        <Dialog.Description>
          You&apos;ve reached the Context limit available on your current plan. Please upgrade your
          plan to create more Context.
        </Dialog.Description>
      </Dialog.Header>
      <div className="flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onOpenChange(false)
          }}
        >
          Close
        </Button>
        <Link href={`/api/deep-link/settings/plans`}>
          <Button>Upgrade</Button>
        </Link>
      </div>
    </Dialog.Content>
  )
}
