import { usePersistStudioState } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'
import { useEditActionForm } from '@/client/containers/views/Studio/components/Topbar/components/SaveAction/components/Edit/form'
import type { StudioContextState } from '@/client/containers/views/Studio/context/types'

const useActionSave = (action: NonNullable<StudioContextState['action']>) => {
  const { selectedTab } = usePersistStudioState()
  const { dirty } = useStudioFormikContext()
  const { formik } = useEditActionForm({ action, deployAction: false })

  const isActionTypeChanged =
    (action.action_type === 'prompt' && selectedTab !== 'completion') ||
    (action.action_type === 'chat' && selectedTab !== 'chat')

  const { isSubmitting, isValid, handleSubmit } = formik
  const stale = !isValid || !(dirty || isActionTypeChanged)

  return {
    state: {
      isEdited: !stale,
      isSubmitting,
    },
    handleSubmit,
  }
}

export { useActionSave }
