import { Result } from 'true-myth'
import { z } from 'zod'
import { messageSchema } from '@/client/containers/views/Studio/components/Tabs/Chat/schemas'
import { getMimeType } from '@/common/lib/getMimeType'
import { env } from '@/env.mjs'
import { getSignedUrl } from '@/utils/s3'

export interface PromptStudioErrorType {
  detail: string
}

interface StreamStudioResponseType {
  msg: string
  full_prompt_sent: FullPromptSent
  streaming_url: string
  feedback_url: string
  streaming: boolean
  metadata?: {
    debug_log: string
    error_log: string
  }
  data_guid?: string
}

const promptStudioPayloadSchema = z.object({
  prompt: z.string().min(1),
  systemMessage: z.string().optional(),
  outputInstructions: z.string().optional(),
  outputFormat: z.string().optional(),
  user: z.string().min(1),
  indexGuids: z.array(z.string()),
  toolGuids: z.array(z.string()),
  modelGuid: z.string(),
  modelConfig: z.object({
    temperature: z.number().default(0.7),
    topP: z.number().default(1),
    maxResponseLength: z.number().default(250),
    frequencyPenalty: z.number().default(0),
    presencePenalty: z.number().default(0),
    stopSequence: z.array(z.string()).nullable(),
    numRetries: z.number().min(0).max(5).default(1),
    timeout: z.number().min(1).max(150).default(60),
    logitBias: z
      .array(
        z.object({
          tokenId: z.string(),
          biasValue: z.number().min(-100).max(100),
        })
      )
      .default([]),
    seed: z.number().nullable(),
    responseFormat: z.enum(['text', 'json', 'json_schema']).nullable(),
    json_schema: z.string().optional(),
    parallel_tool_calls: z.boolean().default(true).optional().nullable(),
  }),
  streaming: z.boolean(),
  values: z.any(),
  version: z.number().optional(),
  actionGuid: z.string().optional(),
  persist: z.boolean().default(false),
  files: z.array(z.string().url()).optional(),
})

export const fullPromptSentSchema = z.object({
  role: z.union([
    z.literal('user'),
    z.literal('assistant'),
    z.literal('system'),
    z.literal('human'),
    z.literal('tool'),
    z.literal('ai'),
  ]),
  content: z.union([
    z.array(
      z.object({
        type: z.union([z.literal('text'), z.literal('image_url'), z.literal('image_file')]),
        image_url: z
          .object({
            url: z.string(),
            detail: z.string().optional(),
          })
          .optional(),
        image_file: z
          .object({
            file_id: z.string(),
            detailt: z.string().optional(),
          })
          .optional(),
        text: z.string().optional(),
      })
    ),
    z.string(),
    z.null(),
  ]),
  tool_calls: z.any().nullable().optional(),
  tool_call_id: z.string().optional(),
})

export type FullPromptSent = z.infer<typeof fullPromptSentSchema>

export type PromptStudioPayloadType = z.infer<typeof promptStudioPayloadSchema>

export async function promptStudio(
  apiKey: string,
  data: PromptStudioPayloadType,
  useNewApi: boolean
): Promise<Result<StreamStudioResponseType, Error>> {
  if (data.files) {
    const files = await Promise.all(
      data.files.map((file) => {
        return getSignedUrl(file)
      })
    )
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data.values = { ...data.values, ...{ files } }
  }

  let url
  if (!useNewApi) {
    url = `${env.ENGINE_URL}/v1/actions/playground`
  } else {
    url = `${env.NEXT_PUBLIC_API_URL}/v1/studio/prompt`
  }

  // url = `${env.ENGINE_URL}/v1/actions/playground`

  const request: RequestInit = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
      KLU_DATA_SOURCE: env.KLU_DATA_SOURCE_HEADER,
    },
  }
  // fetchWithZod was not working well with returning errors
  const result = await fetch(url, request)
  if (result.ok) {
    const json = (await result.json()) as StreamStudioResponseType
    return Result.ok(json)
  }
  const error = (await result.json()) as Error
  return Result.err(error)
}

// Chat Studio -------------------------

const chatStudioPayloadSchema = z.object({
  templateMessages: z.array(messageSchema.omit({ dataGuid: true })),
  messages: z.array(messageSchema).optional(),
  outputInstructions: z.string().optional(),
  outputFormat: z.string().optional(),
  user: z.string().min(1),
  indexGuids: z.array(z.string()),
  toolGuids: z.array(z.string()),
  modelGuid: z.string(),
  modelConfig: z.object({
    temperature: z.number().default(0.7),
    topP: z.number().default(1),
    maxResponseLength: z.number().default(250),
    frequencyPenalty: z.number().default(0),
    presencePenalty: z.number().default(0),
    stopSequence: z.array(z.string()).nullable(),
    numRetries: z.number().min(0).max(5).default(1),
    timeout: z.number().min(1).max(150).default(60),
    seed: z.number().nullable(),
    responseFormat: z.enum(['text', 'json', 'json_schema']).nullable(),
    json_schema: z.string().optional(),
    logitBias: z
      .array(
        z.object({
          tokenId: z.string(),
          biasValue: z.number().min(-100).max(100),
        })
      )
      .default([]),
    parallel_tool_calls: z.boolean().default(true).optional().nullable(),
  }),
  streaming: z.boolean(),
  values: z.any(),
  actionGuid: z.string().optional(),
  version: z.number().optional(),
  persist: z.boolean().default(false),
  session: z.string().optional(),
})

export type ChatStudioPayloadType = z.infer<typeof chatStudioPayloadSchema>

export async function chatStudio(
  apiKey: string,
  data: ChatStudioPayloadType,
  useNewApi: boolean
): Promise<Result<StreamStudioResponseType, Error>> {
  if (data.messages) {
    await Promise.all(
      data.messages.map(async (message) => {
        if (message.files) {
          message.files = await Promise.all(
            message.files.map((url) =>
              url.startsWith('https://')
                ? getSignedUrl(url)
                : url.includes('data:')
                  ? url
                  : 'data:' + (getMimeType(url.slice(0, 50) || '') || '') + ';base64, ' + url
            )
          )
        }
      })
    )
  }

  let url
  if (!useNewApi) {
    url = `${env.ENGINE_URL}/v1/actions/studio/chat`
  } else {
    url = `${env.NEXT_PUBLIC_API_URL}/v1/studio/chat`
  }

  // url = `${env.ENGINE_URL}/v1/actions/studio/chat`

  const request: RequestInit = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
      KLU_DATA_SOURCE: env.KLU_DATA_SOURCE_HEADER,
    },
  }

  const result = await fetch(url, request)

  console.log('result is', result)
  if (result.ok) {
    const json = (await result.json()) as StreamStudioResponseType
    return Result.ok(json)
  }
  const error = (await result.json()) as Error
  // return Result.err(new Error(error.detail))
  return Result.err(error)
}

// Workflow Studio -------------------------

export interface WorkflowStudioResponseType {
  msg: string
  status: string
  blocks: {
    feedback_url: string
    result_url: string
    data_guid: string
    action: {
      name: string
      guid: string
    }
  }[]
  has_output_block: boolean
  run_guid: string
}

const triggerWorkflowSchema = z.object({
  workflow: z.string(), // guid
  input: z.array(z.record(z.string().min(1), z.union([z.string(), z.record(z.string(), z.any())]))),
  blocks: z
    .array(
      z.object({
        type: z.string().min(1),
        config: z.object({
          action: z.number().optional().nullable(),
          skill: z.number().optional().nullable(),
          context: z.number().optional().nullable(),
          blockType: z.enum(['standard', 'map', 'reduce', 'output']),
        }),
      })
    )
    .default([]),
})

export type WorkflowStudioPayloadType = z.infer<typeof triggerWorkflowSchema>

export async function workflowStudio(
  apiKey: string,
  data: WorkflowStudioPayloadType
): Promise<Result<WorkflowStudioResponseType, Error>> {
  const url = `${env.ENGINE_URL}/v1/workflows/playground`
  const request: RequestInit = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
      KLU_DATA_SOURCE: env.KLU_DATA_SOURCE_HEADER,
    },
  }
  // fetchWithZod was not working well with returning errors
  const result = await fetch(url, request)
  if (result.ok) {
    const json = (await result.json()) as WorkflowStudioResponseType
    return Result.ok(json)
  }
  const error = (await result.json()) as PromptStudioErrorType
  return Result.err(new Error(error.detail))
}
