import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useCurrentWorkspace } from '@/common/hooks'
import { classNames } from '@/utils'
import { OpenMobileSidebarButton, Sidebar } from '../Sidebar'

function NavItem({ item }: { item: { href: string; name: string } }) {
  const { workspace } = useCurrentWorkspace()
  const router = useRouter()

  if (!workspace) return null
  const isActive = router.asPath === `/${workspace.slug}/settings${item.href}`

  if (!workspace) return null

  return (
    <Link
      href={`/${workspace.slug}/settings${item.href}`}
      className={classNames(
        isActive
          ? 'border-grey-800 text-grey-800'
          : 'border-transparent text-grey-500 hover:border-grey-300',
        'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
      )}
    >
      {item.name}
    </Link>
  )
}

function SettingsNav({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const { workspace } = useCurrentWorkspace()

  const [selected, setSelected] = useState('1')

  const tabs = [
    { id: '1', name: 'Workspace', href: '' },
    { id: '2', name: 'Members', href: '/members' },
    { id: '3', name: 'API Keys', href: '/developers' },
    { id: '4', name: 'Plans', href: '/plans' },
    { id: '5', name: 'Billing', href: '/billing' },
    { id: '6', name: 'Logs', href: '/logs' },
  ]

  useEffect(() => {
    if (workspace && router.isReady) {
      const tab = tabs.find((tab) => `/${workspace.slug}/settings${tab.href}` === router.asPath)
      if (tab) setSelected(tab.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace])

  return (
    <main>
      <div>
        <div className="pt-6 sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          {workspace && (
            <select
              id="tabs"
              name="tabs"
              onChange={(e) => {
                const tab = tabs.find((tab) => tab.id === e.target.value)
                if (!tab) return
                void router.push(`/${workspace.slug}/settings${tab.href}`)
              }}
              className="block w-full rounded-md border-grey-300 py-2 pl-3 pr-10 text-base focus:border-grey-500 focus:outline-none focus:ring-grey-500 sm:text-sm"
              defaultValue={selected}
            >
              {tabs.map((tab) => (
                <option key={tab.name} value={tab.id}>
                  {tab.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-grey-300">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <NavItem item={tab} key={tab.name} />
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="py-6">{children}</div>
    </main>
  )
}

export function WorkspaceSettingsWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Sidebar>
      <Head>
        <title>Settings</title>
        <meta name="description" content="Klu Settings" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {/* Background color split screen for large screens */}
      <div className="h-full">
        {/* Left sidebar & main wrapper */}
        <div className="sticky z-10 top-0 flex h-16 flex-shrink-0 border-b border-grey-200 bg-white bg-opacity-50 backdrop-blur dark:bg-black">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-4 sm:px-6">
            <OpenMobileSidebarButton />
            <div className="flex flex-1 items-center">
              <h1 className="text-xl font-semibold text-grey-800">Settings</h1>
              <p className="hidden sm:flex ml-4 text-sm text-grey-600">
                Configure your workspace, invite team members, and manage API access
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-8xl mx-auto px-6">
          <SettingsNav>{children}</SettingsNav>
        </div>
      </div>
    </Sidebar>
  )
}
