import type {
  ChatCompletionAssistantMessageParam,
  ChatCompletionMessageParam,
} from 'openai/resources'
import { ToolCallsRenderer } from '@/client/containers/views/Studio/components/Tabs/Chat/components/Main/components/Messages/components/ChatMessage'
import { MessagePromptRenderer } from '@/common/components/tables/v2/drawer'
import MarkdownRenderer from '../../../tables/markdown'

export const ExpectedOutputCell = ({
  value: expectedOutput,
  className = 'max-h-[200px]',
}: {
  value: string
  className?: string
}) => {
  if (!expectedOutput) return null

  let message: ChatCompletionMessageParam | undefined
  try {
    message = JSON.parse(expectedOutput) as ChatCompletionMessageParam
  } catch (error) {
    message = undefined
  }

  if (message?.role) {
    return (
      <div
        className={`whitespace-pre-wrap py-3 overflow-y-auto overflow-x-hidden leading-normal ${className}`}
      >
        <MessagePromptRenderer role={message.role} content={message.content!} />
        {(message as ChatCompletionAssistantMessageParam).tool_calls && (
          <div className="flex flex-col gap-2">
            <ToolCallsRenderer
              toolCalls={(message as ChatCompletionAssistantMessageParam).tool_calls}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={`whitespace-pre-wrap py-3 overflow-y-auto overflow-x-hidden leading-normal ${className}`}
    >
      <MarkdownRenderer markdownText={expectedOutput} />
    </div>
  )
}
