import type { Action } from '@prisma/client'
import { useEffect } from 'react'
import { useFetchActionVersions } from '@/common/hooks'
import type { ActionVersionMetadata } from '@/server/service/types'
import { Select } from '../forms'

export const VersionSelector = ({
  action,
  setVersion,
  versionId,
}: {
  action: Action
  setVersion: (version: number) => void
  versionId?: number
}) => {
  const { versions, isLoading } = useFetchActionVersions(action.id)

  useEffect(() => {
    setVersion(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || !versions) {
    return null
  }

  const versionsOptions = versions.map((version) => {
    const { modelName } = version.metadata as ActionVersionMetadata
    const versionName =
      version.name == action.name ? `${action.name} v${version.version_number}` : version.name

    return {
      id: version.id,
      name: `${versionName}${(modelName && ` (${modelName})`) || ''}`,
    }
  })

  const latestOption = {
    id: 0,
    name: 'Latest version',
  }

  const versionsAsOptions = [latestOption, ...versionsOptions]

  return (
    <div>
      {versions && versions.length > 0 && (
        <Select.Single
          defaultOption={versionsAsOptions.find((v) => v.id === versionId) || latestOption || null}
          fieldName="versionId"
          placeholder="Select a version"
          onSelect={(option) => {
            if (!option) return
            setVersion(option.id as number)
          }}
          options={versionsAsOptions}
          showAvatars={false}
        />
      )}
    </div>
  )
}
