import type { ContextSource } from '@prisma/client'
import { api } from '@/utils'
import { useUser } from './user'

export function useFetchContextSourceStatus(contextSource: ContextSource) {
  const { user } = useUser()
  const statuses = ['PROCESSING', 'FAILED', 'DONE', 'processed'] //'CANCELED',
  const doNotPollStatuses = ['DONE', 'FAILED', 'processed'] //'CANCELED',
  const { data, isLoading, error } = api.contextSource.getStatus.useQuery(
    { id: contextSource.id },
    {
      enabled: user !== undefined,
      refetchInterval: function (data) {
        const DEFAULT_REFETCH_INTERVAL = 3000
        if (data === undefined) return DEFAULT_REFETCH_INTERVAL
        if (!data.status) return false
        if (doNotPollStatuses.includes(data.status)) return false
        if (!statuses.includes(data.status)) return false
        return DEFAULT_REFETCH_INTERVAL
      },
      refetchOnWindowFocus: false,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchContextSource(id: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.contextSource.get.useQuery(
    {
      id: id,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    contextSource: data,
    isLoading,
    error,
  }
}
