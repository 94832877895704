import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import type { DatasetMetadata } from '@/server/service/types'
import { api } from '@/utils'
import { useDatasetBulkAction } from '../../context'
import { transformDatasetItemsFormSchema } from './schema'
import type { TransformDatasetItemsFormInputs, UseTransformDatasetItemsFormProps } from './types'

export const useTransformDatasetItemsForm = ({
  onIsOpenChange,
  onUpdateSuccess,
}: UseTransformDatasetItemsFormProps & {
  onUpdateSuccess?: () => void
}) => {
  const { selectedIds, dataset, clearSelectedItems, selectAllRows, toggleSelectAllRows } =
    useDatasetBulkAction()
  const [loading, setLoading] = React.useState(false)

  const transformDatasetItems = api.dataset.transformDatasetItems.useMutation()
  const utils = api.useContext()

  const { dataCount } = dataset.metadata as DatasetMetadata

  const initialValues = React.useMemo(() => {
    const initialValues: TransformDatasetItemsFormInputs = {
      selectAll: selectAllRows,
      datasetId: dataset.id,
      datasetItemIds: selectedIds ?? [],
      transformation: 'llm_rewrite',
      attribute: 'input',
      config: undefined,
    }

    return initialValues
  }, [dataset.id, selectedIds, selectAllRows])

  const onSubmit = React.useCallback(
    async (
      values: TransformDatasetItemsFormInputs,
      { resetForm }: FormikHelpers<TransformDatasetItemsFormInputs>
    ) => {
      setLoading(true)
      await transformDatasetItems.mutateAsync(
        {
          datasetItemIds: selectedIds ?? [],
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error transforming dataset items',
              description: error.message,
            })
          },
          onSuccess: () => {
            toast.success({
              title: 'Dataset items updated',
              description: 'Your dataset items are being transformed',
            })
            void utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
            void utils.dataset.getByGuid.invalidate({ guid: dataset.guid })
            onUpdateSuccess?.()
          },
        }
      )

      setLoading(false)
      clearSelectedItems()

      resetForm()
      onIsOpenChange(false)
    },
    [
      transformDatasetItems,
      selectedIds,
      clearSelectedItems,
      onIsOpenChange,
      utils.datasetItem.getAll,
      utils.dataset.getByGuid,
      dataset.id,
      dataset.guid,
      onUpdateSuccess,
    ]
  )

  const formikConfig: FormikConfig<TransformDatasetItemsFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(transformDatasetItemsFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
    loading,
    selectedIds,
    clearSelectedItems,
    dataset,
    selectAllRows,
    toggleSelectAllRows,
    dataCount,
  }
}
