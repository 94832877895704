import type { AgGridReact } from '@ag-grid-community/react'
import type { Dataset } from '@prisma/client'
import type { ReactNode } from 'react'
import React from 'react'
import type { DatasetMetadata } from '@/server/service/types'
import { useExportDatasetItemsForm } from './DataExport/Form/form'
import type { FormProps } from './DataExport/Form/types'

interface DatasetBulkActionContextType {
  downloadCsv: () => Promise<void>
  downloadJson: () => Promise<void>
  loading: boolean
  toggleSelectAllRows: () => Promise<void>
  totalRowCount: number
  selectAllRows: boolean
  downloadingJson: boolean
  downloadingCsv: boolean
  isOpen: boolean
  onIsOpenChange: (val: boolean) => void
  selectDatasetItems: (datasetItemId: number) => Promise<void>
  selectedIds: number[] | undefined
  dataset: Dataset
  clearSelectedItems: () => void
  gridRef?: React.RefObject<AgGridReact<Dataset>>
  dataCount: number | null
}

const DatasetBulkActionContext = React.createContext<DatasetBulkActionContextType | undefined>(
  undefined
)

export const useDatasetBulkAction = () => {
  const context = React.useContext(DatasetBulkActionContext)
  if (context === undefined) {
    throw new Error('useExportDataset must be used within a ExportDatasetProvider')
  }
  return context
}

type DatasetBulkActionProviderProps = FormProps & {
  children: ReactNode
  isOpen: boolean
}

export const DatasetBulkActionProvider: React.FC<DatasetBulkActionProviderProps> = ({
  children,
  workspace,
  app,
  dataset,
  isOpen,
  onIsOpenChange,
}) => {
  const {
    downloadCsv,
    downloadJson,
    loading,
    toggleSelectAllRows,
    totalRowCount,
    selectAllRows,
    downloadingJson,
    downloadingCsv,
    selectDatasetItems,
    selectedIds,
    clearSelectedItems,
  } = useExportDatasetItemsForm({
    dataset,
    app,
    workspace,
    onIsOpenChange,
  })

  const gridRef = React.useRef<AgGridReact<Dataset>>(null)
  const { dataCount } = dataset.metadata as DatasetMetadata

  return (
    <DatasetBulkActionContext.Provider
      value={{
        dataCount,
        downloadCsv,
        downloadJson,
        loading,
        toggleSelectAllRows,
        totalRowCount,
        selectAllRows,
        downloadingJson,
        downloadingCsv,
        isOpen,
        onIsOpenChange,
        selectDatasetItems,
        selectedIds,
        dataset,
        clearSelectedItems,
        gridRef,
      }}
    >
      {children}
    </DatasetBulkActionContext.Provider>
  )
}

export const SelectAllRows = () => {
  const { selectAllRows, toggleSelectAllRows } = useDatasetBulkAction()

  return (
    <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        className={'h-4 w-4 rounded border-gray-300 text-grey-600 focus:ring-0 cursor-pointer'}
        checked={selectAllRows}
        onChange={() => void toggleSelectAllRows()}
      />
      <label htmlFor="user" className="block text-sm text-grey-600">
        Select all rows
      </label>
    </div>
  )
}
