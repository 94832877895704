import { Loader } from '@/client/components'
import { useCurrentApp, useCurrentWorkspace } from '@/common/hooks'
import type { DetailedEval } from '@/common/types/eval'
import { EvalUpdateForm } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]/edit'

export const MonitorSettings = ({ evalRecord }: { evalRecord: DetailedEval }) => {
  const { app } = useCurrentApp()
  const { workspace } = useCurrentWorkspace()

  if (!app || !workspace) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400" />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <EvalUpdateForm evalRecord={evalRecord} app={app} />
    </div>
  )
}
