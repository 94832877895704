/* eslint-disable @next/next/no-img-element */
import { signOut } from 'next-auth/react'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type ReactNode } from 'react'
import { Button, DropdownMenu } from '@/client/components'
import { useUser } from '@/common/hooks'

export function Navbar() {
  const { user } = useUser()
  const router = useRouter()
  const showLoginBtn = router.pathname !== '/signin'

  async function handleSignout() {
    await signOut({
      redirect: false,
      callbackUrl: '/signin',
    })
    await router.push('/signin')
  }

  return (
    <nav className="bg-black shadow">
      <div className="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="h-16 flex flex-1 items-center justify-between">
          <Link href="/">
            <img className="h-8 w-auto" src="/klu-spark-white.svg" alt="Klu.ai" />
          </Link>

          {user ? (
            <DropdownMenu.Root modal={false}>
              <DropdownMenu.Trigger asChild>
                <Button variant="outline">{user.email ?? 'Menu'}</Button>
              </DropdownMenu.Trigger>

              <DropdownMenu.Content align="end" className="w-64">
                <DropdownMenu.Item onClick={() => void handleSignout()}>Sign out</DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          ) : (
            <>
              {showLoginBtn && (
                <Link href="/signin" className="ml-3 rounded bg-grey-25 px-4 py-2">
                  Sign in
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </nav>
  )
}

export function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="min-h-full">
        <Navbar />

        <Head>
          <title>Klu</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>

        {children}
      </div>
    </>
  )
}
