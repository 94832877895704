export type NonNullableLiteralString<T extends string | null | undefined> = Exclude<
  T,
  null | undefined
>

export enum ActionTypeEnum {
  PROMPT = 'prompt',
  CHAT = 'chat',
  WORKFLOW = 'workflow',
}

export type ActionType = `${ActionTypeEnum}`

export type ActionContentType = 'Python' | 'Typescript' | 'bash' | 'url'
