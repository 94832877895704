import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { revertToVersionFormSchema } from './schema'
import type { RevertToVersionFormInputs, UseRevertToVersionFormProps } from './types'

export const useRevertVersionForm = ({
  workspace,
  actionVersion: version,
  onIsOpenChange,
}: UseRevertToVersionFormProps) => {
  const deployVersion = api.action.deployVersion.useMutation()
  const revertToVersion = api.actionVersion.revertToVersion.useMutation({})
  const utils = api.useContext()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: RevertToVersionFormInputs = {
      versionId: version.id,
      name: '',
      description: '',
    }

    return initialValues
  }, [version.id])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: `Action reverted to ${version.name}`,
      description: 'Config appended as latest version',
    })
  }, [version.name])

  const onSubmit = React.useCallback(
    async (
      values: RevertToVersionFormInputs,
      { resetForm }: FormikHelpers<RevertToVersionFormInputs>
    ) => {
      const { newVersion } = await revertToVersion.mutateAsync({
        actionId: version.actionId,
        ...values,
      })

      /** Deploy the same exact environment based on the targetted revert action for the new version */
      if (newVersion && version.deployments.length > 0) {
        await deployVersion.mutateAsync({
          actionId: version.actionId,
          versionId: newVersion.id,
          environmentIds: version.deployments.map((deployment) => deployment.environmentId),
        })
      }

      await utils.actionVersion.getAllForAction.invalidate({ actionId: version.actionId })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.action_version_updated, {
        'Action ID': version.actionId,
        'Version ID': version.id,
        'App Name': values.name,
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      revertToVersion,
      version.actionId,
      version.deployments,
      version.id,
      utils.actionVersion.getAllForAction,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onSuccess,
      onIsOpenChange,
      deployVersion,
    ]
  )

  const formikConfig: FormikConfig<RevertToVersionFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(revertToVersionFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
