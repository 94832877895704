export function getMimeType(base64: string) {
  const signatures: Record<string, string> = {
    JVBERi0: 'application/pdf',
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
    UklGR: 'image/webp',
    '/9j/': 'image/jpeg',
  }

  for (const sign in signatures) {
    if (base64.startsWith(sign)) return signatures[sign]
  }

  return 'image/png'
}
