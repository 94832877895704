import type { SafeString } from 'handlebars'
import React from 'react'
import { getTemplateVars } from '@/client/utils'
import hbrs from '@/utils/handlebars'
import type { UseTemplateVarsProps } from './types'

export const useTemplateVars = ({ promptTemplate, variableValues }: UseTemplateVarsProps) => {
  const [computedPrompt, setComputedPrompt] = React.useState<string>('')
  const [promptCompilationErrors, setPromptCompilationErrors] = React.useState<string | null>(null)
  const templateVars = React.useMemo(() => getTemplateVars(promptTemplate), [promptTemplate])

  const hasTemplateVars = templateVars.length > 0

  const mappedVariableValues = React.useMemo(() => {
    return templateVars.reduce(
      (acc, key) => {
        const variableValue = variableValues?.[key]

        if (variableValue) {
          acc[key] = variableValue
        } else {
          acc[key] = ''
        }

        return acc
      },
      {} as Record<string, string>
    )
  }, [templateVars, variableValues])

  React.useEffect(() => {
    try {
      const template = hbrs.compile(promptTemplate, {
        data: false,
      })

      const safeMappedVariableValues = Object.entries(mappedVariableValues).reduce(
        (acc, [key, value]) => {
          if (typeof value === 'string') {
            acc[key] = new hbrs.SafeString(value)
          } else {
            acc[key] = value
          }
          return acc
        },
        {} as Record<string, SafeString>
      )

      const promise = template(safeMappedVariableValues) as unknown as Promise<string>

      promise
        .then((result) => {
          setPromptCompilationErrors(null)
          setComputedPrompt(result)
        })
        .catch(console.error)
    } catch (error) {
      setPromptCompilationErrors((error as Error).message)
      setComputedPrompt(promptTemplate || '')
    }

    // return promptTemplate
    //   ? promptTemplate.replace(/{{(.*?)}}/g, (match: string, name: string) => {
    //       const emptyPlaceholder = `{{${name}}}`
    //       const value = mappedVariableValues?.[name]
    //       // If value is not empty, return the value
    //       if (value) return value
    //       // If value is empty and hideIfEmpty is true, return empty string
    //       if (hideIfEmpty) return ''
    //       // If value is empty and hideIfEmpty is false, return the prompt template variable name
    //       if (value === '') return emptyPlaceholder
    //       return emptyPlaceholder
    //     })
    //   : ''
  }, [promptTemplate, mappedVariableValues])

  return {
    hasTemplateVars,
    templateVars,
    mappedVariableValues,
    computedPrompt,
    promptCompilationErrors,
  }
}
