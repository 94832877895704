import { z } from 'zod'
import { messageSchema } from '@/client/containers/views/Studio/components/Tabs/Chat/schemas'

export const editActionFormSchema = z
  .object({
    name: z.string(),
    prompt: z.string().optional(),
    templateMessages: z.array(messageSchema),
    workspaceModelProviderId: z.number(),
    modelName: z.string(),
    modelConfig: z.object({
      temperature: z.number().min(0),
      maxResponseLength: z.number().min(1).max(4000),
      stopSequence: z.array(z.string()).nullable().optional(),
      topP: z.number().min(0).max(1),
      presencePenalty: z.number().min(-2).max(2),
      frequencyPenalty: z.number().min(-2).max(2),
      numRetries: z.number().min(0),
      timeout: z.number().min(0),
      seed: z.number().nullable(),
      responseFormat: z.enum(['text', 'json', 'json_schema']).nullable(),
      json_schema: z.string().optional(),
    }),
    toolIds: z.array(z.number()),
    indexIds: z.array(z.number()),
    systemMessage: z.string().optional(),
    outputFormat: z.string().optional(),
    outputInstructions: z.string().optional(),
    variableDefaults: z.record(z.string().optional()).optional(),
  })
  .partial({
    outputFormat: true,
    systemMessage: true,
  })
