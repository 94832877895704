import { Button, Label } from '@/client/components'
import { DatasetItemTagsInput } from '@/common/components/datasets/DatasetItemForm'
import { FieldError, Loader, Select } from '@/common/components/forms'
import { DATASET_LABELS } from '@/lib/finetunes'
import type { DatasetMetadata } from '@/server/service/types'
import { SelectAllRows } from '../../context'
import { useUpdateDatasetItemsForm } from './form'
import type { FormProps } from './types'

export const UpdateDatasetItemsForm = ({
  onIsOpenChange,
  isOpen,
  onUpdateSuccess,
  datasetId,
}: FormProps & {
  onDeleteSuccess?: () => void
  datasetId: number
}) => {
  const { formik, loading, selectedIds, selectAllRows, dataset } = useUpdateDatasetItemsForm({
    onIsOpenChange,
    isOpen,
    onUpdateSuccess,
  })

  const labelOptions = Object.values(DATASET_LABELS).map((label) => ({
    name: label,
    id: label,
  }))
  labelOptions.unshift({ name: 'Optimize (80% training/20% evaluation)', id: 'optimize' })
  labelOptions.unshift({ name: 'Training & Eval', id: 'both' })

  const { dataCount } = dataset.metadata as DatasetMetadata

  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        <div>
          <div className="mt-1 flex items-center justify-between pb-2">
            <label htmlFor="user" className="block text-sm text-grey-600">
              {selectAllRows ? (
                <span className="text-grey-600">All rows selected ({dataCount} items)</span>
              ) : (
                <>
                  {selectedIds && (
                    <>
                      <span className="font-medium text-grey-800">{selectedIds.length}</span>{' '}
                      currently selected.
                    </>
                  )}
                </>
              )}
            </label>
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 pt-4">
            {!loading && (
              <div className="py-3 flex flex-col space-y-6">
                <div className="space-y-3">
                  <Label
                    htmlFor="split"
                    title="Split"
                    description="Mark this item to be used for Training, Evaluation, or both."
                  />

                  <Select.Single
                    defaultOption={{ name: 'No change', id: '' }}
                    fieldName="label"
                    placeholder="Select dataset for evaluations"
                    position="below"
                    options={labelOptions}
                    showAvatars={false}
                    onSelect={(val) => {
                      if (val) {
                        void formik.setFieldValue('label', val.id)
                      }
                    }}
                  />

                  <FieldError fieldName="label" formik={formik} />
                </div>

                <div className="space-y-3">
                  <Label
                    htmlFor="labels"
                    title="Set tags"
                    description="Replace all tags on the selected items"
                  />

                  <DatasetItemTagsInput
                    datasetId={datasetId}
                    value={formik.values.tags || []}
                    setTags={(tags) => {
                      void formik.setFieldValue('tags', tags)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 pt-4">
            <SelectAllRows />

            {loading && <Loader className="h-6 w-6" />}
          </div>
        </div>
      </div>
      <div className="mt-3 flex items-center space-x-4 justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
        <Button className="w-full" onClick={() => onIsOpenChange(false)} variant="outline">
          Cancel
        </Button>
        <Button className="w-full" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </div>
    </div>
  )
}
