export const getType = (type: string) => {
  switch (type) {
    /** Image */
    case 'png':
      return 'image/png'
    case 'jpg':
      return 'image/jpeg'
    case 'jpeg':
      return 'image/jpeg'
    case 'gif':
      return 'image/gif'
    case 'svg':
      return 'image/svg+xml'
    case 'webp':
      return 'image/webp'
    /** Audio */
    case 'wav':
      return 'audio/wav'
    case 'mp3':
      return 'audio/mpeg'
    case 'mpeg':
      return 'video/mpeg'
    case 'mpga':
      return 'audio/mpeg'
    case 'm4a':
      return 'audio/mp4'
    case 'webm':
      return 'audio/webm'
    default:
      return null
  }
}

export const getAllExtensions = (mime: string) => {
  switch (mime) {
    case 'image/png':
      return ['png']
    case 'image/jpeg':
      return ['jpg', 'jpeg']
    case 'image/gif':
      return ['gif']
    case 'image/svg+xml':
      return ['svg']
    case 'image/webp':
      return ['webp']
    case 'audio/wav':
      return ['wav']
    case 'audio/mpeg':
      return ['mp3', 'mpeg', 'mpga']
    case 'audio/mp4':
      return ['m4a']
    case 'audio/webm':
      return ['webm']
    default:
      return ['']
  }
}
