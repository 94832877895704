import type { Dataset, Eval } from '@prisma/client'
import { z } from 'zod'

const DatasetSchema = z
  .object({
    name: z.string(),
    guid: z.string(),
    created_at: z.any(),
    updated_at: z.any(),
    created_by_id: z.string(),
  })
  .nullable()

const EvalSchema = z.object({
  name: z.string(),
  metadata: z.any(),
  guid: z.string(),
  created_at: z.any(),
  updated_at: z.any(),
  created_by_id: z.string(),
  dataset: DatasetSchema.optional(),
})

export type EvalWithDataSet = Eval & { dataset?: Dataset }

export const getAllEvalOutputSchema = z.array(EvalSchema)

export const getAllInputSchema = z.object({
  dataType: z.enum(['LIVE', 'TEST']).optional(),
})

// Eval detail schema
// ------------------------------

export const getDetailOutputSchema = EvalSchema

export type GetDetailOutputSchema = z.infer<typeof getDetailOutputSchema>

// ------------------------------
// Eval create schema
// ------------------------------

export const evalTypeMetadataSchema = z
  .object({
    variables: z
      .array(
        z.object({
          value: z.union([
            z.string().min(1),
            z.number(),
            z.boolean(),
            z.record(z.string(), z.any()),
          ]),
          name: z.string().min(1),
        })
      )
      .optional(),
  })
  .optional()

export const baseEvalCreateInputSchema = z.object({
  name: z.string().min(1),
  dataset: z.string().optional(),
  action: z.string(),
  version: z.string().optional(),
  eval_types: z.array(
    z.object({
      guid: z.string().min(1),
      name: z.string().min(1).optional(),
      metadata: evalTypeMetadataSchema,
    })
  ),
  sampling_rate: z.number().optional(),
  alert_on_fail: z.boolean().optional(),
})

export const EvalCreateOutputSchema = EvalSchema

// ------------------------------
// Eval update schema
export const baseEvalUpdateInputSchema = z.object({
  guid: z.string(),
  name: z.string().min(1).optional(),
  dataset: z.string().optional(),
  action: z.string().optional(),
  version: z.string().optional(),
  eval_types: z.array(
    z.object({
      guid: z.string().min(1),
      name: z.string().min(1).optional(),
      new_record: z.boolean().optional(),
      metadata: evalTypeMetadataSchema,
    })
  ),
  sampling_rate: z.number().optional(),
  alert_on_fail: z.boolean().optional(),
})

export const EvalUpdateOutputSchema = EvalSchema

// ------------------------------
// Eval delete schema
// ------------------------------

export const EvalDeleteInputSchema = z.object({
  guid: z.string().uuid(),
})

export const EvalDeleteOutputSchema = EvalSchema

export const datasetItemMessageContentSchema = z.union([
  z.string(),
  z.array(
    z.object({
      type: z.enum(['text', 'image_url']),
      text: z.string().optional(),
      image_url: z
        .object({
          url: z.string().optional(),
        })
        .optional(),
    })
  ),
  z.null(),
])

export const datasetItemMessageSchema = z.object({
  role: z.enum(['user', 'assistant', 'system', 'tool']),
  content: datasetItemMessageContentSchema,
  tool_call_id: z.string().optional(),
  tool_calls: z.array(z.any()).optional(),
})

export const DatasetItemSchema = z.object({
  input: z.union([z.string(), z.array(datasetItemMessageSchema)]),
  output: z.string().optional(),
  labels: z.string().optional(),
  tags: z.array(z.string()).optional(),
  full_prompt_sent: z.string().optional(),
})

export type DatasetItemMessageType = z.infer<typeof datasetItemMessageSchema>

// type Variable = {
//   name: string
//   description: string
//   max?: number
//   min?: number
//   type?: string
// }

// type Metadata = {
//   icon: string
//   description: string
//   klu_action_guid?: string
//   multiple_actions: boolean
//   uses_expected_output: boolean
//   variables?: Variable[]
// }

export const EvalTypeSchema = z.object({
  guid: z.string(),
  name: z.string(),
  type: z.string(),
  owner: z.string(),
  metadata: z.any().optional(),
  created_at: z.any(),
  updated_at: z.any(),
  eval_function: z.string().nullable(),
  eval_run_type: z.string(),
})

export const EvalTypesSchema = z.array(EvalTypeSchema)

export const EvalRunItemSchema = z.object({
  guid: z.string(),
  eval: z.string(),
  action: z.string(),
  version: z.string(),
  created_at: z.any(),
  updated_at: z.any(),
  created_by_id: z.string(),
  run_number: z.number(),
  deleted: z.boolean(),
  metadata: z.object({
    scores: z.record(z.string()),
    status: z.string(),
    total_cost: z.string(),
    average_cost: z.string(),
    execution_time: z.nullable(z.unknown()),
    total_latency: z.string(),
    average_latency: z.string(),
    total_run_items: z.number(),
    execution_time_per_item: z.nullable(z.unknown()),
    run_items_completed: z.number(),
  }),
})

export type RunMetadata = {
  scores: Record<string, string>
  status: string
  total_cost: string
  average_cost: string
  executionTime: number
  total_latency: string
  average_latency: string
  evalRunItemsTotal: number
  executionTimePerItem: number
  evalRunItemsCompleted: number
}
