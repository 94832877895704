import type { CustomCellRendererProps } from '@ag-grid-community/react'
import { CheckIcon, MinusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useFormik } from 'formik'
import type { FormikConfig } from 'formik'
import { useCallback, useMemo } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Input, Slider } from '@/client/components'
import type { EvalTypeMetadata } from '@/common/types/eval'
import { api } from '@/utils'
import type { DynamicGridRow } from '../types'

export const HumanEvalResultRenderer = ({ colDef, value, data }: CustomCellRendererProps) => {
  const upsertResult = api.evalRunItem.upsertResult.useMutation()
  const utils = api.useContext()

  const evalOnEvalType = useMemo(() => {
    if (!colDef?.field) return null

    const evalOnEvalTypeId = colDef.field?.split('-')[0]

    const evalOnEvalType = (data as DynamicGridRow).evalsOnEvalTypes.find(
      (x) => x.id.toString() == evalOnEvalTypeId
    )
    return evalOnEvalType
  }, [colDef, data])

  const upsertResultSchema = z.object({
    score: z.number().int().min(0).max(100).optional(),
    reason: z.string().optional(),
    passed: z.boolean().optional(),
  })

  type UpsertResultInput = z.infer<typeof upsertResultSchema>

  const onSubmit = useCallback(
    async (result: UpsertResultInput) => {
      if (result) {
        const { evalRunItem } = data as DynamicGridRow
        await upsertResult.mutateAsync({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          evalRunItemId: data?.evalRunItem.id || 0,
          evalOnEvalTypeId: evalOnEvalType?.id || 0,
          evalTypeId: evalOnEvalType?.evalTypeId || 0,
          score: result.score,
          reason: result.reason,
          passed: result.passed,
        })
        await utils.evalRun.get.invalidate({ id: evalRunItem.evalRunId || undefined })
        await utils.evalRunItem.getAll.invalidate({
          evalRunId: evalRunItem.evalRunId || undefined,
        })
        await utils.evalRun.getAll.invalidate({ evalId: evalRunItem.evalId })
      }
    },
    [
      data,
      upsertResult,
      evalOnEvalType?.id,
      evalOnEvalType?.evalTypeId,
      utils.evalRun.get,
      utils.evalRun.getAll,
      utils.evalRunItem.getAll,
    ]
  )

  const resultAttribute = useMemo(() => {
    if (!colDef?.field) return null

    return colDef.field?.split('-')[1]
  }, [colDef])

  const formikConfig: FormikConfig<UpsertResultInput> = useMemo(() => {
    const initialValues: UpsertResultInput = {
      score: undefined,
      reason: undefined,
      passed: undefined,
    }

    if (resultAttribute === 'score') {
      initialValues.score = value as number
    } else if (resultAttribute === 'reason') {
      initialValues.reason = value as string
    } else if (resultAttribute === 'verdict') {
      initialValues.passed = value as boolean
    }

    return {
      enableReinitialize: false,
      initialValues: {
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          evalRunItemId: data?.evalRunItem.id || 0,
          evalOnEvalTypeId: evalOnEvalType?.id || 0,
          evalTypeId: evalOnEvalType?.evalTypeId || 0,
        },
        ...initialValues,
      },
      validationSchema: toFormikValidationSchema(upsertResultSchema),
      onSubmit,
    }
  }, [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    data?.evalRunItem.id,
    evalOnEvalType?.id,
    evalOnEvalType?.evalTypeId,
    resultAttribute,
    upsertResultSchema,
    onSubmit,
    value,
  ])

  const formik = useFormik(formikConfig)

  const toggleVerdict = useCallback(() => {
    async function toggle() {
      await formik.setFieldValue(
        'passed',
        formik.values.passed === null ? true : !formik.values.passed
      )
      formik.handleSubmit()
    }
    toggle().catch(console.error)
  }, [formik])

  if (!resultAttribute) return null

  if (resultAttribute === 'score') {
    return (
      <div className="whitespace-pre-wrap text-s relative items-center py-3 leading-tight flex gap-2">
        <div className="flex items-center gap-2">
          <Input
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
            id={`${data?.id}-${colDef?.field}`}
            label="Score"
            hideLabel={true}
            min={0}
            max={100}
            value={formik.values.score}
            onChange={(e) => {
              if (e.target.value) {
                void formik.setFieldValue('score', parseInt(e.target.value))
              } else {
                void formik.setFieldValue('score', undefined)
              }
            }}
            onBlur={() => formik.handleSubmit()}
            className="w-1/4 min-w-[50px]"
          />
          <Slider
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
            id={`${data?.id}-${colDef?.field}`}
            min={0}
            max={100}
            step={1}
            value={[formik.values.score || 0]}
            onValueChange={([value]) => {
              void formik.setFieldValue('score', value)
            }}
            onBlur={() => formik.handleSubmit()}
            className="w-3/4"
          />
        </div>
      </div>
    )
  }

  const instructions = (evalOnEvalType?.metadata as EvalTypeMetadata).variables.find(
    (x) => x.name === 'instructions'
  )?.value as string

  if (resultAttribute === 'reason') {
    return (
      <div className="whitespace-pre-wrap relative">
        <textarea
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
          id={`${data?.id}-${colDef?.field}`}
          value={formik.values.reason || ''}
          placeholder={instructions || 'Reason'}
          onChange={(e) => {
            void formik.setFieldValue('reason', e.target.value)
          }}
          onBlur={() => formik.handleSubmit()}
          className="resize-none py-4 block w-full h-full min-w-0 flex-1 focus:border-grey-500 focus:ring-grey-500 sm:text-sm border-none"
        />
      </div>
    )
  }

  if (resultAttribute === 'verdict') {
    return (
      <div
        onClick={() => {
          toggleVerdict()
        }}
        className="cursor-pointer whitespace-pre-wrap text-s relative items-center py-3 leading-tight flex gap-2 select-none"
      >
        {formik.values.passed === null ? (
          <>
            <div className="h-4 w-4 rounded border-gray-500 bg-grey-600 focus:ring-0 cursor-pointer">
              <MinusIcon className="text-white h-4 w-4" />
            </div>
            Pass/Fail
          </>
        ) : (
          <>
            {formik.values.passed ? (
              <>
                <div className="h-4 w-4 rounded border-green-500 bg-green-500 text-grey-600 focus:ring-0 cursor-pointer">
                  <CheckIcon className="text-white h-4 w-4" />
                </div>
                Passed
              </>
            ) : (
              <>
                <div className="h-4 w-4 rounded border-red-500 bg-red-500 text-grey-600 focus:ring-0 cursor-pointer">
                  <XMarkIcon className="text-white h-4 w-4" />
                </div>
                Failed
              </>
            )}
          </>
        )}
      </div>
    )
  }
}
;`h-4 w-4 rounded border-gray-300 text-grey-600 focus:ring-0 cursor-pointer`
