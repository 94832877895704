import { useFormik } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { usePersistStudioState } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'
import { useStudio } from '@/client/containers/views/Studio/context'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editActionFormSchema } from './schemas'
import type { EditActionFormInputs, UseEditActionFormProps } from './types'

export const useEditActionForm = ({ action, deployAction }: UseEditActionFormProps) => {
  const {
    state: { workspace, sessionId },
  } = useStudio()
  const { selectedTab } = usePersistStudioState()
  const { values, resetForm, setFieldValue } = useStudioFormikContext()
  const updateAction = api.action.update.useMutation()
  const updateSession = api.actionSession.update.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: EditActionFormInputs = {
      name: action.name,
      prompt: values.completion.promptTemplate ?? '',
      templateMessages: values.chat.templateMessages ?? [],
      workspaceModelProviderId: parseInt(values.workspaceModelProviderId, 10),
      modelName: values.modelName,
      modelConfig: values.modelConfig,
      toolIds: values.toolIds ?? action.tools.map((tool) => tool.toolId),
      indexIds: values.indexIds ?? action.indices.map((index) => index.indexId),
      systemMessage: values.completion.systemMessage,
      outputFormat: values.outputFormat,
      outputInstructions: values.outputInstructions,
      variableDefaults: values.variableDefaults,
    }

    return initialValues
  }, [action, values])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: `${action.name} saved`,
      description: `Changes to ${action.name} successfully saved as latest version`,
    })
    resetForm({ values })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, action])

  const onSubmit = React.useCallback(
    async (editValues: EditActionFormInputs) => {
      const isCompletionTabAndTemplateMessagesAreChanged =
        selectedTab === 'completion' &&
        JSON.stringify(initialValues.templateMessages) !==
          JSON.stringify(editValues.templateMessages)
      const isChatTabAndPromptIsChanged =
        selectedTab === 'chat' && initialValues.prompt !== editValues.prompt

      if (
        editValues.prompt &&
        editValues.templateMessages.length &&
        (isCompletionTabAndTemplateMessagesAreChanged || isChatTabAndPromptIsChanged)
      ) {
        const confirmed = confirm(
          `You are trying to save both a prompt and template messages, which is currently not possible. If you save, the ${
            selectedTab === 'completion' ? 'prompt' : 'template messages'
          } will be used and the ${
            selectedTab === 'completion' ? 'template messages' : 'prompt'
          } will be deleted. Do you want to continue?`
        )
        if (!confirmed) return
      }

      if (sessionId) {
        await updateSession.mutateAsync({
          sessionId,
        })
      }

      await updateAction.mutateAsync(
        {
          ...editValues,
          prompt:
            selectedTab === 'completion'
              ? editValues.prompt
              : JSON.stringify(editValues.templateMessages),
          actionType: selectedTab === 'completion' ? 'prompt' : 'chat',
          workspaceId: workspace.id,
          actionId: action.id,
          description: action.description ? action.description : '',
          deployAction: deployAction,
        },
        {
          onSuccess: () => {
            onSuccess()
            if (user) {
              identify_and_group(user.id ?? '', user.email ?? '', user.name ?? '', workspace?.slug)
            }

            track(EVENT_NAMES.action_updated, {
              'Action Name': editValues.name,
              'Action Description': action.description,
              'Action Type': action.action_type,
              'Action ID': action.id,
              'Index IDs': editValues.indexIds,
              Tools: editValues.toolIds,
              Indices: editValues.indexIds,
              'Workspace ID': workspace.id,
              'Workspace Name': workspace.name,
              'User Email': user?.email,
              'User ID': user?.id,
              'User Name': user?.name,
            })
          },
          onError: (error) => {
            toast.error({
              title: 'Error updating action',
              description: error.message,
            })
          },
        }
      )

      await utils.action.get.invalidate({ id: action.id })
      await utils.action.getBySlug.invalidate({ slug: action.slug })
      await utils.app.get.invalidate({ appId: action.appId })
      await utils.action.getAllForApp.invalidate({ appId: action.appId })
      await utils.actionVersion.getAllForAction.invalidate({ actionId: action.id })
      await utils.actionVersion.getAllForAction.invalidate({ actionId: action.id })

      if (selectedTab === 'completion') {
        void setFieldValue('completion.prompts', values.completion.prompts)
      } else {
        void setFieldValue('chat.sessionMessages', values.chat.sessionMessages)
        void setFieldValue('chat.variableValues', values.chat.variableValues)
        void setFieldValue('chat.templateMessages', values.chat.templateMessages)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, onSuccess, updateAction, user, utils.action.get, workspace, values, sessionId]
  )

  const formik = useFormik<EditActionFormInputs>({
    initialValues,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(editActionFormSchema),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit,
  })

  return {
    formik,
  }
}
