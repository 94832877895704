import { z } from 'zod'

export const evalTypeMetadataSchema = z
  .object({
    variables: z
      .array(
        z.object({
          value: z.union(
            [z.string().min(1), z.number(), z.boolean(), z.record(z.string(), z.any())],
            { errorMap: () => ({ message: 'This is required.' }) }
          ),
          name: z.string().min(1),
          description: z.string().optional(),
          type: z.enum(['text', 'number', 'boolean', 'assertions']).optional(),
          min: z.number().optional(),
          max: z.number().optional(),
        })
      )
      .optional(),
  })
  .optional()

export const evalSchemaBase = z.object({
  datasetName: z.string().optional(),
  dataType: z.enum(['LIVE', 'TEST']),
  name: z.string().min(1),
  evalTypes: z.array(
    z.object({
      id: z.number(),
      evalId: z.number().optional(),
      evalTypeId: z.number().min(1),
      metadata: evalTypeMetadataSchema,
      name: z.string().optional(),
    })
  ),
  datasetId: z.number().optional(),
  samplingRate: z.number().gt(0).lte(1).optional(),
  alertOnFail: z.boolean().optional(),
  actionId: z.number().min(1),
  versionId: z.number().optional(),
})

export const evalSchemaDefinition = evalSchemaBase.superRefine((values, ctx) => {
  if (values.dataType === 'LIVE') {
    return //no test set required
  }
  if (!values.datasetId || values.datasetId < 1) {
    if (!values.datasetName || values.datasetName === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['datasetName'],
        message: 'Name your data set.',
      })
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['datasetId'],
        message: 'Select a data set',
      })
    }
  }
})

export type EvalSchema = z.infer<typeof evalSchemaDefinition>
