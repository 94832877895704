import { FolderOpenIcon } from '@heroicons/react/24/outline'
import { type FC } from 'react'
import { Button } from '@/client/components'
import type { SelectAction } from '@/client/containers/views/Studio/components/Topbar/components/OpenAction/components/SelectActionDialog/components/Apps/components/Actions/types'
import type { DetailedAgent } from '@/common/components/actions/types'
import { useStudio } from '../../../../context'
import { SelectActionDialog } from './components'
import { useSelectActionDialog } from './logic'

type Props = {
  action: DetailedAgent
  onActionOpened: (action: SelectAction, appId: number) => void
}

const OpenActionVersion: FC<Props> = ({ onActionOpened }) => {
  const { isOpenSelectActionDialog, onIsOpenSelectActionDialogChange } = useSelectActionDialog()

  return (
    <div className="inline-flex rounded-md">
      <SelectActionDialog
        isOpen={isOpenSelectActionDialog}
        onActionOpened={onActionOpened}
        trigger={
          <Button variant="outline" className={'focus:z-10 hover:z-10'} startIcon={FolderOpenIcon}>
            Open
          </Button>
        }
        onIsOpenChange={onIsOpenSelectActionDialogChange}
      />
    </div>
  )
}

export const OpenAction: FC<{ onActionOpened: (action: SelectAction, appId: number) => void }> = ({
  onActionOpened,
}) => {
  const { isOpenSelectActionDialog, onIsOpenSelectActionDialogChange } = useSelectActionDialog()
  const {
    state: { action },
  } = useStudio()

  return (
    <>
      {action ? (
        <OpenActionVersion onActionOpened={onActionOpened} action={action} />
      ) : (
        <SelectActionDialog
          onActionOpened={onActionOpened}
          isOpen={isOpenSelectActionDialog}
          trigger={
            <Button variant="outline" startIcon={FolderOpenIcon}>
              Open
            </Button>
          }
          onIsOpenChange={onIsOpenSelectActionDialogChange}
        />
      )}
    </>
  )
}
