import { useCallback, useEffect, useRef, useState } from 'react'

interface ResizableSidebarProps {
  defaultWidth?: number
  minWidth?: number
  maxWidth?: number
  localStorageKey?: string
  collapseAt?: number
}

const DEFAULT_VALUE = {
  collapseAt: 200,
  maxWidth: 400,
  defaultWidth: 220,
  minWidth: 65,
  localStorageKey: 'sidebar-width',
}

export const useResizableSidebar = (
  {
    collapseAt = DEFAULT_VALUE.collapseAt,
    maxWidth = DEFAULT_VALUE.maxWidth,
    defaultWidth = DEFAULT_VALUE.defaultWidth,
    minWidth = DEFAULT_VALUE.minWidth,
    localStorageKey = DEFAULT_VALUE.localStorageKey,
  }: ResizableSidebarProps = {
    collapseAt: DEFAULT_VALUE.collapseAt,
    maxWidth: DEFAULT_VALUE.maxWidth,
    defaultWidth: DEFAULT_VALUE.defaultWidth,
    minWidth: DEFAULT_VALUE.minWidth,
    localStorageKey: DEFAULT_VALUE.localStorageKey,
  }
) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const [isResizing, setIsResizing] = useState(false)
  const [sidebarWidth, setSidebarWidth] = useState(
    localStorage.getItem(localStorageKey)
      ? Number(localStorage.getItem(localStorageKey))
      : defaultWidth
  )

  const startResizing = useCallback(() => {
    document.body.style.setProperty(
      '-webkit-user-select',
      'none'
    ) /* Safari and older versions of Chrome */
    document.body.style.setProperty('-moz-user-select', 'none') /* Firefox */
    document.body.style.setProperty('-ms-user-select', 'none') /* Internet Explorer/Edge */
    document.body.style.setProperty(
      'user-select',
      'none'
    ) /* Standard property, supported by modern browsers */

    setIsResizing(true)
  }, [])

  const stopResizing = useCallback(() => {
    document.body.style.setProperty(
      '-webkit-user-select',
      'auto'
    ) /* Safari and older versions of Chrome */
    document.body.style.setProperty('-moz-user-select', 'auto') /* Firefox */
    document.body.style.setProperty('-ms-user-select', 'auto') /* Internet Explorer/Edge */
    document.body.style.setProperty(
      'user-select',
      'auto'
    ) /* Standard property, supported by modern browsers */

    setIsResizing(false)
  }, [])

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing) {
        setSidebarWidth((previousWidth) => {
          if (!sidebarRef || sidebarRef.current === null) {
            return previousWidth
          }

          let newWidth = mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left
          if (collapseAt) {
            if (newWidth < collapseAt) {
              newWidth = minWidth
            }
          }

          const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth
          return isWidthInRange ? newWidth : previousWidth
        })
      }
    },
    [collapseAt, isResizing, maxWidth, minWidth]
  )

  useEffect(() => {
    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResizing)
    return () => {
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResizing)
    }
  }, [resize, stopResizing])

  useEffect(() => {
    localStorage.setItem(localStorageKey, String(sidebarWidth))
  }, [localStorageKey, sidebarWidth])

  return {
    isResizing,
    startResizing,
    sidebarRef,
    sidebarWidth,
  }
}
