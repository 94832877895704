import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { deleteDatasetFormSchema } from './schema'
import type { DeleteDatasetFormInputs, UseDeleteDatasetFormProps } from './types'

export const useDeleteDatasetForm = ({
  dataset,
  workspace,
  app,
  onIsOpenChange,
}: UseDeleteDatasetFormProps) => {
  const deleteDataset = api.dataset.delete.useMutation()
  const utils = api.useContext()
  const router = useRouter()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: DeleteDatasetFormInputs = {}

    return initialValues
  }, [])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Dataset deleted',
      description: 'Your dataset was deleted successfully',
    })
    void router.push(`/${workspace.slug}/apps/${app.slug}/datasets`)
  }, [router, workspace.slug, app.slug])

  const onSubmit = React.useCallback(
    async (
      values: DeleteDatasetFormInputs,
      { resetForm }: FormikHelpers<DeleteDatasetFormInputs>
    ) => {
      await deleteDataset.mutateAsync(
        {
          datasetId: dataset.id,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error deleting dataset',
              description: error.message,
            })
          },
          onSuccess: () => {
            toast.success({
              title: 'Dataset deleted',
              description: 'Your dataset was deleted successfully',
            })
          },
        }
      )
      await utils.dataset.getAll.invalidate({ appId: app.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.dataset_deleted, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'Dataset ID': dataset.id,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      deleteDataset,
      dataset.id,
      utils.dataset.getAll,
      app.id,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onSuccess,
      onIsOpenChange,
    ]
  )

  const formikConfig: FormikConfig<DeleteDatasetFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(deleteDatasetFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
