import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { exportDataFormSchema } from './schema'
import type { ExportDataFormInputs, FormProps } from './types'

export const useExportDatasetItemsForm = ({
  dataset,
  app,
  workspace,
  onIsOpenChange,
}: FormProps) => {
  const exportData = api.dataset.export.useMutation()
  const getExportItemsCount = api.dataset.getExportItemsCount.useMutation()
  const utils = api.useUtils()
  const [downloadingCsv, setDownloadingCsv] = React.useState(false)
  const [downloadingJson, setDownloadingJson] = React.useState(false)
  const [totalRowCount, setTotalRowCount] = React.useState<number>(0)
  const [loading, setLoading] = React.useState(false)
  const [selectAllRows, setSelectAllRows] = React.useState(false)
  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: ExportDataFormInputs = {
      datasetId: dataset?.id,
      fileType: 'jsonl',
      selectAll: false,
      datasetItemIds: [],
    }

    return initialValues
  }, [dataset?.id])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Data export started',
      description: 'Your data is being exported.',
    })
  }, [])

  async function downloadJson() {
    setDownloadingJson(true)
    await exportData.mutateAsync(
      {
        fileType: 'jsonl',
        selectAll: selectAllRows,
        datasetItemIds: selectedIds,
        datasetId: dataset.id,
      },
      {
        onSuccess: () => {
          toast.success({
            title: 'Export started',
            description: 'You will receive an email when the export is ready for download',
          })
          onIsOpenChange(false)
        },
        onSettled: () => setDownloadingJson(false),
        onError: () => {
          setDownloadingJson(false)
          toast.error({
            title: 'Error',
            description: 'Failed to download JSONL',
          })
        },
      }
    )
  }

  async function downloadCsv() {
    setDownloadingCsv(true)
    await exportData.mutateAsync(
      {
        fileType: 'csv',
        selectAll: selectAllRows,
        datasetItemIds: selectedIds,
        datasetId: dataset.id,
      },
      {
        onSuccess: () => {
          toast.success({
            title: 'Export started',
            description: 'You will receive an email when the export is ready for download',
          })
          onIsOpenChange(false)
        },
        onSettled: () => setDownloadingCsv(false),
        onError: () => {
          setDownloadingCsv(false)
          toast.error({
            title: 'An error occurred',
            description: 'Failed to download CSV',
          })
        },
      }
    )
  }

  const toggleSelectAllRows = React.useCallback(async () => {
    if (!selectAllRows) {
      setLoading(true)
      const count = await getExportItemsCount.mutateAsync(
        {
          selectAll: true,
          datasetId: dataset.id,
          fileType: 'jsonl',
        },
        {
          onSettled: () => setLoading(false),
          onError: () => {
            setLoading(false)
            toast.error({
              title: 'An error occurred',
              description: 'Failed to get row count',
            })
          },
        }
      )
      setSelectAllRows(true)
      setTotalRowCount(count || 0)
    } else {
      setTotalRowCount(0)
      setSelectAllRows(false)
    }
  }, [dataset.id, getExportItemsCount, selectAllRows])

  const onSubmit = React.useCallback(
    async (
      values: ExportDataFormInputs,
      { resetForm, setFieldValue }: FormikHelpers<ExportDataFormInputs>
    ) => {
      await exportData.mutateAsync(
        {
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error exporting data',
              description: error.message,
            })
          },
          onSuccess: onSuccess,
        }
      )
      await utils.dataset.getAll.invalidate({ appId: app.id })
      await utils.datasetItem.getAll.invalidate({ datasetId: dataset?.id })

      // duplicating logic because I can't reference clearSelectedItems
      setTotalRowCount(0)
      await setFieldValue('datasetItemIds', [])

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.dataset_exported, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      exportData,
      onSuccess,
      utils.dataset.getAll,
      utils.datasetItem.getAll,
      app.id,
      dataset?.id,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onIsOpenChange,
    ]
  )

  const formikConfig: FormikConfig<ExportDataFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: true,
      validationSchema: toFormikValidationSchema(exportDataFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { isSubmitting, errors } = formik

  const saveDisabled = Object.keys(errors).length > 0 || isSubmitting

  const selectedIds = formik.values.datasetItemIds

  const selectDatasetItems = React.useCallback(
    async (datasetItemId: number) => {
      setSelectAllRows(false)
      const rows = selectedIds || []
      if (rows.includes(datasetItemId)) {
        const index = rows.indexOf(datasetItemId)
        rows.splice(index, 1)
      } else {
        rows.push(datasetItemId)
      }
      await formik.setFieldValue('datasetItemIds', rows)
      setTotalRowCount(rows.length)
    },
    [formik, selectedIds]
  )

  const clearSelectedItems = React.useCallback(() => {
    void formik.setFieldValue('datasetItemIds', [])
    setTotalRowCount(0)
  }, [formik])

  return {
    formik,
    saveDisabled,
    loading,
    downloadingCsv,
    downloadingJson,
    totalRowCount,
    toggleSelectAllRows,
    downloadJson,
    downloadCsv,
    selectAllRows,
    selectDatasetItems,
    selectedIds,
    clearSelectedItems,
  }
}
