import type { FeatureName, PlanName } from 'tier'
import type { TierPlanConstant } from '@/server/api/routers/billing/types'

const PLAN_VERSION = '20240508v1'

// Plans
// export const TIER_FREE_PLAN_ID: PlanName = `plan:hobby@${PLAN_VERSION}`
export const PRO_PLAN_PREFIX = 'plan:pro'
export const SCALE_PLAN_PREFIX = 'plan:scale'
export const ENTERPRISE_PLAN_PREXI = 'plan:enterprise'
export const TIER_PRO_PLAN_ID: PlanName = `${PRO_PLAN_PREFIX}@${PLAN_VERSION}`
export const TIER_SCALE_PLAN_ID: PlanName = `${SCALE_PLAN_PREFIX}@${PLAN_VERSION}`
export const TIER_ENTERPRISE_PLAN_ID: PlanName = `${ENTERPRISE_PLAN_PREXI}@${PLAN_VERSION}`

export const TIER_BASE_FEATURE_ID = 'feature:access'
export const TIER_DOCUMENTS_FEATURE_ID = 'feature:documents'
export const TIER_GENERATIONS_FEATURE_ID = 'feature:generations'
export const TIER_ACTIONS_FEATURE_ID = 'feature:actions'
export const TIER_APPS_FEATURE_ID = 'feature:apps'
export const TIER_CONTEXTS_FEATURE_ID = 'feature:contexts'
export const TIER_LLMS_SCALE_FEATURE_ID = 'feature:llms:scale'

// hide these in the UI
export const TIER_HIDDEN_FEATURES = ['Actions']

// Make sure to maintain the order of the plan that you would require
export const tierPlanConstants: TierPlanConstant[] = [
  // {
  //   planId: TIER_FREE_PLAN_ID,
  //   promoted: false,
  //   public: true,
  // },
  {
    planId: TIER_PRO_PLAN_ID,
    promoted: false,
    public: true,
    trialDays: 7,
  },
  {
    planId: TIER_SCALE_PLAN_ID,
    promoted: false,
    public: true,
    trialDays: 7,
  },
  {
    planId: TIER_ENTERPRISE_PLAN_ID,
    promoted: false,
    public: true,
    customPricing: true,
  },
]

export const tierPlanIds = tierPlanConstants.map((plan) => plan.planId)

// Make sure to maintain the order of the (do not include your base price feature in this)
export const tierFeatureConstants: FeatureName[] = [
  TIER_APPS_FEATURE_ID,
  TIER_ACTIONS_FEATURE_ID,
  TIER_DOCUMENTS_FEATURE_ID,
  TIER_CONTEXTS_FEATURE_ID,
  TIER_GENERATIONS_FEATURE_ID,
  TIER_LLMS_SCALE_FEATURE_ID,
]
