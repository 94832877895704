import type { FormikValues } from 'formik'
import React from 'react'
import type { UseCustomFieldProps } from './types'

export const useCustomField = <T extends FormikValues>({ formik }: UseCustomFieldProps<T>) => {
  const { touched, errors, submitCount, setFieldTouched, setFieldValue } = formik

  const handleChangeField = React.useCallback(
    async <K extends keyof T & string>(name: K, value: T[K]) => {
      if (!touched[name]) {
        await setFieldTouched(name, true)
      }
      await setFieldValue(name, value, true)
    },
    [setFieldTouched, setFieldValue, touched]
  )

  const getFieldError = React.useCallback(
    (name: keyof T) => {
      if ((touched[name] || submitCount > 0) && errors[name]) {
        const fieldError = errors[name]

        if (Array.isArray(fieldError)) {
          return fieldError.join(', ')
        }

        return fieldError as string
      }
    },
    [errors, submitCount, touched]
  )

  const resetField = async (name: string) => {
    await setFieldValue(name, undefined)
    await setFieldTouched(name, false)
  }

  return {
    handleChangeField,
    getFieldError,
    resetField,
  }
}
