import Link from 'next/link'
import { Button, Dialog } from '@/client/components'

export function FeatureGate({
  open,
  setOpen,
  loading,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  loading: boolean
}) {
  const onOpenChange = (open: boolean) => {
    setOpen(open)
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <FeatureGateContent loading={loading} onIsOpenChange={onOpenChange} />
    </Dialog.Root>
  )
}

export function FeatureGateContent({
  loading,
  onIsOpenChange,
}: {
  loading: boolean
  onIsOpenChange: (val: boolean) => void
}) {
  return (
    <>
      <Dialog.Content>
        {loading && <div className="hidden">Apps Loading ...</div>}
        {!loading && (
          <>
            <Dialog.Header className="px-6 pt-6">
              <Dialog.Title>Apps Limit Reached</Dialog.Title>

              <Dialog.Description>
                You&apos;ve reached the Apps limit available on your current plan. Please upgrade
                your plan to create more Apps.
              </Dialog.Description>
            </Dialog.Header>

            <div className="flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
              <Button
                variant="outline"
                onClick={() => {
                  onIsOpenChange(false)
                }}
              >
                Close
              </Button>
              <Link href={`/api/deep-link/settings/plans`}>
                <Button variant="primary">Upgrade</Button>
              </Link>
            </div>
          </>
        )}
      </Dialog.Content>
    </>
  )
}
