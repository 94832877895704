import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import type { Document } from '@/common/types/document'
import { api } from '@/utils'

function DeleteDocumentForm({
  setOpen,
  document,
  onDeletedCallback,
}: {
  document: Document
  setOpen: (val: boolean) => void
  onDeletedCallback?: () => void
}) {
  // const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteDocument = api.document.delete.useMutation()
  const utils = api.useContext()

  async function onSuccess() {
    await utils.document.getAllPaginated.invalidate()
    toast.success({
      title: 'Document deleted from context',
      description: 'Document deleted successfully',
    })
    setOpen(false)
    if (typeof onDeletedCallback === 'function') {
      onDeletedCallback()
    }
  }

  async function onDelete() {
    try {
      setLoading(true)
      await deleteDocument.mutateAsync({ documentId: document.id })
      await onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">Delete this document</h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700">Are you sure you want to delete document</p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-300 bg-grey-50 px-4 py-5 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => void onDelete()}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Deleting...' : 'Delete Document'}
        </Button>
      </div>
    </>
  )
}

export const DeleteDocumentModal = ({
  open,
  setOpen,
  document,
  onDeletedCallback,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  document: Document | null
  onDeletedCallback?: () => void
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {document && (
            <DeleteDocumentForm
              setOpen={setOpen}
              document={document}
              onDeletedCallback={onDeletedCallback}
            />
          )}{' '}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
