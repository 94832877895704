import { Button, Combobox, Skeleton } from '@/client/components'
import { useCustomField } from '@/client/hooks'
import { useFetchDatasets } from '@/common/hooks/datasets'
import { useAddDataForm } from './form'
import type { FormProps } from './types'

export const Form = ({
  workspace,
  app,
  onIsOpenChange,
  dataIds,
  onAdded,
}: FormProps & {
  onAdded: () => void
}) => {
  const { formik, saveDisabled } = useAddDataForm({
    workspace,
    app,
    onIsOpenChange,
    dataIds,
    onAdded,
  })
  const { values, handleSubmit } = formik
  const { datasets, isLoading } = useFetchDatasets(app.id)
  const { getFieldError } = useCustomField({ formik })
  const datasetError = getFieldError('datasetId')

  if (isLoading) {
    return (
      <div className="flex flex-col ">
        <div className="flex flex-col gap-2 px-6 opacity-50">
          <Skeleton className="h-5 w-16 rounded-md" />
          <Skeleton className="h-10 w-full rounded-md" />
        </div>
        <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
          <Skeleton className="h-8 w-14 rounded-md opacity-30" />
          <Skeleton className="h-8 w-14 rounded-md opacity-30" />
        </div>
      </div>
    )
  }

  if (datasets?.length === 0) {
    return (
      <div>
        <p className="text-center">No datasets found</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="px-6">
        <Combobox.Single
          label="Select a dataset"
          placeholder="Select a dataset"
          options={(datasets ?? []).map((dataset) => {
            return {
              label: dataset.name,
              value: dataset.id.toString(),
            }
          })}
          message={datasetError}
          hasError={!!datasetError}
          value={values.datasetId.toString()}
          onChange={(newValue) => {
            void formik.setFieldValue('datasetId', newValue)
          }}
        />
      </div>
      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 bg-grey-50 px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}
