import { ClipboardIcon } from '@heroicons/react/24/outline'
import { DropdownMenu } from '@/client/components'
import { useCopyActionGUID } from './form'
import type { UseCopyActionGUIDProps } from './types'

export function CopyButton({ action, showIcon }: UseCopyActionGUIDProps) {
  const { copy } = useCopyActionGUID({ action })
  if (showIcon)
    return (
      <DropdownMenu.Item onClick={copy} className="flex items-center gap-2">
        <ClipboardIcon className="w-4 h-4" />
        <span>Copy GUID</span>
      </DropdownMenu.Item>
    )
  return <DropdownMenu.Item onClick={copy}>Copy GUID</DropdownMenu.Item>
}
