import type { FormikProps } from 'formik'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { databaseProviders } from '@/utils/databases'
import type { CreateDatabaseProviderFormInputs } from './types'

interface MetadataItem {
  name: string
  value: string
}

const MetadataItem = ({ item, index }: { item: MetadataItem; index: number }) => {
  const getPlaceholder = (name: string) => {
    switch (name) {
      case 'connection_string':
        return 'mongodb://username:password@host:port/database'
      case 'endpoint':
        return 'https://example.es.io:9200'
      case 'index':
        return 'Enter ElasticSearch index (e.g., products_v1)'
      case 'field':
        return 'Enter ElasticSearch field name (e.g., user_id)'
      case 'url':
        return 'sql://user:password@host:port/database'
      case 'api_key':
        return 'Enter API key'
      case 'environment':
        return 'us-west1-gcp'
      case 'index_name':
        return 'Enter index name'
      case 'uri':
        return 'redis://user:password@host:port'
      case 'username':
        return 'Enter username'
      case 'password':
        return 'Enter password'
      case 'account':
        return 'xy12345.us-east-1'
      case 'key':
        return 'Enter key'
      default:
        return 'Enter value'
    }
  }

  const getLabelText = (name: string) => {
    switch (name) {
      case 'api_key':
        return 'API Key'
      case 'index_name':
        return 'Index Name'
      case 'connection_string':
        return 'Connection String'
      case 'url':
        return 'URL'
      case 'uri':
        return 'URI'
      default:
        return name
    }
  }

  return (
    <div className="mt-3 flex flex-row items-end" key={index}>
      <div className="w-full">
        <label htmlFor={`metadata.${index}.value`} className="capitalize text-sm text-grey-500">
          {getLabelText(item.name)}
        </label>

        <Field
          name={`metadata.${index}.value`}
          placeholder={getPlaceholder(item.name)}
          type="text"
          className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
        />
        <ErrorMessage
          name={`metadata.${index}.value`}
          component="div"
          className="mt-2 text-xs text-red-500"
        />
      </div>
    </div>
  )
}

export function DatabaseProviderMetadataForm({
  formik,
  className,
}: {
  formik: FormikProps<CreateDatabaseProviderFormInputs>
  className?: string
}) {
  const providerName = formik.values.provider
  const provider = databaseProviders.find((provider) => provider.name === providerName)

  if (!provider) {
    return null
  }

  return (
    <div className={className}>
      <p className="body2 mb-1.5 block font-medium text-grey-800">Configuration</p>
      <div className="mb-2">
        <FieldArray name="metadata">
          {() => (
            <div>
              {formik.values.metadata.length > 0 &&
                formik.values.metadata.map((item, index) => (
                  <MetadataItem key={index} item={item} index={index} />
                ))}
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  )
}
