import type { ResponseModes } from '@/pages/[workspaceSlug]/context/[contextSlug]'
import { Select } from '../forms'

export const ResponseModeSelect = ({
  responseModes,
  setResponseMode,
  responseMode,
  formik,
}: {
  responseModes: ResponseModes
  setResponseMode: (mode: string) => void
  responseMode: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any
}) => {
  return (
    <div className="w-80">
      {responseModes && (
        <div className="w-80">
          <Select.Single
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            formik={formik}
            fieldName="responseMode"
            options={Object.keys(responseModes).map((mode) => ({
              id: mode,
              name: responseModes[mode]?.name || '',
            }))}
            defaultOption={{ id: responseMode, name: responseModes[responseMode]?.name || '' }}
            onSelect={(option) => setResponseMode(option?.id as string)}
            placeholder="Select an action"
          />
        </div>
      )}
      {responseModes[responseMode] && (
        <div className="pt-3">
          <p className="font-mono text-xs text-grey-700">
            {responseModes[responseMode]?.description}
          </p>
        </div>
      )}
    </div>
  )
}
