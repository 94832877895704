import type { Dataset } from '@prisma/client'
import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import { api } from '@/utils'
import { addDatatoDatasetFormSchema } from './schema'
import type { AddDataFormInputs, UseAddDataFormProps } from './types'

export const useAddDataForm = ({
  workspace,
  app,
  onIsOpenChange,
  dataIds,
  onAdded,
}: UseAddDataFormProps & {
  onAdded: () => void
}) => {
  const addData = api.dataset.add.useMutation()
  const utils = api.useContext()

  const initialValues = React.useMemo(() => {
    const initialValues: AddDataFormInputs = {
      datasetId: '0',
    }

    return initialValues
  }, [])

  const onSuccess = React.useCallback(
    (dataset: Dataset) => {
      toast.success({
        title: 'Data added',
        description: `Data added to ${dataset.name}`,
        action: {
          label: 'Open',
          onClick: () =>
            window.open(`/${workspace.slug}/apps/${app.slug}/datasets/${dataset.guid}`),
        },
      })
    },
    [workspace.slug, app.slug]
  )

  const onSubmit = React.useCallback(
    async (values: AddDataFormInputs, { resetForm }: FormikHelpers<AddDataFormInputs>) => {
      const dataset = await addData.mutateAsync({
        workspaceId: workspace.id,
        appId: app.id,
        dataIds,
        datasetId: parseInt(values.datasetId, 10),
      })
      await utils.app.getAll.invalidate({ workspaceId: workspace.id })
      resetForm()
      onSuccess(dataset)
      onIsOpenChange(false)
      onAdded()
    },
    [addData, workspace.id, app.id, dataIds, utils.app.getAll, onSuccess, onIsOpenChange, onAdded]
  )

  const formikConfig: FormikConfig<AddDataFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(addDatatoDatasetFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const isEnterPressed = (event: KeyboardEvent) =>
    event.code === 'Enter' || event.code === 'NumpadEnter'
  useCmdCallback(formik.submitForm, [isEnterPressed])

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
