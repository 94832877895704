/* eslint-disable @next/next/no-img-element */
import type { ParsedUrlQueryInput } from 'querystring'
import {
  AcademicCapIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  LinkIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import { Composer, Thread } from '@liveblocks/react-comments'
import type { App, Workspace } from '@prisma/client'
import type { Row } from '@tanstack/react-table'
import { useThreads } from 'liveblocks.config'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { AddDataIcon, MessageIcon, StudioIcon, VariableIcon } from '@/client/assets/icons/icons'
import {
  Accordion,
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Button,
  MarkdownRenderer,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Tooltip,
} from '@/client/components'
import { InfoTooltip } from '@/client/components/InfoTooltip/InfoTooltip'
import { CopyButton } from '@/client/components/MarkdownRenderer/CodeBlock/components'
import {
  useCurrentApp,
  useFetchActionVersion,
  useFetchEvalRunItemsForData,
  useFetchFeedbackForData,
  useFetchFullPrompt,
  useFetchRequestResponse,
} from '@/common/hooks'
import { useFetchInsightsForData } from '@/common/hooks/insight'
import { Room } from '@/common/Room'
import type { DetailedEvalRunItem, DetailedEvalRunItemResult } from '@/common/types/eval'
import type { Message, MessageContentItem, RefusalContentItem } from '@/common/types/messages'
import type { DataMetadata } from '@/server/service/types'
import { classNames, dateAgo } from '@/utils'
import { sanitizeImageURL } from '@/utils/image'
import { RetrievedDocumentNode } from '../../contexts/RetrievedDocumentNode'
import { Loader } from '../../forms'
import { DeleteDataModal } from '../../modals/data/DeleteDataModal'
import { AddDataModal } from '../../modals/datasets/AddData'
import { UserAvatar } from '../../ui'
import { useWorkspace } from '../../ui/context'
import { Vote } from '../cells/datatable-vote'
import type { DetailedData, SourceMetadata } from '../types'
import useFeedbackForm from './drawer-feedback'
import type { ContextInsightMetadata, DataInsight, EvalInsightMetadata } from './types'

function LogitBiasRenderer({ logitBias }: { logitBias: Record<string, number>[] }) {
  return (
    <div>
      {logitBias.map((bias) => {
        const biasValue = bias.biasValue
        const tokenId = bias.tokenId
        return (
          <div key={tokenId} className="flex items-center">
            <span>
              Token ID: {tokenId} | Bias Value: {biasValue}
            </span>
          </div>
        )
      })}
    </div>
  )
}

function ErrorRenderer({ metadata, model }: { metadata: DataMetadata; model: string }) {
  const error = metadata.error_log || '{}'

  return (
    <div>
      <Alert variant="destructive">
        <div className="flex flex-row justify-between items-center space-x-4">
          <div>
            <div className="flex items-center space-x-2">
              <ExclamationCircleIcon className="h-6 w-6" />
              <AlertTitle>The LLM returned an error</AlertTitle>
            </div>
            <AlertDescription>
              The LLM ({model}) returned an error. Please see the description below:
              {JSON.stringify(JSON.parse(error))}
            </AlertDescription>
          </div>
        </div>
      </Alert>
    </div>
  )
}

function DataRowOverview({
  data,
  app,
  workspace,
  setDrawerOpen,
  setOpenDelete,
}: {
  data: DetailedData
  app: App
  workspace: Workspace
  setDrawerOpen: (open: boolean) => void
  setOpenDelete: (open: boolean) => void
}) {
  const [selected, setSelected] = useState('input')
  let tabs = [
    { name: 'Details', id: 'input' },
    { name: 'Prompt', id: 'prompt' },
    { name: 'Metadata', id: 'metadata' },
    { name: 'Retrieval', id: 'context' },
    { name: 'Request', id: 'request' },
  ]

  const { feedback, isLoading, refetch: refetchFeedback } = useFetchFeedbackForData(data.id)
  const { version, isLoading: versionIsLoading } = useFetchActionVersion(data.versionId || 0)
  const [showOutputCorrection, setShowOutputCorrection] = useState<boolean>(false)

  const issuesCount: { [key: string]: number } = {}
  const actionsCount: { [key: string]: number } = {}

  feedback?.forEach((item) => {
    if (item.type === 'issue') {
      const value = item.value
      if (value) {
        if (issuesCount[value]) {
          issuesCount[value] += 1
        } else {
          issuesCount[value] = 1
        }
      }
    }
  })

  feedback?.forEach((item) => {
    if (item.type === 'action') {
      const value = item.value
      if (value) {
        if (actionsCount[value]) {
          actionsCount[value] += 1
        } else {
          actionsCount[value] = 1
        }
      }
    }
  })

  const corrections = feedback?.filter((item) => item.type === 'correction').map((item) => item)

  const outputCorrection = useMemo(() => {
    return corrections && corrections.length > 0 ? corrections[0]?.value : null
  }, [corrections])

  useEffect(() => {
    setShowOutputCorrection(outputCorrection ? true : false)
  }, [outputCorrection])

  const ratings = {
    positive: 0,
    negative: 0,
  }
  feedback?.forEach((item) => {
    if (item.type === 'rating') {
      if (item.value === '2') {
        ratings.positive += 1
      } else if (item.value === '1') {
        ratings.negative += 1
      }
    }
  })
  const others = feedback?.filter(
    (item) => !['issue', 'action', 'correction', 'rating'].includes(item.type)
  )

  const hasRatingsOrFeedback = () =>
    ratings.positive > 0 ||
    ratings.negative > 0 ||
    (others && others?.length > 0) ||
    Object.keys(issuesCount).length > 0 ||
    Object.keys(actionsCount).length > 0

  const hasInsights = () => dataInsights && dataInsights.length > 0

  const metadata = data.metadata as never as SourceMetadata
  const metadataWithError = data.metadata as DataMetadata
  const modelConfig = metadata.model_config

  const { insights, isLoading: contextisLoading } = useFetchInsightsForData(data.id)
  const { requestAndResponse, isLoading: reqResisLoading } = useFetchRequestResponse(data.id)

  const contextInsights = insights?.filter((insight) => insight.name === 'CONTEXT')
  // this is AI feedback from the DATA_EVAL action
  const dataInsights = insights?.filter((insight) => insight.name === 'DATA_EVAL')

  if (contextInsights?.length === 0 && !contextisLoading) {
    tabs = tabs.filter((tab) => tab.id !== 'context')
  }

  if (
    Object.keys(requestAndResponse?.raw_llm_request || {}).length === 0 &&
    requestAndResponse?.raw_llm_response === '{}' &&
    !reqResisLoading
  ) {
    tabs = tabs.filter((tab) => tab.id !== 'request')
  }

  const feedbackForm = useFeedbackForm({
    data,
    workspace,
    feedback,
    onSuccess: () => {
      void refetchFeedback()
    },
  })

  /* Function to call when row switches */
  useLayoutEffect(() => {
    /* Refetch and reset form */
    void refetchFeedback()
    void feedbackForm.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div>
      <div className="p-4 sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="w-full rounded-md border-grey-300 py-2 pl-3 pr-10 text-base focus:border-grey-500 focus:outline-none focus:ring-grey-500 block"
          defaultValue={tabs.find((tab) => tab.id === selected)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="">
        <div className="border-b border-grey-300">
          <nav className="flex space-x-8 overflow-x-auto" aria-label="Tabs">
            {tabs.map((tab) => {
              return (
                <button
                  key={tab.name}
                  onClick={() => setSelected(tab.id)}
                  className={classNames(
                    selected === tab.id
                      ? 'border-grey-800 text-grey-800'
                      : 'border-transparent text-grey-500 hover:border-grey-300',
                    'whitespace-nowrap border-b-2 py-4 text-sm font-medium'
                  )}
                >
                  {tab.name}
                </button>
              )
            })}
          </nav>
        </div>
      </div>

      {workspace && app && (
        <div className="text-left">
          {selected === 'input' && (
            <div className="">
              <div className="divide-y divide-gray-100">
                <div className="flex flex-col gap-4 py-5">
                  <dt className="text-xs text-grey-500 uppercase">Prompt</dt>
                  <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                    {typeof data.parsedInput === 'string' || data.parsedInput === null ? (
                      data.parsedInput
                    ) : (
                      <div className="flex flex-col gap-2">
                        {Array.isArray(data.parsedInput) ? (
                          <>
                            {(data.parsedInput as { role: string; content: string }[]).map(
                              ({ role, content }, index) => (
                                <MessagePromptRenderer key={index} role={role} content={content} />
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {Object.entries(data.parsedInput).map(([key, value]) => (
                              <div key={key} className="flex flex-col">
                                <div className="text-sm capitalize leading-6 text-grey-600 font-inter flex">
                                  <VariableIcon className="w-5 h-5 pt-1" />
                                  <span>{key}</span>
                                </div>
                                <pre className="text-sm whitespace-pre-wrap leading-6 text-grey-900 select-text font-inter">
                                  {key == 'files' ? (
                                    <InputFilesRenderer value={value as string[]} />
                                  ) : (
                                    <>{typeof value === 'string' ? value : JSON.stringify(value)}</>
                                  )}
                                </pre>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </dd>
                </div>
                <div className="flex flex-col gap-4 py-5">
                  <div className="flex items-center justify-between">
                    <dt className="text-xs text-grey-500 uppercase">Completion</dt>
                  </div>

                  <dd className="text-sm leading-6 text-grey-900 col-span-4 select-text font-inter">
                    <div className="flex justify-between">
                      <div className={showOutputCorrection ? 'hidden' : ''}>
                        <MarkdownRenderer markdownText={data.output} />
                      </div>
                      {outputCorrection && (
                        <>
                          <div className={!showOutputCorrection ? 'hidden' : ''}>
                            <MarkdownRenderer markdownText={outputCorrection} />
                          </div>
                          <InfoTooltip
                            icon={
                              <AcademicCapIcon
                                className="h-5 w-5 text-blue-400"
                                aria-hidden="true"
                              />
                            }
                          >
                            <div
                              className="text-xs cursor-pointer"
                              onMouseEnter={(e) => {
                                setShowOutputCorrection(false)
                                e.stopPropagation()
                              }}
                              onMouseLeave={(e) => {
                                setShowOutputCorrection(true)
                                e.stopPropagation()
                              }}
                            >
                              <p>This is the corrected output.</p>
                              <p className="text-blue-400 ">View original</p>
                            </div>
                          </InfoTooltip>
                        </>
                      )}
                    </div>
                  </dd>
                </div>
                {metadataWithError.error_log && (
                  <ErrorRenderer metadata={metadataWithError} model={data.model || ''} />
                )}
                <div id="datapoint-feedback-open" className="w-full" />

                {feedbackForm.state.isRefresh ? null : (
                  <div className="flex flex-col gap-2 py-5">
                    <div className="flex items-center justify-between">
                      <dt className="text-xs text-grey-500 uppercase">Feedback</dt>
                    </div>
                    {feedbackForm.render()}
                  </div>
                )}

                <Accordion.Root
                  key={'feedback'}
                  type="multiple"
                  defaultValue={['existing_feedback', ...(hasInsights() ? ['eval_insights'] : [])]}
                >
                  {hasInsights() && dataInsights && (
                    <Accordion.Item value="eval_insights">
                      <Accordion.Trigger className="text-zinc-700 p-0 py-6">
                        AI Feedback
                      </Accordion.Trigger>
                      <Accordion.Content>
                        <AIInsights data={data} insights={dataInsights} />
                      </Accordion.Content>
                    </Accordion.Item>
                  )}

                  {hasRatingsOrFeedback() && (
                    <Accordion.Item value="existing_feedback">
                      <Accordion.Trigger className="text-zinc-700 p-0 py-6">
                        Feedback
                      </Accordion.Trigger>
                      <Accordion.Content>
                        {!hasRatingsOrFeedback() ? (
                          <div className="py-2 sm:grid sm:gap-4">
                            <div className="rounded-md bg-grey-50 px-4 py-5 sm:p-6">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <InformationCircleIcon
                                    className="h-5 w-5 text-blue-400"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                  <p className="text-sm text-grey-700">No feedback provided.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {ratings && (ratings.positive > 0 || ratings.negative > 0) && (
                              <div>
                                <div className="py-2 grid grid-cols-5 gap-4">
                                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                    Ratings
                                  </dt>
                                  <dd className="mt-1 flex items-center text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                                    <div
                                      className={classNames(
                                        'inline-flex h-6 w-6 items-center justify-center rounded-md border',
                                        ratings.positive > 0
                                          ? 'border-green-300 bg-green-200'
                                          : 'border-zinc-300 bg-zinc-200'
                                      )}
                                    >
                                      <HandThumbUpIcon
                                        className={classNames(
                                          'h-4 w-4',
                                          ratings.positive > 0 ? 'text-green-800' : 'text-zinc-600'
                                        )}
                                      />
                                    </div>
                                    <span className="mx-1 text-xs font-medium text-gray-500">
                                      x {ratings.positive}
                                    </span>
                                    <div
                                      className={classNames(
                                        'inline-flex h-6 w-6 items-center justify-center rounded-md border',
                                        ratings.negative > 0
                                          ? 'border-red-300 bg-red-200'
                                          : 'border-zinc-300 bg-zinc-200'
                                      )}
                                    >
                                      <HandThumbDownIcon
                                        className={classNames(
                                          'h-4 w-4',
                                          ratings.negative > 0 ? 'text-red-800' : 'text-zinc-600'
                                        )}
                                      />
                                    </div>
                                    <span className="mx-1 text-xs font-medium text-gray-700">
                                      x {ratings.negative}
                                    </span>
                                  </dd>
                                </div>
                              </div>
                            )}
                            {issuesCount && Object.keys(issuesCount).length > 0 && (
                              <div className="border-b border-gray-100">
                                <div className="py-2 grid grid-cols-5 gap-4">
                                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                    Issues
                                  </dt>
                                  <dd className="mt-1 flex items-center text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                                    {Object.entries(issuesCount).map(([type, count]) => (
                                      <span
                                        key={type}
                                        className="mb-2 mr-2 inline-flex items-center rounded bg-blue-100 px-2 py-1 text-xs font-medium text-zinc-800"
                                      >
                                        {type} ({count})
                                      </span>
                                    ))}
                                  </dd>
                                </div>
                              </div>
                            )}
                            {actionsCount && Object.keys(actionsCount).length > 0 && (
                              <div className="border-b border-gray-100">
                                <div className="py-2 grid grid-cols-5 gap-4">
                                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                    Actions
                                  </dt>
                                  <dd className="mt-1 flex items-center text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                                    {Object.entries(actionsCount).map(([type, count]) => (
                                      <span
                                        key={type}
                                        className="mb-2 mr-2 inline-flex items-center rounded bg-gray-100 px-2 py-1 text-xs font-medium text-zinc-800"
                                      >
                                        {type} ({count})
                                      </span>
                                    ))}
                                  </dd>
                                </div>
                              </div>
                            )}
                            {corrections && corrections.length > 0 && (
                              <div className="border-b border-gray-100">
                                <div className="py-2 grid grid-cols-5 gap-4">
                                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                    Correction
                                  </dt>
                                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4"></dd>
                                </div>
                                {corrections?.map((item) => (
                                  <div
                                    key={item.id}
                                    className="py-2 sm:grid sm:grid-cols-3 sm:gap-4"
                                  >
                                    <dt className="text-sm font-medium leading-6 text-gray-900 col-span-1">
                                      {item.user && (
                                        <div className="inline-flex">
                                          <UserAvatar className="w-4 h-4" user={item?.user} />
                                          <span className="ml-3">{item.user.name}</span>
                                        </div>
                                      )}
                                      {!item.user && <span>User ID: {item.createdById}</span>}
                                    </dt>
                                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                                      <div className="flex items-center">
                                        <MarkdownRenderer markdownText={item.value} />
                                      </div>
                                    </dd>
                                  </div>
                                ))}
                              </div>
                            )}

                            {isLoading && <div>Loading all feedback...</div>}
                            {!isLoading && others && others.length > 0 && (
                              <div className="border-b border-gray-100">
                                {others?.map((item) => (
                                  <div key={item.id} className="px-4 py-2">
                                    <div className="py-2 grid grid-cols-5 gap-4">
                                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                        Source
                                      </dt>
                                      <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                                        <div className="flex items-center">
                                          <span className="inline-flex items-center rounded bg-zinc-100 px-2 py-0.5 text-xs font-medium text-zinc-800">
                                            {item.source}
                                          </span>
                                        </div>
                                      </dd>
                                    </div>
                                    <div className="py-2 grid grid-cols-5 gap-4">
                                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                        Type
                                      </dt>
                                      <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                                        <div className="flex items-center">
                                          <span className="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-zinc-800">
                                            {item.type}
                                          </span>
                                        </div>
                                      </dd>
                                    </div>
                                    <div className="py-2 grid grid-cols-5 gap-4">
                                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                        Value
                                      </dt>
                                      <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                                        {item.value}
                                      </dd>
                                    </div>
                                    {item.user && (
                                      <div className="py-2 grid grid-cols-5 gap-4">
                                        <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                          Created by
                                        </dt>
                                        <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                                          <div className="flex items-center">
                                            <UserAvatar className="w-4 h-4" user={item?.user} />
                                            <span className="ml-3">{item.user.name}</span>
                                          </div>
                                        </dd>
                                      </div>
                                    )}
                                    {!item.user && (
                                      <div className="py-2 grid grid-cols-5 gap-4">
                                        <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                          Created by
                                        </dt>
                                        <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                                          <div className="flex items-center">
                                            <span>{item.createdById}</span>
                                          </div>
                                        </dd>
                                      </div>
                                    )}
                                    <div className="py-2 grid grid-cols-5 gap-4">
                                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                                        Metadata
                                      </dt>
                                      <div className="flex flex-col gap-2">
                                        {Object.entries(item.metadata as object).map(
                                          ([key, value]) => (
                                            <div key={key} className="flex flex-col">
                                              <span className="text-sm font-medium leading-6 text-gray-900">
                                                {key}
                                              </span>
                                              <span className="text-sm leading-6 text-gray-900">
                                                {value as string}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </Accordion.Content>
                    </Accordion.Item>
                  )}
                  {<DataMonitorResults data={data} />}
                </Accordion.Root>

                <Room id={`data-${data.id}`}>
                  <DataComments />
                </Room>
              </div>
            </div>
          )}
          {selected === 'details' && (
            <div>
              <div className="divide-y divide-gray-100">
                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="p-4 text-sm font-medium leading-6 text-gray-900">
                    Action/Workflow
                  </dt>
                  <dd className="mt-1 p-4 text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                    {data.agent.name}
                  </dd>
                </div>
                {data.experiment && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="p-4 text-sm font-medium leading-6 text-gray-900">Experiment</dt>
                    <dd className="mt-1 p-4 text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                      {data.experiment.name}
                    </dd>
                  </div>
                )}
                {data.run && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="p-4 text-sm font-medium leading-6 text-gray-900">Run</dt>
                    <dd className="mt-1 p-4 text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                      <div className="flex items-center">
                        <CopyButton text={data.run.guid} />
                        <div className="ml-2 text-xs">{data.run.guid}</div>
                      </div>
                    </dd>
                  </div>
                )}
                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="p-4 text-sm font-medium leading-6 text-gray-900">Delete</dt>
                  <dd className="mt-1 p-4 text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                    <Button
                      variant="destructive"
                      onClick={() => {
                        setOpenDelete(true)
                        setDrawerOpen(false)
                      }}
                    >
                      <TrashIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      Delete data
                    </Button>
                    <p className="mt-3 rounded-full bg-grey-50 p-2 px-3 font-mono text-xs">
                      Warning: This cannot be undone, proceed with caution
                    </p>
                  </dd>
                </div>
              </div>
            </div>
          )}
          {selected === 'metadata' && (
            <div>
              <div className="divide-y py-5 divide-gray-100">
                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Timestamp</dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex items-center">
                      {new Date(data.createdAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </div>
                  </dd>
                </div>

                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Action</dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex items-center">
                      <Link
                        className="inline-flex items-center space-x-2"
                        href={`/${workspace.slug}/apps/${app.slug}/actions/${data.agent.slug || ''}`}
                      >
                        <span>{data.agent.name}</span>
                      </Link>
                    </div>
                  </dd>
                </div>
                {data.versionId !== null && !versionIsLoading && version && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Version</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">
                        {data.versionId !== null && !versionIsLoading && version && (
                          <span>
                            {version.name
                              ? `${version.name} #${version.version_number}`
                              : `Version #${version.version_number}`}
                          </span>
                        )}
                      </div>
                    </dd>
                  </div>
                )}
                {metadataWithError.environment && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Environment</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">
                        <span>{metadataWithError.environment}</span>
                      </div>
                    </dd>
                  </div>
                )}

                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Source</dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex items-center">
                      <span className="inline-flex items-center">
                        {metadata?.source === 'EVAL_SERVICE'
                          ? 'Eval'
                          : metadata?.source === 'Klu'
                            ? 'Studio'
                            : metadata?.source
                              ? metadata.source
                              : ''}
                      </span>
                    </div>
                  </dd>
                </div>

                {data.model_provider && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Provider</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{data.model_provider}</div>
                    </dd>
                  </div>
                )}

                {data.model && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Model</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{data.model}</div>
                    </dd>
                  </div>
                )}

                {modelConfig?.temperature !== undefined && modelConfig?.temperature !== null && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Temperature</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{modelConfig?.temperature}</div>
                    </dd>
                  </div>
                )}

                {modelConfig?.frequencyPenalty !== undefined &&
                  modelConfig?.frequencyPenalty !== null && (
                    <div className="py-2 grid grid-cols-5 gap-4">
                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                        Frequency Penalty
                      </dt>
                      <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                        <div className="flex items-center">{modelConfig?.frequencyPenalty}</div>
                      </dd>
                    </div>
                  )}

                {modelConfig?.presencePenalty !== undefined &&
                  modelConfig?.presencePenalty !== null && (
                    <div className="py-2 grid grid-cols-5 gap-4">
                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                        Presence Penalty
                      </dt>
                      <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                        <div className="flex items-center">{modelConfig?.presencePenalty}</div>
                      </dd>
                    </div>
                  )}

                {modelConfig?.maxResponseLength !== null &&
                  modelConfig?.maxResponseLength !== undefined && (
                    <div className="py-2 grid grid-cols-5 gap-4">
                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                        Max Response Length
                      </dt>
                      <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                        <div className="flex items-center">{modelConfig?.maxResponseLength}</div>
                      </dd>
                    </div>
                  )}

                {modelConfig?.stopSequence !== null && modelConfig?.stopSequence !== undefined && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                      Stop Sequence
                    </dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{modelConfig?.stopSequence}</div>
                    </dd>
                  </div>
                )}

                {modelConfig?.responseFormat !== null &&
                  modelConfig?.responseFormat !== undefined && (
                    <div className="py-2 grid grid-cols-5 gap-4">
                      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                        Response Format
                      </dt>
                      <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                        <div className="flex items-center">{modelConfig?.responseFormat}</div>
                      </dd>
                    </div>
                  )}

                {modelConfig?.timeout !== undefined && modelConfig?.timeout !== null && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Timeout</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{modelConfig?.timeout}s</div>
                    </dd>
                  </div>
                )}

                {modelConfig?.seed !== undefined && modelConfig?.seed !== null && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Seed</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{modelConfig?.seed}</div>
                    </dd>
                  </div>
                )}

                {modelConfig?.topP !== undefined && modelConfig?.topP !== null && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Top P</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{modelConfig?.topP}</div>
                    </dd>
                  </div>
                )}

                {modelConfig?.logitBias !== undefined && modelConfig?.logitBias !== null && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Logit Bias</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <LogitBiasRenderer logitBias={modelConfig?.logitBias} />
                    </dd>
                  </div>
                )}

                {data.generation_cost !== null && data.generation_cost !== undefined && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Cost</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">
                        ${Number(data.generation_cost).toFixed(3)}
                      </div>
                    </dd>
                  </div>
                )}

                {metadata?.source != 'Proxy' && data.latency ? (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Latency</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      {data.latency ? Math.round(data.latency / 1000) : '-'} ms
                    </dd>
                  </div>
                ) : (
                  <></>
                )}

                {data.num_input_tokens ? (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Input Tokens</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      {data.num_input_tokens}
                    </dd>
                  </div>
                ) : (
                  <></>
                )}

                {data.num_output_tokens ? (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                      Output Tokens
                    </dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      {data.num_output_tokens}
                    </dd>
                  </div>
                ) : (
                  <></>
                )}

                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 pt-1">
                    {metadata?.source === 'API' || metadata?.source === 'Proxy'
                      ? 'API Key Owner'
                      : 'Created by'}
                  </dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex items-center">{data.createdBy.name}</div>
                  </dd>
                </div>

                {metadata?.debug_log && (
                  <div className="py-2 grid grid-cols-5 gap-4">
                    <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Debug Log</dt>
                    <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                      <div className="flex items-center">{metadata.debug_log}</div>
                    </dd>
                  </div>
                )}
              </div>
            </div>
          )}
          {selected === 'context' && <ContextInsights data={data} />}
          {selected === 'prompt' && (
            <div className="py-5">
              <RenderPrompt dataId={data.id} />
            </div>
          )}
          {selected === 'comments' && (
            <Room id={`data-${data.id}`}>
              <DataComments />
            </Room>
          )}
          {selected === 'request' && (
            <RenderRequestResponse
              raw_llm_request={requestAndResponse?.raw_llm_request as Record<string, unknown>}
              raw_llm_response={requestAndResponse?.raw_llm_response as string}
            />
          )}
        </div>
      )}
    </div>
  )
}

const DataComments = () => {
  const { threads } = useThreads()

  return (
    <div className="mt-4 text-sm">
      {threads.map((thread) => (
        <div className="p-4 rounded-lg border bg-white shadow-sm mb-4" key={thread.id}>
          <Thread thread={thread} showResolveAction={true} />
        </div>
      ))}
      <div className="">
        <Composer className="mt-4 rounded-lg border bg-white shadow-sm" />
      </div>
    </div>
  )
}

export const InputFilesRenderer = ({ value }: { value: string[] | { url: string }[] }) => {
  const fileUrls = value.map((item) => {
    const url = typeof item === 'string' ? item : item.url

    return sanitizeImageURL(url)
  })

  return (
    <div className="flex gap-2">
      {fileUrls.map((url, i) => (
        <div key={`input-file-${i}`}>
          <img src={url} alt="content" className="max-w-full max-h-[33vh]" />
        </div>
      ))}
    </div>
  )
}

export const MessagePromptRenderer = ({
  role,
  content,
}: {
  role: string
  content: (MessageContentItem | RefusalContentItem)[] | string | null
}) => {
  return (
    <>
      <div className="text-sm capitalize leading-6 text-grey-600 font-inter flex">
        <MessageIcon className="w-5 h-5 pt-1 mr-1" />
        <span>{role}</span>
      </div>
      {Array.isArray(content) && role !== 'assistant'
        ? content.map((item, idx) => (
            <div key={idx}>
              {item.type === 'text' && (
                <pre className="text-sm whitespace-pre-wrap leading-6 text-grey-900 select-text font-inter">
                  {item.text}
                </pre>
              )}
              {item.type === 'image_url' && item.image_url && (
                <InputFilesRenderer value={[item.image_url]} />
              )}
            </div>
          ))
        : typeof content === 'string' && (
            <pre className="text-sm whitespace-pre-wrap font-inter text-grey-900 select-text">
              {content || ''}
            </pre>
          )}
    </>
  )
}

function ContextInsights({ data }: { data: DetailedData }) {
  const { insights, isLoading } = useFetchInsightsForData(data.id)

  if (isLoading) {
    return (
      <div className="flex items-center justify-center pt-6">
        <Loader className="h-6 w-6 text-grey-400" />
      </div>
    )
  }

  const contextInsights = insights?.filter((insight) => insight.name === 'CONTEXT')

  if (!contextInsights) {
    return null
  }

  if (contextInsights.length === 0) {
    return (
      <div className="py-4">
        <div className="flex items-center">No context provided</div>
      </div>
    )
  }

  return (
    <>
      {contextInsights.map((insight) => {
        const metadata = insight.metadata as ContextInsightMetadata
        return (
          <div key={insight.guid} className="mt-3">
            <div className="divide-y divide-gray-100">
              {/* 

            // I WANT TO NAME THE CONTEXT LIBRARY USED FOR THE RETRIEVAL

            // TODO: context nodes could come from multiple contexts, 
            // if you want to list the context library, we should probably do it per node below

            <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Context Source</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {metadata?.contextSettings?. || ''}
                  </div>
                </dd>
              </div>
            
            */}

              <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Retrieval Mode</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {metadata?.contextSettings?.responseMode === 'no_text'
                      ? 'Search'
                      : metadata?.contextSettings?.responseMode || ''}
                  </div>
                </dd>
              </div>

              <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Latency</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {insight.latency ? Math.round(insight.latency / 1000) : '-'} ms
                  </div>
                </dd>
              </div>

              <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Context Length</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {metadata?.contextSettings?.responseLength || ''}
                  </div>
                </dd>
              </div>

              <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">
                  Number of Results
                </dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {metadata?.contextSettings?.similarityTopK || ''}
                  </div>
                </dd>
              </div>

              {/* 

              // These don't change per retrieval 

              <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Chunk Size</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {metadata?.contextSettings?.chunkSize || ''}
                  </div>
                </dd>
              </div>

              <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Chunk Overlap</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {metadata?.contextSettings?.chunkOverlap || ''}
                  </div>
                </dd>
              </div>

              <div className="py-2 grid grid-cols-5 gap-4">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Splitter</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex items-center">
                    {metadata?.contextSettings?.splitter || ''}
                  </div>
                </dd>
              </div>

              */}

              {metadata?.nodes && (
                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Documents</dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex flex-col gap-6">
                      {metadata?.nodes?.map((node) => (
                        <RetrievedDocumentNode
                          key={node.guid}
                          node={node}
                          showContextLibraryName={true}
                        />
                      ))}
                    </div>
                  </dd>
                </div>
              )}

              <div className="py-2 grid grid-cols-5 gap-4 my-10">
                <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1">Full Context</dt>
                <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                  <div className="flex flex-wrap overflow-auto">
                    <MarkdownRenderer markdownText={`\`\`\`md\n${insight.content}\n\`\`\``} />
                  </div>
                </dd>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export function RenderPrompt({ dataId }: { dataId: number }) {
  const { prompt, isLoading } = useFetchFullPrompt(dataId)

  let fullPromptAsJson: Message[] = []
  let fullPromptAsString = ''

  try {
    fullPromptAsJson = prompt ? (JSON.parse(prompt.full_prompt_sent || '[]') as Message[]) : []
  } catch (error) {
    console.error('Failed to parse JSON, outputting the string itself: ', error)
    fullPromptAsString = prompt?.full_prompt_sent ?? ''
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center pt-6">
        <Loader className="h-6 w-6 text-grey-400" />
      </div>
    )
  }

  if (!prompt) {
    return <div className="flex items-center justify-center pt-6">No prompt provided</div>
  }

  return (
    <>
      <div>
        <div className="divide-y divide-gray-100">
          <div className="sm:grid sm:gap-4">
            <dd className="text-sm leading-6 text-gray-700 col-span space-y-5">
              {Array.isArray(fullPromptAsJson) && fullPromptAsJson.length > 0 ? (
                fullPromptAsJson.map(({ role, content }, index) => (
                  <>
                    <MessagePromptRenderer key={index} role={role} content={content!} />
                  </>
                ))
              ) : (
                <span className="text-sm leading-6 text-grey-900 select-text font-inter">
                  {fullPromptAsString}
                </span>
              )}
            </dd>
          </div>
        </div>
      </div>
    </>
  )
}

function RenderRequestResponse({
  raw_llm_request,
  raw_llm_response,
}: {
  raw_llm_request: Record<string, unknown>
  raw_llm_response: string
}) {
  interface TempResponse {
    stream: {
      id: string
      choices: {
        delta: {
          content: string
        }
        finish_reason: string
        index: number
      }[]
      created: number
      model: string
      object: string
    }[]
  }

  let processedRequest
  let processedResponse

  const replaceImageUrlWithPlaceholder = (content: MessageContentItem[]) => {
    return content.map((item) => {
      if (item.type === 'image_url' && item.image_url) {
        return { type: 'image_url', image_url: { url: 'BASE_64 ENCODED IMAGE' } }
      }
      return item
    })
  }
  if (raw_llm_request) {
    const tempRequest = raw_llm_request
    const messages = raw_llm_request.messages as Message[]
    if (Array.isArray(messages)) {
      const processedLLMPayload = messages.map((item) => {
        if (Array.isArray(item.content) && item.role !== 'assistant') {
          return {
            role: item.role,
            content: replaceImageUrlWithPlaceholder(item.content),
          }
        }
        return item
      })
      tempRequest.messages = processedLLMPayload
    }
    processedRequest = JSON.stringify(tempRequest, null, 2)
  }
  let finalResponse

  if (raw_llm_response) {
    try {
      const parsedResponse = JSON.parse(raw_llm_response) as Record<string, any>
      if (parsedResponse.hasOwnProperty('stream')) {
        const tempResponse = JSON.parse(raw_llm_response) as TempResponse
        const chunk1 = tempResponse.stream[0]
        const lastChunk = tempResponse.stream[tempResponse.stream.length - 1]

        const content = tempResponse.stream
          .map((chunk) => chunk.choices[0]?.delta?.content)
          .join('')

        const finish_reason =
          lastChunk?.choices && lastChunk.choices[0]?.finish_reason
            ? lastChunk.choices[0].finish_reason
            : 'stop'

        const createdAt = chunk1?.created ?? 0

        const skeletonCompletion = {
          id: chunk1 !== undefined ? chunk1.id : '',
          object: 'chat.completion',
          created: createdAt,
          model: chunk1 !== undefined ? chunk1.model : '',
          choices: [
            {
              index: 0,
              message: {
                role: 'assistant',
                content: content,
              },
              logprobs: null,
              finish_reason: finish_reason,
            },
          ],
        }
        processedResponse = JSON.stringify(skeletonCompletion, null, 2)
      } else {
        try {
          finalResponse = JSON.parse(JSON.parse(raw_llm_response || '{}') as string) as Record<
            string,
            any
          >
        } catch (error) {
          console.error('failed to parse twice, trying once')
          try {
            finalResponse = JSON.parse(raw_llm_response || '{}') as Record<string, any>
          } catch {
            finalResponse = raw_llm_response
          }
        }
        processedResponse = JSON.stringify(finalResponse, null, 2)
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (!raw_llm_request && !raw_llm_response) {
    return null
  }

  return (
    <>
      <div>
        <div className="divide-y divide-gray-100">
          <div className="sm:grid sm:gap-4 py-5">
            <div className="flex flex-col gap-4 py-5">
              <dt className="text-xs text-grey-500 uppercase">Request</dt>
              <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                <MarkdownRenderer
                  markdownText={`\`\`\`json\n${processedRequest || '{}'}\n\`\`\``}
                />
              </dd>
            </div>
            <div className="flex flex-col gap-4 py-5">
              <dt className="text-xs text-grey-500 uppercase">Response</dt>
              <dd className="text-sm leading-6 text-grey-900 col-span-4 font-inter select-text">
                <MarkdownRenderer
                  markdownText={`\`\`\`json\n${processedResponse || '{}'}\n\`\`\``}
                />
              </dd>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function AIInsights({ insights }: { data: DetailedData; insights: DataInsight[] }) {
  return (
    <>
      {insights.map((insight, i) => {
        const metadata = insight.metadata as EvalInsightMetadata
        return (
          <div key={i}>
            <div className="divide-y divide-gray-100">
              {metadata.helpfulness && (
                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1 flex items-center">
                    Helpfulness
                  </dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex items-center space-x-3">
                      <Badge>{Number(metadata.helpfulness_score)}</Badge>
                      <span>{metadata.helpfulness}</span>
                    </div>
                  </dd>
                </div>
              )}

              {metadata.sentiment && (
                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1 flex items-center">
                    Sentiment
                  </dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex items-center space-x-3">
                      <Badge>{Number(metadata.sentiment_score)}</Badge>
                      <span>{metadata.sentiment}</span>
                    </div>
                  </dd>
                </div>
              )}

              {metadata.moderation && (
                <div className="py-2 grid grid-cols-5 gap-4">
                  <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1 flex items-center">
                    Moderation
                  </dt>
                  <dd className="text-sm text-gray-900 col-span-3 sm:col-span-4">
                    <div className="flex items-center">
                      <span
                        className={`${metadata.moderation !== 'Approved' ? 'text-red-600' : ''}`}
                      >
                        {metadata.moderation === 'Approved'
                          ? 'Moderation not needed'
                          : 'Flagged for moderation'}
                      </span>
                      {/* <Badge>{Number(metadata.moderation_score)}</Badge> */}
                    </div>
                  </dd>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

interface IParams extends ParsedUrlQueryInput {
  slug: string
}

export function DatatableDrawer({
  row,
  app,
  workspace,
  open,
  setOpen,
  selectNextRow,
  selectPreviousRow,
  hasPreviousRow,
  hasNextRow,
}: {
  row: Row<DetailedData>
  app: App
  workspace: Workspace
  open: boolean
  setOpen: (open: boolean) => void
  selectNextRow: () => void
  selectPreviousRow: () => void
  hasPreviousRow: boolean
  hasNextRow: boolean
}) {
  const dataRecord = row.original
  const [openDelete, setOpenDelete] = useState(false)
  const [openAddDataModal, setAddDataModalOpen] = useState(false)

  const router = useRouter()

  const url = new URL(window.location.href)
  const params = url.searchParams
  const { slug } = router.query as IParams
  params.set('guid', dataRecord.guid)
  const dataUrl = url.toString()

  const studioDataUrl = `/${workspace.slug}/apps/${slug}/actions/${dataRecord.agent.slug || ''}/studio?dataGuid=${dataRecord.guid}`

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the event's target is an input field
      if (event.target instanceof HTMLInputElement) {
        // If it is, stop the execution of the event handler
        return
      }

      /* Disallow keyboard shortcuts when the user is typing in the comment box */
      const commentEl = document.getElementsByClassName('lb-composer-editor')[0]
      /* Dissaallow keyboard shortcuts when the user is typing in the correction/feedback textarea box */
      const correctionEl = document.getElementById('correction')
      const isActive =
        commentEl === document.activeElement || correctionEl === document.activeElement

      if (isActive) return

      if (event.key === 'w' || event.key === 'W') {
        if (!hasPreviousRow) return
        selectPreviousRow()
        return
      }

      if (event.key === 's' || event.key === 'S') {
        if (!hasNextRow) return
        selectNextRow()
        return
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [hasNextRow, hasPreviousRow, row, selectNextRow, selectPreviousRow])

  return (
    <>
      <DeleteDataModal open={openDelete} setOpen={setOpenDelete} data={dataRecord} />
      <AddDataModal
        workspace={workspace}
        app={app}
        isOpen={openAddDataModal}
        onIsOpenChange={setAddDataModalOpen}
        dataIds={[dataRecord.id]}
        onAdded={() => null}
      />
      <Sheet open={open} onOpenChange={setOpen} modal={false}>
        <SheetContent
          className="max-w-4xl sm:max-w-3xl"
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          <SheetHeader>
            <SheetTitle className="">
              <div className="flex items-center space-x-2" id="drawer-top">
                <Tooltip.Root delayDuration={0} disableHoverableContent={true}>
                  <Tooltip.Trigger asChild>
                    <button
                      type="button"
                      onClick={selectPreviousRow}
                      className={`p-2 focus:border-none focus:outline-none focus:ring-0 ${
                        !hasPreviousRow ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={!hasPreviousRow}
                    >
                      <ChevronUpIcon className="h-4 w-4 text-grey-700" />
                    </button>
                  </Tooltip.Trigger>
                  {hasPreviousRow ? (
                    <Tooltip.Content
                      side="bottom"
                      align="start"
                      className="font-normal flex items-center gap-2.5"
                    >
                      <span>Go to previous data point</span>
                      <div className="inline-block min-w-3 rounded-md bg-grey-100 ring-1 ring-grey-200 px-1 py-1 text-center text-xs">
                        W
                      </div>
                    </Tooltip.Content>
                  ) : null}
                </Tooltip.Root>
                <Tooltip.Root delayDuration={0}>
                  <Tooltip.Trigger asChild>
                    <button
                      type="button"
                      onClick={selectNextRow}
                      className={`p-2 focus:border-none focus:outline-none focus:ring-0 ${
                        !hasNextRow ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={!hasNextRow}
                    >
                      <ChevronDownIcon className="h-4 w-4 text-grey-700" />
                    </button>
                  </Tooltip.Trigger>
                  {hasNextRow ? (
                    <Tooltip.Content
                      side="bottom"
                      align="start"
                      className="font-normal flex items-center gap-2.5"
                    >
                      <span>Go to next data point</span>
                      <div className="inline-block min-w-3 rounded-md bg-grey-100 ring-1 ring-grey-200 px-1 py-1 text-center text-xs">
                        S
                      </div>
                    </Tooltip.Content>
                  ) : null}
                </Tooltip.Root>
              </div>
              <div className="mt-5 flex justify-between">
                <div>
                  <div className="flex flex-col sm:flex-row text-left space-x-2">
                    <div className="flex items-center">
                      <Link
                        className="flex items-center group"
                        href={`/${workspace.slug}/apps/${app.slug}/actions/${dataRecord.agent.slug || ''}`}
                      >
                        <span className="text-xs text-grey-700 font-medium group-hover:text-primary-600 duration-200 ease-in-out">
                          {dataRecord.agent.name} &rarr;
                        </span>
                      </Link>
                    </div>
                    <span className="text-xs text-grey-500 font-light">
                      Generated {dateAgo(new Date(dataRecord.createdAt))}
                    </span>
                  </div>
                  <div className="mt-1 text-sm leading-6 text-gray-700 col-span-2 sm:mt-0">
                    <div className="flex items-center">
                      {/* <span className="text-xs uppercase font-l">Data ID</span> */}
                      <div className="font-mono font-light text-xs">{dataRecord.guid}</div>
                      <div className="ml-2">
                        <CopyButton text={dataUrl} icon={LinkIcon} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between space-x-3">
                  <Vote data={dataRecord} app={app} workspace={workspace} />

                  <Tooltip.Root delayDuration={333}>
                    <Tooltip.Trigger asChild>
                      <button
                        type="button"
                        onClick={() => {
                          setAddDataModalOpen(true)
                        }}
                        className="bg-white hover:bg-grey-50 hover:border-grey-400 cursor-pointer inline-flex h-9 w-9 items-center justify-center rounded-md border"
                      >
                        <AddDataIcon className="h-5 w-5 text-grey-700" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Content
                      sideOffset={5}
                      side="bottom"
                      align="end"
                      className="font-normal"
                    >
                      Add to a dataset
                    </Tooltip.Content>
                  </Tooltip.Root>

                  <Tooltip.Root delayDuration={333}>
                    <Tooltip.Trigger asChild>
                      <button
                        type="button"
                        onClick={() => {
                          void router.push(studioDataUrl)
                        }}
                        className="bg-white hover:bg-grey-50 hover:border-grey-400 cursor-pointer inline-flex h-9 w-9 items-center justify-center rounded-md border"
                      >
                        <StudioIcon className="h-5 w-5 text-grey-700" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Content
                      sideOffset={5}
                      side="bottom"
                      align="end"
                      className="font-normal"
                    >
                      Open in Studio
                    </Tooltip.Content>
                  </Tooltip.Root>
                </div>
              </div>
            </SheetTitle>
            <SheetDescription>
              <DataRowOverview
                data={dataRecord}
                app={app}
                workspace={workspace}
                setDrawerOpen={setOpen}
                setOpenDelete={setOpenDelete}
              />
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  )
}

const DataMonitorResults = ({ data }: { data: DetailedData }) => {
  const { evalRunItems } = useFetchEvalRunItemsForData({
    dataId: data.id,
    dataType: 'LIVE',
  })

  if (!evalRunItems || evalRunItems.length === 0) {
    return null
  }

  return (
    <Accordion.Item value="eval_monitors">
      <Accordion.Trigger className="text-zinc-700 p-0 py-6">Monitors</Accordion.Trigger>
      <Accordion.Content>
        <div className="divide-y divide-gray-100">
          {evalRunItems?.map((evalRunItem) => (
            <>
              {evalRunItem.results.map((result) => (
                <MonitorResultDetail
                  key={result.id}
                  result={result}
                  evalRunItem={evalRunItem}
                  actionSlug={data.agent.slug || ''}
                />
              ))}
            </>
          ))}
        </div>
      </Accordion.Content>
    </Accordion.Item>
  )
}

const MonitorResultDetail = ({
  result,
  evalRunItem,
  actionSlug,
}: {
  result: DetailedEvalRunItemResult
  evalRunItem: DetailedEvalRunItem
  actionSlug: string
}) => {
  const workspace = useWorkspace()
  const { slug, app } = useCurrentApp()
  const { passed, score, reason, evalOnEvalType } = result

  if (!app || !workspace) {
    return null
  }

  return (
    <div className="py-2 grid grid-cols-5 gap-4 items-top">
      <dt className="text-xs text-gray-700 col-span-2 sm:col-span-1 flex flex-col items-top gap-2">
        <Link
          href={`/${workspace.slug}/apps/${slug as string}/actions/${actionSlug}/evals?selectedEvalId=${evalRunItem.evalId.toString()}`}
          className="hover:text-primary-600 cursor-pointer"
          target="_blank"
        >
          {evalOnEvalType.name}
        </Link>
      </dt>
      <dd className="text-xs text-gray-900 col-span-3 sm:col-span-4 flex flex-col items-top gap-2">
        <div className="flex items-center">
          {passed ? (
            <div className="flex items-center gap-2">
              <CheckCircleIcon className="h-4 w-4 text-green-500" /> Passed
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <ExclamationCircleIcon className="h-4 w-4 text-red-500" /> Failed
            </div>
          )}
          {score ? (
            <span className="ml-2 rounded-full bg-grey-50 p-2 px-3 font-mono text-xs">
              Score: {score}
            </span>
          ) : null}
        </div>
        <div className="text-xs text-gray-600">{reason}</div>
      </dd>
    </div>
  )
}
