import { useCallback, useEffect, useState } from 'react'
import { Input, Label, Popover, toast } from '@/client/components'
import { useStudio } from '@/client/containers/views/Studio/context'
import { cn } from '@/client/utils'
import { UserAvatar } from '@/common/components'
import { api, formatDataInTimezone } from '@/utils'

export const ActionName = () => {
  const {
    state: { action },
  } = useStudio()
  const [actionName, setActionName] = useState(action?.name || 'Untitled')
  useEffect(() => {
    if (action?.name) {
      setActionName(action.name)
    }
  }, [action?.name])

  const utils = api.useContext()

  const renameAction = api.action.rename.useMutation()

  const submit = useCallback(async () => {
    if (!action) {
      throw new Error("Can't rename action when action doesn't exist")
    }

    if (actionName === action.name) {
      return
    }

    if (actionName.length === 0) {
      toast.error({
        title: 'Action name is required',
        description: 'Please provide a name for the action',
      })
      return
    }

    const response = await renameAction.mutateAsync({
      actionId: action.id,
      name: actionName,
    })
    toast.success({
      title: `${actionName} renamed`,
      description: `Successfully renamed the action to ${response.name}`,
    })
    void utils.action.get.invalidate({ id: action.id })
    void utils.action.getBySlug.invalidate({ slug: action.slug })
  }, [action, actionName, renameAction, utils.action.get, utils.action.getBySlug])

  if (!action) {
    return <div className={'body2 cursor-pointer'}>Untitled New Action</div>
  }

  return (
    <Popover.Root>
      <Popover.Trigger className="flex items-center gap-2 group">
        <div className="flex items-center max-w-xs">
          <p className="font-medium truncate">
            {actionName || actionName.length > 0 ? actionName : 'Untitled'}
          </p>
        </div>
      </Popover.Trigger>
      <Popover.Content className="max-w-lg p-4">
        <Input
          className={cn('font-medium p-0 border-gray-200')}
          id={'action-name'}
          label={'Name'}
          autoComplete="off"
          placeholder={action.name ?? 'Untitled'}
          onBlur={() => {
            if (action?.name !== actionName) {
              void submit()
            }
          }}
          value={actionName}
          onChange={(e) => setActionName(e.target.value)}
        />
        <div className="flex flex-col gap-2 ">
          <Label title="Owner" />
          <div className="flex items-center gap-2 pl-1">
            <UserAvatar className="w-5 h-5" user={action.createdBy} />
            <p className="text-sm text-grey-400">{action.createdBy?.name}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-1">
          <div className="flex flex-col gap-1">
            <Label title="Updated" />
            <p className="text-sm text-grey-400">
              {formatDataInTimezone(action.updatedAt).toDateString()}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <Label title="Created" />
            <p className="text-sm text-grey-400">
              {formatDataInTimezone(action.createdAt).toDateString()}
            </p>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}
