/* eslint-disable @next/next/no-img-element */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { PhotoIcon, XCircleIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { ButtonIcon, Dialog, Button as PrimitiveButton } from '@/client/components'
import type { ButtonVariantProps } from '@/client/components/Button/variants'
import { buttonVariants } from '@/client/components/Button/variants'
import { useImageUploader, type StudioImage } from '@/client/hooks/useImageUploader'
import { cn } from '@/client/utils'
import { Loader } from '@/common/components'
import { sanitizeImageURL } from '@/utils/image'
import * as mime from '@/utils/mime'

interface StudioImageUploaderContext {
  state: {
    isDragActive: boolean
    isUploading: boolean
  }
  data: {
    images: StudioImage[]
    selectedImage: StudioImage | null
  }
  set: {
    selectedImage: React.Dispatch<React.SetStateAction<StudioImage | null>>
  }
  fn: {
    handleLoad: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleRemove: (url: string) => void
  }
  config: {
    workspaceGuid?: string
    allowedMimeTypes: string[]
    allowedTypes: string[]
    enabled: boolean
  }
  id: string
}

const StudioImageUploaderContextImpl = React.createContext<StudioImageUploaderContext | undefined>(
  undefined
)

export const useStudioImageUploader = () => {
  const context = React.useContext(StudioImageUploaderContextImpl)
  if (!context) {
    throw new Error(
      'useStudioImageUploader must be used within a <StudioImageUploaderContext.Provider />'
    )
  }
  return context
}

export const Provider: React.FC<{
  images: string[]
  id: string
  config: {
    workspaceGuid?: string
    allowedMimeTypes?: string[]
    enabled: boolean
  }
  callbacks: {
    onAdded?: (files: File[]) => void | Promise<void>
    onUploaded: (urls: string[]) => void | Promise<void>
    onRemove: (url: string) => void | Promise<void>
  }
  children: (props: {
    state: StudioImageUploaderContext['state']
    data: StudioImageUploaderContext['data']
    config: {
      allowedTypes: string[]
      enabled: boolean
    }
    eventHandler: <T extends HTMLElement>() =>
      | {
          onDragEnter: (e: React.DragEvent<T>) => void
          onDragOver: (e: React.DragEvent<T>) => void
          onDragLeave: (e: React.DragEvent<T>) => void
          onDrop: (e: React.DragEvent<T>) => void
        }
      | undefined
  }) => JSX.Element
}> = ({ images, children, config, callbacks, id }) => {
  const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
  const [selectedImage, setSelectedImage] = React.useState<StudioImage | null>(null)

  const img: StudioImage[] = React.useMemo(
    () =>
      images
        .filter((x) => x !== '')
        .map((img, id) => ({
          id,
          blob: null,
          url: sanitizeImageURL(img),
        })),
    [images]
  )

  const hook = useImageUploader(img, {
    callbacks,
    config: {
      workspaceGuid: config.workspaceGuid,
      allowedMimeTypes,
    },
  })

  const eventHandler = <T extends HTMLElement>() =>
    config.enabled
      ? {
          onDragEnter: hook.handleDrag<T>,
          onDragOver: hook.handleDrag<T>,
          onDragLeave: hook.handleDrag<T>,
          onDrop: hook.handleDrop<T>,
        }
      : undefined

  return (
    <StudioImageUploaderContextImpl.Provider
      value={{
        state: {
          isDragActive: hook.isDragActive,
          isUploading: hook.images.some((i) => i.url === null),
        },
        data: {
          images: hook.images,
          selectedImage,
        },
        set: {
          selectedImage: setSelectedImage,
        },
        fn: {
          handleLoad: hook.handleLoad,
          handleRemove: hook.handleRemove,
        },
        config: {
          workspaceGuid: config.workspaceGuid!,
          allowedMimeTypes,
          allowedTypes: allowedMimeTypes.map((x) => mime.getAllExtensions(x)).flat(),
          enabled: config.enabled,
        },
        id,
      }}
    >
      {children({
        state: {
          isDragActive: hook.isDragActive,
          isUploading: hook.images.some((i) => i.url === null),
        },
        data: {
          images: hook.images,
          selectedImage,
        },
        config: {
          allowedTypes: allowedMimeTypes.map((x) => mime.getAllExtensions(x)).flat(),
          enabled: config.enabled,
        },
        eventHandler,
      })}
    </StudioImageUploaderContextImpl.Provider>
  )
}

const Gallery = (
  { visibleWhenDisabled, className }: { className?: string; visibleWhenDisabled?: boolean } = {
    visibleWhenDisabled: false,
  }
) => {
  const { data, state, set, id, fn, config } = useStudioImageUploader()
  const [wantToDelete, setWantToDelete] = React.useState(false)
  const [isHovering, setIsHovering] = React.useState('')

  const uploadedImages = data.images.filter((i) => i.url !== null && i.url !== '')

  const galleryModal = {
    selectNext: (index: number) => {
      const nextImage = data.images[index + 1]
      if (!nextImage) {
        set.selectedImage(data.images[0] ?? null)
      } else {
        set.selectedImage(nextImage)
      }
    },
    selectPrevious: (index: number) => {
      const prevImage = data.images[index - 1]
      if (!prevImage) {
        set.selectedImage(data.images[data.images.length - 1] ?? null)
      } else {
        set.selectedImage(prevImage)
      }
    },
  }

  React.useEffect(() => {
    if (data.selectedImage === null) return
    if (data.selectedImage.url === null) return

    const index = data.images.findIndex((i) => i.url === data.selectedImage?.url)

    if (index === -1) return

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault()
        galleryModal.selectPrevious(index)
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault()
        galleryModal.selectNext(index)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      if (!data.selectedImage) return
      window.removeEventListener('keydown', handleKeyDown)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.selectedImage, data.images])

  const render = () => (
    <div
      key={`studio-image-gallery-${id}`}
      className={cn('flex gap-3 w-full pb-3', data.images.length > 0 ? className : [])}
    >
      {data.images.map((file, i) =>
        state.isDragActive ? (
          <div key={`floating-${id}-${i}`} className="relative w-20 h-20 shadow-md drop-shadow-md">
            {file.blob === '' ? (
              <div key={i} className="w-20 h-20 bg-gray-400 rounded-md" />
            ) : (
              <img
                src={file.blob ?? file.url}
                className={cn('w-20 h-20 rounded-md object-cover')}
                alt={'Preview'}
              />
            )}
          </div>
        ) : (
          <Dialog.Root
            key={`file-${id}-${i}`}
            open={Boolean(data.selectedImage && data.selectedImage.url === file.url)}
            onOpenChange={(open) => {
              set.selectedImage(open ? file : null)
              setWantToDelete(false)
            }}
          >
            <Dialog.Trigger
              className={cn(
                'relative w-20 h-20 shadow-md drop-shadow-md focus-visible:outline-none focus-visible:ring-0 will-change-auto',
                {
                  'overflow-hidden': file.blob !== null && file.url === '',
                }
              )}
              disabled={file.blob !== null && file.url === ''}
              onMouseEnter={() => setIsHovering(file.url)}
              onMouseLeave={() => setIsHovering('')}
            >
              {file.blob !== null && file.url === '' ? (
                <div className="absolute z-10 flex w-full h-full bg-white/70">
                  <Loader className="w-6 h-6 m-auto" />
                </div>
              ) : null}
              <img
                src={file.blob ?? file.url}
                className={cn('w-20 h-20 rounded-md object-cover')}
                alt={'Preview'}
              />
              {file.url !== '' && isHovering === file.url ? (
                <XCircleIcon
                  className="w-6 h-6 cursor-pointer text-red-700 absolute bg-white -top-1.5 -right-1.5 rounded-full"
                  onClick={() => void fn.handleRemove(file.url)}
                />
              ) : null}
            </Dialog.Trigger>
            <Dialog.Content className="max-w-lg gap-0 space-y-0 overflow-hidden bg-white min-h-3xl ring-1 ring-grey-300/50 will-change-auto">
              <Dialog.Header className="flex flex-row items-center justify-between px-6 py-4">
                <Dialog.Title asChild>
                  <h3 className="title3 grow">Preview</h3>
                </Dialog.Title>
                {uploadedImages.length > 1 ? (
                  <p className="text-sm text-grey-400">
                    {i + 1} / {uploadedImages.length}
                  </p>
                ) : null}
              </Dialog.Header>

              <div className="flex flex-col">
                <div className="relative w-full h-[500px] flex bg-gray-100 border-t border-grey-200">
                  <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex w-full h-full">
                    {uploadedImages.length > 1 ? (
                      <React.Fragment>
                        <PrimitiveButton
                          variant="outline"
                          className="z-10 my-auto ml-3 mr-auto rounded-full text-black/80"
                          onClick={() => galleryModal.selectPrevious(i)}
                        >
                          <ChevronLeftIcon strokeWidth={3} className="w-4 h-4" />
                        </PrimitiveButton>
                        <PrimitiveButton
                          className="z-10 my-auto ml-auto mr-3 rounded-full text-black/80"
                          variant="outline"
                          onClick={() => galleryModal.selectNext(i)}
                        >
                          <ChevronRightIcon strokeWidth={3} className="w-4 h-4" />
                        </PrimitiveButton>
                      </React.Fragment>
                    ) : null}
                  </div>
                  {data.selectedImage ? (
                    <img
                      src={data.selectedImage.blob ?? data.selectedImage.url}
                      className="max-w-[calc(90%)] w-auto h-fit max-h-[calc(90%)] m-auto object-contain shadow-md drop-shadow-md"
                      alt={'Preview'}
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-5 overflow-hidden border-t border-grey-200 bg-grey-50">
                <PrimitiveButton
                  variant="destructive"
                  disabled={data.selectedImage === null}
                  onClick={() => {
                    if (!wantToDelete) return setWantToDelete(true)
                    set.selectedImage(data.images[i + 1] ?? null)
                    if (!data.selectedImage) return
                    if (!data.selectedImage.url) return
                    void fn.handleRemove(data.selectedImage.url)
                    setWantToDelete(false)
                  }}
                >
                  {wantToDelete ? 'Click again to delete' : 'Delete'}
                </PrimitiveButton>

                <PrimitiveButton
                  variant="outline"
                  onClick={() => {
                    set.selectedImage(null)
                  }}
                >
                  Close
                </PrimitiveButton>
              </div>
            </Dialog.Content>
          </Dialog.Root>
        )
      )}
    </div>
  )

  return visibleWhenDisabled ? render() : config.enabled ? render() : null
}

const Button = (
  {
    className,
    allowMultiple,
    visibleWhenDisabled,
    ...rest
  }: {
    variant?: ButtonVariantProps['variant']
    className?: string
    allowMultiple?: boolean
    visibleWhenDisabled?: boolean
  } = {
    allowMultiple: true,
    visibleWhenDisabled: false,
  }
) => {
  const { fn, id, config } = useStudioImageUploader()

  const render = () => (
    <label
      key={`studio-image-button-uploader-label-${id}`}
      htmlFor={`studio-image-button-uploader-${id}`}
    >
      <span
        className={cn(
          buttonVariants({
            variant: rest.variant ?? 'outline',
            size: 'xs',
            className: 'w-9 cursor-pointer',
          }),
          className
        )}
      >
        <ButtonIcon size="xs" icon={PhotoIcon} className="opacity-80" />
      </span>
      <input
        key={`studio-image-button-uploader-${id}`}
        accept={config.allowedMimeTypes.join(',') || undefined}
        type="file"
        multiple={allowMultiple}
        onChange={fn.handleLoad}
        className="hidden"
        id={`studio-image-button-uploader-${id}`}
      />
    </label>
  )

  return visibleWhenDisabled ? render() : config.enabled ? render() : null
}

export const StudioImageUploader = {
  Provider: React.memo(Provider),
  Gallery: React.memo(Gallery),
  Button: React.memo(Button),
}
