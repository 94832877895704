import { Combobox } from '@/client/components'
import { useCustomField } from '@/client/hooks'
import { useEditVersionEnvironmentForm } from './form'
import type { UseEditVersionEnvironmentFormProps } from './types'

export const ActionVersionEnvironmentInlineEditForm = ({
  workspace,
  action,
  version,
  onIsOpenChange,
  environments,
}: UseEditVersionEnvironmentFormProps) => {
  const { formik, onUndeploy } = useEditVersionEnvironmentForm({
    workspace,
    action,
    version,
    onIsOpenChange,
    environments,
  })
  const { values, handleSubmit } = formik
  const { handleChangeField, getFieldError } = useCustomField({ formik })

  const environmentError = getFieldError('environmentIds')

  const options = environments.map((env) => {
    return {
      label: env.name,
      value: env.id.toString(),
    }
  })

  async function handleSubmitOnChange(environments: string[]) {
    // get the difference between the current and new environments
    const newEnvironments = environments.map((i) => parseInt(i))
    const currentEnvironments = values.environmentIds
    const toAdd = newEnvironments.filter((i) => !currentEnvironments.includes(i))
    const toRemove = currentEnvironments.filter((i) => !newEnvironments.includes(i))

    // call the undeploy function for each environment to remove
    for (const envId of toRemove) {
      void onUndeploy(envId)
    }

    // update the form
    await handleChangeField('environmentIds', toAdd)
    handleSubmit()
  }

  return (
    <div className="flex flex-row space-x-2 items-center">
      <div className="max-w-[300px] min-w-[150px]">
        <Combobox.Multiple
          hideTags={false}
          onRemove={(option) => {
            const envIds = values.environmentIds.filter((i) => i !== parseInt(option.value))
            void formik.setFieldValue(
              'environmentIds',
              envIds.map((i) => i.toString())
            )
            void onUndeploy(parseInt(option.value))
          }}
          hasError={!!environmentError}
          message={environmentError}
          className="font-inter"
          label=""
          hideLabel
          placeholder="Deploy version to environments"
          options={options}
          value={values.environmentIds.map((i) => i.toString())}
          onChange={(value) => void handleSubmitOnChange(value)}
          noSearch
          fitHeightToContent
        />
      </div>
    </div>
  )
}
